import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { UsersProcessorService } from '../processors/users-processor.service'
import { GlobalState } from '../../../app.state'

@Injectable()
export class UsersService extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async getUser (userId: string): Promise<UsersSchema> {
    const response = await this.get(`api/admin_api/user/${userId}`)
    if (typeof response.code == 'number' && response.code >= 400) {
      throw Error(response.message || '')
    }
    return response.success && UsersProcessorService.process(response.user) as UsersSchema
  }

  async updateUser (user: Partial<UsersSchema>): Promise<boolean> {
    const body = JSON.stringify(user)
    const response: Users.Response = await this.post(`api/admin_api/update_user`, body, true, 'application/json')
    if (typeof response.code == 'number' && response.code >= 400) {
      throw Error(response.message || '')
    }
    return response.success
  }

  async createNewUser (user: Partial<UsersSchema>): Promise<boolean> {
    const body = JSON.stringify(user)
    const response: Users.Response = await this.post(`api/admin_api/create_user`, body, true, 'application/json')
    if (typeof response.code == 'number' && response.code >= 400) {
      const message = response.message && response.message.includes('duplicate key') ? 'User email is already Auth0 registered' : ''
      throw Error(message)
    }
    return response.success
  }

  async getUsers (options: Users.RequestOptions): Promise<IfDefined<{ numOfAvailableDocs: number, users: UsersSchema[] }>> {
    const queryString = MethodsService.objectToQueryString(options)
    const response = await this.get(`api/admin_api/users?${queryString}`)
    if (typeof response.code == 'number' && response.code >= 400) {
      throw Error(response.message || '')
    }
    return response.success && {
      numOfAvailableDocs: response.count,
      users: UsersProcessorService.process(response.users) as UsersSchema[]
    }
  }

  async deleteUsers (usersIds: string[]): Promise<boolean> {
    const body = JSON.stringify({ usersIds })
    const response = await this.post(`api/admin_api/delete_users`, body, true, 'application/json')
    if (typeof response.code == 'number' && response.code >= 400) {
      throw Error(response.message || '')
    }
    return response.success
  }

}

