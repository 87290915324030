export class PiCustomerPoliciesProcessorService {
  static processPolicies<T extends ExtendedPolicySchema> (data: T[]): T[] {
    return data.map(this.processPolicy)
  }

  private static processPolicy<T extends ExtendedPolicySchema> (data: T): T {
    data.policies.forEach(policy => {
      policy._rules = getPoliciesIcon(policy)
      policy._isActiveAndHasAllInactiveRules = policy.active && policy.policyRules.every(p => !p.active)
    })
    // data.policies.sort((a, b) => b.updatedAt.toString().localeCompare(a.updatedAt.toString()))
    data.policies.sort((a, b) => a.domain.localeCompare(b.domain))
    data._numOfActivePolicies = data.policies.filter(policy => policy.active && policy.policyRules.some(p => p.active)).length
    return data
  }

}

function getPoliciesIcon (policy: PolicySchema): { name: string, icon: string }[] {
  return policy.policyRules
    .filter(rule => rule.active)
    .map(getPolicyRuleIcon)
    .sort((a, b) => a.name.localeCompare(b.name))
}

function getPolicyRuleIcon (policy: CxPolicySyntax.ResolvedPolicyRule): { name: string, icon: string } {
  switch (policy.policyType) {
    case CxPolicySyntax.ResolvedPolicyType.dom:
      return { icon: 'mdi mdi-language-html5', name: 'DOM' }
    case CxPolicySyntax.ResolvedPolicyType.cookie:
      return { icon: 'mdi mdi-cookie', name: 'Cookie' }
    case CxPolicySyntax.ResolvedPolicyType.storage:
      return { icon: 'mdi mdi-database', name: 'Storage' }
    case CxPolicySyntax.ResolvedPolicyType.sensitiveArea:
      return { icon: 'mdi mdi-credit-card-multiple', name: 'Sensitive Areas' }
    case CxPolicySyntax.ResolvedPolicyType.thirdPartyOutbound:
      return { icon: 'mdi mdi-web', name: 'Third Party Outbound' }
  }
}
