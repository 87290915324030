import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { MethodsService, NUMERIC_SORT_ARRAY } from '../../services/methods/methods.service'
import { LoggerService } from '../../services/logger/logger.service'
import { GlobalNotification, GlobalState } from '../../app.state'
import { CxConsts } from '../../shared/typings/chameleonx.typings'
import { PICollectedInputsService } from '../../services/api/services/collected-inputs.service'
import { PITenantsService } from '../../services/api/services/pi-tenants.service'

@Component({
  selector: 'pi-collected-inputs',
  templateUrl: './collected-inputs.component.html',
  styleUrls: ['./collected-inputs.component.scss']
})

export class PICollectedInputsComponent extends TableWithFilters implements OnInit, OnDestroy {

  readonly title = 'Collected Inputs'

  readonly ACTIONS: Record<CollectedInputAction, CollectedInputAction> = {
    [CollectedInputAction.NONE]: CollectedInputAction.NONE,
    [CollectedInputAction.DEFER]: CollectedInputAction.DEFER,
    [CollectedInputAction.ACCEPT]: CollectedInputAction.ACCEPT,
    [CollectedInputAction.DECLINE]: CollectedInputAction.DECLINE
  }

  readonly PROBABILITIES: Record<CollectedInputProbability | 'UNKNOWN', CollectedInputProbability | 'UNKNOWN'> = {
    'UNKNOWN': 'UNKNOWN',
    [CollectedInputProbability.NONE]: CollectedInputProbability.NONE,
    [CollectedInputProbability.LOW]: CollectedInputProbability.LOW,
    [CollectedInputProbability.MEDIUM]: CollectedInputProbability.MEDIUM,
    [CollectedInputProbability.HIGH]: CollectedInputProbability.HIGH
  }

  //selected tenant
  selectedMappedTenant: MappedTenant
  selectedTenant: { id: string, name: string }[]
  associatedTenants: MappedTenants = []

  inputs: AggregatedCollectedInput[] = []
  selectedInput: AggregatedCollectedInput

  //table
  readonly tableHeaders: TableHeader[]

  filterValue: string = ''

  currentSortBy: string

  paginationName = 'aggregated signatures'

  textToClip = MethodsService.copyToClipboard

  normalizeString = MethodsService.normalizeString

  integerWithCommas = MethodsService.integerWithCommas

  TYPES = Object.keys(CxConsts.TYPES)

  SUB_TYPES = Object.keys(CxConsts.SUB_TYPES)

  //types filter
  types: (SensitiveAreaTypeName | 'UNKNOWN')[] = [...Object.keys(CxConsts.TYPES), 'UNKNOWN'].sort() as (SensitiveAreaTypeName | 'UNKNOWN')[]
  currentTypes = this.types

  //sub types filter
  subTypes: (SensitiveAreaSubTypeName | 'UNKNOWN')[] = [...Object.keys(CxConsts.SUB_TYPES), 'UNKNOWN'].sort() as (SensitiveAreaSubTypeName | 'UNKNOWN')[]
  currentSubTypes = this.subTypes

  //probability filter
  probabilities: CollectedInputProbability[] = Object.keys(this.PROBABILITIES).sort() as CollectedInputProbability[]
  currentProbabilities = this.probabilities

  //actions filter
  actions: CollectedInputAction[] = Object.keys(this.ACTIONS).sort() as CollectedInputAction[]
  currentActions = this.actions

  //numeric filter
  numericTypes = NUMERIC_SORT_ARRAY
  minTimesSeenValue = 20

  //signatures filter
  signatures: Set<string> = new Set()

  private _elements: Set<HTMLElement> = new Set()

  private _debounce: void | number
  private debounce_timeout: number = 1000

  _isRefreshing: boolean = false

  hashChanges: Map<string, CollectedInputAction> = new Map()

  signatureChanges: Map<string, { [K in CollectedInputsAllowedPropertiesModification]?: CollectedInputsSchema[K] }> = new Map()

  constructor (private _inputs: PICollectedInputsService, private _customers: PITenantsService, private _zone: NgZone, private _state: GlobalState) {
    super()
    this.currentItemsPerPage = this.itemsPerPageFilter[1]
    this.tableHeaders = [
      //@formatter:off
      { name: 'Signature',            sortable: false,  field: 'signature'        },
      { name: 'Last Updated',         sortable: true,   field: 'selectors.updatedAt'},
      { name: 'Popularity',           sortable: true,   field: 'totalInspections' },
      { name: 'Comment',              sortable: true,   field: 'comment'          },
      { name: 'Probability',          sortable: true,   field: 'probability'      },
      { name: 'Suggested Type',       sortable: true,   field: 'suggestedType'    },
      { name: 'Suggested Sub Type',   sortable: true,   field: 'suggestedSubType' },
      { name: 'Type',                 sortable: true,   field: 'type'             },
      { name: 'Sub Type',             sortable: true,   field: 'subType'          },
      { name: 'Selectors',            sortable: false,  field: ''                 },
      { name: 'Action',               sortable: false,  field: 'action'           },
      //@formatter:on
    ].filter(x => !!x)
    this.currentSortBy = this.tableHeaders[2].field
  }

  ngOnDestroy (): void {
    window.removeEventListener('keyup', this.registerModalListeners.bind(this))
  }

  ngOnInit (): void {
    window.addEventListener('keyup', this.registerModalListeners.bind(this))
    this.refreshTable!()
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    if (!this.selectedInput) {
      return
    }
    if (key === 'Escape') {
      this.selectedInput = undefined
    }
  }

  onFilterChange (item: any) {
    if (this._debounce) {
      this._debounce = clearTimeout(this._debounce)
    }
    this._debounce = setTimeout(() => this.refreshTable(), this.debounce_timeout) as any as number
  }

  private clearState () {
    this.selectedInput = undefined
    this._elements.forEach(element => element.remove())
    this._elements.clear()
    this.hashChanges.clear()
    this.signatureChanges.clear()
  }

  async refreshTable (background: boolean = false): Promise<void> {
    if (this._isRefreshing) {
      return
    }
    this._isRefreshing = true
    try {
      if (!background) {
        SpinnerService.spin('mini')
        this.clearState()
      }

      if (background && this._elements.size) {
        return
      }

      const [{ data, count }, allTenants] = await Promise.all([
        this.getCollectedInputs(),
        this._customers.getMappedCustomers()
      ])

      this.associatedTenants = allTenants.filter(tenant => !!tenant.clusterId)

      //validate
      if (data && typeof count === 'number') {
        this.numOfAvailableDocs = count
        this.inputs = data
        if (!background) {
          LoggerService.info('Aggregated Inputs:', this.inputs)
        }
      }
    } catch (e) {
      if (!background) {
        MethodsService.toast('error', 'Error fetching modules', e.toString(), 8)
      }
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._zone.run(() => {})
      this._isRefreshing = false
    }
  }

  async getCollectedInput (event: Event, _id: string, signature: string) {
    try {
      SpinnerService.spin('mini')
      const module = await this._inputs.getBySignature(_id, signature)
      if (module) {
        this.selectedInput = module
        LoggerService.info('Input: ', this.selectedInput)
      }
    } catch (e) {
      MethodsService.toast('error', `Error fetching module id ${_id}`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
      this._zone.run(() => {})
    }
  }

  async applyChanges () {
    const payload = this.getChangesPayload

    try {
      SpinnerService.spin('mini')
      const success = await this._inputs.setProperties(this.selectedTenant[0].id, payload)

      if (!success) {
        throw Error('')
      }

      this.refreshTable()
    } catch (e) {
      MethodsService.toast('error', 'Error fetching modules', e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }
  }

  private async getCollectedInputs (options?: FilterArguments<GetAllBundledModulesFilters & any>): Promise<CountedResultObject<AggregatedCollectedInput[]>> {
    options = options || {
      from_date: this.currentTime.time(),
      filter: this.filterValue,
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sort_by: this.currentSortBy,
      sort_direction: this.isSortReversed ? 'asc' : 'desc',
      //CollectedInputsAggregationFilters
      type: this.currentTypes.length && this.currentTypes || null,
      action: this.currentActions.length && this.currentActions || null,
      subType: this.currentSubTypes.length && this.currentSubTypes || null,
      probability: this.currentProbabilities.length && this.currentProbabilities || null,
      signatures: [...this.signatures],
      min_times_seen: [this.currentNumericType.data, this.minTimesSeenValue]
    }

    if (this.selectedTenant && this.selectedTenant.length) {
      this.selectedMappedTenant = this.associatedTenants.find(t => t.id == this.selectedTenant[0].id)!
    } else {
      this.selectedMappedTenant = undefined
    }
    return this.selectedMappedTenant ? (await this._inputs.getAggregatedSignatures(this.selectedMappedTenant.id, options)) : ({} as any)
  }

  toggleInputView (event: any, input: AggregatedCollectedInput) {
    if (event.target.tagName !== 'TD') {
      return
    }

    const _id = `tr_${input.signature}`

    const ele_exists = document.getElementById(_id)

    //close
    if (ele_exists) {
      ele_exists.remove()
      this._elements.delete(ele_exists)
      input.is_open = false
      return
    }

    if (input.is_open) {
      input.is_open = false
      return
    }

    let _tr: IfDefined<HTMLTableRowElement>
    let _e = event.target

    do {
      if (_e.tagName == 'TR') {
        _tr = _e
        break
      }
      _e = _e.parentElement
    } while (!_tr || !_e)

    if (!_tr) {
      return
    }

    const tr = document.createElement('tr')
    tr.id = _id

    const td = document.createElement('td')
    td.setAttribute('colspan', this.tableHeaders.length + '')
    tr.appendChild(td)

    const atrs = !Object.keys(input.attributes || {}).length ? null :
      Object
        .entries(input.attributes)
        .flatMap(([k, v]) => `${k}: ${v}`)
        .join(`&emsp;`.repeat(6))

    td.innerHTML = `
        <div class="card bg-gray-45 ph-65px pv-25px">
          ${atrs ? `<p class="font-size-12 font-weight-bold"><span class="font-size-14 font-weight-bolder text-underline">Input attributes:</span>${'&emsp;'.repeat(12)}<span class="font-size-14 font-weight-bolder">Seen ${this.integerWithCommas(input.totalInspections)} times</span>${'&emsp;'.repeat(12)}${atrs}</p>` : ''}
          <div class="row p-2 font-size-14">
            ${[['Selector', 3], ['Hash', 2], ['First Seen'], ['Last Seen'], ['Match'], ['Popularity'], ['Urls'], ['Action'], ['Suggested Action']].map(([h, c = '1']) => `<div class="col-${c} p-2 font-size-14 font-weight-bolder top-6px">${h}</div>`).join('')} 
          </div>
          ${input.selectors
      .sort((a, b) => a.signatureRatio > b.signatureRatio ? -1 : a.signatureRatio < b.signatureRatio ? 1 : 0) //descending by match
      .map(sel => `
            <div class="row p-1 font-size-12 hover-gray-lighter" style="border-top: 1px dotted black">
                                    <i id="cp_${sel.hash}" class="fa fa-copy color-accent font-size-12 absolute mt-7px hover-zoom cursor-pointer" title="copy" style="margin-left: -7px"></i>&emsp;
                <!--Selector -->    <div class="col-3 pv-5px text-truncate"><span class="cursor-help" title="${sel.selector}">${sel.selector}</span></div>
                <!--Hash -->        <div class="col-2 pv-5px">${sel.hash}</div>
                <!--First Seen -->  <div class="col-1 pv-5px"><i class="fa fa-clock-o color-accent" title="${input.first_seen.dts}"></i>&nbsp;${input.first_seen.dateAgo}</div>
                <!--Last Seen -->   <div class="col-1 pv-5px"><i class="fa fa-clock-o color-accent" title="${input.last_seen.dts}"></i>&nbsp;${input.last_seen.dateAgo}</div>
                <!--Match -->       <div class="col-1 pv-5px">${sel.signatureRatio}%</div>
                <!--Popularity -->  <div class="col-1 pv-5px">${sel.signaturePopularity} <i class="fa fa-info-circle color-accent font-size-12 cursor-help" title="${sel.globalRatio}% of total views (seen ${this.integerWithCommas(sel.timesSeen)} times)"></i></div>
                <!--Urls -->        <div class="col-1 pv-5px">${sel.urls.length} <i class="fa fa-info-circle color-accent font-size-12 cursor-help" title="${sel.urls.join('\n')}"></i></div>
                <!--Action -->      <div class="col-1 pv-5px">                                   
                                        <select class="form-control" name="actions" id="act_${sel.hash}">
                                            ${Object.keys(this.ACTIONS).filter(x => sel.action == CollectedInputAction.NONE ? true : x != CollectedInputAction.NONE).map(action => `
                                             <option value="${action}" ${action == sel.action ? 'selected' : ''}>${action}${action == sel.suggested_action ? `<span class="color-accent font-size-11">&nbsp;&nbsp;*</span>` : ''}</option>
                                            `)}
                                        </select>
                                    </div>
                <!--s_Action -->    <div class="col-auto pv-5px">${sel.suggested_action || ''}</div>

            </div>
          `).join('')} 
        </div>`

    _tr.parentElement!.insertBefore(tr, _tr.nextSibling)
    this._elements.add(tr)

    input.selectors.forEach(sel => {
      const action_element = document.getElementById(`act_${sel.hash}`)
      if (action_element) {
        action_element.addEventListener('change', (e) => {

          const _action = (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].value as CollectedInputAction

          if (_action == sel.action) {
            return this.hashChanges.delete(sel.hash)
          }

          this.hashChanges.set(sel.hash, _action)
        })
      }

      const copy_element = document.getElementById(`cp_${sel.hash}`)
      copy_element.addEventListener('click', () => MethodsService.copyToClipboard(sel.selector))

    })

    input.is_open = true

    this._zone.run(() => {})
  }

  editSignature (e: any, signature: AggregatedCollectedInput, atr: CollectedInputsAllowedPropertiesModification) {
    const el = e.target as HTMLSelectElement

    const value: any = atr == 'comment' ?
      el.value :
      el.options[el.selectedIndex].value || null

    const _doc = this.signatureChanges.get(signature.signature)

    if (value == signature[atr] || (signature[atr] == null && value == '')) {
      if (_doc) {
        delete _doc[atr]
        if (!Object.keys(_doc).length) {
          this.signatureChanges.delete(signature.signature)
        }
      }
      return
    }

    if (_doc) {
      (_doc as any)[atr] = value
    } else {
      this.signatureChanges.set(signature.signature, { [atr]: value })
    }
  }

  private get getChangesPayload (): CollectedInputsPOSTRequest {

    const res: CollectedInputsPOSTRequest = { collectedInputs: [] }

    for (const [signature, changes] of this.signatureChanges) {
      res.collectedInputs.push({ signature, ...changes })
    }

    for (const [hash, action] of this.hashChanges) {
      const signature = this.inputs.find(ci => ci.selectors.some(s => s.hash == hash))!.signature
      const _exists_doc = res.collectedInputs.find(ci => ci.signature == signature)
      if (_exists_doc) {
        _exists_doc.selectors = _exists_doc.selectors || []
        _exists_doc.selectors.push({ hash, action })
      } else {
        res.collectedInputs.push({ signature, selectors: [{ hash, action }] })
      }

    }

    return res
  }

}
