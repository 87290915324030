import { MethodsService } from '../../methods/methods.service'

function k8sServiceDeploymentLink (instanceId: string, deployment: string): string {
  return `https://${instanceId}-dashboard.pilayer.net/#/deployment/app/${deployment}?namespace=app`
}

export function azureQueueServiceLink (instanceId: string, queueName: string): string {
  const short =
    'pi' +
    instanceId
      .split('-')
      .filter(s => /^\d/igm.test(s))
      .join('')
      .replace(/[^\d]/igm, '')
    + 'queue'
  return `https://portal.azure.com/#blade/Microsoft_Azure_Storage/QueueMenuBlade/overview/storageAccountId/%2Fsubscriptions%2F90806317-8b28-4eb0-b893-6db8f83710e0%2FresourceGroups%2F${instanceId}%2Fproviders%2FMicrosoft.Storage%2FstorageAccounts%2F${short}/queueName/${queueName}`
}

export class PiQueuePanelProcessorService {
  static process (queueOrQueues: UIScaledResourcesResultAny | UIScaledResourcesResultAny[]): QueuePanelItem | QueuePanelItem[] {

    return Array.isArray(queueOrQueues) ?
      queueOrQueues.map(PiQueuePanelProcessorService.processQueue) :
      PiQueuePanelProcessorService.processQueue(queueOrQueues)

  }

  private static processQueue (item: UIScaledResourcesResultAny): QueuePanelItem {
    const panelItem: QueuePanelItem = {
      _id: item.instanceId + '#' + item.queueName,
      instanceId: item.instanceId,
      queueName: item.queueName,
      count: item.currentCount,
      isTracked: item.isTracked
    }

    const { _class, title } = getHealth(item.currentCount)
    panelItem.healthColor = _class
    panelItem.healthTitle = title

    if (item.isTracked) {
      panelItem.pods = item.trend[item.trend.length - 1].replicasCount
      panelItem.trend = item.trend
      panelItem.namespace = item.namespace
      panelItem.deployment = item.deployment
      panelItem.updatedAt = new Date(item.updatedAt)

      const queueCountLast10PercentOfArrayAvg = Math.round(MethodsService.average(item.trend.slice(Math.floor(item.trend.length * 0.9)).map(t => t.queueCount)))
      panelItem.queueTrendClass = getTrendClass(panelItem.count, queueCountLast10PercentOfArrayAvg)

      if (panelItem.trend.length > 1) {
        const podsLast10PercentOfArrayAvg = Math.round(MethodsService.average(item.trend.slice(Math.floor(item.trend.length * 0.9)).map(t => t.replicasCount)))
        panelItem.podsTrendClass = getTrendClass(panelItem.pods, podsLast10PercentOfArrayAvg)
      }

      panelItem.trend.forEach(t => {
        t.timestamp = new Date(t.timestamp)
      })

      const { _class, title } = getConnectionHealth(panelItem.updatedAt)
      panelItem.connectionHealthColor = _class
      panelItem.connectionHealthTitle = title

      panelItem.k8sDashboardLink = k8sServiceDeploymentLink(panelItem.instanceId, panelItem.deployment)
    }

    panelItem.azureQueueLink = azureQueueServiceLink(item.instanceId, item.queueName)
    return panelItem
  }

}

function getTrendClass (current: number, previous: number, delta = 0.10): string {
  const _class = `font-size-20 relative font-weight-bolder mdi mdi-trending-`
  const up = `${_class}up color-cx-accent-dark`
  const down = `${_class}down color-cx-green top-6px`
  const neutral = `${_class}neutral color-cx-accent top-3px`
  const cur_up = Math.round(current * (1 - delta))
  const cur_down = Math.round(current * (1 + delta))
  return cur_up > previous ? up : cur_down < previous ? down : neutral
}

const fatal_threshold = 100000
const high_threshold = 30000
const medium_threshold = 15000
const low_threshold = 7500

function getHealth (current: number): { title: string, _class: string } {
  const _p = 'color-'
  if (current >= fatal_threshold) {
    return { _class: _p + 'indigo', title: 'Overloaded' }
  }
  if (current >= high_threshold) {
    return { _class: _p + 'red', title: 'Under Pressure' }
  }
  if (current >= medium_threshold) {
    return { _class: _p + 'yellow', title: 'Loaded' }
  }
  if (current >= low_threshold) {
    return { _class: _p + 'lemon-green', title: 'Steady' }
  }
  return { _class: _p + 'green', title: 'Healthy' }
}

function getConnectionHealth (lastTimestamp: Time): { title: string, _class: string } {
  const now = Date.now()
  const _l = new Date(lastTimestamp).getTime()
  const diff = now - _l
  const offline_minutes = 1000 * 60 * 30
  const unstable_minutes = 1000 * 60 * 15
  const delay_minutes = 1000 * 60 * 5
  const delayed_minutes = 1000 * 60 * 2.5

  const _p = 'color-'

  if (diff >= offline_minutes) {
    return { _class: _p + 'indigo', title: 'Offline' }
  }

  if (diff >= unstable_minutes) {
    return { _class: _p + 'red', title: 'Unstable' }
  }

  if (diff >= delay_minutes) {
    return { _class: _p + 'yellow', title: 'Delayed' }
  }

  if (diff >= delayed_minutes) {
    return { _class: _p + 'lemon-green', title: 'Alive' }
  }

  return { _class: _p + 'green', title: 'Online' }
}
