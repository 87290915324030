import { Component, NgZone, OnInit } from '@angular/core'
import { UserDataService } from '../../services/user-data/user-data.service'
import { GlobalState } from '../../app.state'
import { PITenantsService } from '../../services/api/services/pi-tenants.service'
import { MethodsService } from '../../services/methods/methods.service'

@Component({
  selector: '',
  templateUrl: './pi-event-group.component.html',
  styleUrls: ['./pi-event-group.component.scss']
})

export class PiEventGroupComponent implements OnInit {
  readonly _storageKey = 'EventGroupPage_lastVisitedEventGroups'
  readonly title = 'CPC Event Console'

  exampleHref = '/event_group?customerId=5eb98f27f913a30d77a1d98e&eventGroupId=4b5d7161'

  lastVisitedEventGroups: { customerId: string, eventGroupId: string, timestamp: number }[] = []

  customerId: string
  eventGroupId: string

  location = location

  cxDateFormat = MethodsService.cxDateFormat

  constructor (private _userData: UserDataService, private _tenants: PITenantsService, private _zone: NgZone, private _state: GlobalState) {
    this.fetchStorage()
  }

  async ngOnInit () {
    const url = new URL(location.href)
    this.customerId = ['customerId', 'customer_id', 'tenantId', 'tenant_id']
      .map(key => url.searchParams.get(key)).filter(Boolean)[0]
    this.eventGroupId = ['eventGroupId', 'event_group_id', 'incidentId', 'incident_id']
      .map(key => url.searchParams.get(key)).filter(Boolean)[0]

    if (this.customerId && this.eventGroupId) {
      this.fetchStorage()
      this.setStorage()
    }
  }

  buildHref (customerId: string, eventGroupId: string): string {
    return `/event_group?customerId=${customerId}&eventGroupId=${eventGroupId}`
  }

  removeLastVisitedEventGroup (customerId: string, eventGroupId: string) {
    this.lastVisitedEventGroups = this.lastVisitedEventGroups.filter(({
      customerId: cId,
      eventGroupId: eId
    }) => cId !== customerId || eId !== eventGroupId)
    this.prepareData()
    localStorage.setItem(this._storageKey, JSON.stringify(this.lastVisitedEventGroups))
  }

  fetchStorage () {
    const db = localStorage.getItem(this._storageKey)
    if (db) {
      this.lastVisitedEventGroups = JSON.parse(db)
    }
  }

  setStorage () {
    this.lastVisitedEventGroups.push({
      customerId: this.customerId,
      eventGroupId: this.eventGroupId,
      timestamp: Date.now()
    })
    this.prepareData()
    localStorage.setItem(this._storageKey, JSON.stringify(this.lastVisitedEventGroups))
  }

  prepareData () {
    this.lastVisitedEventGroups.sort((a, b) => b.timestamp - a.timestamp)
    const tuples: Set<string> = new Set()
    this.lastVisitedEventGroups = this.lastVisitedEventGroups.filter(({ customerId, eventGroupId }) => {
      const tuple = `${customerId}-${eventGroupId}`
      if (tuples.has(tuple)) {
        return false
      }
      tuples.add(tuple)
      return true
    })

  }

}

