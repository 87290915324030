export class AgentLibrariesProcessor {
  static process<T extends AgentLibModulesSchema> (docOrDocs: T | T[]): T | T[] {
    return Array.isArray(docOrDocs) ?
      docOrDocs.map(AgentLibrariesProcessor.processModule) as T[] :
      AgentLibrariesProcessor.processModule(docOrDocs) as T

  }

  private static processModule<T extends AgentLibModulesSchema> (lib: T): T {
    (lib as any)._modified = lib.createdAt != lib.updatedAt

    return lib
  }

}

