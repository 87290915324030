import { Injectable } from '@angular/core'
import { EventEmitter } from 'events'

export enum GlobalNotification {
  AUTHENTICATED,
  USER_DATA_INITIATE,
  USER_DATA_CHANGED,
  USER_DATA_DROP,
  CUSTOMER_CHANGED,
  TOKEN_REFRESH,
  SIDEBAR_COLLAPSED,
  SIGN_OUT,
  BACKGROUND_REFRESH,
  LOGIN_ERROR
}

@Injectable()
export class GlobalState extends EventEmitter {

  constructor () {
    super()
  }

  notify (event: GlobalNotification, value: any) {
    this.emit(event as any, value)
  }

  subscribe (event: GlobalNotification, callback: any) {
    if (event == GlobalNotification.BACKGROUND_REFRESH) {
      this.removeAllListeners(GlobalNotification.BACKGROUND_REFRESH as any)
    }

    this.addListener(event as any, callback)
  }

  unsubscribe (event: GlobalNotification, callback: any) {
    this.removeListener(event as any, callback)
  }

}
