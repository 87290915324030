export namespace CxConsts {

  export const TYPES: Record<SensitiveAreaTypeName, SensitiveAreaTypeName> = {
    [SensitiveAreaTypeName.CREDENTIALS]: SensitiveAreaTypeName.CREDENTIALS,
    [SensitiveAreaTypeName.CREDIT_CARD]: SensitiveAreaTypeName.CREDIT_CARD,
    [SensitiveAreaTypeName.EMAIL]: SensitiveAreaTypeName.EMAIL,
    [SensitiveAreaTypeName.PII]: SensitiveAreaTypeName.PII,
    [SensitiveAreaTypeName.SSN]: SensitiveAreaTypeName.SSN
  }

  export const SUB_TYPES: Record<SensitiveAreaSubTypeName, SensitiveAreaSubTypeName> = {
    [SensitiveAreaSubTypeName.ADDRESS]: SensitiveAreaSubTypeName.ADDRESS,
    [SensitiveAreaSubTypeName.BANK_ACCOUNT]: SensitiveAreaSubTypeName.BANK_ACCOUNT,
    [SensitiveAreaSubTypeName.CARD_HOLDER_NAME]: SensitiveAreaSubTypeName.CARD_HOLDER_NAME,
    [SensitiveAreaSubTypeName.CREDIT_CARD_NUMBER]: SensitiveAreaSubTypeName.CREDIT_CARD_NUMBER,
    [SensitiveAreaSubTypeName.CVV]: SensitiveAreaSubTypeName.CVV,
    [SensitiveAreaSubTypeName.EMAIL]: SensitiveAreaSubTypeName.EMAIL,
    [SensitiveAreaSubTypeName.EXPIRATION_MONTH]: SensitiveAreaSubTypeName.EXPIRATION_MONTH,
    [SensitiveAreaSubTypeName.EXPIRATION_MONTH_YEAR]: SensitiveAreaSubTypeName.EXPIRATION_MONTH_YEAR,
    [SensitiveAreaSubTypeName.EXPIRATION_YEAR]: SensitiveAreaSubTypeName.EXPIRATION_YEAR,
    [SensitiveAreaSubTypeName.FIRST_NAME]: SensitiveAreaSubTypeName.FIRST_NAME,
    [SensitiveAreaSubTypeName.FULL_NAME]: SensitiveAreaSubTypeName.FULL_NAME,
    [SensitiveAreaSubTypeName.ID_NUMBER]: SensitiveAreaSubTypeName.ID_NUMBER,
    [SensitiveAreaSubTypeName.INTERNATIONAL_BANK_ACCOUNT_NUMBER]: SensitiveAreaSubTypeName.INTERNATIONAL_BANK_ACCOUNT_NUMBER,
    [SensitiveAreaSubTypeName.LAST_NAME]: SensitiveAreaSubTypeName.LAST_NAME,
    [SensitiveAreaSubTypeName.MIDDLE_NAME]: SensitiveAreaSubTypeName.MIDDLE_NAME,
    [SensitiveAreaSubTypeName.PASSPORT]: SensitiveAreaSubTypeName.PASSPORT,
    [SensitiveAreaSubTypeName.PASSWORD]: SensitiveAreaSubTypeName.PASSWORD,
    [SensitiveAreaSubTypeName.PHONE_NUMBER]: SensitiveAreaSubTypeName.PHONE_NUMBER,
    [SensitiveAreaSubTypeName.USER_NAME]: SensitiveAreaSubTypeName.USER_NAME,
    [SensitiveAreaSubTypeName.ZIP_CODE]: SensitiveAreaSubTypeName.ZIP_CODE


  }

  //@ts-ignore
  export const collection_icons: Record<Collections, { imgIcon?: string, iconClass?: CxConsts.IconClasses }> = {
    [CxCollections.akamai_customers]: { imgIcon: 'akamai.com' },
    [CxCollections.ai_models]: { iconClass: CxConsts.IconClasses.CALCULATOR },
    [CxCollections.agent_lib_modules]: { iconClass: CxConsts.IconClasses.CODE },
    [CxCollections.bundler_modules]: { iconClass: CxConsts.IconClasses.CODE },
    [CxCollections.customers]: { iconClass: CxConsts.IconClasses.BANK },
    [CxCollections.customer_mapping]: { iconClass: CxConsts.IconClasses.BOXES },
    [CxCollections.database_migrations]: { iconClass: CxConsts.IconClasses.DATABASE },
    [CxCollections.users]: { iconClass: CxConsts.IconClasses.ACCOUNT },
    [CxCollections.users_permissions_group]: { iconClass: CxConsts.IconClasses.ACCOUNT },
    [CxCollections.customer_config]: { iconClass: CxConsts.IconClasses.FORM },
    [CxCollections.customer_config_staging]: { iconClass: CxConsts.IconClasses.FORM },
    [CxCollections.agent_versions]: { iconClass: CxConsts.IconClasses.VERSION_CONTROL },
    [CxCollections.rwasp_modules]: { iconClass: CxConsts.IconClasses.VERSION_CONTROL },
    [CxCollections.domain_blacklist]: {},
    [CxCollections.vendors]: {},
    [CxCollections.trusted_domains]: {},
    [CxCollections.audience_hijacking_analytics_integrations]: { iconClass: CxConsts.IconClasses.ANALYTICS },
    [CxCollections.audience_hijacking_rules]: {},
    [CxCollections.audience_hijacking_versions]: {},
    [CxCollections.audience_hijacking_rules_metadata]: {},
    [CxCollections.traffic_mirroring]: {},
    [CxCollections.url_normalizer_versions]: {},
    [CxCollections.deployment_images]: { iconClass: CxConsts.IconClasses.DOCKER },
    [CxCollections.deployment_groups]: { iconClass: CxConsts.IconClasses.GROUP },
    [CxCollections.deployment_releases]: { iconClass: CxConsts.IconClasses.BOOK_OPEN },
    [CxCollections.bundles]: {},
    [CxCollections.policies]: {},
    [CxCollections.policy_states]: {},
  }

  export const rwasp_modules_icon: Record<ResolvedApiModulesName, string> = {
    [ResolvedApiModulesName.Engine]: 'fa fa-cogs',
    [ResolvedApiModulesName.Typings]: 'fa fa-file-text-o',
    [ResolvedApiModulesName.Core]: IconClasses.FLASK,
    [ResolvedApiModulesName.Hooks]: 'mdi mdi-hook',
    [ResolvedApiModulesName.Utils]: 'mdi mdi-folder-multiple-outline',
    [ResolvedApiModulesName.Native]: IconClasses.FINGERPRINT,
    [ResolvedApiModulesName.Policy]: IconClasses.NOTE,
    [ResolvedApiModulesName.CustomerConfig]: IconClasses.FORM,
    [ResolvedApiModulesName.Buffer]: 'mdi mdi-buffer',
    [ResolvedApiModulesName.DataDriven]: 'fa fa-credit-card',
    [ResolvedApiModulesName.AsyncDetectors]: 'fa fa-low-vision',
    [ResolvedApiModulesName.AsyncUtils]: 'fa fa-book',
    [ResolvedApiModulesName.InputExplorer]: 'mdi mdi-poll',
    [ResolvedApiModulesName.AudienceHijacking]: IconClasses.USERS,
  }

  export enum cxRoutingTable {
    //@formatter:off
    USERS                       = 'users',
    USERS_PERMISSIONS_GROUP     = 'users_permission_group',
    LOGIN                       = 'login',
    CLUSTERS                    = 'clusters',
    DATABASES                   = 'databases',
    CUSTOMERS                   = 'customers',
    VERSIONS                    = 'versions',
    MODULES                     = 'modules',
    DASHBOARD                   = 'dashboard',
    ACTIVITY_FEED               = 'activity_feed',
    CUSTOMER_CONFIG             = 'customer_config',
    CUSTOMER_POLICIES           = 'customer_policies',
    AKAMAI_CUSTOMERS            = 'akamai_customers',
    REPORTS                     = 'reports',
    TOOLS                       = 'tools',
    INTELLIGENCE                = 'intelligence',
    COLLECTED_INPUTS            = 'inputs',
    AGENT_VERSIONS              = 'agent_versions',
    AGENT_LIBRARIES             = 'agent_libraries',
    AUDIENCE_HIJACKING_RULES    = 'ah_rules',
    AUDIENCE_HIJACKING_VERSIONS = 'ah_versions',
    AUDIENCE_HIJACKING_ANALYTICS= 'ah_analytics_integrations',
    DEPLOYMENT_IMAGES           = 'deployment_images',
    DEPLOYMENTS                 = 'deployments',
    DEPLOYMENT_GROUPS           = 'deployment_groups',
    DEPLOYMENT_RELEASES         = 'deployment_releases',
    QUEUE_PANEL                 = 'queue_panel',
    TRAFFIC_MIRRORING           = 'traffic_mirroring',
    INSTANCES_DASHBOARD         = 'instances_dashboard',
    KAFKA_PANEL                 = 'kafka_panel',
    URL_NORMALIZER_VERSIONS     = 'url_normalizer_versions',
    VENDOR_HUNTING              = 'vendor_hunting',
    MONGODB_ANALYTICS           = 'mongodb_analytics',
    EVENT_GROUPS                = 'event_groups',
    EVENT_GROUP                 = 'event_group',
    //@formatter:on
  }

  export const DEFAULT_ROUTE = cxRoutingTable.DASHBOARD

  export const enum IconClasses {
    //@formatter:off
    ADD =               'fa fa-plus',
    ACCESS_POINT =      'mdi mdi-access-point',
    ACCOUNT =           'mdi mdi-account',
    ACCOUNT_CARD =      'mdi mdi-account-card-details',
    ACCOUNT_EMAIL =     'fa zmdi-account-box-mail',
    ALERT =             'fa fa-warning',
    ALARM =             'fa zmdi-alarm',
    ALARM_CHECK =       'fa zmdi-alarm-check',
    ANALYTICS =         'fa fa-bar-chart',
    AZURE =             'mdi mdi-azure',
    BANK=               'mdi mdi-bank',
    BELL =              'icon-bell',
    BLOCKED =           'fa fa-ban',
    BOXES =             'fa fa-th-large',
    BOLT =              'fa fa-bolt',
    BOOK_OPEN =         'mdi mdi-book-open',
    BUG =               'mdi mdi-bug-report',
    BUIDLING =          'fa fa-building',
    BUILDING_OUTLINE =  'fa fa-building-o',
    BRIEFCASE =         'icon-briefcase',
    CALCULATOR =        'mdi mdi-calculator',
    CHART =             'fa fa-bar-chart',
    CHART_GANTT =       'mdi-chart-gantt',
    CHART_LINE =        'mdi mdi-chart-line',
    CODE =              'fa fa-code',
    CLOCK =             'mdi mdi-clock',
    CLOSE =             'mdi mdi-close',
    CLOUD =             'mdi mdi-cloud',
    COOKIE =            'mdi mdi-cookie',
    COUNTER =           'mdi mdi-counter',
    DASHBOARD =         'icon-grid',
    DATABASE =          'fa fa-database',
    DOCKER =            'mdi mdi-docker',
    DB_PLUS =           'mdi mdi-database-plus',
    DB_MINUS =          'mdi mdi-database-minus',
    DB =                'mdi mdi-database',
    DIRECTIONS =        'icon-directions',
    DOWNLOAD =          'fa fa-download',
    EXTERNAL_LINK =     'fa fa-external-link',
    EYE =               'fa fa-eye',
    FINGERPRINT =       'mdi mdi-fingerprint',
    FILE_ARCHIVE =      'fa fa-file-archive-o',
    FILE_CODE =         'fa fa-file-code-o',
    FLASK =             'fa fa-flask',
    FORM  =             'fa fa-wpforms',
    DOC =               'icon-doc',
    GEARS =             'fa fa-gears',
    GLOBE =             'icon-globe',
    GLOBE_ALT =         'icon-globe-alt',
    GROUP =             'mdi mdi-group',
    HELP =              'mdi mdi-help',
    JAVASCRIPT =        'mdi mdi-language-javascript',
    INCIDENTS =         'icon-size-actual',
    INFORMATION =       'mdi mdi-information',
    KEYBOARD =          'fa fa-keyboard-o',
    LAYERS =            'icon-layers',
    LOCATION =          'fa fa-location-arrow',
    LIBRARY_BOOKS =     'mdi mdi-library-books',
    LIST =              'fa fa-list-ol',
    LIST_ALT =          'fa fa-list-alt',
    LIST_NUMBERS =      'mdi mdi-format-list-numbers',
    LOCK =              'fa fa-lock',
    MEETUP =            'fa fa-meetup',
    NETWORK =           'fa fa-wifi',
    MICROCHIP =         'fa fa-microchip',
    NEWSPAPER =         'mdi mdi-newspaper',
    NOTE =              'icon-note',
    OBJECT_GROUP =      'fa fa-object-group',
    OBJECT_UNGROUP =    'fa fa-object-ungroup',
    PC =                'mdi mdi-desktop-tower',
    POLL =              'mdi mdi-poll',
    SCORE =             'fa fa-star-half-empty',
    SEARCH_WEB =        'mdi mdi-search-web',
    SERVER =            'mdi mdi-server',
    SETTINGS =          'icon-settings',
    SENSITIVE_AREAS =   'mdi mdi-format-float-none',
    SHIELD =            'icon-shield',
    SITE_MAP =          'fa fa-sitemap',
    SWORD =             'mdi mdi-sword',
    TAG =               'fa fa-tag',
    TH =                'fa fa-th',
    USERS =             'fa fa-users',
    USER_SHIELD =       'fa fa-user-shield',
    VERSION_CONTROL =   'fa fa-code-fork',
    VIOLATIONS =        'mdi mdi-gavel',
    WINDOW_MAX =        'fa fa-window-maximize',
    //@formatter:on
  }

  export enum Origins {
    FIRST_PARTY_SCRIPT = 'First Party',
    THIRD_PARTY_SCRIPT = 'Third Party',
    EXTENSION = 'Browser Extension',
  }
}

