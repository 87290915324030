import { MethodsService } from '../../methods/methods.service'
import { CxConsts } from '../../../shared/typings/chameleonx.typings'

const action_icons: Record<LOG_ACTION, { color: string, iconClass: CxConsts.IconClasses }> = {
  [LOG_ACTION.create]: { color: 'color-green', iconClass: CxConsts.IconClasses.DB_PLUS },
  [LOG_ACTION.delete]: { color: 'color-red', iconClass: CxConsts.IconClasses.DB_MINUS },
  [LOG_ACTION.modify]: { color: 'color-accent', iconClass: CxConsts.IconClasses.DB }
}

export class LogsProcessorService<T = LogsSchema> {
  static process<T extends LogsSchema> (logOrLogs: T | T[]): T | T[] {
    return Array.isArray(logOrLogs) ?
      logOrLogs.map(LogsProcessorService.processLog) as T[] :
      LogsProcessorService.processLog(logOrLogs) as T
  }

  private static processLog<T extends LogsSchema> (log: T): T {
    (log as any)._actionIcon = action_icons[log.action];

    (log as any)._colIcon = CxConsts.collection_icons[log.col];

    (log as any)._info = LogsProcessorService.createLogInfo(log)

    const dateObj = MethodsService.cxDateFormat(log.createdAt);

    (log as any)._timestamp = dateObj.dateAgo

    log.action = MethodsService.normalizeString(log.action) as LOG_ACTION
    // log.col = MethodsService.normalizeString(log.col) as CxCollections

    return log
  }

  private static createLogInfo<T extends LogsSchema> (log: T): string | null {
    let message = ''

    let metadata = ''

    let changes: ValueOf<LoggerChanges<LOG_ACTION, CxCollections>>

    switch (log.action) {
      case LOG_ACTION.create:
        changes = log.changes.created
        message += 'Created'
        break
      case LOG_ACTION.delete:
        changes = log.changes.deleted
        message += 'Deleted'
        break
      case LOG_ACTION.modify:
        changes = log.changes.modified
        message += 'Modified'
        break
    }

    const document: ValueOf<DatabaseSchemaResolver> = changes.newDoc || changes.oldDoc

    message += ' '

    const {
      _id,
      email,
      clusterId,
      clusterName,
      name,
      firstName,
      lastName,
      customerId,
      akamaiAccountId,
      akamaiAccountName,

    } = document as any

    let customerIdText = customerId ? ` for customerId <b>${customerId}</b>` : null

    switch (log.col as Collections) {
      case CxCollections.akamai_customers: {
        message += `Akamai Customer <b>${akamaiAccountId}</b> (${akamaiAccountName})`
        break
      }
      case CxCollections.ai_models: {
        message += 'AI Model' + customerIdText
        break
      }
      case CxCollections.bundler_modules: {
        const doc = document as BundlerModulesSchema
        const env = `<span class="${doc.environment == BundleEnvironment.DRY ? 'color-green' : 'text-purple'}">${doc.environment.toUpperCase()}</span>`
        const success = `<span class="${doc.active ? 'color-green' : 'color-red'}">${doc.active ? 'Active' : 'Inactive'}</span>`
        message += `module <b>${doc.module} ${doc.version}</b> as <b>${success}</b> for <b>${env}</b> environment`
        break
      }
      case CxCollections.customers: {
        message += `PI Tenant Id <b>${_id}</b> (${name})`
        break
      }
      case CxCollections.customer_mapping: {
        message += `PI Instance Id <b>${clusterId}</b> (${clusterName})`
        break
      }
      case CxCollections.users: {
        message += `PI User <b>${email}</b> (${firstName} ${lastName})`
        break
      }
      case CxCollections.customer_config: {
        message += 'Customer Config' + customerIdText
        break
      }
      case CxCollections.customer_config_staging: {
        message += 'Staging Customer Config' + customerIdText
        break
      }
      case CxCollections.users_permissions_group: {
        const doc = document as UsersPermissionsGroupSchema
        message += `Permission Group ${doc.name} - ${doc.permissions.length} Permissions <i class="fa fa-info-circle cursor-help" title="Permissions: \n${doc.permissions.join('\n')}"></i>`
        break
      }

      case CxCollections.database_migrations: {
        const doc = document as DatabaseMigrationsSchema
        const tenants = doc.tenants
        const type = doc.options.migrateDNS ? 'migration' : 'copy'
        message += `a ${type} job (${doc.podName}) for <b class="cursor-help" title="${tenants.join('\n')}">${tenants.length}</b> tenant${tenants.length > 1 ? 's' : ''} | from ${doc.source.clusterName} to ${doc.target.clusterName}`
        break
      }

      case CxCollections.agent_lib_modules: {
        const doc = document as AgentLibModulesSchema
        message += `Agent Library module <b>${doc.module} v${doc.version}</b> is now Available`
        break
      }

      case CxCollections.agent_versions: {
        const doc = document as AgentVersionsSchema
        const modules = doc.modules.map(({ module, version }) => `${module}: ${version}`)
          .sort()
          .join('\n')
        const version = `&nbsp;v${doc.version}&nbsp;<span title="${modules}"><i class="fa fa-info-circle color-accent cursor-help"></i></span>&nbsp;`
        if (log.action == LOG_ACTION.create) {
          message += ` Agent ${version}`
          break
        }

        const atr = changes.diffFields.find(f => f.startsWith(CxEnvironment.PRODUCTION) || f.startsWith(CxEnvironment.STAGING) || f.startsWith(CxEnvironment.DEV))

        if (atr) {
          const [env, changedField] = atr.split('.') || []

          if (!env && !changedField) {
            return null
          }
          if (!['available', 'isDefault'].includes(changedField)) {
            return null
          }

          const reason = reason => reason ? `<span title="${reason}"><i class="fa fa-info-circle color-orange cursor-help"></i></span>` : ''
          const isNegativeAction = changes.newDoc[env][changedField] === false
          const color = isNegativeAction ? 'color-red' : 'color-green'
          let val = ''
          if (changedField == 'available') {
            val = isNegativeAction ? `Disapproved ${reason(doc[env]?.disapproved?.comment || '')}` : 'Activated'
          } else {
            val = isNegativeAction ? `Removed Default` : 'Set Default'
          }
          message = `<span class="${color} font-weight-600">${val}</span> Agent ${version} for <span class="color-theme font-weight-bolder">${env.toLowerCase()}</span> environment.`

        }

        break
      }

      case CxCollections.audience_hijacking_versions: {
        const doc = document as AudienceHijackingVersionsSchema
        const version = `&nbsp;v${doc.version}&nbsp;`
        if (log.action == LOG_ACTION.create) {
          message += ` Agent ${version}`
          break
        }

        const atr = changes.diffFields.find(f => f.startsWith(CxEnvironment.PRODUCTION) || f.startsWith(CxEnvironment.STAGING) || f.startsWith(CxEnvironment.DEV))

        if (atr) {
          const [env, changedField] = atr.split('.') || []

          if (!env && !changedField) {
            return null
          }
          if (!['available', 'isDefault'].includes(changedField)) {
            return null
          }

          const reason = reason => reason ? `<span title="${reason}"><i class="fa fa-info-circle color-orange cursor-help"></i></span>` : ''
          const isNegativeAction = changes.newDoc[env][changedField] === false
          const color = isNegativeAction ? 'color-red' : 'color-green'
          let val = ''
          if (changedField == 'available') {
            val = isNegativeAction ? `Disapproved ${reason(doc[env]?.disapproved?.comment || '')}` : 'Activated'
          } else {
            val = isNegativeAction ? `Removed Default` : 'Set Default'
          }
          message = `<span class="${color} font-weight-600">${val}</span> Agent ${version} for <span class="color-theme font-weight-bolder">${env.toLowerCase()}</span> environment.`

        }

        break
      }

      case CxCollections.rwasp_modules: {
        const doc = document as RwaspBundlesSchema
        const modules = doc.modules.map(({ module, version }) => `${module}: ${version}`)
          .sort()
          .join('\n')

        const version = `&nbsp${doc.version}&nbsp;<span title="${modules}"><i class="fa fa-info-circle color-accent cursor-help"></i></span>&nbsp;`

        if (log.action == LOG_ACTION.create) {
          message += `Created RWASP Version ${version}`
          break
        }

        //prod_avail/approve/disapprove
        const changedField = changes.diffFields.find(f => f.startsWith('is_'))!

        if (changedField == 'is_prod_available') {
          const is_available = doc.is_prod_available
          message = `Set RWASP Version ${version} as <span class="font-weight-600 color-${is_available ? 'green' : 'red'}"> Production ${is_available ? 'A' : 'Una'}vailable</span></span>`

        } else {
          const isNegativeAction = changes.newDoc[changedField] === false
          const color = isNegativeAction ? 'color-red' : 'color-green'
          let val: string

          const reason = reason => `<span title="${reason}"><i class="fa fa-info-circle color-orange cursor-help"></i></span>`

          if (changedField == 'is_verified') {
            val = isNegativeAction ? `Rejected ${reason(doc.verified_metadata.disapproved_comment)}` : 'Verified'
          } else {
            val = isNegativeAction ? `Disapproved ${reason(doc.approved_metadata.disapproved_comment)}` : 'Approved'
          }
          message = `<span class="${color} font-weight-600">${val}</span> RWASP Version ${version}`

        }

        break
      }

      case CxCollections.deployment_releases: {
        const doc = document as DeploymentReleasesSchema

        const { release, updatedAt, imageEnvironment, images, info } = doc

        if (log.action == LOG_ACTION.modify) {
          const old_doc = changes.oldDoc as DeploymentReleasesSchema

          const is_name_changed = !!changes.diffFields.find(f => f == 'group')
          const is_info_changed = !!changes.diffFields.find(f => f == 'info')
          // const is_images_changed = !!changes.diffFields.find(f => f.startsWith('images[') && f.endsWith(']') && f.indexOf('[') == f.lastIndexOf('['))

          message += `Release <b>${release}</b> - `

          if (is_name_changed) {
            message += `name from ${old_doc.release}`
          }

          if (is_info_changed) {
            is_name_changed && (message += ' | ')
            message += `Info from <i class="fa fa-info-circle cursor-help" title="Info: \n${old_doc.info}"> to <i class="fa fa-info-circle cursor-help" title="Info: \n${info}">`
          }

          if (!is_name_changed && !is_info_changed) {
            message += `Release ID ${_id} - ${release} `
          }

          // if (is_images_changed) {
          //   (is_name_changed || is_info_changed) && (message += ' | ')
          //   message += `Feature into release `
          // }
        }

        if (log.action == LOG_ACTION.delete) {
          message += `Release ID <b>${_id} - ${release}</b>.`
        }

        if (log.action == LOG_ACTION.create) {
          message += `Release @ <b>[${imageEnvironment}] ${release}</b>`
          message += ` along with ${images.length} Images <i class="fa fa-info-circle cursor-help" title="Images: \n${images.join('\n')}"></i> `
        }

        break
      }

      case CxCollections.deployment_groups: {
        const doc = document as DeploymentGroupsSchema

        const { group, updatedAt, clusters, deploymentEnvironment, imageEnvironment } = doc

        if (log.action == LOG_ACTION.modify) {
          const old_doc = changes.oldDoc as DeploymentGroupsSchema

          const is_name_changed = !!changes.diffFields.find(f => f == 'group')
          const is_clusters_changed = !!changes.diffFields.find(f => f.startsWith('clusters[') && f.endsWith(']') && f.indexOf('[') == f.lastIndexOf('['))
          const is_release = !is_name_changed && !is_clusters_changed

          message += `Group <b>${group}</b> -`

          if (is_name_changed) {
            message += `name from ${old_doc.group} to ${group}`
          }

          if (is_clusters_changed) {
            is_name_changed && (message += ' | ')
            message += `Assigned Clusters from ${old_doc.clusters.length} <i class="fa fa-info-circle cursor-help" title="Clusters: \n${old_doc.clusters.join('\n')}"></i> to ${clusters.length} <i class="fa fa-info-circle cursor-help" title="Clusters: \n${clusters.join('\n')}"></i>`
          }

          if (is_release) {
            (is_name_changed || is_clusters_changed) && (message += ' | ')
            message += `release to ${clusters.length} Clusters <i class="fa fa-info-circle cursor-help" title="clusters: \n${clusters.join('\n')}">`
          }
        }

        if (log.action == LOG_ACTION.delete) {
          message += `Group ID <b>${_id} - ${group}</b>.`
        }

        if (log.action == LOG_ACTION.create) {
          message += `Group @ <b>[${imageEnvironment}][${deploymentEnvironment}] ${group}</b>`
          if (clusters.length) {
            message += ` along with ${clusters.length} Clusters <i class="fa fa-info-circle cursor-help" title="clusters: \n${clusters.join('\n')}"></i> `
          } else {
            message += '.'
          }
        }

        break
      }

      case CxCollections.deployment_images: {
        const doc = document as DeploymentImagesSchema
        const { image, isFeatureBranch, version, active } = doc

        if (log.action == LOG_ACTION.modify) {
          const is_active_change = !!changes.diffFields.find(f => f == 'active')
          const is_env_changed = !!changes.diffFields.find(f => f == 'deploymentEnvs')

          if (is_active_change) {
            const active_txt = active ?
              '<span class="color-green font-weight-bolder">Activated.</span>' :
              `<span class="color-red font-weight-bolder">Deactivated.</span><i class="fa fa-info-circle cursor-help" title="Reason: \n${doc.disapproved.comment}"></i>`
            message = `${isFeatureBranch ? 'Feature ' : ''} Image <b>${image} ${isFeatureBranch ? `- v${version}` : ''}</b> ${active_txt}`
            break
          }

          if (is_env_changed) {
            message = isFeatureBranch ?
              `Feature Image <b>${image} - v${version}</b> updated .` :
              `Image <b>${image}</b> added for environment <b>${doc.deploymentEnvs.reverse()[0]}</b>`
          }
        }

        if (log.action == LOG_ACTION.create) {
          message += `${isFeatureBranch ? 'Feature' : ''} Image <b>${image}${isFeatureBranch ? ` - v${version}` : ''}</b>`
        }

        if (log.action == LOG_ACTION.delete) {
          message += `${isFeatureBranch ? 'Feature' : ''} Image <b>${image}${isFeatureBranch ? ` - v${version}` : ''}</b>`
        }

        break
      }

    }

    (log as any)._infoMetadata = metadata
    message += ' '

    return message
  }

}

