import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { PILoginComponent } from './pages/pi-login/login.component'
import { PIActivityFeedComponent } from './pages/pi-activity-feed/activity-feed.component'

import { CxConsts } from './shared/typings/chameleonx.typings'
import { DummyComponent } from './shared/components/dummy-component/dummy-component'
import { MethodsService } from './services/methods/methods.service'
import { PITenantsComponent } from './pages/pi-tenants/pi-tenants.component'
import { PIUsersComponent } from './pages/pi-users/users.component'
import { PIAkamaiCustomersComponent } from './pages/pi-akamai-customers/akamai-customers.component'
import { PICustomerConfigComponent } from './pages/pi-customer-config/customer-config.component'
import { PIInstancesComponent } from './pages/pi-instances/pi-instances.component'
import { PIDatabasesComponent } from './pages/pi-databases/pi-databases.component'
import { PIReportsComponent } from './pages/pi-reports/reports.component'
import { PIToolsComponent } from './pages/pi-tools/tools.component'
import { PIIntelligenceComponent } from './pages/pi-intelligence/pi-intelligence.component'
import { PICollectedInputsComponent } from './pages/pi-collected-inputs/collected-inputs.component'
import { PIAgentVersionsComponent } from './pages/pi-agent-versions/agent-versions.component'
import { PIAgentLibrariesComponent } from './pages/pi-agent-libraries/agent-libraries.component'
import {
  PIAudienceHijackingAnalyticsComponent
} from './pages/pi-audience-hijacking-analytics/audience-hijacking-analytics.component'
import {
  PIAudienceHijackingVersionsComponent
} from './pages/pi-audience-hijacking-versions/audience-hijacking-versions.component'
import {
  PIAudienceHijackingRulesComponent
} from './pages/pi-audience-hijacking-rules/audience-hijacking-rules.component'
import { PIDeploymentImagesComponent } from './pages/pi-deployment-images/pi-deployment-images.component'
import { PIDeploymentsComponent } from './pages/pi-deployments/pi-depyloyments.component'
import { PIDeploymentGroupsComponent } from './pages/pi-deployment-groups/pi-depyloyment-groups.component'
import { PIDeploymentReleasesComponent } from './pages/pi-deployment-releases/pi-depyloyment-releases.component'
import { PIUsersPermissionsGroupsComponent } from './pages/pi-users-groups/users-permissions-group.component'
import { PIQueuePanelComponent } from './pages/pi-queue-panel/pi-queue-panel.component'
import { PIDashboardComponent } from './pages/pi-dashboard/dashboard.component'
import { PITrafficMirroringComponent } from './pages/pi-traffic-mirroring/traffic-mirroring.component'
import { PIInstancesDashboardComponent } from './pages/pi-instances-dashboard/pi-instances-dashboard.component'
import { PIKafkaPanelComponent } from './pages/pi-kafka-panel/pi-kafka-panel.component'
import { PIURLNormalizerVersionsComponent } from './pages/pi-url-normalizer-versions/url-normalizer-versions.component'
import { PIVendorHuntingComponent } from './pages/pi-vendor-hunting/vendor-hunting.component'
import { PIMongoDBAnalyticsComponent } from './pages/pi-mongodb-analytics/pi-mongodb-analytics.component'
import { PiEventConsoleComponent } from './pages/pi-event-console/pi-event-console.component'
import { PiEventGroupComponent } from './pages/pi-event-group/pi-event-group.component'
import { PiCustomerPoliciesComponent } from './pages/pi-customer-policies/pi-customer-policies.component'

export const dummyRoute = MethodsService.generateUniqueID()

const routes: Routes = [
  //@formatter:off
  {path: '', redirectTo: CxConsts.DEFAULT_ROUTE, pathMatch: 'full'},

  {path: CxConsts.cxRoutingTable.LOGIN,                       component: PILoginComponent},
  {path: CxConsts.cxRoutingTable.DASHBOARD,                   component: PIDashboardComponent},
  {path: CxConsts.cxRoutingTable.ACTIVITY_FEED,               component: PIActivityFeedComponent},
  {path: CxConsts.cxRoutingTable.CUSTOMERS,                   component: PITenantsComponent},
  {path: CxConsts.cxRoutingTable.CLUSTERS,                    component: PIInstancesComponent},
  {path: CxConsts.cxRoutingTable.DATABASES,                   component: PIDatabasesComponent},
  {path: CxConsts.cxRoutingTable.AKAMAI_CUSTOMERS,            component: PIAkamaiCustomersComponent},
  {path: CxConsts.cxRoutingTable.USERS,                       component: PIUsersComponent},
  {path: CxConsts.cxRoutingTable.USERS_PERMISSIONS_GROUP,     component: PIUsersPermissionsGroupsComponent},
  {path: CxConsts.cxRoutingTable.CUSTOMER_CONFIG,             component: PICustomerConfigComponent},
  {path: CxConsts.cxRoutingTable.CUSTOMER_POLICIES,           component: PiCustomerPoliciesComponent},
  {path: CxConsts.cxRoutingTable.AGENT_VERSIONS,              component: PIAgentVersionsComponent},
  {path: CxConsts.cxRoutingTable.AGENT_LIBRARIES,             component: PIAgentLibrariesComponent},
  {path: CxConsts.cxRoutingTable.DEPLOYMENT_IMAGES,           component: PIDeploymentImagesComponent},
  {path: CxConsts.cxRoutingTable.DEPLOYMENTS,                 component: PIDeploymentsComponent},
  {path: CxConsts.cxRoutingTable.DEPLOYMENT_GROUPS,           component: PIDeploymentGroupsComponent},
  {path: CxConsts.cxRoutingTable.DEPLOYMENT_RELEASES,         component: PIDeploymentReleasesComponent},
  {path: CxConsts.cxRoutingTable.AUDIENCE_HIJACKING_ANALYTICS,component: PIAudienceHijackingAnalyticsComponent},
  {path: CxConsts.cxRoutingTable.AUDIENCE_HIJACKING_RULES,    component: PIAudienceHijackingRulesComponent},
  {path: CxConsts.cxRoutingTable.AUDIENCE_HIJACKING_VERSIONS, component: PIAudienceHijackingVersionsComponent},
  {path: CxConsts.cxRoutingTable.REPORTS,                     component: PIReportsComponent},
  {path: CxConsts.cxRoutingTable.TOOLS,                       component: PIToolsComponent},
  {path: CxConsts.cxRoutingTable.INTELLIGENCE,                component: PIIntelligenceComponent},
  {path: CxConsts.cxRoutingTable.COLLECTED_INPUTS,            component: PICollectedInputsComponent},
  {path: CxConsts.cxRoutingTable.INSTANCES_DASHBOARD,         component: PIInstancesDashboardComponent},
  {path: CxConsts.cxRoutingTable.QUEUE_PANEL,                 component: PIQueuePanelComponent},
  {path: CxConsts.cxRoutingTable.KAFKA_PANEL,                 component: PIKafkaPanelComponent},
  {path: CxConsts.cxRoutingTable.TRAFFIC_MIRRORING,           component: PITrafficMirroringComponent},
  {path: CxConsts.cxRoutingTable.URL_NORMALIZER_VERSIONS,     component: PIURLNormalizerVersionsComponent},
  {path: CxConsts.cxRoutingTable.VENDOR_HUNTING,              component: PIVendorHuntingComponent},
  {path: CxConsts.cxRoutingTable.MONGODB_ANALYTICS,           component: PIMongoDBAnalyticsComponent},
  {path: CxConsts.cxRoutingTable.EVENT_GROUPS,                component: PiEventConsoleComponent},
  {path: CxConsts.cxRoutingTable.EVENT_GROUP,                 component: PiEventGroupComponent},

  {path: dummyRoute,    component: DummyComponent},
  {path: '**', redirectTo: CxConsts.DEFAULT_ROUTE}
  //@formatter:on
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
