import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { AkamaiCustomersService } from '../../services/api/services/akamai-customers.service'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { LoggerService } from '../../services/logger/logger.service'
import { MethodsService } from '../../services/methods/methods.service'
import { PITenantsService } from '../../services/api/services/pi-tenants.service'
import { UserDataService } from '../../services/user-data/user-data.service'
import { USER_POWER_LEVEL } from '../../shared/user-power'
import { PIInstancesService } from '../../services/api/services/pi-instances.service'
import { GlobalNotification, GlobalState } from '../../app.state'
import { isInstanceActive } from '../../services/api/processors/pi-instances-processor.service'

@Component({
  selector: 'pi-akamai-customers',
  templateUrl: './akamai-customers.component.html',
  styleUrls: ['./akamai-customers.component.scss']
})
export class PIAkamaiCustomersComponent extends TableWithFilters implements OnInit, OnDestroy {

  readonly title = 'Akamai Customers Management'

  CONTRACT_TYPES: Record<CONTRACT_TYPE, string> = {
    [CONTRACT_TYPE.PAID]: 'Paid',
    [CONTRACT_TYPE.TRIAL]: 'Trial'
  }

  contractTypes: MappedContractTypes = []

  filterValue: string = ''

  paginationName = 'Akamai Customers'

  //states
  akamaiCustomers: AkamaiCustomersSchema[] = []
  selectedAkamaiCustomer?: AkamaiCustomersSchema

  clusters: MappedClusters = []
  availableInstances: MappedClusters = []
  availableFreeInstances: MappedClusters = []
  activeInstances: MappedClusters = []
  activeFreeTierInstances: MappedClusters = []

  allTenants: MappedTenants = []
  availableTenants: MappedTenants = []
  associatedTenants: MappedTenants = []
  unAssociatedTenants: MappedTenants = []

  isCreateAkamaiCustomerActive: boolean = false

  //table
  readonly tableHeaders: TableHeader[]

  isEligibleToDelete: boolean = false

  currentSortBy: string

  _isRefreshing: boolean = false

  constructor (private _userData: UserDataService, private _akamaiCustomers: AkamaiCustomersService, private _customers: PITenantsService, private _clusters: PIInstancesService, private _zone: NgZone, private _state: GlobalState) {
    super()
    this.currentItemsPerPage = this.itemsPerPageFilter[1]
    this.tableHeaders = [
      //@formatter:off
      USER_POWER_LEVEL[this._userData.permission] >= USER_POWER_LEVEL[UserPermission.ADMIN] ?
        { /*width: '4', */      name: '',                       sortable: false,    field: ''                     } : undefined,
      { /*width: '12',*/      name: 'Created At',             sortable: true,     field: 'createdAt'            },
      { /*width: '12',*/      name: 'Updated At',             sortable: true,     field: 'updatedAt'            },
      { /*width: '18',*/      name: 'Akamai Account ID',      sortable: true,     field: 'akamaiAccountId'      },
      { /*width: '18',*/      name: 'Akamai Account Name',    sortable: true,     field: 'akamaiAccountName'    },
      { /*width: '18',*/      name: 'Default Instance',       sortable: true,     field: 'cluster.clusterName'  },
      { /*width: '18',*/      name: 'Associated Tenants',     sortable: false,    field: 'relatedTenants'       },
      { /*width: '18',*/      name: 'Contract Type',          sortable: true,     field: 'contractType'         },
      { /*width: '18',*/      name: 'Actions',                   sortable: false,                                  },
      //@formatter:on
    ].filter(x => !!x)
    Object.entries(this.CONTRACT_TYPES).forEach(([type, name]) => this.contractTypes.push({
      type: parseInt(type),
      name
    }))
    this.currentSortBy = this.tableHeaders[2].field
    this.isEligibleToDelete = USER_POWER_LEVEL[this._userData.permission] >= USER_POWER_LEVEL[UserPermission.ADMIN]
  }

  ngOnInit (): void {
    window.addEventListener('keyup', this.registerModalListeners.bind(this))
    const url = new URL(location.href)
    const filter = url.searchParams.get('filter')
    if (filter) {
      this.filterValue = filter
    }
    this.refreshTable!()
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  ngOnDestroy (): void {
    window.removeEventListener('keyup', this.registerModalListeners.bind(this))
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    if (!this.selectedAkamaiCustomer && !this.isCreateAkamaiCustomerActive) {
      return
    }
    if (key === 'Escape') {
      this.clearSelections()
    }
  }

  private clearSelections () {
    this.selectedAkamaiCustomer = undefined
    this.isCreateAkamaiCustomerActive = false
    this.associatedTenants = []
  }

  private isFreeTierCluster (clusterId: string): boolean {
    const clusterObject = this.clusters.find(c => c.id === clusterId)
    return clusterObject && clusterObject.isFreeTier
  }

  async getAkamaiCustomer (akamaiCustomerId: string) {
    try {
      SpinnerService.spin('mini')
      const customer = await this._akamaiCustomers.getCustomer(akamaiCustomerId)
      if (customer) {
        this.associatedTenants = customer.relatedTenants.map(tenantId => {
          const tenant = this.allTenants.find(t => t.id == tenantId)
          return tenant ? {
            id: tenantId,
            name: tenant.name,
            _name: tenant.name,
            website: tenant.website
          } : undefined
        }).filter(x => !!x)
        this.selectedAkamaiCustomer = customer

        this.availableInstances = this.clusters
          .filter(cl => isInstanceActive(cl.status) && !cl.isFreeTier || cl.id == customer.akamaiAccountId)

        this.availableFreeInstances = this.clusters
          .filter(cl => isInstanceActive(cl.status) && cl.isFreeTier || cl.id == customer.akamaiAccountId)

        this.availableTenants = [...this.allTenants.filter(t => !t.clusterId || t?.clusterId == customer.defaultCluster)]
          .sort(({ name: a }, { name: b }) => a > b ? 1 : b > a ? -1 : 0)

        LoggerService.info(customer)
      }
    } catch (e) {
      MethodsService.toast('error', 'Error fetching customer ' + akamaiCustomerId, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
      this._zone.run(() => {})
    }
  }

  async refreshTable (background: boolean = false): Promise<void> {
    if (this._isRefreshing) {
      return
    }
    this._isRefreshing = true

    try {
      if (!background) {
        this.akamaiCustomers = []
        this.availableTenants = []
        this.clearSelections()
        SpinnerService.spin('mini')
      }

      const [{ count, data }, mappedCustomers, clusters]: any = await Promise.all([
        this.getCustomers(),
        this._customers.getMappedCustomers(),
        this._clusters.getMappedClusters()
      ])

      this.clusters = clusters
      this.akamaiCustomers = data
      this.numOfAvailableDocs = count
      this.allTenants = mappedCustomers
      this.activeInstances = this.clusters.filter(c => isInstanceActive(c.status) && !c.isFreeTier)
      this.activeFreeTierInstances = this.clusters.filter(c => isInstanceActive(c.status) && c.isFreeTier)
      this.unAssociatedTenants = this.allTenants.filter(t => !t.clusterId)
      if (!background) {
        LoggerService.info(this.akamaiCustomers)
      }
    } catch (e) {
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._zone.run(() => {})
      this._isRefreshing = false
    }
  }

  async updateCustomer (akamaiAccountId: string, akamaiAccountName: string, defaultCluster: string, contractType: CONTRACT_TYPE) {
    if (!akamaiAccountId || !akamaiAccountName || !contractType) {
      return MethodsService.dialog(
        'Bad Customer Parameters',
        'Please fill the required fields'
      )
    }

    this.selectedAkamaiCustomer.akamaiAccountId = akamaiAccountId
    this.selectedAkamaiCustomer.akamaiAccountName = akamaiAccountName
    this.selectedAkamaiCustomer.contractType = contractType
    this.selectedAkamaiCustomer.defaultCluster = defaultCluster || null
    this.selectedAkamaiCustomer.relatedTenants = this.associatedTenants.map(({ id }) => id)

    const isFreeTier = this.isFreeTierCluster(defaultCluster)

    try {
      SpinnerService.spin('mini')
      const updated = await this._akamaiCustomers.updateCustomer(this.selectedAkamaiCustomer, isFreeTier)
      if (updated) {
        this.selectedAkamaiCustomer = undefined
        await this.refreshTable()
      }
    } catch (e) {
      MethodsService.toast('error', 'Error updating customer', e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }

  }

  async createNewCustomer (akamaiAccountId: string, akamaiAccountName: string, defaultCluster: string, contractType: string) {
    if (!akamaiAccountId || !akamaiAccountName || !contractType || !defaultCluster) {
      return MethodsService.dialog(
        'Bad Customer Parameters',
        'Please fill the required fields'
      )
    }

    const customer: Partial<AkamaiCustomersSchema> = {
      akamaiAccountId, akamaiAccountName, defaultCluster,
      contractType: parseInt(contractType) as CONTRACT_TYPE,
      relatedTenants: this.associatedTenants.map(({ id }) => id)
    }

    const isFreeTier = this.isFreeTierCluster(defaultCluster)

    try {
      SpinnerService.spin('mini')
      const newCustomer = await this._akamaiCustomers.createNewCustomer(customer, isFreeTier)
      if (newCustomer) {
        // this.isCreateCustomerActive = false
        await this.refreshTable()
      }
    } catch (e) {
      MethodsService.toast('error', 'Error creating new customer', e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }
  }

  async deleteCustomers () {
    const title = `Delete Confirmation`
    const content = `Delete ${this.selectedTableItems.size} Akamai Customer${this.selectedTableItems.size > 1 ? 's' : ''} ?`
    const deleteConfirmationText = 'Delete'
    if (await MethodsService.confirm(title, content, deleteConfirmationText)) {

      SpinnerService.spin('mini')

      try {
        const success = await this._akamaiCustomers.deleteCustomers([...this.selectedTableItems])
        if (success) {
          this.selectedTableItems.clear()
          await this.refreshTable()
        }
      } catch (e) {
        LoggerService.error(e)
      }
      SpinnerService.stop('mini')
    }
  }

  private async getCustomers (options?: FilterArguments) {
    options = options || {
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sort_by: this.currentSortBy,
      sort_direction: this.isSortReversed ? 'asc' : 'desc',
      filter: this.filterValue
    }
    return await this._akamaiCustomers.getCustomers(options)
  }
}
