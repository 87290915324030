import { get_disapproved_message } from './agent-versions-processor.service'
import { MethodsService } from '../../methods/methods.service'
import { GIT_REPO } from '../../data/data.service'

export const ENV_BADGES: EnvObject[] = [
  {
    standard: CxEnvironment.DEV,
    name: PiDeploymentEnvironment.DEV,
    short: CxEnvironmentShort.DEV,
    badge: 'info',
    prs: [],
  },
  {
    standard: CxEnvironment.STAGING,
    name: PiDeploymentEnvironment.STAGING,
    short: CxEnvironmentShort.STAGING,
    badge: 'primary',
    hover: 'hover-background-primary-70',
    prs: [],
  },
  {
    standard: null,
    name: PiDeploymentEnvironment.CANARY,
    short: 'cnr',
    badge: 'fb',
    hover: 'hover-background-fb-70',
    prs: [],
  },
  {
    standard: CxEnvironment.PRODUCTION,
    name: PiDeploymentEnvironment.PRODUCTION,
    short: CxEnvironmentShort.PRODUCTION,
    badge: 'note',
    prs: [],
  },
]

export function getShortEnv (env: PiDeploymentEnvironment): string | CxEnvironmentShort {
  switch (env) {
    case PiDeploymentEnvironment.DEV:
      return CxEnvironmentShort.DEV

    case PiDeploymentEnvironment.STAGING:
      return CxEnvironmentShort.STAGING

    case PiDeploymentEnvironment.CANARY:
      return 'cnr'

    case PiDeploymentEnvironment.PRODUCTION:
      return CxEnvironmentShort.PRODUCTION
  }
}

export class PIDeploymentImagesProcessor {
  static process<T extends DeploymentImagesSchema> (docOrDocs: T | T[]): T | T[] {
    return Array.isArray(docOrDocs) ?
      docOrDocs.map(PIDeploymentImagesProcessor.processModule) as T[] :
      PIDeploymentImagesProcessor.processModule(docOrDocs) as T

  }

  static getImagesHashMap (releases: ReleasesByImageEnv): Map<string, ExtendedDeploymentReleasesSchema> {
    const map = new Map()

    for (const e in releases) {
      releases[e].forEach(r => map.set(r.imagesHash, { ...r }))
    }

    return map
  }

  static getVersionsSchema (versions: DockerImagesAvailableVersions): DeploymentReleaseEnv {
    return {
      [PiDeploymentEnvironment.DEV]: PIDeploymentImagesProcessor.getVersionsSchemaByEnv(versions, PiDeploymentEnvironment.DEV),
      [PiDeploymentEnvironment.STAGING]: PIDeploymentImagesProcessor.getVersionsSchemaByEnv(versions, PiDeploymentEnvironment.STAGING),
      [PiDeploymentEnvironment.CANARY]: PIDeploymentImagesProcessor.getVersionsSchemaByEnv(versions, PiDeploymentEnvironment.CANARY),
      [PiDeploymentEnvironment.PRODUCTION]: PIDeploymentImagesProcessor.getVersionsSchemaByEnv(versions, PiDeploymentEnvironment.PRODUCTION),

    }
  }

  static getVersionsSchemaByEnv (versions: DockerImagesAvailableVersions, env: PiDeploymentEnvironment = PiDeploymentEnvironment.DEV): DeploymentReleaseImageEnv {
    const scope = versions[env] as Required<DockerImagesAvailableVersionsEnv>

    const imagesEnvObject: DeploymentReleaseImageEnv = {
      [PiOperationalEnvironment.CLUSTER]: [],
      [PiOperationalEnvironment.MASTER]: [],
    }

    for (const imageEnv in scope) {
      for (const repository in scope[imageEnv]) {
        for (const { _id, version, active, image, tag, deploymentEnvs } of scope[imageEnv][repository]) {
          const arr = imagesEnvObject[imageEnv]
          const repoExists = arr.find(image => repository == image.repository)
          const repoObject: DeploymentReleaseEnvVersion = repoExists || {
            repository,
            versions: []
          }

          if (!repoExists) {
            arr.push(repoObject)
          }
          repoObject.versions.push({ _id, version, image, tag, active, deploymentEnvs })
        }
      }
    }

    return imagesEnvObject
  }

  static getInactiveImagesSet (versions: DockerImagesAvailableVersions): InactiveImagesSets {
    const set: InactiveImagesSets = {
      _ids: new Set(),
      images: new Set()
    }

    for (const environment in versions) {
      for (const imageEnv in versions[environment]) {
        for (const repository in versions[environment][imageEnv]) {
          for (const { active, image, _id } of versions[environment][imageEnv][repository]) {
            if (!active) {
              set._ids.add(_id)
              set.images.add(image)
            }
          }
        }
      }
    }

    return set
  }

  static createImagesHash (images: DockerImageContainerNameSchema[]): string {
    const sorted = images
      .map(image => image.toLowerCase())
      .sort()
      .join('#')

    return MethodsService.MD5(sorted)
  }

  private static processModule<T extends DeploymentImagesSchema> (lib: T): T {
    //alias
    const _l = lib as DeploymentImagesSchema & StandardObject
    const _master = lib.imageEnvironment == PiOperationalEnvironment.MASTER

    if (lib.disapproved?.timestamp) {
      _l._disapproved = get_disapproved_message(lib.disapproved)
    }

    const isHighProd = lib.deploymentEnvs.includes(PiDeploymentEnvironment.PRODUCTION)
    const isHighCanary = !isHighProd && lib.deploymentEnvs.includes(PiDeploymentEnvironment.CANARY)

    _l['_envs'] = JSON.parse(JSON.stringify(
      ENV_BADGES.filter(e =>
        e.short != 'dev' && lib.deploymentEnvs.map(getShortEnv).includes(e.short)
      ))).map(enrich_badge)

    if (lib.isFeatureBranch) {
      _l._pr_feature = getPRLink(lib.repository, lib.tag, 'master')
    }

    function enrich_badge (e: EnvObject) {
      // if (e.short == CxEnvironmentShort.STAGING && ((_master && !isHighProd) || (!_master && !isHighCanary && !isHighProd))) {
      if (e.short == CxEnvironmentShort.STAGING) {
        if (!_master) {
          e.prs.push(
            getPRLink(lib.repository, lib.tag, PiDeploymentEnvironment.CANARY)
          )
        }
        e.prs.push(
          getPRLink(lib.repository, lib.tag, PiDeploymentEnvironment.PRODUCTION)
        )
      }

      return e
    }

    if (lib.git) {
      lib.git.pages = lib.git.pages?.sort()
      lib.git.commitMessage = lib.git.commitMessage?.replace(/\w(PAGE-\d+)/gm, `\n$1`)
    }

    _l._modified = lib.createdAt != lib.updatedAt

    _l._clusterIdsText = [
      'Found in Clusters:',
      ..._l.clusters.map(c => c.clusterId)
    ].join('\n')

    _l._releasesText = [
      'Found in Releases:',
      ..._l.releases.map(r => r.release)
    ].join('\n')

    _l._crLink = getCRLink(lib.image, lib.tag)

    _l._lunaBuild = getLunaBuild(lib.repository)

    _l._gitRepo = GIT_REPO(lib.repository, lib.tag)

    return lib
  }

}

function getLunaBuild (repository: string) {
  return `https://lunabuild.akamai.com/view/picore/search/?q=${repository}`
}

function getCRLink (image: string, tag: string) {
  return `https://portal.azure.com/#view/Microsoft_Azure_ContainerRegistries/TagMetadataBlade/registryId/%2Fsubscriptions%2F90806317-8b28-4eb0-b893-6db8f83710e0%2FresourceGroups%2Fpi-container-registry%2Fproviders%2FMicrosoft.ContainerRegistry%2Fregistries%2Fpageintegrity/repositoryName/${encodeURIComponent(image.split(':')[0])}/tag/${tag}/loginServer/pageintegrity.azurecr.io`
}

function getPRLink (repository: string, source: string, target: string) {
  return `https://git.source.akamai.com/projects/PI-CORE/repos/${repository}/pull-requests?create&sourceBranch=${encodeURIComponent(`refs/heads/${source}`)}&targetBranch=${encodeURIComponent(`refs/heads/${target}`)}`
}

