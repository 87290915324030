import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { AkamaiCustomersProcessorService } from '../processors/akamai-customers-processor.service'
import { GlobalState } from '../../../app.state'

@Injectable()
export class AkamaiCustomersService extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async deleteCustomers (akamaiAccountIds: string[]): Promise<boolean> {
    const body = JSON.stringify({ akamaiAccountIds })
    const response = await this.post(`api/admin_api/delete_akamai_customers`, body, true, 'application/json')
    return response.success
  }

  async getCustomer (akamaiCustomerId: string): Promise<AkamaiCustomersSchema> {
    const response = await this.get<AkamaiCustomersSchema>(`api/admin_api/akamai_customer/${akamaiCustomerId}`)
    return response.success && AkamaiCustomersProcessorService.process(response.data) as AkamaiCustomersSchema
  }

  async getCustomerWithoutInstance (): Promise<AkamaiCustomerWithTenant[]> {
    const response = await this.get(`api/admin_api/akamai_customer_no_instance`)
    return response.success && AkamaiCustomersProcessorService.process(response.data) as AkamaiCustomerWithTenant[]
  }

  async getMappedCustomers (): Promise<MappedAkamaiCustomers> {
    const map: MappedAkamaiCustomers = []
    const { data } = await this.getCustomers({
      limit: Number.MAX_SAFE_INTEGER
    })
    data.forEach(({ akamaiAccountId, akamaiAccountName }) => {
      map.push({ name: akamaiAccountName, id: akamaiAccountId })
    })
    return map
  }

  async getCustomers (options: FilterArguments): Promise<IfDefined<CountedResultObject<AkamaiCustomersSchema[]>>> {
    const queryString = MethodsService.objectToQueryString(options)
    const response = await this.get(`api/admin_api/akamai_customers?${queryString}`)
    return response.success && {
      success: response.success,
      count: response.count,
      data: AkamaiCustomersProcessorService.process(response.data) as AkamaiCustomersSchema[]
    }
  }

  async createNewCustomer (customer: Partial<AkamaiCustomersSchema>, isFreeTier: boolean = false): Promise<boolean> {
    const body = JSON.stringify({ ...customer, isFreeTier })
    const response: ResultObject = await this.post(`api/admin_api/create_akamai_customer`, body, true, 'application/json')
    return response.success
  }

  async updateCustomer (customer: AkamaiCustomersSchema, isFreeTier: boolean = false): Promise<boolean> {
    const body = JSON.stringify({ ...customer, isFreeTier })
    const response: ResultObject = await this.post(`api/admin_api/update_akamai_customer`, body, true, 'application/json')
    return response.success
  }
}

