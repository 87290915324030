export class AkamaiCustomersProcessorService {
  static process<T extends AkamaiCustomersSchema>(customerOrCustomers: T | T[]): T | T[] {
    return Array.isArray(customerOrCustomers) ?
      customerOrCustomers.map(AkamaiCustomersProcessorService.processCustomer) as T[]:
      AkamaiCustomersProcessorService.processCustomer(customerOrCustomers) as T
  }

  private static processCustomer(customer: AkamaiCustomersSchema): AkamaiCustomersSchema {
    return customer
  }

}

