import { MethodsService } from '../../methods/methods.service'

export class RwaspVersionsProcessorService {
  static process<T extends RwaspBundlesSchema> (customerOrCustomers: T | T[]): T | T[] {
    return Array.isArray(customerOrCustomers) ?
      customerOrCustomers.map(RwaspVersionsProcessorService.processVersion) as T[] :
      RwaspVersionsProcessorService.processVersion(customerOrCustomers) as T
  }

  private static processVersion (rwaspModule: RwaspBundlesSchema): RwaspBundlesSchema {
    rwaspModule.createdAt = MethodsService.cxDateFormat(rwaspModule.createdAt).dts
    rwaspModule.updatedAt = MethodsService.cxDateFormat(rwaspModule.updatedAt).dts

    //@ts-ignore
    rwaspModule._versions = rwaspModule.modules
      .map(({ module, version }) => `${module}: ${version}`)
      .sort()
      .join('\n')

    if (rwaspModule.verified_metadata) {
      rwaspModule.verified_metadata.at = MethodsService.cxDateFormat(rwaspModule.verified_metadata.at).dts

      //@ts-ignore
      rwaspModule._verified_metadata_tooltip =
        `By: ${rwaspModule.verified_metadata.by}` +
        `\nAt: ${rwaspModule.verified_metadata.at}` +
        (rwaspModule.verified_metadata.email && rwaspModule.verified_metadata.email.toLowerCase() != 'system' ? `\nEmail: ${rwaspModule.verified_metadata.email}` : '') +
        (rwaspModule.verified_metadata.disapproved_comment ? `\nRejection Reason: ${rwaspModule.verified_metadata.disapproved_comment}` : '')
    }

    if (rwaspModule.approved_metadata) {
      rwaspModule.approved_metadata.at = MethodsService.cxDateFormat(rwaspModule.approved_metadata.at).dts

      //@ts-ignore
      rwaspModule._approved_metadata_tooltip =
        `By: ${rwaspModule.approved_metadata.by}` +
        `\nAt: ${rwaspModule.approved_metadata.at}` +
        (rwaspModule.approved_metadata.email && rwaspModule.approved_metadata.email.toLowerCase() != 'system' ? `\nEmail: ${rwaspModule.approved_metadata.email}` : '') +
        (rwaspModule.approved_metadata.disapproved_comment ? `\nDisapprove Reason: ${rwaspModule.approved_metadata.disapproved_comment}` : '')

    }

    return rwaspModule
  }

}

