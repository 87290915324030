import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core'
import { MethodsService } from '../../../services/methods/methods.service'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
  selector: 'cx-toggler',
  templateUrl: './cx-toggler.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CxTogglerComponent),
      multi: true
    }
  ]
})
export class CxTogglerComponent implements AfterViewInit, ControlValueAccessor {
  @Input() public toggleModel: boolean //ngModel
  @Input() public checked: boolean = false

  @Input() public onText?: string = 'On'
  @Input() public offText?: string = 'Off'

  @Input() public width?: number = 70
  @Input() public height?: number = 16

  @Input() private offColor?: string = '#aaaaaa'
  @Input() private offBgColor?: string = '#eceeef'

  @Input() private onColor?: string = '#ffffff'
  @Input() private onBgColor?: string = '#6e3dbe'

  @Input() private textOffset?: number = 0

  @Output() private changes: EventEmitter<boolean> = new EventEmitter<boolean>() //fires an event when clicked

  private readonly topOffset: number = -3

  public togglerId = MethodsService.generateUniqueID('letters')
  public switchBtnId = MethodsService.generateUniqueID('letters')
  public topOffsetCss: string

  ngAfterViewInit () {
    this.topOffsetCss = `${this.topOffset + this.textOffset}px`

    const colors = document.createElement('style')
    colors.innerText = `.cx-switch-left-right .${this.switchBtnId}:before {background: ${this.offBgColor} !important;color: ${this.offColor} !important;padding-left: 44%; top: ${this.topOffsetCss} !important;} .cx-switch-left-right .${this.switchBtnId}:after {background: ${this.onBgColor} !important;color: ${this.onColor} !important; top: ${this.topOffsetCss} !important;`

    const toggler_btn = document.getElementById(this.togglerId)
    toggler_btn.appendChild(colors)

    const switch_btn = document.getElementById(this.switchBtnId)
    switch_btn.setAttribute('data-on', this.onText)
    switch_btn.setAttribute('data-off', this.offText)
  }

  toggleCheck (check: boolean) {
    this.toggle = check
    this.changes.emit(check)
  }

  get toggle () {
    return this.toggleModel
  }

  set toggle (v) {
    this.toggleModel = v
    this.onChange(this.toggleModel)
    this.onTouched()
  }

  writeValue (data: boolean): void {
    this.toggleModel = data
  }

  registerOnChange (fn: any): void {
    this.onChange = fn
  }

  registerOnTouched (fn: any): void {
    this.onTouched = fn
  }

  onChange: any = (active: boolean) => {
    this.toggleModel = active
  }

  onTouched: any = () => {
  }

}
