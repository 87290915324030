import { get_disapproved_message } from './agent-versions-processor.service'
import { GIT_REPO } from '../../data/data.service'

export class UrlNormalizerProcessorService {
  static process<T extends URLNormalizerVersionsSchema> (docOrDocs: T | T[]): ExtendedURLNormalizerVersionsSchema | ExtendedURLNormalizerVersionsSchema[] {
    return Array.isArray(docOrDocs) ?
      docOrDocs.map(UrlNormalizerProcessorService.processVersion) :
      UrlNormalizerProcessorService.processVersion(docOrDocs)
  }

  private static processVersion<T extends URLNormalizerVersionsSchema> (version: T): ExtendedURLNormalizerVersionsSchema {
    const alias = version as any as ExtendedURLNormalizerVersionsSchema

    if (alias.git) {
      alias.git.pages = alias.git.pages?.sort()
      alias.git.commitMessage = alias.git.commitMessage?.replace(/\w(PAGE-\d+)/gm, `\n$1`)
    }

    if (alias.disapproved) {
      alias.disapproved.pages = alias.disapproved.pages?.sort()
    }

    if (alias.disapproved) {
      alias._disapproved = get_disapproved_message(alias.disapproved)
    }

    let _branch = `v${version.version}`
    if (!alias.isApproved && ENV != CxEnvironment.PRODUCTION) {
      _branch = ENV == CxEnvironment.STAGING ? 'staging' : 'dev'
    }

    alias._repoBranchUrl = GIT_REPO('pi-url-normalizer-configuration', _branch)

    if (ENV == CxEnvironment.PRODUCTION) {
      alias._viewable = true
    } else {
      alias._viewable = (version.isApproved || version.isRollout || version.isDefault)

    }

    if (!version.isRollout || !version.isDefault) {
      if (ENV == CxEnvironment.PRODUCTION) {
        alias._actionable = version.isApproved && version.available
      } else {
        alias._actionable = version.available && (version.isApproved || version.isRollout || version.isDefault)
      }
    }

    return alias
  }

}
