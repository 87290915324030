import { AfterContentInit, Component, Input } from '@angular/core'
import { MethodsService } from '../../../services/methods/methods.service'

@Component({
  selector: 'pi-images-environment',
  templateUrl: './pi-images-environment.component.html',
})
export class PIImagesEnvironmentComponent implements AfterContentInit {
  @Input() public env: PiOperationalEnvironment

  className: string
  text: string

  ngAfterContentInit () {
    this.text = MethodsService.normalizeString(this.env)
    this.className = 'inline-block badge badge-' +
      (this.env == PiOperationalEnvironment.MASTER ?
        'outline-info' :
        'outline-fb')
  }

}
