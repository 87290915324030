import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { PITenantsService } from '../../services/api/services/pi-tenants.service'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { LoggerService } from '../../services/logger/logger.service'
import { MethodsService } from '../../services/methods/methods.service'
import { PIInstancesService } from '../../services/api/services/pi-instances.service'
import { UserDataService } from '../../services/user-data/user-data.service'
import { USER_POWER_LEVEL } from '../../shared/user-power'
import { GlobalNotification, GlobalState } from '../../app.state'
import { CLUSTER_STATUSES, JIRA_TICKET_PREFIX } from '../../services/data/data.service'
import { PiDeploymentGroupsService } from '../../services/api/services/pi-deployment-groups.service'
import { PiDeploymentReleasesService } from '../../services/api/services/pi-deployment-releases.service'
import { PIDeploymentImagesService } from '../../services/api/services/pi-deployment-images.service'
import { PIDeploymentImagesProcessor } from '../../services/api/processors/deployment-images-processor.service'
import { AddPageQuery, DeletePageQuery } from '../../shared/components/jira/pi-table-jira.component'

@Component({
  selector: '',
  templateUrl: './pi-depyloyment-releases.component.html',
  styleUrls: ['./pi-depyloyment-releases.component.scss']
})
export class PIDeploymentReleasesComponent<T extends ExtendedDeploymentReleasesSchema = ExtendedDeploymentReleasesSchema> extends TableWithFilters implements OnInit, OnDestroy {

  readonly title = 'PI Deployment Releases'

  readonly paginationName = 'Releases'

  readonly DISABLED_BANNER = ENV != CxEnvironment.PRODUCTION

  filterValue: string = ''

  readonly JIRA_TICKET_PREFIX = JIRA_TICKET_PREFIX
  CLUSTER_STATUSES = CLUSTER_STATUSES

  releases: T[] = []
  releaseConfiguration: DeploymentReleasesConfigObject
  availableImages: DeploymentReleaseImageEnv
  inactiveImages: InactiveImagesSets
  inactiveImagesIds: Set<string> = new Set()
  allReleases: ReleasesByImageEnv

  releaseEnvs: PiDeploymentEnvironment[] = [
    PiDeploymentEnvironment.STAGING,
    PiDeploymentEnvironment.CANARY,
    PiDeploymentEnvironment.PRODUCTION
  ]

  //state
  currentEligibleReleases: ExtendedDeploymentReleasesSchema[] = []
  currentSelectionImages: DeploymentReleaseEnvVersion[] = []
  currentGitPages: string[] = []
  currentImagesHash: string
  isAdmin: boolean = false

  //modals
  isCreateReleaseActive: boolean = false
  selectedRelease: T

  //table
  readonly tableHeaders: TableHeader[]
  currentSortBy: string
  _isRefreshing: boolean = false

  //operational filter
  availableOperationalEnvironments: string[] = []
  currentOperationalEnvironments = this.availableOperationalEnvironments

  textToClip = MethodsService.copyToClipboard

  normalizeString = MethodsService.normalizeString

  upperCasedString = MethodsService.upperCasedString

  AddPageQuery = AddPageQuery

  DeletePageQuery = DeletePageQuery

  private _debounce: void | number
  private debounce_timeout: number = 1000

  constructor (private _userData: UserDataService, private _clusters: PIInstancesService, private _groups: PiDeploymentGroupsService, private _images: PIDeploymentImagesService, private _releases: PiDeploymentReleasesService, private _customers: PITenantsService, private _zone: NgZone, private _state: GlobalState) {
    super()
    this.currentItemsPerPage = this.itemsPerPageFilter[1]
    this.tableHeaders = [
      //@formatter:off
      USER_POWER_LEVEL[this._userData.permission] >= USER_POWER_LEVEL[UserPermission.ADMIN] ? { /*width: '4', */  name: '',                   sortable: false,    field: ''                   } : undefined,
      { /*width: '12',*/  name: 'Created',              sortable: true,     field: 'createdAt'          },
      { /*width: '12',*/  name: 'Updated',              sortable: true,     field: 'updatedAt'          },
      { /*width: '12',*/  name: 'Release ID',           sortable: true,     field: '_id'                },
      { /*width: '12',*/  name: 'Release Name',         sortable: true,     field: 'release'            },
      // { /*width: '12',*/  name: 'Images Hash',          sortable: true,     field: 'imagesHash'         },
      { /*width: '12',*/  name: 'Images Environment',   sortable: true,     field: 'imageEnvironment'   },
      { /*width: '12',*/  name: 'JIRA',                 sortable: false,                                },
      { /*width: '12',*/  name: 'Images',               sortable: true,     field: 'numOfImages'        },
      // { /*width: '12',*/  name: 'Groups',               sortable: true,     field: 'numOfGroups'        },
      { /*width: '12',*/  name: 'Clusters',             sortable: true,     field: 'numOfClusters'      },
      { /*width: '12',*/  name: 'Actions',                 sortable: false                                 },
      //@formatter:on
    ].filter(x => !!x)

    this.currentSortBy = this.tableHeaders[1].field
    this.isAdmin = USER_POWER_LEVEL[this._userData.permission] >= USER_POWER_LEVEL[UserPermission.ADMIN]
  }

  clearTouches () {
    this.currentSelectionImages.forEach(image => image._touched_image = false)
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    if (!this.selectedRelease && !this.isCreateReleaseActive) {
      return
    }
    if (key === 'Escape') {
      this.clearSelections()
    }
  }

  ngOnDestroy (): void {
    window.removeEventListener('keyup', this.registerModalListeners.bind(this))
  }

  onFilterChange (item: any) {
    if (this._debounce) {
      this._debounce = clearTimeout(this._debounce)
    }
    this._debounce = setTimeout(() => this.refreshTable(), this.debounce_timeout) as any as number
  }

  ngOnInit (): void {
    window.addEventListener('keyup', this.registerModalListeners.bind(this))
    this.refreshTable!()
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  openCreateReleaseModal () {
    this.setVersionScope(this.releaseConfiguration.imageEnvironments[0])
    this.currentGitPages = []
    this.isCreateReleaseActive = true
  }

  selectRelease (release: T) {
    this.currentGitPages = [...release.gitPages || []]
    this.selectedRelease = release
  }

  getHighestEnv (version_id: string) {
    for (const imageEnv in this.availableImages) {
      const scope = this.availableImages[imageEnv]
      const repo = scope.find(s => !!s.versions.find(({ _id }) => _id == version_id))
      if (!repo) {continue}
      const version = repo.versions.find(({ _id }) => _id == version_id)
      if (version) {
        switch (true) {
          case version.deploymentEnvs.includes(PiDeploymentEnvironment.PRODUCTION):
            return CxEnvironmentShort.PRODUCTION
          case version.deploymentEnvs.includes(PiDeploymentEnvironment.CANARY):
            return 'cnr'
          case version.deploymentEnvs.includes(PiDeploymentEnvironment.STAGING):
            return CxEnvironmentShort.STAGING
          case version.deploymentEnvs.includes(PiDeploymentEnvironment.DEV):
            return CxEnvironmentShort.DEV
        }
      }
    }
  }

  updateImagesHash (form: HTMLFormElement, checkbox: HTMLSelectElement) {
    const repositories = this.currentSelectionImages.map(image => image.repository)
    const versions = this.currentSelectionImages.map(image => image.versions).flat()
    const images: DockerImageContainerNameSchema[] = []

    for (const repository of repositories) {
      const element = form[repository]
      const _id = element.value
      const { image } = versions.find(v => v._id == _id)!
      images.push(image)
    }

    this.currentImagesHash = PIDeploymentImagesProcessor.createImagesHash(images)
    const release = this.currentEligibleReleases.find(({ imagesHash }) => imagesHash == this.currentImagesHash)
    if (release) {
      for (let i = 0; i < Array.from(checkbox.options).length; i++) {
        const ele = Array.from(checkbox.options)[i]
        if (ele.value == release._id) {
          checkbox.selectedIndex = i
        }
      }
    } else {
      checkbox.selectedIndex = 0
    }
  }

  setImagesFromEnv (form: HTMLFormElement, imageEnv: PiOperationalEnvironment, deploymentEnv: PiDeploymentEnvironment) {
    this.availableImages[imageEnv].forEach(image => {
      let option_index = form[image.repository].selectedIndex
      const options_array = Array.from(form[image.repository].options) as HTMLSelectElement[]
      const selected_version = image.versions.find(v => v.active && v.deploymentEnvs.includes(deploymentEnv))
      if (!selected_version) {
        form[image.repository].selectedIndex = 0
        return
      }
      for (let i = 0; i < options_array.length; i++) {
        const element = options_array[i]
        if (element.value == selected_version._id) {
          option_index = i
        }
      }
      form[image.repository].selectedIndex = option_index
    })
  }

  setImagesFromRelease (form: HTMLFormElement, release_id?: string) {
    if (!release_id) {
      return
    }
    const release = this.currentEligibleReleases.find(r => r._id == release_id)
    if (!release) {
      return
    }

    release.images.forEach(({ _id, repository }) => {
      let option_index = form[repository].selectedIndex
      const options_array = Array.from(form[repository].options) as HTMLSelectElement[]
      for (let i = 0; i < options_array.length; i++) {
        const element = options_array[i]
        if (element.value == _id) {
          option_index = i
        }
      }
      form[repository].selectedIndex = option_index
    })
  }

  setVersionScope (imageEnv: PiOperationalEnvironment) {
    this.currentEligibleReleases = this.allReleases[imageEnv]
    this.currentSelectionImages = this.availableImages[imageEnv.toUpperCase()].sort((a, b) => a.repository.localeCompare(b.repository))
  }

  isNewerVersionAvailable (versions: DeploymentReleaseEnvVersionVersions[], _id: string): boolean {
    for (const { active, _id } of versions) {
      if (_id == _id) {
        return false
      }
      if (active) {
        return true
      }
    }
    return false
  }

  async refreshTable (background: boolean = false, ignoreSelected: boolean = false): Promise<void> {
    if (this._isRefreshing || (!ignoreSelected && (this.selectedRelease || this.isCreateReleaseActive))) {
      return
    }
    this._isRefreshing = true
    try {
      if (!background) {
        this.releases = []
        this.clearSelections()
        SpinnerService.spin('mini')
      }
      const [
        { data, count },
        versions,
        releases,
        releaseConfiguration,
      ] = await Promise.all([
        this.getReleases(),
        this._images.getAvailableVersions(),
        this._releases.getAllDeploymentReleases(),
        this._releases.getConfig(),
      ].filter(x => !!x) as PromiseLike<any>[])

      this.numOfAvailableDocs = count
      this.releases = data

      this.releaseConfiguration = releaseConfiguration
      this.availableOperationalEnvironments = releaseConfiguration.imageEnvironments.map(s => MethodsService.normalizeString(s)).sort()

      this.allReleases = releases

      for (const scope in this.allReleases) {
        for (const release of this.allReleases[scope]) {
          release.images = release.images.sort((a, b) => a.repository.localeCompare(b.repository))
        }
      }

      this.availableImages = PIDeploymentImagesProcessor.getVersionsSchemaByEnv(versions)
      this.inactiveImages = PIDeploymentImagesProcessor.getInactiveImagesSet(versions)
      this.setVersionScope(this.releaseConfiguration?.imageEnvironments[0] || PiOperationalEnvironment.CLUSTER)
    } catch (e) {
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._zone.run(() => {})
      this._isRefreshing = false
    }

  }

  async removePage ({ item: release, page }: IfDefined<TableJiraEmitValue<T>>) {
    try {
      SpinnerService.spin()
      const updatedAt = new Date(release.updatedAt).getTime()
      const doc = await this._releases.removePages(release._id, [page], updatedAt)
      this.releases[this.releases.indexOf(this.releases.find(r => r._id == doc._id))].gitPages = doc.gitPages
      this.releases[this.releases.indexOf(this.releases.find(r => r._id == doc._id))].updatedAt = doc.updatedAt
    } catch (e) {
      MethodsService.toast('error', `Error deleting PAGE-${page} for release ${release.release}`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop()
      this._zone.run(() => {})
    }
  }

  async addPage (emitValue: IfDefined<TableJiraEmitValue<T>>, current = false) {
    const { item: release, page } = emitValue || {}
    if (current) {
      const page = await AddPageQuery()
      if (!page) {
        return
      }
      this.currentGitPages.push(...page.input.split(','))
      this.currentGitPages = [...new Set(this.currentGitPages)]
    }

    const pages = page.split(',')

    if (release) {
      try {
        SpinnerService.spin()
        const updatedAt = new Date(release.updatedAt).getTime()
        const doc = await this._releases.addPages(release._id, pages, updatedAt)
        this.releases[this.releases.indexOf(this.releases.find(r => r._id == doc._id))].gitPages = doc.gitPages
        this.releases[this.releases.indexOf(this.releases.find(r => r._id == doc._id))].updatedAt = doc.updatedAt
      } catch (e) {
        MethodsService.toast('error', `Error adding PAGES ${pages} for image ${release.release}`, e.toString(), 8)
        LoggerService.error(e)
      } finally {
        SpinnerService.stop()
        this._zone.run(() => {})
      }
    }

  }

  async deleteReleases () {
    const title = `Delete Confirmation`
    const content = `Delete ${this.selectedTableItems.size} Release${this.selectedTableItems.size > 1 ? 's' : ''} ?`
    const deleteConfirmationText = 'Delete'
    if (await MethodsService.confirm(title, content, deleteConfirmationText)) {

      SpinnerService.spin('mini')

      try {
        const success = await this._releases.deleteReleases([...this.selectedTableItems])
        if (success) {
          this.selectedTableItems.clear()
          await this.refreshTable()
        }
      } catch (e) {
        LoggerService.error(e)
      }
      SpinnerService.stop('mini')
    }
  }

  async createNewRelease (form: HTMLFormElement, release: string, info: string, imageEnvironment: PiOperationalEnvironment) {
    if (!this.testReleaseName(release)) {
      return
    }

    const images: string[] = this.currentSelectionImages.map(({ repository }) => form[repository].value)

    const createOptions: NewDeploymentRelease = {
      info,
      images,
      release,
      imageEnvironment,
      gitPages: this.currentGitPages
    }

    try {
      SpinnerService.spin('mini')
      const newRelease = await this._releases.createNewRelease(createOptions)
      if (newRelease) {
        await this.refreshTable(false, true)
      }
    } catch (e) {
      MethodsService.toast('error', `Error creating new release ${release}`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }

  }

  async updateRelease (form: HTMLFormElement, release: string, info: string) {
    if (!this.testReleaseName(release)) {
      return
    }

    const updateObject: UpdateDeploymentReleaseOptions = {
      info,
      release,
      gitPages: this.currentGitPages,
      updatedAt: new Date(this.selectedRelease.updatedAt).getTime()
    }

    try {
      SpinnerService.spin('mini')
      const updatedGroup = await this._releases.updateRelease(this.selectedRelease._id, updateObject)
      if (updatedGroup) {
        await this.refreshTable(false, true)
      }
    } catch (e) {
      MethodsService.toast('error', `Error editing release ${this.selectedRelease._id}`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }

  }

  private clearSelections () {
    this.isCreateReleaseActive = false
    this.selectedRelease = undefined
    this.currentImagesHash = undefined
    this.currentGitPages = []
    this.currentSelectionImages = []
    this.currentEligibleReleases = []

  }

  private async getReleases (options?: FilterArguments) {
    options = options || {
      filter: this.filterValue,
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sort_by: this.currentSortBy,
      sort_direction: this.isSortReversed ? 'asc' : 'desc'
    }

    if (this.currentOperationalEnvironments?.length) {
      options.operational_envs = this.currentOperationalEnvironments.map(s => s.toUpperCase())
    }

    return await this._releases.getAllReleasesFiltered(options)
  }

  private testReleaseName (release: string): boolean {
    const { pattern, flags } = this.releaseConfiguration.name
    const name_regex = new RegExp(pattern, flags)
    if (!name_regex.test(release)) {
      MethodsService.dialog(`Bad Arguments`, `Invalid release format! (regex: /${name_regex.toString()})`)
      return false
    }
    return true
  }

}
