import { Component, NgZone } from '@angular/core'
import { PITenantsService } from '../../services/api/services/pi-tenants.service'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { MethodsService } from '../../services/methods/methods.service'
import { LoggerService } from '../../services/logger/logger.service'
import { AkamaiCustomersService } from '../../services/api/services/akamai-customers.service'
import { PIInstancesService } from '../../services/api/services/pi-instances.service'
import { UserDataService } from '../../services/user-data/user-data.service'
import { USER_POWER_LEVEL } from '../../shared/user-power'
import { CustomerConfigService } from '../../services/api/services/customer-config.service'
import { GlobalNotification, GlobalState } from '../../app.state'

@Component({
  selector: '',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class PIReportsComponent {
  isEligibleToDelete: boolean = false

  readonly title = 'PI Reports'

  public akamaiCustomersWithoutInstances: AkamaiCustomerWithTenant[] = []
  public customerConfigsWithoutSensitiveAreas: ConfigWithCustomer[] = []

  _isRefreshing: boolean = false

  constructor (private _userData: UserDataService, private _customers: PITenantsService, private _customerConfigs: CustomerConfigService, private _akamaiCustomers: AkamaiCustomersService, private _clusters: PIInstancesService, private _zone: NgZone, private _state: GlobalState) {
    // super()

    this.isEligibleToDelete = USER_POWER_LEVEL[this._userData.permission] >= USER_POWER_LEVEL[UserPermission.ADMIN]
  }

  ngOnDestroy (): void {
    // window.removeEventListener('keyup', this.registerModalListenersalListeners.bind(this))
  }

  ngOnInit (): void {
    // window.addEventListener('keyup', this.registerModalListeners.bind(this))
    // this.refreshTable!()
    this.reload()
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.reload(true))
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    // if (!this.selectedCustomer && !this.isCreateCustomerActive) {
    //   return
    // }
    // if (key === 'Escape') {
    //   this.selectedCustomer = this.isCreateCustomerActive = undefined
    // }
  }

  async reload (background: boolean = false) {
    if (this._isRefreshing) {
      return
    }
    this._isRefreshing = true
    try {
      if (!background) {
        SpinnerService.spin('mini')
      }

      await Promise.all([
        this.getAkamaiCustomerWithoutInstance(),
        this.getCustomerConfigWithoutSensitiveAreas()
      ])

    } catch (e) {
      if (!background) {
        MethodsService.toast('error', 'Error fetching reports', e.toString(), 8)
      }
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._zone.run(() => {})
      this._isRefreshing = false
    }
  }

  async getAkamaiCustomerWithoutInstance () {
    const result = await this._akamaiCustomers.getCustomerWithoutInstance()
    this.akamaiCustomersWithoutInstances = result
  }

  async getCustomerConfigWithoutSensitiveAreas () {
    const result = await this._customerConfigs.getCustomerConfigWithoutSensitiveAreas()
    this.customerConfigsWithoutSensitiveAreas = result.data
  }

}
