import { AfterViewInit, Component, Input, OnInit } from '@angular/core'
import { AgChartOptions } from 'ag-charts-types/dist/types/src/chart/chartBuilderOptions'
import { MethodsService } from '../../../services/methods/methods.service'

@Component({
  selector: 'pi-timeseries-bar-chart',
  templateUrl: './pi-timeseries-bar-chart.component.html',
  styleUrls: ['./pi-timeseries-bar-chart.component.scss']
})

export class PiTimeseriesBarChartComponent implements OnInit, AfterViewInit {
  @Input() timesSeries: { value: number, timestamp: Date }[]
  @Input() height?: number
  @Input() width?: number
  @Input() xKeyName?: string = 'Date'
  @Input() yKeyNName?: string = 'Value'
  @Input() tooltipContentSuffix?: string = ''
  @Input() maxIntervalValues?: number = 33

  readonly chartId = MethodsService.generateUniqueID('letters')
  private options: AgChartOptions

  constructor () {
  }

  ngOnInit () {
    this.timesSeries.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime())
    const intervalValues = this.timesSeries.map(({ timestamp }) => timestamp)//leave up to {{MAX_INTERVAL_VALUES}} labels on the x-axis
    if (intervalValues.length > this.maxIntervalValues) {
      let step = Math.ceil(intervalValues.length / 2)
      while (step > this.maxIntervalValues) {
        step = Math.ceil(step / 2)
      }
      const newIntervalValues: Date[] = []
      for (let i = 0; i < intervalValues.length - 1; i += step) {
        newIntervalValues.push(intervalValues[i])
      }
      newIntervalValues.push(intervalValues[intervalValues.length - 1])
      intervalValues.length = 0
      intervalValues.push(...newIntervalValues)
    }

    this.options = {
      series: [{
        type: 'bar',
        xKey: 'timestamp',
        xName: this.xKeyName,
        yKey: 'value',
        yName: this.yKeyNName,
        stroke: 'transparent',
        strokeWidth: 2,
        cornerRadius: 6,
        tooltip: {
          renderer: ({ datum, xKey, yKey }) => {
            const { localDts, ts } = MethodsService.cxDateFormat(datum[xKey])
            const dayOfWeekString = new Intl.DateTimeFormat('en-GB', { weekday: 'long' }).format(ts)
            return {
              title: `${dayOfWeekString}, ${localDts}`,
              content: `${MethodsService.numberToSuffix(datum[yKey])} ${this.tooltipContentSuffix}`
            }
          },
        },
      }],
      axes: [
        {
          type: 'time',
          position: 'bottom',
          nice: false,
          interval: {
            values: intervalValues
          },
          label: {
            autoRotate: true,
            avoidCollisions: true,
            formatter: ({ value }) => MethodsService.cxDateFormat(value).localDts
          },
        },
        {
          type: 'number',
          position: 'left',
          label: {
            formatter: ({ value }) => Number(value).toLocaleString(),
          },
        },
      ],
      data: this.timesSeries
    }
  }

  ngAfterViewInit () {
    this.options.container = document.getElementById(this.chartId)
    agCharts.AgCharts.create(this.options)
  }
}
