import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { GlobalState } from '../../../app.state'
import { PICollectedInputsProcessorService } from '../processors/collected-inputs-processor.service'

@Injectable()
export class PICollectedInputsService extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async getAggregatedSignatures (_id: string, options: FilterArguments): Promise<CountedResultObject<AggregatedCollectedInput[]>> {
    const queryString = MethodsService.objectToQueryString(options)
    const response = await this.get(`api/admin_api/collected_inputs/${_id}?${queryString}`) as CountedResultObject<CountedAggregatedCollectedInputs>
    return {
      count: response.data.count,
      success: response.success,
      data: PICollectedInputsProcessorService.process(response.data.collectedInputs) as AggregatedCollectedInput[]
    }
  }

  async getBySignature (_id: string, signature: string): Promise<AggregatedCollectedInput> {
    const response = await this.get(`api/admin_api/collected_input/${_id}/${signature}`) as ResultObject<CountedAggregatedCollectedInputs>
    return PICollectedInputsProcessorService.process(response.data.collectedInputs)[0] as AggregatedCollectedInput
  }

  async setProperties (_id: string, collectedInputs: CollectedInputsPOSTRequest): Promise<boolean> {
    const body = JSON.stringify(collectedInputs)
    const response = await this.post(`api/admin_api/collected_input/${_id}`, body, true, 'application/json')
    return response.success
  }

}

