import { MethodsService } from '../../methods/methods.service'

export class PIDatabasesProcessor {
  static processJob<T extends DatabaseMigrationsSchema> (job: T): T {
    job.createdAt = MethodsService.cxDateFormat(job.createdAt).dts
    //@ts-ignore
    job.options.copyFromDate = job.options.copyFromDate ? MethodsService.cxDateFormat(job.options.copyFromDate).dts : job.options.copyFromDate
    //@ts-ignore
    job.options.copyToDate = job.options.copyToDate ? MethodsService.cxDateFormat(job.options.copyToDate).dts : job.options.copyToDate

    if (job.jobDurationMs) {
      //@ts-ignore
      job._duration = MethodsService.getPastTime(job.jobDurationMs)
    }

    return job
  }

  static processOne<T extends GetDatabaseClusterResponse> (db: T): T {
    db.jobs = db.jobs.map(PIDatabasesProcessor.processJob)

    //@ts-ignore
    db._stringified = JSON.stringify(db.cluster, null, 4)

    return db
  }

  static processAll<T extends DatabaseClusterData> (databases: T[]): T[] {
    return databases
      .map(PIDatabasesProcessor._processOne)
  }

  private static _processOne<T extends DatabaseClusterData> (db: T): T {
    //@ts-ignore
    db._stringified = JSON.stringify(db, null, 4)

    return db
  }
}
