import { Injectable } from '@angular/core'
import { GlobalNotification, GlobalState } from '../../app.state'

@Injectable()
export class SidebarService {
  private readonly TOP_NAV_BAR_SELECTOR = 'navbar-top'
  private readonly SIDE_BAR_TOP_SELECTOR = 'sidebar-top'
  private readonly SIDE_BAR_BODY_SELECTOR = 'sidebar'
  private readonly SIDE_BAR_NAV_SELECTOR = 'sidebar-nav'
  private readonly SIDE_BAR_SCROLL_OVERLAY_SELECTOR = 'sidebar-scroll-overlay'
  private readonly BRAND_LOGO_FULL_SELECTOR = 'brand-logo-full'
  private readonly BRAND_LOGO_SMALL_SELECTOR = 'brand-logo-sm'

  private readonly SIDEBAR_WIDTH = '265px'
  private readonly SIDEBAR_WIDTH_COLLAPSED = '70px'
  private readonly SMALL_LOGO_SIZE = '20px'
  
  
  private readonly LOCAL_STORAGE_KEY = '__cxsb'

  private _sidebarTop: HTMLElement
  private _sidebarBody: HTMLElement
  private _sidebarNav: HTMLElement
  private _sidebarScrollOverlay: HTMLElement
  private _navbarTop: HTMLElement
  private _logoFull: HTMLElement
  private _logoSmall: HTMLElement

  private _isCollapsed: boolean

  constructor (private _state: GlobalState) {
  }

  get isCollapsed() {
    return this._isCollapsed
  }

  init () {
    this._isCollapsed = !!localStorage.getItem(this.LOCAL_STORAGE_KEY) ?
      atob(localStorage.getItem(this.LOCAL_STORAGE_KEY)) === 'true' :
      false
    
      this._navbarTop = document.getElementById(this.TOP_NAV_BAR_SELECTOR)
      this._sidebarTop = document.getElementById(this.SIDE_BAR_TOP_SELECTOR)
      this._sidebarBody = document.getElementById(this.SIDE_BAR_BODY_SELECTOR)
      this._sidebarNav = document.getElementById(this.SIDE_BAR_NAV_SELECTOR)
      this._sidebarScrollOverlay = document.getElementById(this.SIDE_BAR_SCROLL_OVERLAY_SELECTOR)
      this._logoFull = document.getElementById(this.BRAND_LOGO_FULL_SELECTOR)
      this._logoSmall = document.getElementById(this.BRAND_LOGO_SMALL_SELECTOR)
      this._logoSmall.style.width = this.SMALL_LOGO_SIZE

      window.addEventListener('resize', () => window.innerWidth <= 991 ? this.toggleSidebar(true) : null)

      this.toggleSidebar(this._isCollapsed)

  }

  public toggleSidebar (collapsed?: boolean) {
    this._isCollapsed = collapsed === undefined ?
      !this._isCollapsed :
      collapsed

    this._navbarTop.style.width = this._isCollapsed ? `calc(100% - ${this.SIDEBAR_WIDTH_COLLAPSED})` : `calc(100% - ${this.SIDEBAR_WIDTH})`
    this._sidebarTop.style.width = this._isCollapsed ? this.SIDEBAR_WIDTH_COLLAPSED : this.SIDEBAR_WIDTH
    this._sidebarBody.style.width = this._isCollapsed ? this.SIDEBAR_WIDTH_COLLAPSED : this.SIDEBAR_WIDTH
    this._sidebarNav.style.width = this._isCollapsed ? this.SIDEBAR_WIDTH_COLLAPSED : this.SIDEBAR_WIDTH
    // this._sidebarScrollOverlay.style.left = this._isCollapsed ? this.SIDEBAR_WIDTH_COLLAPSED : this.SIDEBAR_WIDTH
    this._logoFull.style.display = this._isCollapsed ? 'none' : 'block'
    this._logoSmall.style.display = this._isCollapsed ? 'block' : 'none'

    // document.querySelectorAll<any>(this.DASHBOARD_ELEMENTS_TEXT_QUERY_SELECTOR).forEach(node => node.style.display = this._isCollapsed ? 'none' : 'block')

    this.saveSidebarState()
  }

  private saveSidebarState () {
    this._state.notify(GlobalNotification.SIDEBAR_COLLAPSED, this._isCollapsed)
    localStorage.setItem(this.LOCAL_STORAGE_KEY, btoa(this._isCollapsed.toString()))
  }

}
