import { Injectable } from '@angular/core'
import { GlobalNotification, GlobalState } from '../../app.state'
import { LoggerService } from '../logger/logger.service'
import { USER_POWER_LEVEL } from '../../shared/user-power'

@Injectable()

export class UserDataService implements Omit<UsersSchema, keyof DefaultCollectionSchema | 'auth0Id'> {
  private _token: CxUserToken

  private readonly _id: string
  private readonly _permission: UserPermission
  private readonly _firstName: string
  private readonly _middleName: string
  private readonly _lastName: string
  private readonly _email: string
  private readonly _country: string
  private readonly _phoneNumber: string
  private readonly _permissions: UsersPermissionsGroupSchema[]

  private isDataReady: boolean = false

  constructor (private _state: GlobalState) {
  }

  initiateData (cxUserInformation: CxUserInformation) {
    this.clearInformation()
    Object.entries(cxUserInformation).forEach(([key, value]) => this[`_${key}`] = value)

    /**user data state changed notification */
    this.isDataReady = true
    this._state.notify(GlobalNotification.USER_DATA_CHANGED, this.isDataReady)
    LoggerService.info('User Information: ', this)
  }

  clearInformation () {
    Object.keys(this).forEach(key => !key.startsWith('_') ? delete this[key] : null)
    this.isDataReady = false
    this._state.notify(GlobalNotification.USER_DATA_CHANGED, this.isDataReady)
  }

  hasPermissions (path: string, autoAccessPermission?: UserPermission): boolean {
    return !!this.permissions.find(p => p.permissions.includes(path)) ||
      (autoAccessPermission && USER_POWER_LEVEL[this.permission] >= USER_POWER_LEVEL[autoAccessPermission])
  }

  public get permissions () {
    return this._permissions
  }

  public get dataReady (): boolean {
    return this.isDataReady
  }

  public get country (): string {
    return this._country
  }

  public get email (): string {
    return this._email
  }

  public get firstName (): string {
    return this._firstName
  }

  public get id (): string {
    return this._id
  }

  public get lastName (): string {
    return this._lastName
  }

  public get middleName (): string {
    return this._middleName
  }
  public get permission (): UserPermission {
    return this._permission
  }

  public get phoneNumber (): string {
    return this._phoneNumber
  }

  public get token (): CxUserToken {
    return this._token
  }

  get name () {
    return [this._firstName, this._middleName, this._lastName].filter(Boolean).join(' ')
  }


}
