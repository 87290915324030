import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { GlobalState } from '../../../app.state'
import { PiMongoDBAnalyticsProcessorService } from '../processors/pi-mongodb-analytics-processor.service'

@Injectable()
export class PiMongodbAnalyticsService extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async syncIndexes (clusters: string[]): Promise<ResultObject<Record<string, boolean>>> {
    const body = JSON.stringify({ clusters })
    const response = await this.post(`api/admin_api/mongodb_analytics/sync_indexes`, body, true, 'application/json')
    return response.data
  }

  async getCollections (options: FilterArguments<CollectionStatsFilters>): Promise<MongoDBAnalyticsCollectionsResponse> {
    const queryString = MethodsService.objectToQueryString(options)
    const res = await this.get<MongoDBAnalyticsCollectionsResponse>(`api/admin_api/mongodb_analytics/collections?${queryString}`)
    return {
      filterOptions: res.data.filterOptions,
      count: res.data.count,
      data: PiMongoDBAnalyticsProcessorService.processCollections(res.data.data)
    }
  }

  async getCollectionsIndexes (options: FilterArguments<CollectionIndexStatsFilters>): Promise<MongoDBAnalyticsCollectionsIndexesResponse> {
    const queryString = MethodsService.objectToQueryString(options)
    const res = await this.get<MongoDBAnalyticsCollectionsIndexesResponse>(`api/admin_api/mongodb_analytics/collections_indexes?${queryString}`)
    return {
      filterOptions: res.data.filterOptions,
      count: res.data.count,
      data: PiMongoDBAnalyticsProcessorService.processCollectionsIndexes(res.data.data)
    }
  }

}

