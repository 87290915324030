import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { GlobalState } from '../../../app.state'
import { PITrafficMirroringProcessorService } from '../processors/traffic-mirroring-processor.service'

@Injectable()
export class PITrafficMirroringService extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async getMetadata (): Promise<TrafficMirroringMetadataResponse> {
    const response = await this.get(`api/admin_api/traffic_mirroring/metadata`)
    return PITrafficMirroringProcessorService.processMetadata(response.data)
  }

  async getMirrors (options: FilterArguments): Promise<CountedResultObject<EnrichedTrafficMirroringSchema[]>> {
    const queryString = MethodsService.objectToQueryString(options)
    const response = await this.get(`api/admin_api/traffic_mirrors?${queryString}`)
    if (typeof response.code == 'number' && response.code >= 400) {
      throw Error(response.message || '')
    }
    return response.success && {
      success: response.success,
      count: response.count,
      data: PITrafficMirroringProcessorService.process(response.data) as EnrichedTrafficMirroringSchema[]
    }
  }

  async getMirror (_id: string): Promise<EnrichedTrafficMirroringSchema> {
    const response = await this.get(`api/admin_api/traffic_mirroring/${_id}`)
    if (typeof response.code == 'number' && response.code >= 400) {
      throw Error(response.message || '')
    }
    return response.success && PITrafficMirroringProcessorService.process(response.data as ExtendedTrafficMirroringSchema) as EnrichedTrafficMirroringSchema
  }

  async updateMirror (_id: string, body: Partial<TrafficMirroringUpdateArguments>): Promise<boolean> {
    const response = await this.post(`api/admin_api/traffic_mirroring/update`, JSON.stringify({ _id, ...body }), true, 'application/json')
    if (typeof response.code == 'number' && response.code >= 400) {
      throw Error(response.error || response.message || response.data || '')
    }
    return response.success
  }

  async syncEdgeKV (): Promise<boolean> {
    const response = await this.post(`api/admin_api/traffic_mirroring/sync_edgekv`, JSON.stringify({}), true, 'application/json')
    if (typeof response.code == 'number' && response.code >= 400) {
      throw Error(response.error || response.message || response.data || '')
    }
    return response.success
  }

  async bundleWithNewToken (): Promise<boolean> {
    const response = await this.post(`api/admin_api/traffic_mirroring/bundle_with_new_token`, JSON.stringify({}), true, 'application/json')
    if (typeof response.code == 'number' && response.code >= 400) {
      throw Error(response.error || response.message || response.data || '')
    }
    return response.success
  }

  async deleteUnusedTokens (): Promise<string[]> {
    const response = await this.post(`api/admin_api/traffic_mirroring/delete_unused_tokens`, JSON.stringify({}), true, 'application/json')
    if (typeof response.code == 'number' && response.code >= 400) {
      throw Error(response.error || response.message || response.data || '')
    }
    return response?.deletedTokens || []
  }

  async createNewMirror (body: TrafficMirroringNewArguments): Promise<boolean> {
    const response = await this.post(`api/admin_api/traffic_mirroring/create`, JSON.stringify(body), true, 'application/json')
    if (response.success) {
      return response.success
    }
    throw Error(response.data)
  }

  async deleteMirrors (_ids: string[]): Promise<boolean> {
    const response = await this.post(`api/admin_api/traffic_mirroring/delete`, JSON.stringify({ _ids }), true, 'application/json')
    if (response.success) {
      return response.success
    }
    throw Error(response.data)
  }

}

