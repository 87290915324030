import { MethodsService } from '../../methods/methods.service'
import { ENV_SHORT_TO_FULL_RESOLVER, PiInstancesProcessorService } from './pi-instances-processor.service'

export class PiDeploymentsReleasesProcessorService {
  static process<T extends ExtendedDeploymentReleasesSchema> (groupOrGroups: T | T[]): T | T[] {
    return Array.isArray(groupOrGroups) ?
      groupOrGroups.map(PiDeploymentsReleasesProcessorService.processRelease) as T[] :
      PiDeploymentsReleasesProcessorService.processRelease(groupOrGroups) as T
  }

  static MapReleases (releases: ExtendedDeploymentReleasesSchema[]): ReleasesByImageEnv {
    const map: ReleasesByImageEnv = {
      [PiOperationalEnvironment.CLUSTER]: [],
      [PiOperationalEnvironment.MASTER]: [],
    }
    releases.forEach(release => map[release.imageEnvironment].push(PiDeploymentsReleasesProcessorService.processRelease(release)))
    return map
  }

  static getEligibleReleases (release: ExtendedDeploymentReleasesSchema[], deploymentEnvironment: PiDeploymentEnvironment) {
    const every = (image: DeploymentImagesSchema) => {
      if (!image.active) {
        return false
      }
      switch (deploymentEnvironment) {
        case PiDeploymentEnvironment.PRODUCTION:
          return image.deploymentEnvs.includes(deploymentEnvironment)
        case PiDeploymentEnvironment.CANARY:
          return image.deploymentEnvs.includes(deploymentEnvironment) || image.deploymentEnvs.includes(PiDeploymentEnvironment.PRODUCTION)
        case PiDeploymentEnvironment.STAGING:
          return image.deploymentEnvs.includes(deploymentEnvironment) || image.deploymentEnvs.includes(PiDeploymentEnvironment.CANARY) || image.deploymentEnvs.includes(PiDeploymentEnvironment.PRODUCTION)
        case PiDeploymentEnvironment.DEV:
          return true
      }
    }
    return release.map(release => {
      (release as any)._active = release.images.every(every)
      return release
    })
      .sort((a, b) => {
        const da = new Date(a.createdAt)
        const db = new Date(b.createdAt)
        return da > db ? -1 : da < db ? 1 : 0
      })
  }

  static getEligibleVersions (versions: DeploymentReleaseEnv, deployment: EnrichedCustomerMappingSchema, deployEnvironment: PiDeploymentEnvironment): DeploymentReleaseEnvVersion[] {

    const stgImages = versions[PiDeploymentEnvironment.STAGING][deployment.imageEnvironment]
    const cnrImages = versions[PiDeploymentEnvironment.CANARY][deployment.imageEnvironment]
    const prdImages = versions[PiDeploymentEnvironment.PRODUCTION][deployment.imageEnvironment]

    switch (deployEnvironment) {
      case PiDeploymentEnvironment.PRODUCTION:
        return returnSorted(prdImages)
      case PiDeploymentEnvironment.CANARY:
        return returnSorted(combineImages(cnrImages, prdImages))
      default:
        return returnSorted(stgImages)
    }

  }

  private static processRelease (release: ExtendedDeploymentReleasesSchema): ExtendedDeploymentReleasesSchema {
    (release as any)._env = release.imageEnvironment ? MethodsService.normalizeString(ENV_SHORT_TO_FULL_RESOLVER[release.imageEnvironment]) : '-'

    if (release.clusters) {
      release.clusters = PiInstancesProcessorService.process(release.clusters) as CustomerMappingSchema[]
    }

    ;(release as any)._clustersText = [
      'Associated Clusters:',
      ...(release as any).clusters.map(c => c.clusterId)
    ].join('\n')

    release.gitPages = release.gitPages?.sort()

    release.images = release.images.sort((a, b) => a.repository.localeCompare(b.repository))
    ;(release as any)._imagesObject = {}
    release.images.forEach(image => (release as any)._imagesObject[image.repository] = image)

    ;(release as any)._imagesText = [
      'Images:',
      ...(release as any).images.map(i => `${i.repository}:${i.tag}`)
    ].join('\n')

    return release
  }
}

const combineImages = <T extends DeploymentReleaseEnvVersion = DeploymentReleaseEnvVersion> (a: T[], b: T[]): T[] => {
  const arr: T[] = MethodsService.cloneJsonObject(a)
  for (const { versions, repository } of b) {
    const aRepo = arr.find(v => v.repository == repository)
    if (!aRepo) {
      continue
    }

    for (const version of aRepo.versions) {
      const aVersion = versions.find(v => v.image == version.image && v.tag == version.tag)
      if (!aVersion) {
        versions.push(MethodsService.cloneJsonObject(version))
      }
    }

    for (const version of versions) {
      const bVersion = aRepo.versions.find(v => v.image == version.image && v.tag == version.tag)
      if (!bVersion) {
        aRepo.versions.push(MethodsService.cloneJsonObject(version))
      }
    }

    aRepo.versions.sort((a, b) => {
      const pA = MethodsService.padVersion(a.version)
      const pb = MethodsService.padVersion(b.version)
      return pA > pb ? -1 : pA < pb ? 1 : 0
    })
  }

  return arr
}

const returnSorted = (images: DeploymentReleaseEnvVersion[]) => images
  .sort((a, b) => a.repository > b.repository ? 1 : a.repository < b.repository ? -1 : 0)
