import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { MethodsService } from '../../services/methods/methods.service'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { LoggerService } from '../../services/logger/logger.service'
import { LogsService } from '../../services/api/services/logs.service'
import { GlobalNotification, GlobalState } from '../../app.state'

@Component({
  selector: 'pi-activity-feed',
  templateUrl: './activity-feed.component.html',
  styleUrls: ['./activity-feed.component.scss']
})
export class PIActivityFeedComponent extends TableWithFilters implements OnInit, OnDestroy {

  readonly title = 'Activity Feed'

  logs: LogsSchema[] = []

  selectedLog: LogsSchema

  filterValue: string = ''

  //table
  readonly tableHeaders: TableHeader[]

  currentSortBy: string

  paginationName = 'Logs'

  collections: CxMasterCollections[] = []
  selected_collections: CxMasterCollections[] = []

  users: string[] = []
  selected_users: string[] = []

  actions: string[] = [LOG_ACTION.create, LOG_ACTION.delete, LOG_ACTION.modify].map(a => MethodsService.normalizeString(a))
  selected_actions = this.actions
  _isRefreshing: boolean = false
  textToClip = MethodsService.copyToClipboard
  normalizeString = MethodsService.normalizeString
  private _debounce: void | number
  private debounce_timeout: number = 1000

  constructor (private _logs: LogsService, private _zone: NgZone, private _state: GlobalState) {
    super()
    this.tableHeaders = [
      //@formatter:off
      { name: 'Log ID',                 sortable: true,   field: '_id'                },
      { name: 'Timestamp',              sortable: true,   field: 'createdAt'          },
      { name: 'Action',                 sortable: true,   field: 'action'             },
      { name: 'Collection',             sortable: true,   field: 'col'                },
      { name: 'User',                   sortable: true,   field: 'user.email'         },
      { name: 'Info',                   sortable: false,                              },
      //@formatter:on
    ].filter(x => !!x)
    this.currentItemsPerPage = this.itemsPerPageFilter[2]
    this.currentSortBy = this.tableHeaders[1].field
  }

  ngOnDestroy (): void {
    window.removeEventListener('keyup', this.registerModalListeners.bind(this))
  }

  ngOnInit (): void {
    window.addEventListener('keyup', this.registerModalListeners.bind(this))
    this.refreshTable!(true)
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(false, true))
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    if (!this.selectedLog) {
      return
    }
    if (key === 'Escape') {
      this.selectedLog = undefined
    }
  }

  async refreshTable (init: boolean = false, background: boolean = false): Promise<void> {
    if (this._isRefreshing) {
      return
    }
    this._isRefreshing = true
    try {
      if (!background) {
        this.selectedLog = undefined
        SpinnerService.spin('mini')
      }

      const [{ data, count }, { collections, users }] = await Promise.all([
        this.getLogs(),
        this._logs.getUniqueData()
      ])

      //validate
      if (data && typeof count === 'number') {
        this.numOfAvailableDocs = count
        this.logs = data.filter(l => !!(l as any)._info)
        this.users = users
        this.collections = collections
        if (init) {
          this.selected_users = users
          this.selected_collections = collections
        }
        if (!background) {
          LoggerService.info('Logs:', this.logs)
        }
      } else {
        throw Error('Bad Response')
      }
    } catch (e) {
      if (!background) {
        MethodsService.toast('error', 'Error fetching logs', e.toString(), 8)
      }
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._zone.run(() => {})
      this._isRefreshing = false
    }
  }

  onFilterChange (item: any) {
    if (this._debounce) {
      this._debounce = clearTimeout(this._debounce)
    }
    this._debounce = setTimeout(() => this.refreshTable(), this.debounce_timeout) as any as number
  }

  private async getLogs (): Promise<CountedResultObject<LogsSchema[]>> {
    return await this._logs.getAllLogs({
      users: this.selected_users,
      actions: this.selected_actions,
      collections: this.selected_collections,
      filter: this.filterValue,
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sort_by: this.currentSortBy,
      sort_direction: this.isSortReversed ? 'asc' : 'desc'
    })
  }

}
