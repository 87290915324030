export class AnalyticsProcessorService {
  static process<T extends AnalyticsIntegrationsSchema>(integrations: T[], filter: string): T[] {
      return AnalyticsProcessorService.processIntegrationsSearchFilter(integrations, filter) as T[]
  }

  private static processIntegrationsSearchFilter(integrations: AnalyticsIntegrationsSchema[], filter: string): AnalyticsIntegrationsSchema[] {
    const filterValLowercase = filter.toLowerCase()
    return integrations.filter(o =>
      o.integrationId.toLowerCase().includes(filterValLowercase) ||
      o.provider.toLowerCase().includes(filterValLowercase) ||
      o.name.toLowerCase().includes(filterValLowercase)
    )
  }
}