import { MethodsService } from '../../methods/methods.service'
import { AZURE_REGIONS } from '../../../shared/azure-regions'

export const ENV_TO_FREE_STRING: Record<CxEnvironment, string> = {
  [CxEnvironment.LOCAL]: 'local',
  [CxEnvironment.DEV]: 'dev',
  [CxEnvironment.STAGING]: 'staging',
  [CxEnvironment.PRODUCTION]: 'production'

}

export const ENV_TO_DEPLOY_FULL_RESOLVER: Record<CxEnvironment, PiDeploymentEnvironmentFull | string> = {
  [CxEnvironment.LOCAL]: 'local',
  [CxEnvironment.DEV]: PiDeploymentEnvironmentFull.DEV,
  [CxEnvironment.STAGING]: PiDeploymentEnvironmentFull.STAGING,
  [CxEnvironment.PRODUCTION]: PiDeploymentEnvironmentFull.PRODUCTION
}

export const ENV_DEPLOY_TO_DEPLOY_FULL_RESOLVER: Record<PiDeploymentEnvironment, PiDeploymentEnvironmentFull> = {
  [PiDeploymentEnvironment.DEV]: PiDeploymentEnvironmentFull.DEV,
  [PiDeploymentEnvironment.STAGING]: PiDeploymentEnvironmentFull.STAGING,
  [PiDeploymentEnvironment.CANARY]: PiDeploymentEnvironmentFull.CANARY,
  [PiDeploymentEnvironment.PRODUCTION]: PiDeploymentEnvironmentFull.PRODUCTION
}

export const ENV_DEPLOY_FULL_TO_DEPLOY_RESOLVER: Record<PiDeploymentEnvironmentFull, PiDeploymentEnvironment> = {
  [PiDeploymentEnvironmentFull.DEV]: PiDeploymentEnvironment.DEV,
  [PiDeploymentEnvironmentFull.STAGING]: PiDeploymentEnvironment.STAGING,
  [PiDeploymentEnvironmentFull.CANARY]: PiDeploymentEnvironment.CANARY,
  [PiDeploymentEnvironmentFull.PRODUCTION]: PiDeploymentEnvironment.PRODUCTION
}

export const ENV_SHORT_TO_DEPLOY_RESOLVER: Record<Exclude<CxEnvironmentShort, CxEnvironmentShort.LOCAL>, PiDeploymentEnvironment> = {
  [CxEnvironmentShort.DEV]: PiDeploymentEnvironment.DEV,
  [CxEnvironmentShort.STAGING]: PiDeploymentEnvironment.STAGING,
  [CxEnvironmentShort.PRODUCTION]: PiDeploymentEnvironment.PRODUCTION
}

export const ENV_SHORT_TO_DEPLOY_FULL_RESOLVER: Record<Exclude<CxEnvironmentShort, CxEnvironmentShort.LOCAL>, PiDeploymentEnvironmentFull> = {
  [CxEnvironmentShort.DEV]: PiDeploymentEnvironmentFull.DEV,
  [CxEnvironmentShort.STAGING]: PiDeploymentEnvironmentFull.STAGING,
  [CxEnvironmentShort.PRODUCTION]: PiDeploymentEnvironmentFull.PRODUCTION
}

export const ENV_DEPLOY_FULL_TO_SHORT_RESOLVER: Record<Exclude<PiDeploymentEnvironmentFull, PiDeploymentEnvironmentFull.CANARY>, CxEnvironmentShort> = {
  [PiDeploymentEnvironmentFull.DEV]: CxEnvironmentShort.DEV,
  [PiDeploymentEnvironmentFull.STAGING]: CxEnvironmentShort.STAGING,
  [PiDeploymentEnvironmentFull.PRODUCTION]: CxEnvironmentShort.PRODUCTION
}

export const ENV_SHORT_TO_FULL_RESOLVER: Record<CxEnvironmentShort, CxEnvironment> = {
  [CxEnvironmentShort.LOCAL]: CxEnvironment.LOCAL,
  [CxEnvironmentShort.DEV]: CxEnvironment.DEV,
  [CxEnvironmentShort.STAGING]: CxEnvironment.STAGING,
  [CxEnvironmentShort.PRODUCTION]: CxEnvironment.PRODUCTION
}

export const ENV_FULL_TO_SHORT_RESOLVER: Record<CxEnvironment, CxEnvironmentShort> = {
  [CxEnvironment.LOCAL]: CxEnvironmentShort.LOCAL,
  [CxEnvironment.DEV]: CxEnvironmentShort.DEV,
  [CxEnvironment.STAGING]: CxEnvironmentShort.STAGING,
  [CxEnvironment.PRODUCTION]: CxEnvironmentShort.PRODUCTION
}

export function ShortEnvToDeploymentEnv (shortEnv: CxEnvironmentShort, isCanary: boolean = false): PiDeploymentEnvironment {
  if (shortEnv == CxEnvironmentShort.PRODUCTION && isCanary) {
    return PiDeploymentEnvironment.CANARY
  }
  return ENV_SHORT_TO_DEPLOY_RESOLVER[shortEnv]
}

const static_operations_domain = 'pilayer.net'

export class PiInstancesProcessorService {
  static enrichInstance (data: ProcessedPIOperationsEnvironments[], cluster: CustomerMappingSchema): EnrichedCustomerMappingSchema {
    const enrichedDoc: Partial<EnrichedCustomerMappingSchema> = {
      ...cluster,
    }

    const country_code =
      data
        .flatMap(d => d.regions)
        .find(r => r.azure_location_code == cluster.geo)?.country_code

    enrichedDoc._country_code = country_code || AZURE_REGIONS[cluster.geo]

    enrichedDoc._full_env = data.find(e => e.id == cluster.environment).name

    enrichedDoc._deployment_env = ShortEnvToDeploymentEnv(cluster.environment, cluster.isCanary)

    enrichedDoc._full_deploy_env = enrichedDoc._deployment_env == PiDeploymentEnvironment.CANARY ? 'Production Canary' : enrichedDoc._deployment_env

    enrichedDoc._imagesText = [
      'Current Images:',
      ...enrichedDoc.images.map(({ repository, tag }) => `${repository}:${tag}`)
    ].join('\n')

    return enrichedDoc as EnrichedCustomerMappingSchema
  }

  static process<T extends CustomerMappingSchema> (clusterOrClusters: T | T[]): T | T[] {
    return Array.isArray(clusterOrClusters) ?
      clusterOrClusters.map(PiInstancesProcessorService.processInstance) as T[] :
      PiInstancesProcessorService.processInstance(clusterOrClusters) as T
  }

  static processUniqueData (data: PIOperationsDataRequest): ProcessedPIOperationsEnvironments[] {
    return data.environments
      // .filter(env => eligibleEnvs.includes(env.id.trim() as Expected<CxEnvironmentShort>))
      .map(env => ({
        id: env.id,
        name: env.name,
        regions: env.regions
          .map(id => data.regions.find(region => region.id == id)),

        plans: env.plans
          .map(id => data.plans.find(plan => plan.id == id))
      }))
  }

   static processInstance (cluster: CustomerMappingSchema): CustomerMappingSchema {
    //alias
    const _c = cluster as EnrichedCustomerMappingSchema
    _c._env = cluster.environment ? MethodsService.normalizeString(ENV_SHORT_TO_FULL_RESOLVER[cluster.environment]) : '-'

    _c._endpoints = [
      { key: 'argo', value: `https://pi-ops-argo.pilayer.net/workflows` },
      { key: 'keel', value: `https://${cluster.clusterId}-keel.${static_operations_domain}` },
      { key: 'grafana', value: `https://${cluster.clusterId}-grafana.${static_operations_domain}` },
      { key: 'dashboard', value: `https://${cluster.clusterId}-dashboard.${static_operations_domain}` },
      { key: 'prometheus', value: `https://${cluster.clusterId}-prometheus.${static_operations_domain}` },
      { key: 'alertmanager', value: `https://${cluster.clusterId}-alertmanager.${static_operations_domain}` },
      { key: 'linkerd', value: `https://${cluster.clusterId}-linkerd.${static_operations_domain}` },
    ]

    if (Array.isArray(cluster.images)) {
      _c._imagesObject = {}
      cluster.images.forEach(image => (cluster as any)._imagesObject[image.repository] = image)
    }

    return cluster
  }
}

export function isInstanceActive (status: CLUSTER_STATUS): boolean {
  return [
    CLUSTER_STATUS.ACTIVE,
    CLUSTER_STATUS.TESTING,
    CLUSTER_STATUS.UPDATING,
    CLUSTER_STATUS.SUCCEEDED
  ]
    .some(s => s == status)
}

export function canPerformActionOnInstance (status: CLUSTER_STATUS): boolean {
  return [
    CLUSTER_STATUS.ACTIVE,
    CLUSTER_STATUS.SUCCEEDED
  ]
    .some(s => s == status)
}
