import { Component, Input, OnInit } from '@angular/core'
import { DataService } from '../../../services/data/data.service'
import { MethodsService } from '../../../services/methods/methods.service'

@Component({
  selector: 'cx-doughnut-chart',
  templateUrl: './cx-doughnut-chart.component.html',
  styleUrls: ['./cx-doughnut-chart.component.scss']
})

export class CxDoughnutChartComponent implements OnInit {
  public readonly doughnutChartType: Chart.ChartType = 'doughnut'
  public readonly UNIQUE_CHART_ID = MethodsService.generateUniqueID('letters')
  public readonly UNIQUE_CANVAS_ID = MethodsService.generateUniqueID('letters')

  @Input() public doughnutChartLabels: string[]
  @Input() public doughnutChartData: number[]
  @Input() public doughnutChartLegend: boolean
  @Input() public doughnutChartLegendPosition?: Chart.PositionType
  @Input() public doughnutCutoutPercentage?: number
  @Input() public doughnutChartColors?: Chart.ChartArcOptions[]
  @Input() public style?: Obj<string>
  private doughnutChartOptions: Chart.ChartOptions

  public isReady = false

  constructor () {
  }

  ngOnInit () {
    this.style = this.style || {}
    this.doughnutChartColors = this.doughnutChartColors || [
      {
        backgroundColor: [...DataService.chartsColors.pie_colors_dark.map(color => MethodsService.hexToRGBA(color))]
      }
    ]
    this.doughnutChartOptions = {
      responsive: true,
      cutoutPercentage: this.doughnutCutoutPercentage || 65,
      legend: {
        labels: {
          boxWidth: 10,
          fontFamily: '\'Open Sans\', sans-serif',
          usePointStyle: true
        },
        position: 'top'
      }
    }
    this.doughnutChartOptions.legend.position = this.doughnutChartLegendPosition || this.doughnutChartOptions.legend.position
    this.isReady = true
  }

}
