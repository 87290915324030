import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { PITenantsService } from '../../services/api/services/pi-tenants.service'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { LoggerService } from '../../services/logger/logger.service'
import { MethodsService } from '../../services/methods/methods.service'
import { PIInstancesService } from '../../services/api/services/pi-instances.service'
import { UserDataService } from '../../services/user-data/user-data.service'
import { USER_POWER_LEVEL } from '../../shared/user-power'
import { GlobalNotification, GlobalState } from '../../app.state'
import { CLUSTER_STATUSES } from '../../services/data/data.service'
import {
  ENV_DEPLOY_FULL_TO_DEPLOY_RESOLVER,
  ENV_DEPLOY_TO_DEPLOY_FULL_RESOLVER,
  ENV_SHORT_TO_DEPLOY_FULL_RESOLVER,
  ENV_SHORT_TO_DEPLOY_RESOLVER,
  PiInstancesProcessorService,
  ShortEnvToDeploymentEnv
} from '../../services/api/processors/pi-instances-processor.service'
import { PIDeploymentImagesService } from '../../services/api/services/pi-deployment-images.service'
import { PIDeploymentImagesProcessor } from '../../services/api/processors/deployment-images-processor.service'
import { PiDeploymentReleasesService } from '../../services/api/services/pi-deployment-releases.service'
import {
  PiDeploymentsReleasesProcessorService
} from '../../services/api/processors/pi-deployment-releases-processor.service'

@Component({
  selector: 'pi-deployments',
  templateUrl: './pi-depyloyments.component.html',
  styleUrls: ['./pi-depyloyments.component.scss']
})
export class PIDeploymentsComponent<T extends EnrichedCustomerMappingSchema = EnrichedCustomerMappingSchema> extends TableWithFilters implements OnInit, OnDestroy {

  readonly title = 'PI Deployments'

  readonly paginationName = 'Clusters'

  filterValue: string = ''

  //resolvers
  CLUSTER_STATUSES = CLUSTER_STATUSES
  ENV_SHORT_TO_DEPLOY_RESOLVER = ENV_SHORT_TO_DEPLOY_RESOLVER
  ENV_SHORT_TO_DEPLOY_FULL_RESOLVER = ENV_SHORT_TO_DEPLOY_FULL_RESOLVER

  clusters: T[] = []
  availableVersions: DockerImagesAvailableVersions
  availableImages: DeploymentReleaseEnv
  inactiveImages: InactiveImagesSets
  allReleases: ReleasesByImageEnv

  allRepositories: PiDeploymentsMetadata['repositories']

  //state
  currentEligibleReleases: ExtendedDeploymentReleasesSchema[] = []
  currentImages: DeploymentReleaseEnvVersion[] = []
  currentImagesHash: string
  // isCurrentImagesHashMatchRelease: boolean = false

  isAdmin: boolean = false

  //modals
  selectedDeployment: T

  //table
  readonly tableHeaders: TableHeader[]
  currentSortBy: string
  _isRefreshing: boolean = false

  //environments filter
  availableDeploymentEnvironments: string[] = []
  currentDeploymentEnvironments = this.availableDeploymentEnvironments

  //operational filter
  availableOperationalEnvironments: string[] = []
  currentOperationalEnvironments = this.availableOperationalEnvironments

  textToClip = MethodsService.copyToClipboard

  normalizeString = MethodsService.normalizeString

  upperCasedString = MethodsService.upperCasedString

  private _debounce: void | number
  private debounce_timeout: number = 1000

  constructor (private _userData: UserDataService, private _clusters: PIInstancesService, private _images: PIDeploymentImagesService, private _releases: PiDeploymentReleasesService, private _customers: PITenantsService, private _zone: NgZone, private _state: GlobalState) {
    super()
    this.currentItemsPerPage = this.itemsPerPageFilter[1]
    this.tableHeaders = [
      //@formatter:off
      { /*width: '12',*/  name: 'Created',              sortable: true,     field: 'createdAt'          },
      { /*width: '12',*/  name: 'Updated',              sortable: true,     field: 'DRSUpdatedAt'       },
      { /*width: '12',*/  name: 'Cluster Id',           sortable: true,     field: 'clusterId'          },
      { /*width: '12',*/  name: 'Name',                 sortable: true,     field: 'clusterName'        },
      { /*width: '12',*/  name: 'Location',             sortable: true,     field: 'geo'                },
      { /*width: '12',*/  name: 'Environment',          sortable: true,     field: 'environment'        },
      { /*width: '12',*/  name: 'Images Environment',   sortable: true,     field: 'clusterEnvironment' },
      { /*width: '12',*/  name: 'Deployment Group',     sortable: true,     field: 'deploymentGroup'    },
      { /*width: '12',*/  name: 'Release',              sortable: true,     field: 'release.release'    },
      { /*width: '12',*/  name: 'Images',               sortable: true,     field: 'numOfImages'        },
      { /*width: '12',*/  name: 'Actions',                 sortable: false                                 },

      //@formatter:on
    ].filter(x => !!x)

    this.currentSortBy = this.tableHeaders[1].field
    this.isAdmin = USER_POWER_LEVEL[this._userData.permission] >= USER_POWER_LEVEL[UserPermission.ADMIN]
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    if (!this.selectedDeployment) {
      return
    }
    if (key === 'Escape') {
      this.clearSelections()
    }
  }

  ngOnDestroy (): void {
    window.removeEventListener('keyup', this.registerModalListeners.bind(this))
  }

  onFilterChange (item: any) {
    if (this._debounce) {
      this._debounce = clearTimeout(this._debounce)
    }
    this._debounce = setTimeout(() => this.refreshTable(), this.debounce_timeout) as any as number
  }

  ngOnInit (): void {
    window.addEventListener('keyup', this.registerModalListeners.bind(this))
    this.refreshTable!()
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  openCreateGroupModal () {}

  selectDeployment (deployment: T) {
    const environment = ShortEnvToDeploymentEnv(deployment.environment, deployment.isCanary)

    this.currentEligibleReleases = PiDeploymentsReleasesProcessorService.getEligibleReleases(this.allReleases[deployment.imageEnvironment], environment)
    this.currentImages = PiDeploymentsReleasesProcessorService.getEligibleVersions(this.availableImages, deployment, environment)

    this.currentImagesHash = deployment.imagesHash
    if (!this.currentImagesHash) {
      const images: DockerImageContainerNameSchema[] = []
      this.currentImages.forEach(({ repository, versions }) => {
        const currentImage = deployment.images?.find(image => image.repository == repository)
        images.push(currentImage?.image || versions[0].image)
      })
      this.currentImagesHash = PIDeploymentImagesProcessor.createImagesHash(images)
    }

    this.selectedDeployment = deployment
  }

  updateImagesHash (form: HTMLFormElement) {
    const repositories = this.currentImages.map(image => image.repository)
    const versions = this.currentImages.map(image => image.versions).flat()
    const images: DockerImageContainerNameSchema[] = []

    for (const repository of repositories) {
      const element = form[repository]
      const _id = element.value
      const { image } = versions.find(v => v._id == _id) || {}
      if (!image) {
        continue
      }
      images.push(image)
    }

    this.currentImagesHash = PIDeploymentImagesProcessor.createImagesHash(images)
  }

  setImagesFromRelease (form: HTMLFormElement, release_id: string) {
    const release = this.currentEligibleReleases.find(r => r._id == release_id)
    if (!release) {
      return
    }

    release.images.forEach(({ _id, repository }) => {
      let option_index = form[repository].selectedIndex
      const options_array = Array.from(form[repository].options) as HTMLSelectElement[]
      for (let i = 0; i < options_array.length; i++) {
        const element = options_array[i]
        if (element.value == _id) {
          option_index = i
        }
      }
      form[repository].selectedIndex = option_index
      form[`${repository}_custom`].value = ''
    })
  }

  isCurrentImagesHashMatchRelease (form: HTMLFormElement, release_id?: string): boolean {
    if (!release_id) {
      return false
    }

    //any custom image
    if (this.selectedDeployment.environment == CxEnvironmentShort.DEV || this.selectedDeployment.environment == CxEnvironmentShort.STAGING) {
      const repos = this.currentImages.map(image => image.repository)
      for (const repository of repos) {
        if (!!form[`${repository}_custom`]?.value) {
          return false
        }
      }
    }

    const release = this.currentEligibleReleases.find(r => r._id == release_id)!
    if (!release) {
      return false
    }

    if (!release.imagesHash || !this.currentImagesHash) {
      return false
    }

    return release.imagesHash == this.currentImagesHash
  }

  isNewerVersionAvailable (versions: DeploymentReleaseEnvVersionVersions[], id: string): boolean {
    for (const { active, _id } of versions) {
      if (_id == id) {
        return false
      }
      if (active) {
        return true
      }
    }
    return false
  }

  async updateDeployment (form: HTMLFormElement, isCanary: boolean, deployImages?: boolean) {
    const isCanaryEligible = this.selectedDeployment.environment == CxEnvironmentShort.PRODUCTION
    const isCanaryChanged = this.selectedDeployment.isCanary != isCanary

    const updateOptions: UpdateDRSClusterOptions = {}

    if (this.selectedDeployment.DRSUpdatedAt) {
      updateOptions.updatedAt = new Date(this.selectedDeployment.DRSUpdatedAt).getTime()
    }

    if (isCanaryEligible && isCanaryChanged) {
      updateOptions.isCanary = isCanary
    }

    if (this.currentEligibleReleases.find(r => r.imagesHash == this.currentImagesHash && (r as any)._active === false)) {
      return MethodsService.dialog('Error', `Cannot apply disabled release!`)
    }


    if (deployImages) {
      const scope = Object.fromEntries(Object.entries(
        this.allRepositories[this.selectedDeployment.imageEnvironment]
      ).filter(([key]) => !!this.currentImages.find(ci => ci.repository == key)))

      updateOptions.images = []

      for (const repository in scope) {
        const customImage = form[`${repository}_custom`].value
        if (customImage) {
          updateOptions.images.push({
            repository: repository as PiRepository,
            tag: customImage,
            isCustomImage: true,
            autoPullImage: form[`${repository}_autopull`].checked
          })
        } else {
          updateOptions.images.push({
            repository: repository as PiRepository,
            tag: this.availableVersions[this.selectedDeployment._deployment_env][this.selectedDeployment.imageEnvironment][repository]
              .find(image => image._id == form[repository].value)!.tag,
            _id: form[repository].value,
            isCustomImage: false
          })
        }
      }
    }

    try {
      SpinnerService.spin('mini')
      const updatedGroup = await this._clusters.updateDRSCluster(this.selectedDeployment._id, updateOptions)
      if (updatedGroup) {
        await this.refreshTable(false, true)
      }
    } catch (e) {
      MethodsService.toast('error', `Error editing deployment ${this.selectedDeployment.clusterId}`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }
  }

  async refreshTable (background: boolean = false, ignoreSelected: boolean = false): Promise<void> {
    if (this._isRefreshing || (!ignoreSelected && this.selectedDeployment)) {
      return
    }
    this._isRefreshing = true
    try {
      if (!background) {
        this.clusters = []
        this.clearSelections()
        SpinnerService.spin('mini')
      }
      const [
        { data, count },
        versions,
        releases,
        { repositories, deploymentEnvironments, operationalEnvironments },
        uniqueData
      ] = await Promise.all([
        this.getClusters(),
        this._images.getAvailableVersions(),
        this._releases.getAllDeploymentReleases(),
        this._clusters.getDRSFilteringOptions(),
        this._clusters.getUniqueData()
      ].filter(x => !!x) as PromiseLike<any>[])

      this.numOfAvailableDocs = count
      this.clusters = data.map(cluster => PiInstancesProcessorService.enrichInstance(uniqueData, cluster))

      this.availableVersions = versions
      this.availableImages = PIDeploymentImagesProcessor.getVersionsSchema(versions)
      this.inactiveImages = PIDeploymentImagesProcessor.getInactiveImagesSet(versions)

      this.allReleases = releases
      this.availableDeploymentEnvironments = deploymentEnvironments.map(s => ENV_DEPLOY_TO_DEPLOY_FULL_RESOLVER[s]).sort().filter(Boolean)

      this.allRepositories = repositories
      this.availableOperationalEnvironments = operationalEnvironments.map(s => MethodsService.normalizeString(s)).sort()
    } catch (e) {
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._zone.run(() => {})
      this._isRefreshing = false
    }

  }

  private clearSelections () {
    this.currentImages = []
    this.currentEligibleReleases = []
    this.currentImagesHash = undefined
    this.selectedDeployment = undefined
  }

  private async getClusters (options?: FilterArguments) {
    options = options || {
      filter: this.filterValue,
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sort_by: this.currentSortBy,
      sort_direction: this.isSortReversed ? 'asc' : 'desc',
    }

    if (this.currentDeploymentEnvironments?.length) {
      options.deployment_envs = this.currentDeploymentEnvironments.map(s => ENV_DEPLOY_FULL_TO_DEPLOY_RESOLVER[s])
    }

    if (this.currentOperationalEnvironments?.length) {
      options.operational_envs = this.currentOperationalEnvironments.map(s => s.toUpperCase())
    }

    return await this._clusters.getAllClusters(options)
  }
}
