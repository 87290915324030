import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'fav-icon',
  templateUrl: './fav-icon.component.html',
  styleUrls: ['./fav-icon.component.scss'],
})
export class FavIconComponent implements OnInit {

  @Input() public domain?: string
  @Input() public url?: string
  @Input() public injectClass: string = ''
  @Input() private sub?: boolean = false

  @Input() private size?: 'sm' | 'md' | 'lg' = 'sm'

  public src: string

  public iconClass: string
  private readonly google_fav_url = 'https://www.google.com/s2/favicons?domain='

  constructor () {
  }

  ngOnInit (): void {
    this.iconClass = (this.sub ? 'img-icon-sub' : 'img-icon') + ` img-icon-${this.size} ` + this.injectClass

    if (this.domain?.length && this.url?.length) {
      throw Error('pls provide either domain or url, but not both')
    }

    switch (true) {

      case !!this.domain?.length: {
        this.src = this.getGoogleFavIconUrl(this.domain)
        break
      }

      case !!this.url?.length: {
        this.src = this.url
        break
      }

      default: {
        this.src = this.getGoogleFavIconUrl('noFav')
      }
    }

  }

  private getGoogleFavIconUrl (search: string) {
    return this.google_fav_url + encodeURIComponent(search)
  }

}
