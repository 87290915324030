import { MethodsService } from '../../methods/methods.service'
import { ENV_BADGES } from './deployment-images-processor.service'
import { TenantName } from '../../data/data.service'

export class PITrafficMirroringProcessorService {

  static process<T extends ExtendedTrafficMirroringSchema> (mirrorOrMirrors: T | T[]): EnrichedTrafficMirroringSchema | EnrichedTrafficMirroringSchema[] {
    return Array.isArray(mirrorOrMirrors) ?
      mirrorOrMirrors.map(PITrafficMirroringProcessorService.processMirror) :
      PITrafficMirroringProcessorService.processMirror(mirrorOrMirrors)
  }

  static processMetadata<T extends TrafficMirroringMetadataResponse> (metadata: T): T {

    for (const env of ['dev', 'staging']) {
      for (const obj of metadata[env] as any as TrafficMirroringAvailableTenants[]) {
        obj._name = obj.name
        obj.name = TenantName(obj.customerId, obj.clusterId, obj._name)
      }
    }

    //check if token has more than 1 month to expire
    if (metadata.edgeworker.edgekv_token_expiration) {
      const { text, icon } = getTokenExpirationData(metadata.edgeworker.edgekv_token_expiration)
      metadata.edgeworker._expiry_text = text
      metadata.edgeworker._expiry_icon = icon
    }

    if (metadata.edgeworker.version_created_at) {
      metadata.edgeworker._bundle_created_at = new Date(metadata.edgeworker.version_created_at).toLocaleString()
    }

    return metadata
  }

  private static processMirror (mirror: ExtendedTrafficMirroringSchema): EnrichedTrafficMirroringSchema {
    const _alias = mirror as EnrichedTrafficMirroringSchema
    _alias._env = MethodsService.normalizeString(mirror.environment)
    _alias._targetEnv = MethodsService.normalizeString(mirror.targetEnvironment)
    _alias._envBadge = ENV_BADGES.find(e => e.standard == mirror.environment)!
    _alias._targetEnvBadge = ENV_BADGES.find(e => e.standard == mirror.targetEnvironment)!
    _alias._duplicationTenants = [
      'Duplication Tenants Ids:',
      ..._alias.customerDuplicationIds || []
    ].join('\n')
    return _alias
  }
}

function getTokenExpirationData (exp: number): { icon: string, text: string } {
  const _d = new Date(exp)
  switch (true) {
    // more than 1 month
    case _d > new Date(Date.now() + 1000 * 60 * 60 * 24 * 30):
      return {
        icon: 'fa fa-check-circle-o color-green',
        text: `Expires in ${Math.floor((_d.getTime() - Date.now()) / (1000 * 60 * 60 * 24 * 30))} months - At: ${_d.toLocaleDateString()}`
      }
    // less than 1 month
    case _d < new Date(Date.now() + 1000 * 60 * 60 * 24 * 30):
      return {
        icon: 'fa fa-warning color-orange',
        text: `Expires in ${Math.floor((_d.getTime() - Date.now()) / (1000 * 60 * 60 * 24))} days - At: ${_d.toLocaleDateString()}`
      }
    // less than 14 days
    case _d < new Date(Date.now() + 1000 * 60 * 60 * 24 * 14):
      return {
        icon: 'fa fa-exclamation-triangle color-red',
        text: `Expires in ${Math.floor((_d.getTime() - Date.now()) / (1000 * 60 * 60 * 24))} days - At: ${_d.toLocaleDateString()}`
      }
  }
}
