import { Component, Input, OnInit } from '@angular/core'
import { COUNTRY_CODES_TO_FULL, COUNTRY_FULL_TO_CODES } from '../cx-world-map/country-codes'

@Component({
  selector: 'cx-country',
  templateUrl: './cx-country.component.html',
  styleUrls: ['./cx-country.component.scss'],
})
export class CxCountryComponent implements OnInit {

  @Input() country: string

  @Input() class?: string

  @Input() labelClass?: string

  @Input() classFunction?: Function

  @Input() title?: boolean = false

  @Input() showLabel?: boolean = true

  @Input() private sub?: boolean = false

  iconClass: 'img-icon' | 'img-icon-sub'

  countryCode: string

  dataReady: boolean = false

  countryName: string

  constructor () {
  }

  ngOnInit (): void {
    this.class = this.class || ''
    this.labelClass = this.labelClass || ''
    this.iconClass = this.sub ? 'img-icon-sub' : 'img-icon'
    this.countryCode = this.getCountryCode()
    this.countryName = this.getCountryName()
    this.dataReady = true
  }

  private getCountryCode (): string {
    if (COUNTRY_FULL_TO_CODES[this.country]) {
      return COUNTRY_FULL_TO_CODES[this.country].toLowerCase()
    }
    if (COUNTRY_CODES_TO_FULL[this.country.toUpperCase()]) {
      return this.country.toLowerCase()
    }

    const countryFound = Object.keys(COUNTRY_FULL_TO_CODES).find(countryName => {
      const lowerCaseCountryName = countryName.toLowerCase()
      const lowerCaseInputCountry = this.country.toLowerCase()
      return lowerCaseCountryName.includes(lowerCaseInputCountry) || lowerCaseInputCountry.includes(lowerCaseCountryName)
    })

    if (countryFound){
      return COUNTRY_FULL_TO_CODES[countryFound].toLowerCase()
    }
  }

  private getCountryName (): string {
    return COUNTRY_CODES_TO_FULL[this.countryCode?.toUpperCase() || ''] || this.country
  }

}
