import { Component, Input, NgZone, OnInit, } from '@angular/core'
import { MethodsService } from '../../../services/methods/methods.service'
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'pi-trend-chart',
  templateUrl: './pi-trend-chart.component.html',
  styleUrls: ['./pi-trend-chart.component.scss']
})
export class PITrendChartComponent implements OnInit {
  readonly _id = 'pi-trend-chart-'
  readonly _wrapper_id = 'pi-trend-chart-'
  readonly _tooltip_tag = 'ngb-tooltip-window'
  readonly _helper_tag = 'svg-helper-'
  readonly _position_line_tag = 'svg-midline-'

  _helper_element: HTMLDivElement

  tooltipText: string = ''

  isReady = false

  @Input() data: (StandardObject | number)[]
  @Input() dataKey?: string
  @Input() width: number = 160
  @Input() height: number = 40
  @Input() lineWidth: number = 5
  @Input() padding: number = 4
  @Input() yColor: string = '#ffd200'
  @Input() downColor: string = '#0e3762'
  @Input() upColor: string = '#FF9933'
  @Input() markerWidth: number = 1
  @Input() markerColor: string = 'black'
  @Input() maxData?: number | string
  @Input() minData?: number | string
  @Input() positionLine?: number
  @Input() allowOverflow: boolean = true
  @Input() numberFormat: 'count' | 'bytes' = 'count'
  @Input() dateKey: string = 'timestamp'
  @Input() keepParams?: string[] = undefined
  @Input() formatParam?: Record<string, 'count' | 'bytes'> = {}
  // @Input() markerDeviation: number = 0.012

  integerWithCommas = MethodsService.integerWithCommas

  numArray: number[] = []

  currentCount: number = 0
  timeout: boolean = false
  positionLineStyle: StandardObject

  constructor (private _zone: NgZone) {
    const random = MethodsService.generateUniqueID()
    this._id += random
    this._wrapper_id += random
    this._helper_tag += random
    this._position_line_tag += random
  }

  ngOnInit () {
    this.data = JSON.parse(JSON.stringify(this.data))
    if (this.keepParams) {
      for (const trend of this.data) {
        for (const key in trend as any) {
          if (key == this.dataKey || key == this.dateKey || this.keepParams.includes(key)) {
            continue
          }
          delete trend[key]
        }
      }
    }
    if (Array.isArray(this.data) && this.dataKey && this.dateKey) {
      this.data.sort((a, b) => new Date(a[this.dateKey]).getTime() - new Date(b[this.dateKey]).getTime())
    }
    const tempArray = (this.dataKey ? this.data.map(d => d[this.dataKey]) : this.data)
      .filter(n => n != null)
    this.numArray = tempArray.map(v => {
      if (typeof v == 'number') {
        return v
      } else if (typeof v == 'string') {
        return Number(v.split(' ', 1))
      }
    })
    this.positionLine ? this.initPositionLine() : null
    queueMicrotask ?
      queueMicrotask(() => this.initHandler()) :
      setTimeout(() => this.initHandler(), 100)
  }

  initHandler () {
    const _wrapper = document.getElementById(this._wrapper_id)
    const style = document.createElement('style')
    style.innerHTML = `
      #${this._id} {
        position: relative;
      }
      #${this._id} svg {
        width: ${this.width ? `${this.width}px` : 'auto'}!important;
        height: ${this.height ? `${this.height}px` : 'auto'}!important;
      }
    `
    _wrapper.appendChild(style)

    this.initHelper()

    this.isReady = true
  }

  initPositionLine () {
    this.positionLineStyle = initPositionLineStyle(this._position_line_tag, this.width, 1, `${this.positionLine - (100 / this.height)}%`, 'block', '#ba4a4a87',)
  }

  initHelper () {
    const _wrapper = document.getElementById(this._wrapper_id)
    const helper = this._helper_element = document.createElement('div')
    helper.id = this._helper_tag
    helper.style.display = 'none'
    helper.style.position = 'absolute'
    helper.style.width = `${this.markerWidth}px`
    helper.style.height = `${this.height + 5}px`
    helper.style.bottom = '-10px'
    helper.style.backgroundColor = this.markerColor

    setTimeout(() => _wrapper.querySelector('svg')?.parentElement.appendChild(helper), 200)
  }

  closeHelper () {
    this._helper_element.style.display = 'none'
  }

  drawHelper (x: number, tooltip: NgbTooltip) {
    this._helper_element.style.left = `${x}%`
    this._helper_element.style.display = 'block'
    tooltip.open()
  }

  setCurrentCount (event: MouseEvent, tooltip: NgbTooltip) {
    const target = event.target as Expected<HTMLElement>
    if (target.tagName.toLowerCase() != 'svg') {
      return
    }

    const { clientX } = event
    const rects = target.getClientRects()[0]

    let delta = 1 - ((rects.right - clientX) / rects.width)
    delta = delta > 1 ? 1 : delta < 0 ? 0 : delta

    let pos = Math.ceil((this.data.length - 1) * delta)

    this.currentCount = this.numArray[pos]

    this.setTooltipText(this.data[pos])
    try {
      this.drawHelper(delta * 100, tooltip)
      document.getElementsByTagName(this._tooltip_tag)[0]
        .querySelector('.tooltip-inner')
        .innerHTML = this.tooltipText
    } catch {}

  }

  private setTooltipText (data: (StandardObject | number)): string {
    const text: string[] = []
    if (typeof data == 'number') {
      return data.toString()
    }

    for (const key in data) {
      let val = ''
      switch (true) {
        case key == this.dateKey || data[key] instanceof Date : {
          val = MethodsService.cxDateFormat(data[key]).localDts
          text.unshift(val)
          continue
        }
        case Array.isArray(data[key]): {
          val = data[key].map(d => this.setTooltipText(d)).join('\n')
          break
        }
        case typeof data[key] == 'number': {
          if (this.formatParam[key]) {
            val = this.formatParam[key] == 'count' ?
              MethodsService.numberToSuffix(data[key]) :
              MethodsService.formatBytes(data[key])
            break
          }
          val = this.numberFormat == 'count' ?
            MethodsService.numberToSuffix(data[key]) :
            MethodsService.formatBytes(data[key])
          break
        }
        case typeof data[key] == 'string': {
          val = data[key]
          break
        }
        default: {
          val = MethodsService.upperCasedString(data[key])
        }
      }
      text.push(key == '_txt' ? val : `${MethodsService.upperCasedString(key)}: ${val}`)
    }

    return this.tooltipText = text.join('\n')
  }

}

function initPositionLineStyle (id: string, width: number, height: number, position: string, display: string = 'none', color: string = 'lightgray', style: string = 'dashed') {
  const styleObject: StandardObject = {}
  styleObject.display = display
  styleObject.position = 'absolute'
  styleObject.width = `${width}px`
  styleObject.borderWidth = `${height}px`
  styleObject.bottom = position
  styleObject.height = '0'
  styleObject.borderColor = color
  styleObject.borderTopStyle = style
  return styleObject
}
