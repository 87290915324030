import { MethodsService, NUMERIC_SORT_ARRAY } from '../../services/methods/methods.service'

export abstract class TableWithFilters {
  //table
  readonly abstract tableHeaders: TableHeader[]

  readonly LOCALE = Intl.Collator.supportedLocalesOf([...navigator.languages, 'en-US'], { localeMatcher: 'lookup' })[0]
  readonly COLLATOR = new Intl.Collator(this.LOCALE, { caseFirst: 'upper' })

  isSortReversed: boolean = false
  abstract currentSortBy: string

  //filters & pagination
  readonly timeFilter = MethodsService.timeObject()
  currentTime = this.timeFilter[4]

  readonly itemsPerPageFilter = [10, 20, 50, 100, 200]
  currentPage = 1
  numOfAvailableDocs: number = 0
  currentItemsPerPage: number = this.itemsPerPageFilter[0]

  abstract readonly paginationName: string

  //versions filter
  numericFilterType: { id: number, name: string }[] = [{ id: 0, name: 'Operators' }, { id: 1, name: 'Range' }]
  selectedNumericFilterType = this.numericFilterType[0]
  numericFilterSearch?: string
  numericFilterSearchLow?: string
  numericFilterSearchHigh?: string
  currentNumericType: {
    name: string,
    data: NumericOperator
  } = NUMERIC_SORT_ARRAY.find(({ data }) => data == NumericOperator.EQUALS)!

  paginationText () {
    const numOfShowing = this.numOfAvailableDocs ? (this.currentPage - 1) * this.currentItemsPerPage + 1 : this.numOfAvailableDocs
    const numOfAvailable = ((this.currentPage - 1) * this.currentItemsPerPage + this.currentItemsPerPage) >= this.numOfAvailableDocs ? this.numOfAvailableDocs : (this.currentPage - 1) * this.currentItemsPerPage + this.currentItemsPerPage
    return `Showing ${numOfShowing}-${numOfAvailable} of ${this.numOfAvailableDocs} ${this.paginationName}`
  }

  selectedTableItems?: Set<string> = new Set()

  public addToSelectFilter? (selected: Obj<any>, param: string, table: any[], checked: boolean, filter?: { arg1: string, arg2: string }, selectAll?: boolean): void {
    if (selectAll) {
      this.selectedTableItems = new Set(checked ? table.filter(item => filter ? item[filter.arg1] !== item[filter.arg2] : true).map(item => item[param]) : [])
      // LoggerService.info('selectAll: ', this.selectedTableItems)
    } else {
      checked ? this.selectedTableItems.add(selected[param]) : this.selectedTableItems.delete(selected[param])
      // LoggerService.info('checked: ', checked)
    }
  }

  async setSortBy (header, refresh = true, handler?: Function) {
    if (header.sortable) {
      this.isSortReversed = this.currentSortBy === header.field ? !this.isSortReversed : false
      this.currentSortBy = header.field
      if (refresh) {
        this.refreshTable()
      }
      if (handler) {
        handler()
      }
    }
  }

  sortTableData<T> (data: T[], sortBy: string, isReversed: boolean, filterValue: string, searchFields?: string[]): T[] {
    let sortedData: T[]

    sortedData = data.sort((a:any, b:any) => {
      const _a = this.getPath(a, sortBy)
      const _b = this.getPath(b, sortBy)

      if (typeof _a == 'number' || typeof _b == 'number') {
        if (typeof _a == 'number' && typeof _b == 'number') {
          return isReversed ?
            _a - _b :
            _b - _a
        }
        if (typeof _a == 'number') {
          return isReversed ? 1 : -1
        }
        if (typeof _b == 'number') {
          return isReversed ? -1 : 1
        }
      }

      if (_a instanceof Date || _b instanceof Date) {
        if (_a instanceof Date && _b instanceof Date) {
          return isReversed ?
            _a.getTime() - _b.getTime() :
            _b.getTime() - _a.getTime()
        }
        if (_a instanceof Date) {
          return isReversed ? 1 : -1
        }
        if (_b instanceof Date) {
          return isReversed ? -1 : 1
        }
      }

      if(MethodsService.isVersion(_a) || MethodsService.isVersion(_b)) {
        if(MethodsService.isVersion(_a) && MethodsService.isVersion(_b)) {
          return isReversed ?
            MethodsService.compareVersions(_b, _a) :
            MethodsService.compareVersions(_a, _b)
        }
        if(MethodsService.isVersion(_a)) {
          return isReversed ? -1 : 1
        }
        return isReversed ? 1 : -1
      }

      if (typeof _a == 'string' && typeof _b == 'string') {
        return isReversed ?
          this.COLLATOR.compare(_b.trim(), _a.trim()) :
          this.COLLATOR.compare(_a.trim(), _b.trim())
      }

      return isReversed ?
        !!_a ? (!!_b ? (_a > _b ? 1 : _a < _b ? -1 : 0) : -1 ) : 1 :
        !!_a ? (!!_b ? (_a > _b ? -1 : _a < _b ? 1 : 0) : 1 ) : -1
    })

    if (filterValue) {
      sortedData = sortedData.filter((v: T) =>
        searchFields.some(f => this.getPath(v, f)?.toString().toLowerCase().includes(filterValue.toLowerCase()))
      )
    }
    return sortedData
  }

  ref_hljs (zone: any) {
    setTimeout(() => {
      zone?.run(() => {})
      document.querySelectorAll('pre code').forEach((block) => hljs.highlightBlock(block))
    }, 10)
  }

  getPath = (obj: any, path: string) => path.split(".").reduce((r, k) => r?.[k], obj)

  abstract refreshTable (): void

}
