import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { PITenantsService } from '../../services/api/services/pi-tenants.service'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { LoggerService } from '../../services/logger/logger.service'
import { MethodsService } from '../../services/methods/methods.service'
import { UserDataService } from '../../services/user-data/user-data.service'
import { USER_POWER_LEVEL } from '../../shared/user-power'
import { PIDatabasesService } from '../../services/api/services/pi-databases.service'
import { GlobalNotification, GlobalState } from '../../app.state'

type ngbdate = {
  day: number
  year: number
  month: number
}

type kvmultitenant = {
  item_id: any, item_text: string
}

type JobOptions = {
  type: 'migrate' | 'copy'
  from?: ngbdate | string
  to?: ngbdate | string
  source_id: null | string
  target_id: null | string
  avail_tenants: kvmultitenant[]
  tenants: kvmultitenant[]
  delete_source?: boolean
  set_default_cluster?: boolean
  mode: 'tenant' | 'account'
  akamai_account_ids: kvmultitenant[]
  avail_akamai_account_ids: kvmultitenant[]
}

@Component({
  selector: 'pi-databases',
  templateUrl: './pi-databases.component.html',
  styleUrls: ['./pi-databases.component.scss']
})
export class PIDatabasesComponent extends TableWithFilters implements OnInit, OnDestroy {

  readonly title = 'Database Management'

  readonly paginationName = 'Databases'

  filterValue: string = ''

  databases: DatabaseClusterData[] = []
  filtered: DatabaseClusterData[] = []
  selectedDatabase?: GetDatabaseClusterResponse

  selectedJob: JobOptions = {
    type: 'migrate',
    from: null,
    to: null,
    source_id: null,
    target_id: null,
    avail_tenants: [],
    tenants: [],
    avail_akamai_account_ids: [],
    akamai_account_ids: [],
    mode: 'tenant',
    delete_source: false,
    set_default_cluster: true
  }

  availableTargetDbs: DatabaseClusterData[] = []

  allTenants: MappedTenants = []

  multiTenants: kvmultitenant[] = []
  akamaiAccounts: kvmultitenant[] = []

  jobStatusClass: Record<DB_MIGRATION_STATUS, string> = {
    [DB_MIGRATION_STATUS.CREATING]: 'mdi mdi-shovel color-accent',
    [DB_MIGRATION_STATUS.RUNNING]: 'fa fa-spinner fa-spin text-black',
    [DB_MIGRATION_STATUS.COMPLETED]: 'fa fa-check-circle text-green',
    [DB_MIGRATION_STATUS.FAILED]: 'fa fa-times text-red'
  }

  isAdmin: boolean = false

  private intervalMap: Map<string, number> = new Map()

  //table
  readonly tableHeaders: TableHeader[]

  currentSortBy: string

  _isRefreshing: boolean = false

  textToClip = MethodsService.copyToClipboard

  upperCasedString = MethodsService.upperCasedString

  constructor (private _userData: UserDataService, private _databases: PIDatabasesService, private _customers: PITenantsService, protected _zone: NgZone, private _state: GlobalState) {
    super()
    this.currentItemsPerPage = this.itemsPerPageFilter[1]
    this.tableHeaders = [
      //@formatter:off
      { /*width: '12',*/  name: 'Created At',         sortable: true,     field: 'createdAt'     },
      { /*width: '12',*/  name: 'Cluster ID',         sortable: true,     field: 'clusterId'     },
      { /*width: '12',*/  name: 'Cluster Name',       sortable: true,     field: 'clusterName'   },
      { /*width: '12',*/  name: 'Version',            sortable: true,     field: 'padded_version'},
      { /*width: '12',*/  name: 'Group ID',           sortable: true,     field: 'groupId'       },
      { /*width: '12',*/  name: 'Group Name',         sortable: true,     field: 'groupName'     },
      { /*width: '12',*/  name: 'Availability',       sortable: true,     field: 'availability'  },
      { /*width: '12',*/  name: 'Backup Enabled',     sortable: true,     field: 'backupEnabled' },
      { /*width: '12',*/  name: '',                   sortable: false                            },
      //@formatter:on
    ]
    this.currentSortBy = this.tableHeaders[0].field
    this.isAdmin = USER_POWER_LEVEL[this._userData.permission] >= USER_POWER_LEVEL[UserPermission.ADMIN]
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    if (!this.selectedDatabase) {
      return
    }
    if (key === 'Escape') {
      this.clearSelections()
    }
  }

  ngOnDestroy (): void {
    window.removeEventListener('keyup', this.registerModalListeners.bind(this))
  }

  ngOnInit (): void {
    window.addEventListener('keyup', this.registerModalListeners.bind(this))
    this.refreshTable!()
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  getType (value: any): 'bool' | 'str' | 'num' | 'obj' | 'arr' | 'null' {
    switch (typeof value) {
      case 'boolean':
        return 'bool'
      case 'string':
        return 'str'
      case 'number':
        return 'num'
      case 'undefined':
        return 'null'
      case 'object': {
        if (!value) {
          return 'null'
        }
        if (Array.isArray(value)) {
          return 'arr'
        }
        return 'obj'
      }
    }
    return 'null'
  }

  private clearSelections () {
    this.selectedDatabase = undefined
    this.handleLogsIntervals()
  }

  async refreshTable (background: boolean = false): Promise<void> {
    if (this._isRefreshing) {
      return
    }
    this._isRefreshing = true

    try {
      if (!background) {
        this.databases = []
        this.allTenants = []
        this.clearSelections()
        SpinnerService.spin('mini')
      }

      const [databases, mappedCustomers] = await Promise.all([
        this.getDatabases(),
        this._customers.getMappedCustomers(),
      ])
      this.databases = databases
      this.allTenants = mappedCustomers
      this.numOfAvailableDocs = this.databases.length

    } catch (e) {
      LoggerService.error(e)
    }
    SpinnerService.stop('mini')
    this.sortTable()
    this._isRefreshing = false
  }

  sortTable () {
    this.filtered = [...this.databases]
    const searchFields = ['clusterId', 'clusterName', 'version', 'groupId', 'groupName', 'availability', 'backupEnabled']
    this.filtered = this.sortTableData(this.filtered, this.currentSortBy, this.isSortReversed, this.filterValue, searchFields)
    this.numOfAvailableDocs = this.filtered.length
    this.filtered = this.filtered.slice((this.currentPage - 1) * this.currentItemsPerPage, this.currentItemsPerPage * this.currentPage)
    this.ref_hljs(this._zone)
  }

  async getDatabase (id: string, groupId: string, clusterName: string) {
    const clusterIdTime = clusterName.slice(-16)
    try {
      SpinnerService.spin('mini')
      const database = await this._databases.getDatabase(id, groupId, clusterName)
      if (database) {
        this.selectedDatabase = database
        this.selectedDatabase.jobs.reverse()
        this.selectedJob.source_id = id
        this.availableTargetDbs = [
          //@ts-ignore
          {},
          ...this.databases
            .filter(db => this.selectedJob.source_id != db.clusterId)
        ]
        this.selectedJob.avail_tenants = this.allTenants
          .filter(t => t.clusterId.endsWith(clusterIdTime))
          .map(t => ({
            item_id: t.id,
            item_text: `[${t.id}] - ${t._name}`,
          }))
        const accounts: Record<string, { name: string, count: number }> = {}
        this.allTenants
          .filter(({ akamaiCustomerId, clusterId }) => !!akamaiCustomerId && clusterId.endsWith(clusterIdTime))
          .forEach(({ akamaiCustomerId, akamaiAccountName }) => {
            accounts[akamaiCustomerId] = accounts[akamaiCustomerId] || { name: akamaiAccountName, count: 0 }
            accounts[akamaiCustomerId].count++
          })
        this.selectedJob.avail_akamai_account_ids = []
        Object.entries(accounts).forEach(([key, { name, count }]) => this.selectedJob.avail_akamai_account_ids.push({
          item_id: key,
          item_text: `[${key}] [${count}] - ${name}`
        }))
        this.handleLogsIntervals()
        LoggerService.info(database)
      }
    } catch (e) {
      MethodsService.toast('error', 'Error fetching database ' + groupId, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
      this.ref_hljs(this._zone)
    }
  }

  private async getDatabases (options?: FilterArguments): Promise<DatabaseClusterData[]> {
    options =  {
      limit: Number.MAX_SAFE_INTEGER,
      fromDate: this.currentTime.time()
    }
    return await this._databases.getAllDatabases(options)
  }

  async createJob (type: 'migrate' | 'copy') {
    this.selectedJob.type = type

    const source = this.databases.find(db => db.clusterId == this.selectedJob.source_id)
    if (!source) {
      return MethodsService.dialog(`Bad Arguments`, 'Must provide source cluster!')
    }

    const target = this.databases.find(db => db.clusterId == this.selectedJob.target_id)
    if (!target) {
      return MethodsService.dialog(`Bad Arguments`, 'Must provide target cluster!')
    }

    if (!this.selectedJob.tenants.length && !this.selectedJob.akamai_account_ids.length) {
      return MethodsService.dialog(`Bad Arguments`, 'Must provide at least one tenant!')
    }

    const req: MigrateCustomerArguments = {
      source: {
        id: source.clusterId,
        groupId: source.groupId,
        clusterName: source.clusterName
      },
      target: {
        id: target.clusterId,
        groupId: target.groupId,
        clusterName: target.clusterName
      },
      options: {
        migrateDNS: this.selectedJob.type == 'migrate',
        deleteSourceAfter: this.selectedJob.type == 'migrate' && this.selectedJob.delete_source,
        setDefaultCluster: this.selectedJob.type == 'migrate' && this.selectedJob.mode == 'account' && this.selectedJob.set_default_cluster
      }
    }

    if (this.selectedJob.mode == 'tenant') {
      req.tenants = this.selectedJob.tenants.map(t => t.item_id)
    } else {
      req.akamaiAccountIds = this.selectedJob.akamai_account_ids.map(t => t.item_id)
    }

    if (this.selectedJob.type == 'copy') {
      if (this.selectedJob.from) {

        if (typeof this.selectedJob.from == 'string') {
          return MethodsService.dialog(`Bad Arguments`, 'Invalid date format!')
        }

        const { year, month, day } = this.selectedJob.from

        req.options.copyFromDate = new Date(year, month - 1, day).getTime()
      }

      if (this.selectedJob.to) {

        if (typeof this.selectedJob.to == 'string') {
          return MethodsService.dialog(`Bad Arguments`, 'Invalid date format!')
        }

        const { year, month, day } = this.selectedJob.to

        req.options.copyToDate = new Date(year, month - 1, day).getTime()
      }
    }

    try {
      SpinnerService.spin('mini')
      const success = await this._databases.createJob(req)
      if (success) {
        return this.clearSelections()
      }
      throw Error('Failed creating job')
    } catch (e) {
      MethodsService.toast('error', 'Error creating job', e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }
  }

  private handleLogsIntervals () {
    if (!this.selectedDatabase) {
      //clear all intervals if any exists
      for (const [id, interval] of this.intervalMap) {
        clearInterval(interval)
      }
      return this.intervalMap.clear()
    }

    for (const { podName, status } of this.selectedDatabase.jobs) {
      if ((status == DB_MIGRATION_STATUS.CREATING || status == DB_MIGRATION_STATUS.RUNNING) && !this.intervalMap.has(podName)) {

        const interval = setInterval(async () => {

          const { status, logs } = await this._databases.getJobInfo(podName)

          const job = this.selectedDatabase.jobs.find(j => j.podName == podName)
          job.logs = logs || 'No logs received yet..'

          job.status = status

          if (status == DB_MIGRATION_STATUS.FAILED || status == DB_MIGRATION_STATUS.COMPLETED) {
            clearInterval(interval)
            this.intervalMap.delete(podName)
          }

          this.rewriteLogs(job)
        }, 5000) as any as number

        this.intervalMap.set(podName, interval)
      }
    }

  }

  private rewriteLogs (job: DatabaseMigrationsSchema) {
    const ele = document.getElementById(`job-${job.podName}`)

    if (ele) {
      ele.querySelector('code').remove()
      const code = document.createElement('code')
      code.className = 'language-plaintext'
      //@ts-ignore
      code.innerText = job.logs
      ele.appendChild(code)
    }

    this.ref_hljs(this._zone)
  }

  async deleteJob (jobName: string) {

    if (!await MethodsService.confirm(
      `Delete confirmation`,
      `Are you sure you want to delete job ${jobName}`,
      `Delete`
    )) {
      return
    }

    try {
      SpinnerService.spin('mini')

      const success = await this._databases.deleteJob(jobName)

      if (!success) {
        throw Error(`failed deleting job ${jobName}`)
      }

      const indexOfJob = this.selectedDatabase.jobs.findIndex(j => j.podName == jobName)

      if (indexOfJob > -1) {
        this.selectedDatabase.jobs.splice(indexOfJob, 1)
      }

    } catch (e) {
      MethodsService.toast('error', 'Error creating job', e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')

    }

  }

}
