import { Injectable } from '@angular/core'

@Injectable()
export class DataService {
  public static readonly colors = {
    //@formatter:off
    notification_palette:{
      info:     { background: '#d9f4fb', color: '#7ba5b4'},
      success:  { background: '#eef8da', color: '#92b161'},
      warning:  { background: '#fffce3', color: '#76704b'},
      error:    { background: '#ffebeb', color: '#a6656c'}
    },
    severity_palette:{
      na:        { background: '#e3e1e3', color: '#171517'},
      note:     { background: '#f2cb33', color: '#ffffff'},
      low:      { background: '#f28925', color: '#ffffff'},
      medium:   { background: '#fb7636', color: '#ffffff'},
      // high:     { background: '#ee7c23', color: '#ffcf98'},
      high:     { background: '#f44336', color: '#ffffff'},
      critical: { background: '#d32f2f', color: '#ffffff'}
    },
    palette: {
      red: [
          '#900909',
          '#b41010',
          '#c71a1a',
          '#d41313',
          '#ea2323',
          '#fecccc'
      ],
      red_dark: [
          '#450000',
          '#720000',
          '#a70000',
          '#ce0000',
          '#ff0000'
      ],
      golden: [
          '#ff9900',
          '#ffa800',
          '#ffb800',
          '#ffc200',
          '#ffd100'
      ],
      orange: [
          '#ff9913',
          '#ffab08',
          '#ffb720',
          '#ffb700',
          '#ffc12e'
        ],
      purple: [
          '#512bdb',
          '#5a2bdb',
          '#632bdb',
          '#6b2bdb',
          '#7d2bdb',
        ],
        orange_deep: [
          '#BF360C',
          '#E64A19',
          '#FF5722',
          '#FF8A65',
          '#FFCCBC'
        ],
        maroon: [
          '#3a1010',
          '#562020',
          '#602626',
          '#6d3232',
          '#8d4343'
        ],
        grey: [
            '#636065',
            '#7a777d',
            '#8e8b90',
            '#9e9da2',
            '#b5b4bb'
        ],

    },
    global:
    {

        na:                 '#9c9c9c',
        note:               '#757575',
        low:                '#fff176',
        medium:             '#fbc02d',
        high:               '#f44336',
        critical:           '#d32f2f',
      //
        theme:              '#44546A',
        grey_light:         '#919191',
        grey:               '#7c7c7c',
        grey_dark:          '#5a5a5a',
        sky_blue_light:     '#f0f6ff',
        sky_blue:           '#afdaf5',
        sky_blue_dark:      '#56a5ac',
        dark_blue_light:    '#34495E',
        dark_blue:          '#2E4053',
        dark_blue_dark:     '#212F3C',
        light_blue_light:   '#5DADE2',
        light_blue:         '#2E86C1',
        light_blue_dark:    '#21618C',
        green_light:        '#2ECC71',
        green:              '#229954',
        green_dark:         '#196F3D',
        yellow_light:       '#F5B041',
        yellow:             '#F39C12',
        yellow_dark:        '#D68910',
        red_light:          '#CD6155',
        red:                '#a5151c',
        red_dark:           '#711115',
        purple_light:       '#9247ff',
        purple:             '#5a2da3',
        purple_dark:        '#3d1e6b',
        magneta_light:      '#AF7AC5',
        magneta:            '#884EA0',
        magneta_dark:       '#633974',
        teal:               '#28BEBD',
        deep_purple_0_light:'#fff7ff',
        deep_purple_0:      '#d1c4e9',
        deep_purple_1_light:'#d9c1ff',
        deep_purple_1:      '#a98fdb',
        deep_purple_2_light:'#b085f5',
        deep_purple_2:      '#7e57c2',
        deep_purple_3_light:'#744dc2',
        deep_purple_3:      '#6435c2',
        deep_purple_4_light:'#5723b7',
        deep_purple_4:      '#4411b7',

    },
    charts: {
      golden: {
        backgroundColor: 'rgba(189, 163, 48, 0.4)',
        borderColor: '#bda330',
        pointBackgroundColor: 'rgba(189, 163, 48, 1)',
        pointBorderColor: 'rgba(189, 163, 48, 1)',
        pointHoverBackgroundColor: '#bda330',
        pointHoverBorderColor: '#bda330'
      },
      dark_grey: {
        backgroundColor: 'rgba(100, 100, 100, 0.4)',
        borderColor: '#646464',
        pointBackgroundColor: 'rgba(100, 100, 100, 1)',
        pointBorderColor: 'rgba(100, 100, 100, 1)',
        pointHoverBackgroundColor: '#646464',
        pointHoverBorderColor: '#646464'
      },
      green_stacked:
        {
          backgroundColor: 'rgba(46, 170, 22, 0.4)',
          borderColor: '#33b347',
          pointBackgroundColor: 'rgba(0, 0, 0, 0)',
          pointBorderColor: 'rgba(0, 0, 0, 0)',
          pointHoverBackgroundColor: '#33b347',
          pointHoverBorderColor: '#33b347'
        },
      yellow_stacked:
        {
          backgroundColor: 'rgba(245, 245, 80, 0.4)',
          borderColor: '#f8931f',
          pointBackgroundColor: 'rgba(0, 0, 0, 0)',
          pointBorderColor: 'rgba(0, 0, 0, 0)',
          pointHoverBackgroundColor: '#f8931f',
          pointHoverBorderColor: '#f8931f'
        },
      maroon_stacked:
        {
          backgroundColor: 'rgba(156, 17, 25, 0.4)',
          borderColor: '#9c1119',
          pointBackgroundColor: 'rgba(0, 0, 0, 0)',
          pointBorderColor: 'rgba(0, 0, 0, 0)',
          pointHoverBackgroundColor: '#9c1119',
          pointHoverBorderColor: '#9c1119'
        },
      magneta_stacked:
        {
          backgroundColor: 'rgba(245, 90, 202, 0.4)',
          borderColor: '#f55aca',
          pointBackgroundColor: 'rgba(0, 0, 0, 0)',
          pointBorderColor: 'rgba(0, 0, 0, 0)',
          pointHoverBackgroundColor: '#f55aca',
          pointHoverBorderColor: '#f55aca'
        },
      purple_stacked:
        {
          backgroundColor: 'rgba(164, 224, 152, 0.4)',
          borderColor: '#6a66e0',
          pointBackgroundColor: 'rgba(0, 0, 0, 0)',
          pointBorderColor: 'rgba(0, 0, 0, 0)',
          pointHoverBackgroundColor: '#6a66e0',
          pointHoverBorderColor: '#6a66e0'
        },
      red_white:
        {
          backgroundColor: 'rgba(235, 34, 47, 0)',
          borderColor: '#eb222f',
          pointBackgroundColor: 'rgba(255, 255, 255, 1)',
          pointBorderColor: '#000000',
          pointHoverBackgroundColor: '#eb222f',
          pointHoverBorderColor: '#eb222f'
        },
      blue_red:
        {
          backgroundColor: 'rgba(0, 0, 0, 0)',
          borderColor: 'rgba(68, 84, 106, 1)',
          pointBackgroundColor: 'rgba(175, 44, 44, 1)',
          pointBorderColor: 'rgba(175, 44, 44, 1)',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(40, 190, 189,0.8)'
        },
      teal:
        {
          backgroundColor: 'rgba(40, 190, 189,0.4)',
          borderColor: 'rgba(40, 190, 189,1)',
          pointBackgroundColor: 'rgba(40, 190, 189,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(40, 190, 189,0.8)'
        },
      purple:
        {
          backgroundColor: 'rgba(88, 103, 195,0.6)',
          borderColor: 'rgba(88, 103, 195,1)',
          pointBackgroundColor: 'rgba(88, 103, 195,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(88, 103, 195,1)'
        },
      blue:
        {
          backgroundColor: 'rgba(66, 165, 245,0.4)',
          borderColor: 'rgba(66, 165, 245,1)',
          pointBackgroundColor: 'rgba(66, 165, 245,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(66, 165, 245,0.8)'
        },
      orange_deep:
        {
          backgroundColor: 'rgba(236, 64, 122,0.4)',
          borderColor: 'rgba(236, 64, 122,1)',
          pointBackgroundColor: 'rgba(236, 64, 122,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(236, 64, 122,0.8)'
        }
    },
    cx: {
      theme:          '#573dbe',
      accent:         '#6e3dbe',
      accent_dark:    '#343485',
      red:            '#ff0000',
      maroon:         '#af0000',
      delicate_grey:  '#f6f6f6',
      fade_grey:      '#f4f4f8',
      light_grey:     '#ededed',
      grey:           '#a7a7ab',
      dark_grey:      '#7B7B7B',
      green:          '#28a745',
      blue:           '#1094f7',
      yellow:         '#ff9800',
      black:          '#000'
    }
  }
  public static readonly chartsColors = {
    pie_colors_severity: {
      CRITICAL: DataService.colors.global.critical,
      HIGH: DataService.colors.global.high,
      MEDIUM: DataService.colors.global.medium,
      LOW: DataService.colors.global.low,
      NOTE: DataService.colors.global.note,
      NA: DataService.colors.global.na
    },
    pie_colors_dark: [
      DataService.colors.global.dark_blue_dark,
      DataService.colors.palette.maroon[0],
      DataService.colors.palette.golden[0],
      DataService.colors.palette.purple[0],
      DataService.colors.palette.orange_deep[0]
    ],
  }
}

export const GIT_REPO = (repo: string, branch = 'master') =>  encodeURI(`https://git.source.akamai.com/projects/PI-CORE/repos/${repo}/browse?at=refs/heads/${branch}`)
export const JIRA_TICKET_PREFIX = 'https://track.akamai.com/jira/browse/'
export const CLUSTER_STATUSES: Record<CLUSTER_STATUS, { status: string, icon: string }> = {
  [CLUSTER_STATUS.ACTIVE]: { status: 'Active', icon: 'fa fa-check-circle text-green' },
  [CLUSTER_STATUS.FAILED]: { status: 'Failed', icon: 'fa fa-times text-red' },
  [CLUSTER_STATUS.TESTING]: { status: 'Testing', icon: 'fa fa-spinner fa-spin text-orange' },
  [CLUSTER_STATUS.CREATING]: { status: 'Creating', icon: 'fa fa-spinner fa-spin color-info' },
  [CLUSTER_STATUS.INACTIVE]: { status: 'Inactive', icon: 'fa fa-minus-circle text-grey' },
  [CLUSTER_STATUS.UPDATING]: { status: 'Updating', icon: 'fa fa-spinner fa-spin color-accent' },
  [CLUSTER_STATUS.DELETING]: { status: 'Deleting', icon: 'fa fa-times text-red' },
  [CLUSTER_STATUS.SUCCEEDED]: { status: 'Succeeded', icon: 'fa fa-check-circle text-green' },
  [CLUSTER_STATUS.RE_ACTIVATING]: { status: 'Re Activating', icon: 'fa fa-times text-red' },
  [CLUSTER_STATUS.DE_ACTIVATING]: { status: 'De Activating', icon: 'fa fa-times text-red' },
}

export function TenantName(customerId: string, name: string, clusterId?: string){
  return `[${customerId}] @ [${clusterId || 'Unassigned'}] - ${name}`
}
