import { Injectable } from '@angular/core'
import { GlobalNotification, GlobalState } from '../../app.state'
import { ApiService } from '../../shared/absracts/api-service'
import { UserDataService } from '../user-data/user-data.service'

const MAX_TIMEOUT = 2147483647

@Injectable()
export class AuthService extends ApiService {
  private readonly TOKEN_NAME: string = '_cxt'

  private authenticated: boolean = false

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
    this._state.subscribe(GlobalNotification.SIGN_OUT, () => this.signOut())

  }

  async signOut (force = false) {
    void this.get('auth/logout')
    this.setUnauthenticated()
  }

  async isAuthenticated (): Promise<boolean> {
    try {
      const response = await this.get(`auth/azure_ad/is_auth`, false) as ResultObject<any>
      return response.success
    } catch (e) {
      return false
    }
  }

  async verifyAkamaiSSO (redirect: string = location.href): Promise<void> {
    const response = await this.get(`auth/azure_ad/verify?redirectFrom=${encodeURIComponent(redirect)}`, false) as ResultObject<any>

    if (!response.success) {
      if (response.code == 401)
        throw Error(`Unauthorized user ${response.data?.email}`)
      else
        throw Error(`Could not validate user ${response.data?.email}`)
    }

    this.setAuthenticated(response.data)
  }

  private setToken (token: CxUserToken) {
    localStorage.setItem(this.TOKEN_NAME, btoa(JSON.stringify(token)))
  }

  private removeToken () {
    !!localStorage.getItem(this.TOKEN_NAME) && localStorage.removeItem(this.TOKEN_NAME)
    !!sessionStorage.getItem(this.TOKEN_NAME) && sessionStorage.removeItem(this.TOKEN_NAME)
  }

  private setAuthenticated (data: CxUserInformation, token?: CxUserToken) {
    token && this.setToken(token)
    this.authenticated = true
    this._user.initiateData({ token, ...data })
    this._state.notify(GlobalNotification.AUTHENTICATED, this.authenticated)
    token && this.logoutOnTokenExpiration(token)
  }

  private setUnauthenticated () {
    this.removeToken()
    this.authenticated = false
    this._user.clearInformation()
    this._state.notify(GlobalNotification.AUTHENTICATED, this.authenticated)
    setTimeout(() => location.reload, 200)
  }

  private logoutOnTokenExpiration (token: CxUserToken) {
    const tokenExpirationTime = token.tokenExpiresAt - Date.now()
    if (tokenExpirationTime > 0 && tokenExpirationTime <= MAX_TIMEOUT) {
      setTimeout(() => this.signOut(true), tokenExpirationTime)
    }
  }

}
