import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Observable } from 'rxjs'
import { NgModel } from '@angular/forms'

@Directive({
  selector: `[cx-debounce]`
})

export class CxDebounce implements OnInit {
  @Input() debounceTime: number
  @Output() debounceFunc: EventEmitter<any> = new EventEmitter();

  constructor (private elementRef: ElementRef, private model: NgModel) {
  }

  ngOnInit () {
    const eventStream = Observable.fromEvent(this.elementRef.nativeElement, 'keyup')
      .map(() => this.model.value)
      .debounceTime(this.debounceTime)

    eventStream.subscribe(input => this.debounceFunc.emit(input))
  }
}
