import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { GlobalState } from '../../../app.state'
import { PiDeploymentsReleasesProcessorService } from '../processors/pi-deployment-releases-processor.service'

@Injectable()
export class PiDeploymentReleasesService<T extends ExtendedDeploymentReleasesSchema = ExtendedDeploymentReleasesSchema> extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async getConfig (): Promise<DeploymentReleasesConfigObject> {
    const response = await this.get(`api/admin_api/deployment_release/config`)
    return response.data
  }

  async getAllReleasesFiltered (options: FilterArguments): Promise<IfDefined<CountedResultObject<T[]>>> {
    const queryString = MethodsService.objectToQueryString(options)
    const response = await this.get(`api/admin_api/deployment_releases?${queryString}`)
    return response.success && {
      success: response.success,
      count: response.count,
      data: PiDeploymentsReleasesProcessorService.process(response.data) as T[]
    }
  }

  async getAllDeploymentReleases (): Promise<IfDefined<ReleasesByImageEnv>> {
    const response = await this.get(`api/admin_api/deployment_release/all`) as ResultObject<T[]>
    return PiDeploymentsReleasesProcessorService.MapReleases(response.data) as ReleasesByImageEnv
  }

  async createNewRelease (body: NewDeploymentRelease): Promise<boolean> {
    const response = await this.post(`api/admin_api/deployment_release/create`, JSON.stringify(body), true, 'application/json')
    if (response.success) {
      return response.success
    }
    throw Error(response.data || response.message)
  }

  async updateRelease (_id: string, body: UpdateDeploymentReleaseOptions): Promise<boolean> {
    const response = await this.post(`api/admin_api/deployment_release/update`, JSON.stringify({ _id, ...body }), true, 'application/json')
    if (response.success) {
      return response.success
    }
    throw Error(response.data || response.message)
  }

  async deleteReleases (_ids: string[]): Promise<boolean> {
    const response = await this.post(`api/admin_api/deployment_release/delete`, JSON.stringify({ _ids }), true, 'application/json')
    if (response.success) {
      return response.success
    }
    throw Error(response.data || response.message)
  }

  async addPages (_id: string, pages: string[], updatedAt: number): Promise<T> {
    const body = JSON.stringify({ _id, pages, updatedAt })
    const response = await this.post(`api/admin_api/deployment_release/add_pages`, body, true, 'application/json')
    return response.data as T
  }

  async removePages (_id: string, pages: string[], updatedAt: number): Promise<T> {
    const body = JSON.stringify({ _id, pages, updatedAt })
    const response = await this.post(`api/admin_api/deployment_release/remove_pages`, body, true, 'application/json')
    return response.data as T
  }

}

