import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { GlobalState } from '../../../app.state'
import { AudienceHijackingRulesProcessor } from '../processors/audience-hijacking-rules-processor.service'

@Injectable()
export class AudienceHijackingRulesService extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async getAllVersions (options: FilterArguments): Promise<CountedResultObject<AudienceHijackingRulesSchema[]>> {
    const queryString = MethodsService.objectToQueryString(options)
    const response = await this.get(`api/admin_api/ah_rules?${queryString}`) as CountedResultObject<AudienceHijackingRulesSchema[]>
    return {
      count: response.count,
      success: response.success,
      data: AudienceHijackingRulesProcessor.process(response.data) as AudienceHijackingRulesSchema[]
    }
  }

  async getLatestSchemaVersion (): Promise<AHSchemaVersion> {
    const response = await this.get(`api/admin_api/ah_rules/latest_schema`) as ResultObject<AHSchemaVersion>
    return response.data
  }

}

