import { Component, Input, NgZone, OnInit } from '@angular/core'
import { PIInstancesService } from '../../../../services/api/services/pi-instances.service'
import { GlobalState } from '../../../../app.state'
import { PiEventConsoleService } from '../../../../services/api/services/pi-event-console.service'
import { MethodsService } from '../../../../services/methods/methods.service'

@Component({
  selector: 'event-group-alert-summary',
  templateUrl: './event-group-alert-summary.component.html',
  styleUrls: ['./event-group-alert-summary.component.scss'],
})
export class EventGroupAlertSummaryComponent implements OnInit {

  @Input() tenantId: string
  @Input() eventGroupId: string
  @Input() currentTime: { text: string, absTime: Function, time: Function }
  @Input() metadata: ExtendedEventGroupSchema
  @Input() scripts: { count: number, scripts: Omit<EventGroupScriptsSchema, keyof DefaultCollectionSchema>[] }
  @Input() scriptDestinations: EventGroupScriptEndpointsResult

  topUrls: string[] = []
  topSources: string[] = []

  readonly title = ''

  //state
  _isRefreshing: boolean = false

  //imports
  integerWithCommas = MethodsService.integerWithCommas
  truncateURL = MethodsService.truncateURL
  copyToClipboard = MethodsService.copyToClipboard
  stringify = JSON.stringify

  constructor (private _api: PiEventConsoleService, private _clusters: PIInstancesService, private _zone: NgZone, private _state: GlobalState) {
  }

  ngOnInit (): void {
    this.topUrls = Array.from(new Set(this.scriptDestinations.endpoints
      .sort((a, b) => b.impactedBeacons - a.impactedBeacons)
      .map(script => script.url)
    )).slice(0, 7)


    this.topSources = Array.from(new Set(this.scripts.scripts
      .map(script => ({ script: script.source.url, count: script.timesSeen }))
      .sort((a, b) => b.count - a.count)
      .map(script => script.script)
    )).slice(0, 7)
  }

}
