import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core'
import { AudienceHijackingAnalyticsService } from '../../services/api/services/audience-hijacking-analytics.service'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { USER_POWER_LEVEL } from '../../shared/user-power'
import { UserDataService } from '../../services/user-data/user-data.service'
import { MethodsService } from '../../services/methods/methods.service'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { LoggerService } from '../../services/logger/logger.service'
import { GlobalNotification, GlobalState } from '../../app.state'

@Component({
  selector: '',
  templateUrl: './audience-hijacking-analytics.component.html',
  styleUrls: ['./audience-hijacking-analytics.component.scss']
})

export class PIAudienceHijackingAnalyticsComponent<T extends AnalyticsIntegrationsSchema = AnalyticsIntegrationsSchema> extends TableWithFilters implements OnInit {

  // Repeater reference variables (can not be read from template when inside *ngfor, so @ViewChildren is in use)
  @ViewChildren("param_type", { read: ElementRef }) paramTypes: QueryList<HTMLSelectElement>;
  @ViewChildren("val", { read: ElementRef }) vals: QueryList<HTMLInputElement>;
  @ViewChildren("event_name", { read: ElementRef }) eventNames: QueryList<HTMLInputElement>;

  readonly title = 'Audience Hijacking Analytics Integrations Management'
  readonly isAdmin: boolean = false
  filterValue: string = ''
  integrationObjects: T[] = []

  //table
  readonly tableHeaders: TableHeader[]
  currentSortBy: string
  _isRefreshing: boolean = false
  readonly paginationName = 'Integration objects'
  paginationSkip: number = 1

  //modals
  isCreateIntegrationActive: boolean = false
  selectedIntegration: T
  newlyCreatedIntegration: T
  errorDialog: boolean = false
  errorMessage: string = ""

  private _debounce: void | number
  private debounce_timeout: number = 1000

  constructor (private _userData: UserDataService, private _integrations: AudienceHijackingAnalyticsService, private _state: GlobalState) {
    super()

    this.currentItemsPerPage = this.itemsPerPageFilter[1]
    this.tableHeaders = [
      //@formatter:off
      USER_POWER_LEVEL[this._userData.permission] >= USER_POWER_LEVEL[UserPermission.ADMIN] ? { /*width: '4', */  name: '', sortable: false, field: '' } : undefined,
      { /*width: '12',*/  name: 'Created',              sortable: true,     field: 'createdAt'          },
      { /*width: '12',*/  name: 'Updated',              sortable: true,     field: 'updatedAt'          },
      { /*width: '12',*/  name: 'Integration ID',       sortable: true,     field: 'integrationId'      },
      { /*width: '12',*/  name: 'Integration Provider', sortable: true,     field: 'provider'           },
      { /*width: '12',*/  name: 'Integration Name',     sortable: true,     field: 'name'               },
      { /*width: '12',*/  name: 'Actions',              sortable: false                                 },
      //@formatter:on
    ].filter(x => !!x)

    this.currentSortBy = this.tableHeaders[2].field
    this.isAdmin = USER_POWER_LEVEL[this._userData.permission] >= USER_POWER_LEVEL[UserPermission.ADMIN]
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    if (!this.selectedIntegration && !this.isCreateIntegrationActive) {
      return
    }
    if (key === 'Escape') {
      this.clearSelections()
    }
  }

  ngOnDestroy (): void {
    window.removeEventListener('keyup', this.registerModalListeners.bind(this))
  }

  onFilterChange (): void {
    if (this._debounce) {
      this._debounce = clearTimeout(this._debounce)
    }
    this._debounce = setTimeout(() => this.refreshTable(), this.debounce_timeout) as any as number
  }

  openCreateIntegrationObjectModal () {
    this.selectedIntegration = {
      functionToCallFullPath: "",
      name: "",
      provider: "",
      description: "",
      sendEventFunctionArgs: [],
    } as T
    this.isCreateIntegrationActive = true
  }

  ngOnInit (): void {
    window.addEventListener('keyup', this.registerModalListeners.bind(this))
    this.refreshTable!()
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  async refreshTable (background: boolean = false, ignoreSelected: boolean = false): Promise<void> {
    if (this._isRefreshing || (!ignoreSelected && this.selectedIntegration)) {
      return
    }
    this._isRefreshing = true
    try {
      if (!background) {
        this.integrationObjects = []
        this.clearSelections()
        SpinnerService.spin('mini')
      }

      const integrationsResult: CountedResultObject<AnalyticsIntegrationsSchema[]> = await this.getIntegrations()
      this.integrationObjects = integrationsResult.data as T[]
      this.numOfAvailableDocs = integrationsResult.count

    } catch (e) {
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._isRefreshing = false
    }
  }

  selectIntegration (integration: any) {
    this.selectedIntegration = integration
  }

  async createNewIntegrationObject(provider: string, integrationName: string, description: string, functionPath: string): Promise<void> {
    const newIntegrationData = this.formCreateUpdateIntegrationModal(provider, integrationName, description, functionPath)
    if (!(await this.validateIntegrationObject(newIntegrationData))) {
      return
    }
    try {
      SpinnerService.spin('mini')
      const newIntegration = await this._integrations.createNewIntegration(newIntegrationData)
      if (newIntegration) {
        this.clearSelections()
        await this.refreshTable()
      }
    } catch (e) {
      MethodsService.toast('error', `Error creating new integration object: ${integrationName}`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }
  }

  private formCreateUpdateIntegrationModal(provider, integrationName, description, functionPath): NewAnalyticsIntegrationsOptions {
    const result = {
      provider: provider,
      name: integrationName,
      description: description,
      functionToCallFullPath: functionPath,
      sendEventFunctionArgs: this.formEventParamsFromRepeater()
    }
    return result
  }

  private formEventParamsFromRepeater() {
    const arrParamTypes = this.paramTypes.toArray().map(pt => pt['nativeElement'].value)
    const arrVals = this.vals.toArray().map(val => val['nativeElement'].value)
    const arrEventNames = this.eventNames.toArray().map(en => en['nativeElement'].value)

    let valsIndex = 0, restIndex = 0
    const resultEventParamsArr = []

    for (const pt of arrParamTypes) {
      if(pt !== 'object') {
        resultEventParamsArr.push({
          type: pt,
          val: arrVals[valsIndex]
        })
        valsIndex++
      } else {
        resultEventParamsArr.push({
          type: pt,
          objectTemplate: {
            eventName: arrEventNames[restIndex],
            category: 0,
            action: 0
          }
        })
        restIndex++
      }
    }
    return resultEventParamsArr
  }

  clearSelections() {
    this.selectedIntegration = undefined
    this.isCreateIntegrationActive = false
  }

  closeErrorDialog() {
    this.errorDialog = false
    this.errorMessage = ""
  }

  private async getIntegrations (options?: FilterArguments & {paginationSkip?: number}):  Promise<CountedResultObject<AnalyticsIntegrationsSchema[]>> {
    options = options || {
      limit: this.currentItemsPerPage,
      filter: this.filterValue,
      paginationSkip: this.paginationSkip
    }

    return await this._integrations.getAllIntegrations(options)
  }

  async deleteIntegrations (): Promise<void> {
    const title = `Delete Confirmation`
    const content = `Delete ${this.selectedTableItems.size} Integration Object${this.selectedTableItems.size > 1 ? 's' : ''} ?`
    const deleteConfirmationText = 'Delete'
    if (await MethodsService.confirm(title, content, deleteConfirmationText)) {

      SpinnerService.spin('mini')

      try {
        const success = await this._integrations.deleteIntegrations([...this.selectedTableItems])
        if (success) {
          this.selectedTableItems.clear()
          await this.refreshTable()
        }
      } catch (e) {
        LoggerService.error(e)
      }
      SpinnerService.stop('mini')
    }
  }

  async updateIntegration (provider: string, integrationName: string, description: string, functionPath: string): Promise<void> {
    const currOptions = this.formCreateUpdateIntegrationModal(provider, integrationName, description, functionPath)
    const updateOptions: UpdateAnalyticsIntegrationsOptions = { ...currOptions, updatedAt: new Date(this.selectedIntegration.updatedAt).getTime() }

    if (!(await this.validateIntegrationObject(currOptions, true))) {
      return
    }
    try {
      SpinnerService.spin('mini')
      const updatedIntegration = await this._integrations.updateIntegration(this.selectedIntegration.integrationId, updateOptions)
      if (updatedIntegration) {
        this.clearSelections()
        await this.refreshTable()
      }
    } catch (e) {
      MethodsService.toast('error', `Error editing Integration Object ${this.selectedIntegration.integrationId}`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }
  }

  addEventParameter(selectedIntegration) {
    const newEventParam = { type: "string", value: "" }
    selectedIntegration.sendEventFunctionArgs.push(newEventParam)
  }

  deleteEventParameter(selectedIntegration, index: number) {
    selectedIntegration.sendEventFunctionArgs.splice(index, 1)
  }

  changeEventParamType(selectedIntegration, index: number, type: string) {
    if(type !== 'object') {
      selectedIntegration.sendEventFunctionArgs[index] = {
        type: type,
        val: ""
      }
    } else {
      selectedIntegration.sendEventFunctionArgs[index] = {
        type: "object",
        objectTemplate: {
          eventName: "",
          category: 0,
          action: 0
        }
      }
    }
  }

  private async validateIntegrationObject(obj: NewAnalyticsIntegrationsOptions, update: boolean = false): Promise<boolean> {
    if (!obj.name || obj.name.length == 0) {
      return this.showDialogWithMessage("a valid name")
    }
    if (!obj.provider || obj.provider.length == 0) {
      return this.showDialogWithMessage("a valid provider name")
    }
    if (!obj.functionToCallFullPath || obj.functionToCallFullPath.length == 0) {
      return this.showDialogWithMessage("a valid function path (in dot-notation)")
    }
    if (!obj.sendEventFunctionArgs || obj.sendEventFunctionArgs.length == 0) {
      return this.showDialogWithMessage("at least one valid function parameter")
    }
    if (!this.validateSendEventFunctionArgs(obj.sendEventFunctionArgs)) {
      this.errorMessage = "One or more of the defined parameters is missing value/event name. Object has not been saved to DB."
      this.errorDialog = true
      return false
    }
    if (this.selectedIntegration.name == obj.name && update) {
      return true
    }
    if(await this.validateIntegrationObjectUniqueName(obj.name)) {
      this.errorMessage = "Please note that another Integration Object with identical name as you entered is already exist." +
        " Integration Object names must be unique. Object has not been saved to DB."
      this.errorDialog = true
      return false
    }
    return true
  }

  private validateSendEventFunctionArgs(args: EventParams[]): boolean {
    const paramValidationArr = args.map(arg => arg.type == "object" ? arg.objectTemplate.eventName : arg.val)
    return paramValidationArr.every(v => v && v.length > 0)
  }

  private showDialogWithMessage(message: string): boolean {
    this.errorMessage = `You must provide ${message} for the Integration Object. Object has not been saved to DB.`
    this.errorDialog = true
    return false
  }

  private async validateIntegrationObjectUniqueName(name: string): Promise<boolean> {
    const integrationObjectsNames = (await this.getIntegrations()).data.map(io => io.name)
    return integrationObjectsNames.includes(name)
  }
}
