import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { GlobalState } from '../../../app.state'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { PiInstancesDashboardProcessorService } from '../processors/pi-instances-dashboard-processor.service'
import { LoggerService } from '../../logger/logger.service'
import { PITenantsService } from './pi-tenants.service'
import { CustomerConfigService } from './customer-config.service'
import { PICustomerConfigComponent } from '../../../pages/pi-customer-config/customer-config.component'

@Injectable()
export class PIInstancesDashboardService extends ApiService {
  allTenants: MappedInstanceDashboardTenants = []

  constructor (protected _state: GlobalState, protected _user: UserDataService, private _customers?: PITenantsService, private _configs?: CustomerConfigService) {
    super(_state, _user)
  }

  async getAllClusters (options: FilterArguments): Promise<IfDefined<CountedResultObject<InstancesDashboardItem[]>>> {

    const queryString = MethodsService.objectToQueryString(options)
    try {

      const [{ success, count, data: clusterData }, { data: { clusterMetrics } }, {data: beaconsData}]:
        [{ success: boolean, count?: number, data?: ExtendedCustomerMappingSchema }, { data?: GetExtendedDatabasesResults }, { data?: AggregatedHitsByClusterQueryResult[] }
        ] = await Promise.all([
        this.get(`api/admin_api/clusters?${queryString}`),
        this.get(`api/admin_api/instance_dashboard/all_metrics?${queryString}`),
        this.get(`api/admin_api/instance_dashboard/beacons?${queryString}`)
      ])
      return success && {
        success: success,
        count: count,
        data: PiInstancesDashboardProcessorService.processInstances( clusterData, clusterMetrics, beaconsData) as InstancesDashboardItem[]
      }
    } catch (e) {
      console.log(e)
    }
  }


  async getCluster (_cluster: InstancesDashboardItem, options: FilterArguments): Promise<InstancesDashboardSubItem>{
    let cluster:  ExtendedCustomerMappingSchema = null
    let beacons: AggregatedHitsPerTenantQueryResult[] = []
    try{
      const [clusterData, beaconsData] = await Promise.allSettled([
        this.get(`api/admin_api/cluster/${_cluster.clusterId}`),
        this.getBeacons(_cluster.clusterEndpoint, options)
      ])

      if (clusterData.status === 'rejected') {
        LoggerService.error('Failed to get cluster data')
        return null
      }

      if (clusterData.status === 'fulfilled') {
        cluster = clusterData.value.data
        beacons = (beaconsData.status === 'fulfilled') ? beaconsData.value : []

        if (cluster.imageEnvironment == PiOperationalEnvironment.CLUSTER) {
          if (Array.isArray(this.allTenants) && this.allTenants.length == 0) {
            const allCustomers = (await this._customers.getCustomers({
              limit: Number.MAX_SAFE_INTEGER,
              skip: 0
            })).data as InstanceExtendedCustomer[]
            this.allTenants = PiInstancesDashboardProcessorService.getMappedTenants(allCustomers)

          }

          return {
            ...PiInstancesDashboardProcessorService.processTenants( cluster, beacons, this.allTenants),
            ..._cluster
          }

        }
      }
      return
    } catch (e) {
      LoggerService.error(e)
      return
    }
  }

  async getBeacons (endpoint: string, options: FilterArguments): Promise<AggregatedHitsPerTenantQueryResult[]> {
    try{
      const queryString = MethodsService.objectToQueryString(options)
      const encodedEndpoint = encodeURIComponent(endpoint)
      const response = await this.get(`api/admin_api/instance_dashboard/beacons/${encodedEndpoint}?${queryString}`)
      if (!response.success) {
        LoggerService.error(`Failed to get beacons data ${response?.statusText} ${response?.status} ${response?.data}`)
        return []
      }
      return response.data as AggregatedHitsPerTenantQueryResult[]
    } catch (e) {
      LoggerService.error(e)
      return
    }
  }

  async getStatistics<T = ExtendedGetMonthlyStatisticsResults> (getFromStorytelling?: boolean): Promise<T> {
    try {
      let queryString = ''
      if (getFromStorytelling) {
        queryString = '?getFromStorytelling=true'
      }
      let response = (await this.get(`api/admin_api/instance_dashboard/statistics${queryString}`)).data as ExtendedGetMonthlyStatisticsResults
      response?.totalJs?.dailyJS?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        .forEach((item) => {
          item.date = MethodsService.cxDateFormat(item?.date).localDate
          item.formattedTotal = MethodsService.numberToSuffix(item?.total, 2)
        })
      return response as T
    } catch (e) {
      LoggerService.error(e)
      return
    }
  }

  async getConfigDifferences (): Promise<any> {
    const _configHelps = new PICustomerConfigComponent(this._configs)
    let customersWithConfig: tenantsConfigDetailed[] = []
    try {
      const { data } = await this._configs.getCustomersConfigs({ limit: Number.MAX_SAFE_INTEGER })
      const { staging, production } = data
      const { count: stagingCount, data: stagingData } = staging
      const { count: productionCount, data: productionData } = production

      // if (stagingCount !== productionCount || stagingData.some((c, i) => c._id !== productionData[i]._id)) {
      //   LoggerService.info('stagingData:', stagingData)
      //   LoggerService.info('productionData:', productionData)
      //   LoggerService.error(`stagingCount: ${stagingCount}, productionCount: ${productionCount}`)
      // }

      if (staging && production) {
        customersWithConfig = stagingData.map((c) => ({
          _id: c._id,
          staging:  _configHelps.fillDefaults(c.customerConfig),
          production:  _configHelps.fillDefaults(productionData.find(p => p._id === c._id).customerConfig),
          hasConfigDiff: this.hasConfigDiff(_configHelps.defaults, c, productionData.find(p => p._id === c._id))
        }))
      } else {
        throw Error('Bad Response')
      }
      return PiInstancesDashboardProcessorService.processConfigs(customersWithConfig)
    } catch (e) {
      LoggerService.error(e)
      return
    }
  }

  hasConfigDiff (defaultConfig: Record<keyof EditableCustomer, any>, staging: CustomerWithConfig, production: CustomerWithConfig): boolean {

    if (!staging.customerConfig) {
      return true
    }

    if (staging.customerConfig && !production.customerConfig) {
      return true
    }

    const stagingJson: Partial<CustomerConfigSchema> = {}
    const productionJson: Partial<CustomerConfigSchema> = {}

    for (const key in defaultConfig) {
      if (!(key in staging.customerConfig) || staging.customerConfig[key] === undefined || staging.customerConfig[key] === null) {
        stagingJson[key] = defaultConfig[key]
      } else {
        stagingJson[key] = staging.customerConfig[key]
      }

      if (production.customerConfig) {
        if(!(key in production.customerConfig) || production.customerConfig[key] === undefined || production.customerConfig[key] === null) {
          productionJson[key] = defaultConfig[key]
        } else {
          productionJson[key] = production.customerConfig[key]
        }
      }
      if (!MethodsService.objectsAreEqual(stagingJson[key], productionJson[key])) {
        return true
      }
    }
    return false
  }


}

Promise.allSettled = Promise.allSettled || ((promises) => Promise.all(
  promises.map(p => p
    .then(value => ({
      status: "fulfilled",
      value
    }))
    .catch(reason => ({
      status: "rejected",
      reason
    }))
  )
))
