export class CustomerConfigProcessorService {
  static process<T extends CustomerWithConfig> (customerOrCustomers: T | T[]): T | T[] {
    return Array.isArray(customerOrCustomers) ?
      customerOrCustomers.map(CustomerConfigProcessorService.processCustomer) as T[] :
      CustomerConfigProcessorService.processCustomer(customerOrCustomers) as T
  }

  static deepSortConfig (configObject) {
    function getTrueType (item) {
      if (item == null) {
        return 'undefined'
      }

      const ctorType = item && item.constructor && item.constructor.name && item.constructor.name.toLowerCase()
      const naturalType = typeof item

      return ctorType || naturalType.toLowerCase()
    }

    function sortObjectByKey (unorderedObject) {
      const ordered = {}
      Object.keys(unorderedObject).sort().forEach(function (key) {
        ordered[key] = unorderedObject[key]
      })

      return ordered
    }

    function deepSortArray (array) {
      const sortedArray = []
      const arrayHasObjects = array.filter(value => getTrueType(value) === 'object')
      const arrayHasArrays = array.filter(value => getTrueType(value) === 'array')
      if (arrayHasObjects.length || arrayHasArrays.length) {
        // sort inner array values
        array.forEach(value => {
          sortedArray.push(deepSort(value))
        })

        // Sort array values
        return sortedArray.sort()
      }

      // Sort original array values
      return array.sort()
    }

    function deepSort (item) {
      const currentType = getTrueType(item)
      if (currentType === 'object') {
        item = sortObjectByKey(item)
        for (const objectKey in item) {
          item[objectKey] = deepSort(item[objectKey])
        }
      } else if (currentType === 'array') {
        item = deepSortArray(item)
        for (let index = 0; index < item.length; index++) {
          item[index] = deepSort(item[index])
        }
      }
      return item
    }

    return deepSort(configObject)
  }

  private static processCustomer (customer: CustomerWithConfig): CustomerWithConfig {
    if (customer.customerConfig) {
      customer.customerConfig = CustomerConfigProcessorService.deepSortConfig(customer.customerConfig)
    }
    return customer
  }

}

