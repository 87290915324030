import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { MethodsService } from '../../services/methods/methods.service'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { LoggerService } from '../../services/logger/logger.service'
import { LogsService } from '../../services/api/services/logs.service'
import { GlobalNotification, GlobalState } from '../../app.state'

@Component({
  selector: 'pi-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class PIDashboardComponent implements OnInit, OnDestroy {

  readonly title = 'Dashboard'

  readonly timeFilter = MethodsService.timeObject()
  currentTime = this.timeFilter[4]

  private _debounce: void | number
  private debounce_timeout: number = 1000

  _isRefreshing: boolean = false

  textToClip = MethodsService.copyToClipboard

  normalizeString = MethodsService.normalizeString

  constructor (private _logs: LogsService, private _zone: NgZone, private _state: GlobalState) {
  }

  ngOnDestroy (): void {
  }

  ngOnInit (): void {
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshData(true))
  }

  onFilterChange (item: any) {
    if (this._debounce) {
      this._debounce = clearTimeout(this._debounce)
    }
    this._debounce = setTimeout(() => this.refreshData(), this.debounce_timeout) as any as number
  }

  async refreshData (background: boolean = false): Promise<void> {
    if (this._isRefreshing) {
      return
    }
    this._isRefreshing = true
    try {
      if (!background) {
        SpinnerService.spin('mini')
      }

      if (background) {
        return
      }

    } catch (e) {
      if (!background) {
        MethodsService.toast('error', 'Error', e.toString(), 8)
      }
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._zone.run(() => {})
      this._isRefreshing = false
    }
  }

}
