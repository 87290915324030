import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { MethodsService, XlsService } from '../../services/methods/methods.service'
import { PIInstancesDashboardService } from '../../services/api/services/pi-instances-dashboard.service'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { LoggerService } from '../../services/logger/logger.service'
import { AkamaiCustomersService } from '../../services/api/services/akamai-customers.service'
import { CustomerConfigService } from '../../services/api/services/customer-config.service'
import { USER_POWER_LEVEL } from '../../shared/user-power'
import { UserDataService } from '../../services/user-data/user-data.service'
import {
  PiInstancesDashboardProcessorService
} from '../../services/api/processors/pi-instances-dashboard-processor.service'
import { GlobalNotification, GlobalState } from '../../app.state'


@Component({
  selector: '',
  templateUrl: './pi-instances-dashboard.component.html',
  styleUrls: ['./pi-instances-dashboard.component.scss']
})
export class PIInstancesDashboardComponent extends TableWithFilters implements OnInit, OnDestroy {

  readonly title: string = 'Instances Dashboard'
  readonly paginationName: string = 'Instances'

  filterValue: string = ''
  clusters: InstancesDashboardItem[] = []

  selectedCluster?: InstancesDashboardSubItem
  availableTenants: MappedTenants = []
  akamaiCustomerContract: object = {}
  configData: tenantConfigurationData = {}
  spaAndAhTenantData = {}
  monthlyBeacons: HitCounter
  monthlyJS: ExtendedDailyJsObject
  monthlyJSTitle: string
  _isRefreshing: boolean = false
  isoFilters = MethodsService.timeObjectToIso(this.currentTime.text)
  granularity = this.isoFilters.granularity
  period = this.isoFilters.period
  filtered: InstancesDashboardItem[]
  filteredDataForDownload: InstancesDashboardItem[]
  numOfAllDocs: number
  memoryDataKey = 'relativeAverage'
  cpuDataKey = 'average'
  dataGraphMax: boolean = false
  getFromStorytelling: boolean = false
  statisticsError: string
  _showMaster: boolean = true

  textToClip = MethodsService.copyToClipboard
  numberToSuffix = MethodsService.numberToSuffix
  export = XlsService.export
  @ViewChild('mainTable') mainTable: ElementRef

  //table
  readonly tableHeaders: TableHeader[]
  currentSortBy: string

  //@Override
  timeFiltersUsed = 8
  readonly timeFilter = MethodsService.timeObject().slice(0, this.timeFiltersUsed)
  currentTime = this.timeFilter[3]
  isAdmin: boolean = false

  constructor (
    protected _state?: GlobalState,
    protected _clusters?: PIInstancesDashboardService,
    private _userData?: UserDataService,
    private _akamaiCustomers?: AkamaiCustomersService,
    private _configs?: CustomerConfigService,
    protected _zone?: NgZone
  ) {
    super()
    this.currentItemsPerPage = this.itemsPerPageFilter[1]
    this.tableHeaders = [
      //@formatter:off
      { /*width: '12',*/    name: 'Instance ID',         sortable: true,  field: 'instanceId'       },
      { /*width: '18',*/    name: 'Accounts',            sortable: true,  field: 'akamaiIdsLength'  },
      { /*width: '12',*/    name: 'Tenants',             sortable: true,  field: 'tenantsLength'    },
      { /*width: '12',*/    name: 'Provider',            sortable: true,  field: '$provider'        },
      { /*width: '12',*/    name: 'Mongo Tier',          sortable: true,  field: '$mongoTier.value' },
      { /*width: '12',*/    name: 'Storage',             sortable: true,  field: '$storageInUse'    },
      { /*width: '12',*/    name: 'Beacons',             sortable: true,  field: '$beacons.sort'    },
      { /*width: '12',*/    name: 'Total JS',            sortable: true,  field: '$totalJS.sort'    },
      { /*width: '12',*/    name: 'Memory Size & Trend', sortable: true,  field: '$memoryFilter'    },
      { /*width: '12',*/    name: 'CPU Trend (%)',       sortable: false,                           },
      { /*width: '18',*/    name: 'Details',             sortable: false,                           },

      //@formatter:on
    ].filter(x => !!x)
    this.currentSortBy = '$beacons.sort'
    this.isAdmin = USER_POWER_LEVEL[this._userData?.permission] >= USER_POWER_LEVEL[UserPermission.ADMIN]
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    if (!this.selectedCluster) {
      return
    }
    if (key === 'Escape') {
      this.clearSelections()
    }
  }

  ngOnInit (): void {
    window.addEventListener('keyup', this.registerModalListeners.bind(this))
    this.updateStatistics(this.getFromStorytelling)
    this.refreshTable!()
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  ngOnDestroy (): void {
    window.removeEventListener('keyup', this.registerModalListeners.bind(this))
  }

  clearSelections () {
    this.selectedCluster = undefined
    this.availableTenants = []
  }

  updateTimeFilter (time: { text: string; absTime: Function; time: Function }) {
    this.currentTime = time
    this.isoFilters = MethodsService.timeObjectToIso(time.text)
    this.granularity = this.isoFilters.granularity
    this.period = this.isoFilters.period
    this.refreshTable!()
  }

  updateTextFilter () {
    if (this.clusters.length < this.numOfAllDocs) {
      this.refreshTable!()
    } else {
      this.sortTable()
    }
  }

  updateSource () {
    this._showMaster = !this._showMaster
    this.refreshTable!()
  }

  updateStatistics (getFromStorytelling?: boolean) {
    this.getFromStorytelling = getFromStorytelling !== undefined ? getFromStorytelling : !this.getFromStorytelling
    this.statisticsError = undefined
    this.monthlyBeacons = undefined
    this.monthlyJS = undefined
    this.monthlyJSTitle = undefined
    this._clusters.getStatistics(this.getFromStorytelling)
      .then(data => {
        this.monthlyBeacons = data.totalBeacons
        this.monthlyJS = data.totalJs
        this.monthlyJSTitle = this.getDailyJSTitle(data?.totalJs)
        if (data.timedOutInstances?.length > 0) {
          this.setStatisticsError(data.timedOutInstances)
        }
      })
    this.ref_hljs(this._zone)
  }

  private setStatisticsError (timedOutInstances: StandardObject[]) {
    this.statisticsError = `Failed to get statistics for the following instances\ndata can be incomplete or missing:\n`
    const errorInstances = []
    timedOutInstances.forEach(instance => {
      errorInstances.push(`Instance ${instance.instance} returned error: ${instance.error}`)
    })
    this.statisticsError += errorInstances.join(`\n`)
  }

  changeGraphView () {
    this.dataGraphMax = !this.dataGraphMax
    this.memoryDataKey = this.memoryDataKey === 'relativeAverage' ? 'relativeMax' : 'relativeAverage'
    this.cpuDataKey = this.cpuDataKey === 'average' ? 'max' : 'average'
    this.refreshGraphs()
  }

  refreshGraphs () {
    this.filtered.forEach(cluster => {
      if (!cluster.memory || !cluster.cpuTrend) {
        return
      }
      const temp = {
        memory: cluster.memory,
        cpuTrend: cluster.cpuTrend
      }
      cluster.memory = undefined
      cluster.cpuTrend = undefined
      setTimeout(() => {
        this._zone.run(() => {
          cluster.memory = temp.memory
          cluster.cpuTrend = temp.cpuTrend
        })
      }, 500)
    })
  }

  async refreshTable (background: boolean = false): Promise<void> {
    if (this.selectedCluster || this._isRefreshing) {
      return
    }
    this._isRefreshing = true
    try {
      if (!background) {
        this.clusters = []
        this.clearSelections()
        SpinnerService.spin('mini')
      }
      const { count, data } = await this.getClusters() as any as { count: number, data: InstancesDashboardItem[] }
      if (typeof count == 'number' && data ) {
        this.clusters = data
      }
    } catch (e) {
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
      this.sortTable()
      this.ref_hljs(this._zone)
      this._isRefreshing = false
    }
  }

  sortTable () {
    this.filtered = [...this.clusters]
    let filterSortBy: string
    if (this.currentSortBy == '$memoryFilter') {
      filterSortBy = this.dataGraphMax ? 'memory.activeMaxUse' : 'memory.activeAverageUse'
    } else {
      filterSortBy = this.currentSortBy.replace('$', '')
    }
    const searchFields = ['instanceId', 'mongoTier.value', 'storage.size', 'memory.size']
    this.filtered = this.sortTableData(this.filtered, filterSortBy, this.isSortReversed, this.filterValue, searchFields)
    this.numOfAvailableDocs = this.filtered.length
    this.filteredDataForDownload = this.filtered
    this.filtered = this.filtered.slice((this.currentPage - 1) * this.currentItemsPerPage, this.currentItemsPerPage * this.currentPage)

    this.ref_hljs(this._zone)
  }

  async getCluster (_cluster: InstancesDashboardItem, doNotShowTable: boolean = false): Promise<void> {
    try {
      if (Object.keys(this.akamaiCustomerContract || {}).length === 0) {
        this._akamaiCustomers.getCustomers({limit: Number.MAX_SAFE_INTEGER}).then(result => {
          result.data?.forEach(customer => {
            this.akamaiCustomerContract[customer.akamaiAccountId] = customer.contractType
          })
        })
      }
      if (Object.keys(this.configData).length === 0){
        this._clusters.getConfigDifferences().then(data => this.configData = data || {})
      }
      if (Object.keys(this.spaAndAhTenantData).length === 0) {
        this._configs.getClusterConfigData().then(data => {
          data.forEach((tenant) => {
            this.spaAndAhTenantData[tenant._id] = {
              hasSpa: tenant.hasSPA,
              ahRatio: tenant.AHRatio,
              fullAh: tenant.fullAH
            }
          })
        })
      }
      SpinnerService.spin('mini')
      const clusterData = await this._clusters.getCluster(_cluster, { fromDate: this.currentTime.time() })
      if(!doNotShowTable) this.selectedCluster = clusterData
    } catch (e) {
      const secondsToShow = 8
      if (!doNotShowTable) {
        MethodsService.toast('error', 'Error fetching cluster ' + _cluster.clusterId, e.toString(), secondsToShow)
      }
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
      this._zone.run(() => {})
    }
  }

  private getDailyJSTitle (data: ExtendedDailyJsObject): string {
    const titleHeaderLength = 55
    let jsTitle = `Raw sum: ${data?.averageDailyJS?.toLocaleString()}\n${'-'.repeat(titleHeaderLength)}\n`
    let dataArray = []
    const amountMinWidth = 6
    data?.dailyJS?.forEach(i => {
      dataArray.push(`${i?.date}: ${i?.formattedTotal.padStart(amountMinWidth)}`)
    })
    let rowArray = []
    for (let i = 0, j = Math.ceil(dataArray?.length / 2); i < (dataArray?.length / 2); i++, j++) {
      const columnWidth = 19
      const rowData = `${dataArray[i].padEnd(columnWidth)}${dataArray[j] ? `| ${dataArray[j]}` : ``}`
      rowArray.push(rowData)
    }
    return jsTitle + rowArray.join(`\n`)
  }

  private async getClusters(): Promise<InstancesDashboardItem[]> {
    const options =  {
      limit: 1000,
      fromDate: this.currentTime.time(),
      granularity: this.granularity,
      period: this.period,
      clusterOnly: !this._showMaster
    }
    return await this._clusters.getAllClusters(options) as any as InstancesDashboardItem[];
  }

  async exportDataToExcel(): Promise<void> {
    let jsonData = {}
    let tableData = {}
    const { customersWithoutConfig, paidCustomers } = await this.getCustomersDataForDownload()
    const fullData = this.filteredDataForDownload ? this.filteredDataForDownload : this.filtered

    jsonData['fullData'] = PiInstancesDashboardProcessorService.processDownloadClusters(fullData)
    jsonData['customersWithoutConfig'] = PiInstancesDashboardProcessorService.processDownloadTenants(customersWithoutConfig)
    jsonData['paidCustomers'] = PiInstancesDashboardProcessorService.processDownloadTenants(paidCustomers)
    tableData['tableData'] = this.mainTable
    XlsService.multipleSheetsExport('instanceDashboardData', jsonData, tableData)
  }

  async getCustomersDataForDownload(): Promise<{ customersWithoutConfig: MappedInstanceDashboardTenants, paidCustomers: MappedInstanceDashboardTenants }> {
    try {
      if (this._clusters.allTenants.length == 0) {
        await this.getCluster(this.clusters[0], true)
      }
      const allDataLoaded = await this.allDataLoaded()
      if (!allDataLoaded) {
        throw new Error('Could not fetch tenants or akamai customer details')
      }
      const customersWithoutConfig = this._clusters.allTenants.filter(tenant => {
        return !this.configData[tenant.id].hasConfig && this.akamaiCustomerContract[tenant.akamaiId] == '0'
      })
      const paidCustomers = this._clusters.allTenants.filter(tenant => {
        return this.akamaiCustomerContract[tenant.akamaiId] == '0'
      })
      return { customersWithoutConfig, paidCustomers }
    } catch (e) {
      const secondsToShow = 8
      LoggerService.error(e)
      MethodsService.toast('error', 'Error fetching customer details downloading limited data', e.toString(), secondsToShow)
      return { customersWithoutConfig: [], paidCustomers: [] }
    }
  }

  private async allDataLoaded (): Promise<boolean> {
    const timeoutMS = 1000 * 60
    return await Promise.race([
      (async () => {
        while (Object.keys(this.akamaiCustomerContract).length === 0 || Object.keys(this.configData).length === 0 || this._clusters.allTenants.length === 0) {
          await MethodsService.wait(100)
        }
        return true
      })(),
      MethodsService.wait(timeoutMS).then(() => false)
    ]) as boolean
  }
}
