import { Component, Input, OnInit } from '@angular/core'
import { DataService } from '../../../services/data/data.service'
import { MethodsService } from '../../../services/methods/methods.service'

@Component({
  selector: 'cx-bar-chart',
  templateUrl: './cx-bar-chart.component.html',
  styleUrls: ['./cx-bar-chart.component.scss']
})

export class CxBarChartComponent implements OnInit {

  public readonly barChartType: Chart.ChartType = 'bar'
  public readonly barChartColors: Chart.ChartArcOptions[]

  public readonly UNIQUE_CHART_ID = MethodsService.generateUniqueID('letters')
  public readonly UNIQUE_CANVAS_ID = MethodsService.generateUniqueID('letters')

  @Input() public barChartLabels: string[]
  @Input() public barChartLegend: boolean
  @Input() public barChartData: Chart.ChartDataSets[]
  @Input() public barChartLegendPosition?: Chart.PositionType
  @Input() public style?: Obj<string>

  private barChartOptions: Chart.ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        stacked: true,
        barPercentage: 0.8,
        barThickness: 10,
        maxBarThickness: 14,
        gridLines: {
          offsetGridLines: true
        }
      }],
      yAxes: [{
        stacked: true
      }],
    },
    legend: {
      labels: {
        boxWidth: 10,
        fontFamily: '\'Open Sans\', sans-serif',
        usePointStyle: true
      },
      position: 'left'
    }
  }

  public isReady = false

  constructor () {
    this.barChartColors = [
      {
        backgroundColor: [...DataService.chartsColors.pie_colors_dark.map(color => MethodsService.hexToRGBA(color))]
      }
    ]
  }

  ngOnInit () {
    this.style = this.style || {}
    this.barChartOptions.legend.position = this.barChartLegendPosition || this.barChartOptions.legend.position
    this.isReady = true
  }
}
