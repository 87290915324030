const appState: Map<AppStateKey, AppStateValue[AppStateKey]> = new Map()

export function setState<T extends AppStateKey> (key: T, value: AppStateValue[T]): void {
  appState.set(key, value)
}

export function getState<T extends AppStateKey> (key: T): IfDefined<AppStateValue[T]> {
  return appState.get(key)
}

export function clearState () {
  appState.clear()
}

export function deleteState<T extends AppStateKey> (key: T): void {
  appState.delete(key)
}

export function hasState<T extends AppStateKey> (key: T): boolean {
  return appState.has(key)
}
