import { Injectable } from '@angular/core'

const FULL_SCREEN_SPINNER_SELECTOR = 'cx-spinner-wrapper'
const PAGE_SPINNER_SELECTOR = 'cx-spinner-mini-wrapper'

@Injectable()
export class SpinnerService {

  public static spin (spinner: 'full' | 'mini' | null = 'mini', id?: string, style?: object): void {
    const logic = () => {
      const element = document.getElementById(spinner === 'full' ? FULL_SCREEN_SPINNER_SELECTOR : spinner === 'mini' ? PAGE_SPINNER_SELECTOR : id)
      element.style.display = 'block'
      if (style) {
        for (const key in style) {
          element.style[key] = style[key]
        }
      }
    }
    //using a 0 timeout for the mini spinner to ensure that elements are present after writing sync html code
    spinner == 'mini' ? setTimeout(() => logic(), 0) : logic()
  }

  public static stop (spinner: 'full' | 'mini' | null = 'mini', id?: string, style?: object): void {
    const logic = () => {
      const element = document.getElementById(spinner === 'full' ? FULL_SCREEN_SPINNER_SELECTOR : spinner === 'mini' ? PAGE_SPINNER_SELECTOR : id)
      element.style.display = 'none'
      if (style) {
        for (const key in style) {
          element.style[key] = style[key]
        }
      }
    }
    spinner == 'mini' ? setTimeout(() => logic(), 0) : logic()
  }

}
