import { MethodsService } from '../../methods/methods.service'

export class RwaspModulesProcessorService {
  static process<T extends BundlerModulesSchema> (customerOrCustomers: T | T[]): T | T[] {
    return Array.isArray(customerOrCustomers) ?
      customerOrCustomers.map(RwaspModulesProcessorService.processModule) as T[] :
      RwaspModulesProcessorService.processModule(customerOrCustomers) as T
  }

  private static processModule (rwaspModule: BundlerModulesSchema): BundlerModulesSchema {
    ['createdAt', 'updatedAt'].forEach(atr => {
      const dd = MethodsService.cxDateFormat(rwaspModule[atr]);
      (rwaspModule as any)[atr + '_timestamp'] = dd.dateAgo
      rwaspModule[atr] = dd.dts
    });

    (rwaspModule as any)._modified = rwaspModule.createdAt != rwaspModule.updatedAt

    return rwaspModule
  }

}

