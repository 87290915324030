import { DataService } from '../data/data.service'
import { utils, WorkBook, WorkSheet, writeFile } from 'xlsx'
import { Ng2OdometerConfigModel } from 'ng2-odometer/dist/odometer/odometer.config'
import * as crypto from 'crypto-js'
import { LoggerService } from '../logger/logger.service'
import { ElementRef } from '@angular/core'

export const OdometerConfig: Ng2OdometerConfigModel = {
  auto: true,
  format: '(,ddd)',
  animation: 'count',
  duration: 1000
}

export const OdometerFloatConfig: Ng2OdometerConfigModel = {
  auto: true,
  format: '(,ddd).dd',
  animation: 'count',
  duration: 1000
}

//@formatter:off
export const NUMERIC_SORT_ARRAY: { name: string, data: NumericOperator, sign: string }[] = [
  { name: 'Equals',               sign: '==', data: NumericOperator.EQUALS },
  { name: 'Not Equal',            sign: '!=', data: NumericOperator.NOT_EQUALS },
  { name: 'Less Than',            sign: '<',  data: NumericOperator.LESS_THAN },
  { name: 'Greater Than',         sign: '>',  data: NumericOperator.GREATER_THAN },
  { name: 'Less Than Equals',     sign: '<=', data: NumericOperator.LESS_THAN_EQUAL },
  { name: 'Greater Than Equals',  sign: '>=', data: NumericOperator.GREATER_THAN_EQUAL },
]
//@formatter:on

declare const $: any

const NUM_K = 1000
const NUM_M = 1000000
const NUM_B = 1000000000

export class MethodsService {

  static readonly cxErrorMessage = 'Please try again or contact the Akamai support if the error repeats.'

  static htmlDecode (input: string): string | null {
    return new DOMParser().parseFromString(input, 'text/html').documentElement
      .textContent
  }

  static removeSameValues<T extends Obj<any>> (original: T, modified: T, keep: string[] = []): T {
    const res = { ...original }
    for (const key in original) {

      if (key in modified && JSON.stringify(original[key]) !== JSON.stringify(modified[key])) {
        res[key] = modified[key]
      } else if (keep.includes(key)) {
        res[key] = original[key]
      } else {
        delete res[key]
      }

    }
    return res
  }

  static downloadAsFile (buffer: BlobPart, filename: string) {
    const alink = document.createElement('a')
    alink.style.display = 'none'
    const blob = new Blob([buffer], { type: 'octet/stream' })
    const url = window.URL.createObjectURL(blob)
    alink.href = url
    alink.download = filename
    document.body.appendChild(alink)
    alink.click()
    window.URL.revokeObjectURL(url)
    alink.remove()
  }

  static openTab (html: string) {
    const tab = window.open('about:blank', '_blank')
    tab.document.write(html)
    tab.document.close()
  }

  static openNewJsonPage (json: any, title = '', indent = 2) {
    const html =
      `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>${title}</title>
    <link rel="stylesheet" href="/path/to/styles/default.css">
    <link rel="stylesheet"
          href="//cdn.jsdelivr.net/gh/highlightjs/cdn-release@10.0.1/build/styles/default.min.css">
    <script src="//cdn.jsdelivr.net/gh/highlightjs/cdn-release@10.0.1/build/highlight.min.js"></script>
</head>
<body>
    <pre><code class="json">
           ${JSON.stringify(json, null, indent)}
    </code></pre>
    <script>hljs.initHighlightingOnLoad();</script>
</body>
</html>`
    return MethodsService.openTab(html)
  }

  static isFloat = (num: Expected<number>) => typeof num === 'number' && num.toString().split('.').length == 2

  static aggregateNum = (num: number, maxLevel?: '' | 'K' | 'M' | 'B'): {
    value: number,
    suffix: '' | 'K' | 'M' | 'B'
  } => {
    if (num < NUM_K || maxLevel == '') {
      return { value: num, suffix: '' }
    }
    if (num < NUM_M || maxLevel == 'K') {
      return { value: num / NUM_K, suffix: 'K' }
    }
    if (num < NUM_B || maxLevel == 'M') {
      return { value: num / NUM_M, suffix: 'M' }
    }
    return { value: num / NUM_B, suffix: 'B' }
  }

  static aggregateNumFix (number: number, decimalPoints: number = 2) {
    const result = this.aggregateNum(number)
    result.value = parseFloat(result.value.toFixed(decimalPoints))
    return `${result.value}${result.suffix}`
  }

  static cloneJsonObject (object: Object): any {
    return JSON.parse(JSON.stringify(object))
  }

  static padVersion (version: string, padding: number = 4): string {
    const pad = (num: string, width = 4, filler = '0') =>
      num.length >= width ? num : new Array(width - num.length + 1).join(filler) + num

    return version
      .split('.')
      .map(num => pad(num, padding))
      .join('.')
  }

  static isVersion(value: any) {
    if(typeof value != 'string') {
      return false
    }
    return /^\d+\.\d+\.\d+$/.test(value)
  }

  static versionForFiltering(version: string): number {
    return version.split('.').reverse().reduce((b, a ,i) => {
      return (Number(a) * Math.pow(10, i * 4)) + Number(b)
    }, 0)
  }

  static compareVersions(a: string, b: string): number {
    const aVersion = MethodsService.versionForFiltering(a)
    const bVersion = MethodsService.versionForFiltering(b)
    return aVersion - bVersion
  }

  //@formatter:off
  //@ts-ignore
  static reverseObjectKV = <K extends any, V extends any> (obj: { [key: K]: V }): { [key: V]: K } => Object.fromEntries(Object.entries(obj).reverse().map(([k, v]) => [v, k]))
  //@formatter:on

  static filterEmpty = (item: any) => item !== '' && item !== null && item !== undefined

  static generateUniqueID = (type?: 'digits' | 'letters'): string => type ?
    (new Date().getTime().toString().substr(7, 5) + Math.random().toString(36).substr(2, 9)).replace(type === 'digits' ? /[A-Za-z]/gm : /\d/gm, '') :
    new Date().getTime().toString().substr(7, 5) + Math.random().toString(36).substr(2, 9)

  static toggleHtmlScroll = (active: boolean) => {
    document.getElementsByTagName('html')[0].setAttribute('style', `overflow-y: ${active ? 'auto' : 'hidden'}`)
  }

  static stripUrl = (domain: string): string => domain.startsWith('chrome-extension:') ? domain : domain.replace(/https?:\/\//, '').split('/')[0]

  static stripUrlQueryString = (domain: string): string => domain.split('?')[0]

  static validateDomain = (domain: string, alert?: boolean): boolean => {
    const regex = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/
    const check = regex.test(domain)
    if (alert && !check) {
      MethodsService.dialog('Invalid Domain!', 'Please Enter a Valid Domain')
    }
    return check
  }

  static validateSimpleInput = (input: string): boolean => {
    const regex = /^[\d\w _\-#]*$/gim
    return regex.test(input)
  }

  static validateEmail = (email: string): boolean => {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
  }

  private static getDateAgo = originalDate => {
    const now = (new Date()).getTime()
    const then = originalDate.getTime()
    // Get diff in seconds
    let diffSeconds = (now - then) / 1000
    let diffMinutes = diffSeconds / 60
    let diffHours = diffMinutes / 60
    let diffDays = diffHours / 24
    let diffWeeks = diffDays / 7
    let diffMonths = diffWeeks / 4
    let diffYears = diffMonths / 12

    // Set default
    let [diff, unit] = [diffYears, 'year']

    if (Math.ceil(diffSeconds) < 60) {
      [diff, unit] = [diffSeconds, 'second']
    } else if (Math.ceil(diffMinutes) < 60) {
      [diff, unit] = [diffMinutes, 'minute']
    } else if (Math.ceil(diffHours) < 24) {
      [diff, unit] = [diffHours, 'hour']
    } else if (Math.ceil(diffDays) < 7) {
      [diff, unit] = [diffDays, 'day']
    } else if (Math.ceil(diffWeeks) < 4) {
      [diff, unit] = [diffWeeks, 'week']
    } else if (Math.ceil(diffMonths) < 12) {
      [diff, unit] = [diffMonths, 'month']
    }

    // Normalize
    diff = Math.ceil(diff)
    unit = diff > 1 ? unit + 's' : unit

    return `${diff} ${unit} ago`
  }

  static copyToClipboard = (text: string | number | boolean) => {
    text = text.toString()
    const textElement = document.createElement('textarea')
    textElement.value = text
    document.body.appendChild(textElement)
    textElement.select()
    document.execCommand('copy')
    document.body.removeChild(textElement)
  }

  static objectsAreEqual = <T extends object> (a: T, b: Expected<T>): b is T => {
    return JSON.stringify(a) === JSON.stringify(b)
  }

  static getDomain = (domain: string): string => {
    const regex = /[\w-:]*\/\/([\d\w-\.]*)\//
    return regex.test(domain) ? regex.exec(domain)[1] : domain
  }

  static hexToRGBA = (hex: string, alpha: number = 1): string =>
    'rgba(' + parseInt(hex.slice(1, 3), 16) + ', ' + parseInt(hex.slice(3, 5), 16) + ', ' + parseInt(hex.slice(5, 7), 16) + ', ' + alpha + ')'

  static reverseEnum = (obj: any): Obj<string> => {
    const reversed: Obj<string> = {}
    //@ts-ignore
    Object.entries(obj).forEach(([k, v]) => reversed[v] = k)
    return reversed
  }

  static enumToObj = (obj: any): Obj<string> => {
    const objectEnum: Obj<string> = {}
    //@ts-ignore
    Object.entries(obj).forEach(([k, v]) => objectEnum[k] = v)
    return objectEnum
  }

  static integerWithCommas = (intNumber: number | string): string => intNumber?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  static floatWithCommas = (floatNumber: number | string): string => {
    floatNumber = typeof floatNumber === 'number' ? floatNumber.toString() : floatNumber
    return MethodsService.integerWithCommas(parseFloat(floatNumber.replace(',', '')).toFixed(2))
  }

  static mockLatestTime = (timeBackwardsInMinutes: number) => {
    const now = new Date()
    const date = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      now.getMinutes(),
      now.getSeconds(),
      now.getMilliseconds() - (Math.random() * (1000 * 60 * timeBackwardsInMinutes))
    )
    const timeString = date.toLocaleTimeString([], { hour12: false }).split(' ')[0]
    let dateString: any = date.toLocaleDateString().split('/')
    dateString[dateString.length - 1] = now.getFullYear().toString().substr(2)
    dateString = dateString.join('/')
    return `${dateString} ${timeString}`
  }

  static upperCasedString = (str: string): string => {
    const splat = []
    let lastCutIndex = 0
    try {
      for (let i = 0; i < str?.length; i++) {
        const charCode = str.charCodeAt(i)
        if (65 <= charCode && charCode <= 90 || (i == str.length - 1)) {
          splat.push(str.slice(lastCutIndex, i == str.length - 1 ? str.length : i))
          lastCutIndex = i
        }
      }
    } catch (e) {
      LoggerService.error(e)
    }
    return splat.map(s => s[0].toUpperCase() + s.slice(1).toLowerCase()).join(' ')
  }

  static normalizeString = (str: Expected<string>, splitter = '_'): string => !str ?
    '' :
    str.length > 3 ? str.split(splitter).map(a => `${a[0].toUpperCase()}${a.slice(1).toLowerCase()}`.trim()).join(' ') : str

  static sliceString (str: string, maxLength: number = 40): string {
    if (str.length > maxLength) {
      return `${str.slice(0, maxLength)}`
    }
    return str
  }

  static cxDateFormat = (timestamp?: any | string | number): {
    dts: string,
    date: string,
    time: string,
    dateAgo: string,
    timestamp: number,
    localDts: string,
    localDate: string,
    localTime: string,
    ts: Date
  } => {
    if (typeof timestamp == 'string' && timestamp.match(/^\d*$/gim)) {
      timestamp = parseInt(timestamp)
    }
    const ts = timestamp && new Date(timestamp) || new Date()
    const utc: any = {
      seconds: ts.getUTCSeconds(),
      minutes: ts.getUTCMinutes(),
      hours: ts.getUTCHours(),
      day: ts.getUTCDate(),
      month: ts.getUTCMonth() + 1,
    }
    const local: any = {
      seconds: ts.getSeconds(),
      minutes: ts.getMinutes(),
      hours: ts.getHours(),
      day: ts.getDate(),
      month: ts.getMonth() + 1,
    }

    for (const key in utc) {
      utc[key] = (utc[key] < 10 ? '0' : '') + utc[key]
      local[key] = (local[key] < 10 ? '0' : '') + local[key]
    }
    utc.year = ts.getUTCFullYear().toString().substr(2)
    local.year = ts.getFullYear().toString().substr(2)
    const time = `${utc.hours}-${utc.minutes}-${utc.seconds}`
    const localTime = `${local.hours}-${local.minutes}-${local.seconds}`
    const date = `${utc.day}-${utc.month}-${utc.year}`
    const localDate = `${local.day}-${local.month}-${local.year}`
    const dts = `${date.replace(/-/g, '/')}, ${time.replace(/-/g, ':')}`
    const localDts = `${localDate.replace(/-/g, '/')}, ${localTime.replace(/-/g, ':')}`
    const dateAgo = MethodsService.getDateAgo(ts)
    return { dts, localDts, date, localDate, time, localTime, dateAgo, timestamp: ts.getTime(), ts }
  }

  static generateChartLabels = (input: string) => {
    const labels = []
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth()
    const day = now.getDate()
    const hours = now.getHours()
    const minutes = now.getMinutes()
    const time = now.getTime()

    let date
    let labelFormat
    //@formatter:off
    switch (input) {
      case 'Last 30 Minutes':
        labelFormat = [6, 'Hours', 'Minutes', ':']
        date = new Date(year, month, day, hours, minutes - 30)
        break
      case 'Last Hour':
        labelFormat = [12, 'Hours', 'Minutes', ':']
        date = new Date(year, month, day, hours - 1, minutes)
        break
      case 'Last 3 Hours':
        labelFormat = [12, 'Hours', 'Minutes', ':']
        date = new Date(year, month, day, hours - 3, minutes)
        break
      case 'Last 6 Hours':
        labelFormat = [12, 'Hours', 'Minutes', ':']
        date = new Date(year, month, day, hours - 6, minutes)
        break
      case 'Last 12 Hours':
        labelFormat = [12, 'Hours', 'Minutes', ':']
        date = new Date(year, month, day, hours - 12, minutes)
        break
      case 'Last 24 Hours':
        labelFormat = [12, 'Hours', 'Minutes', ':']
        date = new Date(year, month, day, hours - 24, minutes)
        break
      case 'Last 3 Days':
        labelFormat = [3, 'Date', 'Month', '/']
        date = new Date(year, month, day - 3, hours, minutes)
        break
      case 'Last 7 Days':
        labelFormat = [7, 'Date', 'Month', '/']
        date = new Date(year, month, day - 7, hours, minutes)
        break
      case 'Last 2 Weeks':
        labelFormat = [7, 'Date', 'Month', '/']
        date = new Date(year, month, day - 14, hours, minutes)
        break
      case 'Last Month':
        labelFormat = [12, 'Date', 'Month', '/']
        date = new Date(year, month - 1, day, hours, minutes)
        break
      case 'Last 3 Months':
        labelFormat = [12, 'Date', 'Month', '/']
        date = new Date(year, month - 3, day, hours, minutes)
        break
      default:
        return null
    }
    //@formatter:on

    const [numOfLabels, leftLabel, rightLabel, separator] = labelFormat
    const difference = Math.floor((time - date.getTime()) / numOfLabels)

    for (let i = 0; i <= numOfLabels; i++) {
      const point = new Date((difference * i) + date.getTime())
      const labelL = (point[`get${leftLabel}`]() < 10 ? '0' : '') + point[`get${leftLabel}`]()
      const labelR = /** new Date(2018, 1) will return February 2018 */
        (point[`get${rightLabel}`]() < (rightLabel === 'Month' ? 9 : 10) ? '0' : '') +
        (point[`get${rightLabel}`]() + (rightLabel === 'Month' ? 1 : 0))
      const label = (labelL + separator + labelR).trim()
      labels.push(label)
    }
    /**
     * ng2-charts lineChartLabels update is bugged!
     * their ngOnChanges is not updating new label content...
     * therefore we need to clear the array WITHOUT its reference.
     * this.linesChartLabels.length = 0 does the trick.
     */
    return { labels, numOfLabels }
  }

  static loadScript = (path: string, loadAsync: boolean = false): void => {
    const body = <HTMLDivElement>document.body
    const script: HTMLScriptElement | any = document.createElement('script')
    script.innerHTML = ''
    script.src = path
    script.async = loadAsync
    // script.defer = true
    body.appendChild(script)
    script.remove()
  }

  static htmlifyString = (htmlTag: string, innerHTML: string, attributes?: string) => {
    attributes = attributes || ''
    return `<${htmlTag} ${attributes}>${innerHTML}</${htmlTag}>`
  }

  static boldifyHTML = (innerHTML: string, attributes?: string) => {
    attributes = attributes || ''
    return MethodsService.htmlifyString('b', innerHTML, attributes)
  }

  static italicifyHTML = (innerHTML: string, attributes?: string) => {
    attributes = attributes || ''
    return MethodsService.htmlifyString('i', innerHTML, attributes)
  }

  static objectToQueryString = (obj: object): string => Object.entries(obj).map(([key, value]) => `${key}=${encodeURIComponent(value.toString())}`).join('&')

  static cloneObject = (obj: Obj<any>): any => ({ ...obj })

  static timeObject = (): { text: string, absTime: Function, time: Function }[] => {
    //@formatter:off
    return [
      //0
      {
        text: 'Last Hour',
        absTime: () => 1000 * 60 * 60,
        time: () => new Date().getTime() - (1000 * 60 * 60)
      },
      //1
      {
        text: 'Last 3 Hours',
        absTime: () => 1000 * 60 * 60 * 3,
        time: () => new Date().getTime() - (1000 * 60 * 60 * 3)
      },
      //2
      {
        text: 'Last 12 Hours',
        absTime: () => 1000 * 60 * 60 * 12,
        time: () => new Date().getTime() - (1000 * 60 * 60 * 12)
      },
      //3
      {
        text: 'Last 24 Hours',
        absTime: () => 1000 * 60 * 60 * 24,
        time: () => new Date().getTime() - (1000 * 60 * 60 * 24)
      },
      //4
      {
        text: 'Last 3 Days',
        absTime: () => 1000 * 60 * 60 * 24 * 3,
        time: () => new Date().getTime() - (1000 * 60 * 60 * 24 * 3)
      },
      //5
      {
        text: 'Last 7 Days',
        absTime: () => 1000 * 60 * 60 * 24 * 7,
        time: () => new Date().getTime() - (1000 * 60 * 60 * 24 * 7)
      },
      //6
      {
        text: 'Last 14 Days',
        absTime: () => 1000 * 60 * 60 * 24 * 14,
        time: () => new Date().getTime() - (1000 * 60 * 60 * 24 * 14)
      },
      //7
      {
        text: 'Last Month',
        absTime: (now = new Date()) => now.getTime() - new Date(now.getUTCFullYear(), now.getUTCMonth() - 1, now.getUTCDate(), now.getUTCHours()).getTime(),
        time: (now = new Date()) => new Date(now.getUTCFullYear(), now.getUTCMonth() - 1, now.getUTCDate(), now.getUTCHours()).getTime()
      },
      //8
      {
        text: 'Last 3 Months',
        absTime: (now = new Date()) => now.getTime() - new Date(now.getUTCFullYear(), now.getUTCMonth() - 3, now.getUTCDate(), now.getUTCHours()).getTime(),
        time: (now = new Date()) => new Date(now.getUTCFullYear(), now.getUTCMonth() - 3, now.getUTCDate(), now.getUTCHours()).getTime()
      }
      //@formatter:on
    ]
  }

  static timeObjectToIso = (input: string): { granularity: string, period: string } => {
    let granularity: string
    let period: string

    switch (input) {
      case 'Last Hour':
        period = 'PT1H'
        granularity = 'PT1M'
        break
      case 'Last 3 Hours':
        period = 'PT3H'
        granularity = 'PT3M'
        break
      case 'Last 12 Hours':
        period = 'PT12H'
        granularity = 'PT12M'
        break
      case 'Last 24 Hours':
        period = 'P1D'
        granularity = 'PT24M'
        break
      case 'Last 3 Days':
        period = 'P3D'
        granularity = 'PT72M'
        break
      case 'Last 7 Days':
        period = 'P7D'
        granularity = 'PT3H'
        break
      case 'Last Month':
        period = 'P30D'
        granularity = 'PT12H'
        break
      case 'Last 3 Months':
        period = 'P90D'
        granularity = 'P1DT12H'
        break
      default:
        period = 'PT3H'
        granularity = 'PT3M'
        break
    }
    return { granularity, period }
  }

  static toast = (type: 'success' | 'warning' | 'error' | 'info', heading: string, text?: string, hideAfterSeconds: number = 5) => {

    const color = DataService.colors.notification_palette[type]

    const toast = {
      heading,
      icon: type,
      position: 'top-center',
      loaderBg: color.background,
      showHideTransition: 'plain',
      hideAfter: hideAfterSeconds * 1000
    }

    if (text) {
      toast['text'] = text
    }

    $.toast(toast)
  }

  static dialog = (title: string, content: string, backgroundDismiss: Function | boolean = true) => {
    $.dialog({
      animateFromElement: false,
      animation: 'zoom',
      closeAnimation: 'scale',
      title,
      content,
      backgroundDismiss
    })
  }

  static prompt = (
    title: string,
    content: string,
    placeholder: string = '',
    errMsg?: { title: string, message: string },
    btnText: string = 'Submit',
    validateInput?: ((input: string) => boolean) | string,
    datePicker: boolean = false,
    selectFromList?: { text: string, list?: string[], multi?: boolean, input?: boolean },
    value?: string,
    id = '_dialogInput'
  ): Promise<Nullable<{ input: string, pickedFromList?: string[] }>> => {
    if (typeof validateInput === 'string') {
      switch (validateInput.toLowerCase()) {
        case 'domain':
          validateInput = MethodsService.validateDomain
          break
        case 'simple':
          validateInput = MethodsService.validateSimpleInput
          break
        default:
          validateInput = null
      }
    }

    return new Promise((resolve) => {
      const res: { input: string, pickedFromList?: string[] } = { input: '' }
      // noinspection TypeScriptValidateJSTypes
      $.confirm({
        animateFromElement: false,
        animation: 'zoom',
        closeAnimation: 'scale',
        title,
        content: '' +
          '<form action="" class="formName">' +
          '<div class="form-group">' +
          `<label>${content}</label>` +

          (!datePicker ? '' :
            `<mat-form-field>
                <input id="${id}" matInput type="datetime-local" placeholder="start date">
            </mat-form-field>`) +

          (!selectFromList?.list?.length ? '' :

            `<div class="mt-3 mb-3 ml-1"> 
                <span class="font-size-14"> ${selectFromList.text}</span> : 
                <select ${selectFromList.multi ? 'multiple' : ''} id="_dialoglist" class="border-radius-10px btn btn-inverse-accent font-size-12 ${selectFromList.multi ? 'height-auto' : 'height-35px'}" style="padding: 7px 14px 7px 14px; width: fit-content">
                    ${selectFromList.list.map((item, i) => `<option ${!selectFromList.multi && i == 0 ? 'selected' : ''}>${item}</option>`).join('\n')}
                </select>
            </div>`) +

          (datePicker || (selectFromList && typeof selectFromList.input == 'boolean' && selectFromList.input === false) ? '' :
            `<input id="${id}" ${value ? `value=${value}` : ''} type="text" placeholder="${placeholder}" class="name form-control" required />`) +

          '</div>' +
          '</form>',
        buttons: {
          formSubmit: {
            text: btnText,
            btnClass: 'cx-btn-accent font-size-11',
            action: function () {
              const input: string = this.$content.find(`#${id}`).val()

              if (datePicker) {
                try {
                  new Date(input)
                } catch (e) {
                  $.alert(`<span class="jconfirm-title">Invalid Date Error</span>` + `<br><br>Invalid Date ${input}`)
                  return false
                }
              } else {
                if (errMsg && !input || (typeof validateInput === 'function' && !validateInput(input))) {
                  $.alert(`<span class="jconfirm-title">${errMsg?.title || 'Validator error'}</span>` + `<br><br>${errMsg?.message || 'received bad input'}`)
                  return false
                }
              }
              if (selectFromList) {
                res.pickedFromList = Array.from(((document.getElementById('_dialoglist') as Nullable<HTMLSelectElement>)?.selectedOptions || [])).map(x => x.value)
              }
              res.input = input
              resolve(res)
            }
          },
          cancel: {
            keys: ['esc'],
            btnClass: 'cx-btn-simple font-size-11',
            action: () => resolve(null)
          },
        },
        onContentReady: function () {
          const jc = this
          this.$content.find('form').on('submit', function (e) {
            e.preventDefault()
            jc.$$formSubmit.trigger('click')
          })
        }
      })
    })
  }

  static getPastTime (abs: number): string {
    const ms = 1
    const second = ms * 1000
    const minute = second * 60
    const hour = minute * 60

    if (abs > hour) {
      const h = Math.floor(abs / hour)
      const m = Math.floor((abs - (hour * h)) / minute)
      return h + ' hours and ' + m + ' minutes'
    } else if (abs > minute) {
      const m = Math.floor(abs / minute)
      const s = Math.floor((abs - (minute * m)) / second)
      return m + ' minutes and ' + s + ' seconds'
    } else {
      return (abs / second).toFixed(1) + ' seconds'
    }
  }

  static confirm = (title: string, content: string, confirmTxt: string = 'Save', cancelTxt: string = 'Cancel'): Promise<boolean> => new Promise((resolve) => {

    const buttons: Obj<any> = {}

    if (confirmTxt) {
      buttons.confirm = {
        text: confirmTxt,
        keys: ['enter'],
        btnClass: 'cx-btn-accent font-size-11',
        action: () => resolve(true)
      }
    }

    // if (discardTxt) {
    //   buttons.discard = {
    //     text: discardTxt,
    //     btnClass: 'cx-btn-danger font-size-11',
    //     action: () => resolve(discardTxt)
    //   }
    // }

    if (cancelTxt) {
      buttons.cancel = {
        text: cancelTxt,
        keys: ['esc'],
        btnClass: 'cx-btn-simple font-size-11',
        action: () => resolve(false)
      }
    }

    // noinspection TypeScriptValidateJSTypes
    $.confirm({
      animateFromElement: false,
      animation: 'zoom',
      closeAnimation: 'scale',
      title,
      content,
      buttons
    })
  })

  static loadJson = async (path: string) => {
    const response = await fetch(path)
    return await response.json()
  }

  static mapToJson = (map: Map<any, any>): string => JSON.stringify([...map])

  static jsonToMap = <K, V> (jsonStr: string): Map<K, V> => new Map(JSON.parse(jsonStr))

  static wait (ms: number) { return new Promise(resolve => setTimeout(resolve, ms)) }

  static truncateURL = (url: string, maxLength?: number) => {
    maxLength = maxLength || 75
    const suffix = '...'
    if (url.length + suffix.length > maxLength) {
      return `${url.slice(0, maxLength)}${suffix}`
    }
    return url
  }

  static getHashCode (value: string) {
    let hash = 0
    for (let i = 0; i < value.length; i++) {
      const char = value.charCodeAt(i)
      hash = ((hash << 5) - hash) + char
      hash = hash & hash // Convert to 32bit integer
    }
    return hash

  }

  static MD5 (value: string): string {
    return crypto.MD5(value).toString()
  }

  static numberToFixed = (num: any, digits: number = 2): number => {
    if (digits < 0) return num
    if (typeof num != 'number') return num
    let pow = Math.pow(10, digits)
    return Math.round(num * pow) / pow
  }

  static numberToSuffix = (num: number, fractionDigits: number = 2, fractionAuto?: boolean): string => {
    if (!num) {
      return '0'
    }

    if (num < 1e4) {
      return MethodsService.integerWithCommas(MethodsService.isFloat(num) ? num.toFixed(fractionDigits) : num)
    }

    if (fractionAuto) {
      switch (true) {
        case num < 1e5:
          fractionDigits = 0
          break
        case num < 1e7:
          fractionDigits = 1
          break
        case num < 1e9 && fractionDigits > 2:
          fractionDigits = 2
          break
        default:
          fractionDigits = fractionDigits
      }
    }

    return Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: fractionDigits
    }).format(num)
  }

  static joinWithAnd (text: string[], last?: string, suffix?: string, sort = false): string {
    const copy = [...text]
    sort && copy.sort()
    if (last) {
      const i = copy.indexOf(last)
      if (i !== -1) {
        copy.splice(i, 1)
        copy.push(`${last}${suffix || ''}`)
      }
    }
    return copy.join(', ').replace(/, ([^,]*)$/, ' and $1')
  }

  static mongoTierToRAM = {
    'M10': 2,
    'M20': 4,
    'M30': 8,
    'M40': 16,
    'M50': 32,
    'M60': 64,
    'M80': 128,
    'M200': 256,
    'M300': 384
  }

  static arrayGroupBy = (arr: any[], key: string): any[] => {
    return arr.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, {})
  }

  static average (arr: number[]): number {
    return arr.reduce((p, c) => p + c, 0) / arr.length
  }

  static getCurrentZoomLevel (): number {
    return Math.round(((window.outerWidth - 10) / window.innerWidth) * 100)
  }

  static formatBytes (bytes: number, decimals = 2): string {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  static recursiveDeleteKeysWithPrefix<T extends StandardObject> (obj: T, prefix: string): void {
    for (const key in obj) {
      if (key.startsWith(prefix)) {
        delete obj[key]
      } else if (typeof obj[key] === 'object') {
        MethodsService.recursiveDeleteKeysWithPrefix(obj[key], prefix)
      }
    }
  }
}

export class XlsService {

  private static toExportFileName (excelFileName: string, extension: string = 'csv'): string {
    return `${excelFileName}_${new Date().getTime()}.${extension}`
  }

  static export (json: any[], excelFileName: string, extension: string = 'csv'): void {
    const worksheet: WorkSheet = utils.json_to_sheet(json)
    const workbook: WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] }
    writeFile(workbook, XlsService.toExportFileName(excelFileName, extension))
  }

  static exportTable (table: ElementRef, excelFileName: string, extension: string = 'xlsx', sheetName = 'Data'): void {
    const worksheet: WorkSheet = utils.table_to_sheet(table.nativeElement)
    const workbook: WorkBook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] }
    writeFile(workbook, XlsService.toExportFileName(excelFileName, extension))
  }

  static multipleSheetsExport (excelFileName: string, jsonArray: { [key: string]: any }, table?: {
    [key: string]: ElementRef
  }): void {
    const workbook: WorkBook = { Sheets: {}, SheetNames: [] }
    if (table) {
      Object.entries(table).forEach(([key, value]) => {
        const worksheet: WorkSheet = utils.table_to_sheet(value.nativeElement)
        workbook.Sheets[key] = worksheet
        workbook.SheetNames.push(key)
      })
    }
    Object.entries(jsonArray).forEach(([key, value]) => {
      const worksheet: WorkSheet = utils.json_to_sheet(value)
      workbook.Sheets[key] = worksheet
      workbook.SheetNames.push(key)
    })
    writeFile(workbook, XlsService.toExportFileName(excelFileName, 'xlsx'))
  }

}


