import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core'
import { PIInstancesDashboardComponent } from '../pi-instances-dashboard/pi-instances-dashboard.component'
import { MethodsService, XlsService } from '../../services/methods/methods.service'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { PIInstancesDashboardService } from '../../services/api/services/pi-instances-dashboard.service'
import { PiInstancesDashboardProcessorService } from '../../services/api/processors/pi-instances-dashboard-processor.service'
import { LoggerService } from '../../services/logger/logger.service'
import { GIT_REPO } from '../../services/data/data.service'

@Component({
  selector: 'app-pi-instances-dashboard-sub',
  templateUrl: './pi-instances-dashboard-sub.component.html',
  styleUrls: ['./pi-instances-dashboard-sub.component.scss']
})

export class PIInstancesDashboardSubComponent extends PIInstancesDashboardComponent implements OnInit {

  readonly title = 'Tenants'
  readonly paginationName = 'Tenants'
  readonly tableHeaders: TableHeader[]
  @Input() filterValue: string = ''
  tenants: InstanceDashboardTenant[] = []
  @Input() cluster: InstancesDashboardSubItem
  @Input() clearParentSelections: Function
  @Input() akamaiCustomerContract = {}
  @Input() currentSortBy: string
  @Input() currentTime: { text: string, absTime: Function, time: Function }
  @Input() isAdmin: boolean = false
  @Input() configData: tenantConfigurationData = {}
  @Input() spaAndAhTenantData: SpaAndAhStatus = {}
  @ViewChild('subTable') subTable: ElementRef
  subFiltered: InstanceDashboardTenant[] = []
  numOfAllDocs: number
  activeAccountsThreshold = 5000
  activeAccounts: number
  _isSubRefreshing: boolean = false
  integerWithCommas = MethodsService.integerWithCommas
  GIT_REPO = GIT_REPO

  //FILTERS
  availableAccountTypes: { id: string, text: string }[] = [
    //@formatter:off
    { id: 'allAccount', text: 'Has account' },
    { id: 'paid',       text: 'Paid'        },
    { id: 'trial',      text: 'Trial'       },
    { id: 'noAccount',  text: 'No account'  },
    //@formatter:on
  ]
  currentAccountType: { id: string, text: string }[] = []

  availableConfigTypes: { id: string, text: string }[] = [
    //@formatter:off
    { id: 'allConfig',  text: 'Has config'  },
    { id: 'matching',   text: 'Matching'    },
    { id: 'hasDiff',    text: 'Config diff' },
    { id: 'noConfig',   text: 'No config'   },
    //@formatter:on
  ]
  currentConfigType: { id: string, text: string }[] = []

  availableSpaTypes: { id: string, text: string }[] = [
    //@formatter:off
    { id: 'isSpa',      text: 'Is SPA'      },
    { id: 'notSpa',     text: 'No Spa'      },
    //@formatter:on
  ]
  currentSpaType: { id: string, text: string }[] = []

  availableAhTypes: { id: string, text: string }[] = [
    //@formatter:off
    { id: 'isAhp',      text: 'AHP'      },
    { id: 'isAhv',      text: 'AHV'      },
    { id: 'hasAh',      text: 'Has AH'      },
    { id: 'noAh',       text: 'No AH'       },
    //@formatter:on
  ]
  currentAhType: { id: string, text: string }[] = []

  private _debounce: void | number
  private debounce_timeout: number = 200
  private dataForDownload: InstanceDashboardTenant[]

  constructor (private _clusterService?: PIInstancesDashboardService) {
    super()
    this.currentItemsPerPage = this.itemsPerPageFilter[2]
    this.tableHeaders = [
      //@formatter:off
      { /*width: '12',*/  name: 'Tenant ID',            sortable: true,     field: 'id'                         },
      { /*width: '18',*/  name: 'Akamai Account',       sortable: true,     field: 'akamaiAccountName'          },
      { /*width: '12',*/  name: 'Tenant Beacons',       sortable: true,     field: 'beacons.sort'               },
      { /*width: '12',*/  name: 'Account Beacons',      sortable: false,                                        },
      { /*width: '12',*/  name: 'SPA',               sortable: false,                                        },
      { /*width: '12',*/  name: 'AHP',                  sortable: false,                                        },
      { /*width: '12',*/  name: 'AH Ratio',             sortable: false,                                        },
      { /*width: '12',*/  name: 'Agent Prod.',          sortable: true,     field: 'agentVersions.wet.version'  },
      { /*width: '12',*/  name: 'Agent Stag.',          sortable: true,     field: 'agentVersions.dry.version'  },
      { /*width: '12',*/  name: 'Has Config.',          sortable: false,                                        },
      { /*width: '12',*/  name: 'Config. Match',        sortable: false,                                        },
      { /*width: '12',*/  name: 'Account Type',         sortable: false,                                        },
      //@formatter:on
    ].filter(x => !!x)
    this.currentSortBy = 'beacons.sort'
  }

  ngOnInit() {
    this.tenants = this.cluster.associatedTenants
    this.sortTable()
  }

  updateTimeFilter (time: { text: string; absTime: Function; time: Function }) {
    this.currentTime = time
    this.refreshTable!()
    this.refreshGraphs()
  }

  async refreshTable (): Promise<void> {
    if (this._isSubRefreshing) {
      return
    }
    this._isSubRefreshing = true
    SpinnerService.spin('mini')
    try {
      const beacons = await this.updateCluster()
      const { tenants, allBeacons } = PiInstancesDashboardProcessorService.updateTenantBeacons(this.tenants, beacons)
      this.tenants = tenants
      this.cluster.accountBeacons = allBeacons
      this.sortTable()
    } catch (e) {
      console.error(e)
    } finally {
      SpinnerService.stop('mini')
      this.ref_hljs(this._zone)
      this._isSubRefreshing = false
    }
  }

  sortTable () {
    this.subFiltered = [...this.tenants]

    if (this.currentAccountType[0]?.id) {
      if (this.currentAccountType[0].id == 'allAccount') {
        this.subFiltered = this.subFiltered.filter(t => !!(this.akamaiCustomerContract[t.akamaiId])?.toString())
      } else if (this.currentAccountType[0].id == 'noAccount') {
        this.subFiltered = this.subFiltered.filter(t => !(this.akamaiCustomerContract[t.akamaiId]?.toString()))
      }
      else {
        this.subFiltered = this.subFiltered.filter(t => this.currentAccountType[0].id == 'paid' ? this.akamaiCustomerContract[t.akamaiId] == '0' : this.akamaiCustomerContract[t.akamaiId])
      }
    }

    if (this.currentConfigType[0]?.id) {
      if (this.currentConfigType[0].id == 'allConfig') {
        this.subFiltered = this.subFiltered.filter(t => (this.configData[t.id])?.hasConfig)
      } else if (this.currentConfigType[0].id == 'noConfig') {
        this.subFiltered = this.subFiltered.filter(t => !(this.configData[t.id]?.hasConfig))
      }
      else {
        this.subFiltered = this.subFiltered.filter(t => this.currentConfigType[0].id == 'hasDiff' ? this.configData[t.id]?.hasConfigDiff : this.configData[t.id]?.hasConfigDiff == false)
      }
    }

    if (this.currentAhType[0]?.id) {
      if (this.currentAhType[0].id == 'isAhp') {
        this.subFiltered = this.subFiltered.filter(t => this.spaAndAhTenantData[t.id]?.fullAh)
      } else if (this.currentAhType[0].id == 'isAhv') {
        this.subFiltered = this.subFiltered.filter(t => (!this.spaAndAhTenantData[t.id]?.fullAh && (this.spaAndAhTenantData[t.id]?.ahRatio > 0)))
      } else if (this.currentAhType[0].id == 'hasAh') {
        this.subFiltered = this.subFiltered.filter(t => this.spaAndAhTenantData[t.id]?.ahRatio > 0 || this.spaAndAhTenantData[t.id]?.fullAh)
      } else if (this.currentAhType[0].id == 'noAh') {
        this.subFiltered = this.subFiltered.filter(t => this.spaAndAhTenantData[t.id]?.ahRatio == 0 || !this.spaAndAhTenantData[t.id]?.ahRatio)
      }
    }

    if (this.currentSpaType[0]?.id) {
      if (this.currentSpaType[0].id == 'isSpa') {
        this.subFiltered = this.subFiltered.filter(t => this.spaAndAhTenantData[t.id]?.hasSpa)
      } else if (this.currentSpaType[0].id == 'notSpa') {
        this.subFiltered = this.subFiltered.filter(t => !(this.spaAndAhTenantData[t.id]?.hasSpa))
      }
    }

    this.activeAccounts = this.tenants.filter(t => t.beacons?.sort > this.activeAccountsThreshold).length

    const searchFields = ['id', 'name', 'akamaiId', 'akamaiAccountName', 'website', 'agentVersions.wet.version', 'agentVersions.dry.version']
    this.subFiltered = this.sortTableData(this.subFiltered, this.currentSortBy, this.isSortReversed, this.filterValue ,searchFields)
    this.numOfAvailableDocs = this.subFiltered.length

    this.dataForDownload = this.subFiltered
    this.subFiltered = this.subFiltered.slice((this.currentPage - 1) * this.currentItemsPerPage, this.currentItemsPerPage * this.currentPage)

    this.ref_hljs(this._zone)
  }

  refreshGraphs () {
    this.subFiltered.forEach(tenant => {
      if (!tenant.beacons) {
        return
      }
      const temp = tenant.beacons
      tenant.beacons = undefined

      setTimeout(() => {
        this._zone.run(() => {
          tenant.beacons = temp
        })
      }, 500)
    })
  }

  isBoolean (value: any): boolean {
    return typeof value === 'boolean'
  }

  hasNoData (value: any): boolean {
    value = value || {}
    return Object?.keys(value).length === 0
  }

  onFilterChange () {
    if (this._debounce) {
      clearTimeout(this._debounce)
    }

    this._debounce = setTimeout(() => this.sortTable(), this.debounce_timeout) as any as number
  }

  private async updateCluster(): Promise<AggregatedHitsPerTenantQueryResult[]> {
    const options =  {
      fromDate: this.currentTime.time(),
      granularity: this.granularity,
      period: this.period,
    }
    try {
      return await this._clusterService.getBeacons(this.cluster.clusterEndpoint, options)
    } catch (e) {
      LoggerService.error(e)
      return
    }
  }
  async exportDataToExcel(): Promise<void> {
    let jsonData = {}
    let tableData = {}
    jsonData['fullData'] = this.processDownloadData(this.dataForDownload)
    tableData['tableData'] = this.subTable
    XlsService.multipleSheetsExport(`Instance ${this.cluster.clusterId} data`, jsonData, tableData)
  }

  processDownloadData(data: InstanceDashboardTenant[]): any {
    return data.map((item: any) => {
      let newItem = {...item}
      newItem.beacons = item?.beacons?.total
      delete newItem.agentVersions
      return newItem
    })
  }
}
