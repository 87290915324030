import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { GlobalState } from '../../../app.state'
import { RwaspVersionsProcessorService } from '../processors/rwasp-versions-processor.service'

@Injectable()
export class RwaspVersionsService extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async getAllVersions (options: FilterArguments): Promise<CountedResultObject<RwaspBundlesSchema[]>> {
    const queryString = MethodsService.objectToQueryString(options)
    const response = await this.get(`api/admin_api/versions?${queryString}`) as CountedResultObject<RwaspBundlesSchema[]>
    return {
      count: response.count,
      success: response.success,
      data: RwaspVersionsProcessorService.process(response.data) as RwaspBundlesSchema[]
    }
  }

  async getVersion (hash: number): Promise<RwaspBundlesSchema> {
    const response = await this.get(`api/admin_api/version/${hash}`) as ResultObject<RwaspBundlesSchema>
    return RwaspVersionsProcessorService.process(response.data) as RwaspBundlesSchema
  }

  async setVerified (hash: number, is_verified: boolean, disapproved_comment: null | string = null): Promise<boolean> {
    const body = JSON.stringify({ hash, is_verified, disapproved_comment })
    const response = await this.post(`api/admin_api/versions/set_verified`, body, true, 'application/json')
    return response.success
  }

  async setApproved (hash: number, is_approved: boolean, disapproved_comment: null | string = null): Promise<boolean> {
    const body = JSON.stringify({ hash, is_approved, disapproved_comment })
    const response = await this.post(`api/admin_api/versions/set_approved`, body, true, 'application/json')
    return response.success
  }

}

