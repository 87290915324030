import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { MethodsService } from '../../services/methods/methods.service'
import { LoggerService } from '../../services/logger/logger.service'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { UsersService } from '../../services/api/services/users.service'
import { PITenantsService } from '../../services/api/services/pi-tenants.service'
import { COUNTRY_OBJECT } from '../../shared/components/cx-world-map/country-codes'
import { UserDataService } from '../../services/user-data/user-data.service'
import { USER_POWER_LEVEL } from '../../shared/user-power'
import { GlobalNotification, GlobalState } from '../../app.state'
import { UsersPermissionsGroupService } from '../../services/api/services/users-permissions-group.service'

@Component({
  selector: '',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})

export class PIUsersComponent extends TableWithFilters implements OnInit, OnDestroy {
  readonly title = 'PI Users'

  readonly paginationName = 'Users'

  //table
  readonly tableHeaders: TableHeader[]

  users: UsersSchema[]
  selectedUser: UsersSchema<false>

  availablePermissions: ExtendedUsersPermissionsGroupSchema[] = []
  currentPermissions: ExtendedUsersPermissionsGroupSchema[] = []

  availableTenants: MappedTenants = []
  associatedTenants: MappedTenants = []
  isCreateUserActive: boolean = false

  PERMISSIONS: Record<UserPermission, MappedPermission> = {
    [UserPermission.READ_ONLY]: { perm: UserPermission.READ_ONLY, name: 'Read Only', icon: 'mdi mdi-book' },
    [UserPermission.MAINTAINER]: { perm: UserPermission.MAINTAINER, name: 'Maintainer', icon: 'mdi mdi-account-edit' },
    [UserPermission.ADMIN]: { perm: UserPermission.ADMIN, name: 'Admin', icon: 'mdi mdi-account-star' },
    [UserPermission.OWNER]: { perm: UserPermission.OWNER, name: 'Owner', icon: 'mdi mdi-crown' },
  }

  PERMISSION_POWER = USER_POWER_LEVEL
  ALL_USERS_POWER_REQUIRED = UserPermissionLevel.MAINTAINER

  permissions: MappedPermission[]

  isEligibleToDelete: boolean = false

  currentSortBy: string

  filterValue: string = ''

  countryObj = COUNTRY_OBJECT

  _isRefreshing: boolean = false

  constructor (private _userData: UserDataService, private _users: UsersService, private _users_groups: UsersPermissionsGroupService, private _customers: PITenantsService, private _zone: NgZone, private _state: GlobalState) {
    super()
    this.tableHeaders = [
      //@formatter:off
      USER_POWER_LEVEL[this._userData.permission] >= USER_POWER_LEVEL[UserPermission.ADMIN] ?
        { /*width: '4', */  name: '',                     sortable: false,    field: ''               } : undefined,
      { /*width: '8', */    name: 'Created At',           sortable: true,     field: 'createdAt'    },
      { /*width: '8', */    name: 'Last Modified',        sortable: true,     field: 'updatedAt'    },
      // { /*width: '8', */    name: 'User ID',              sortable: true,     field: '_id'          },
      { /*width: '12',*/    name: 'Email',                sortable: true,     field: 'email'        },
      { /*width: '12',*/    name: 'Name',                 sortable: true,     field: 'firstName'    },
      { /*width: '12',*/    name: 'Country',              sortable: true,     field: 'country'      },
      { /*width: '12',*/    name: 'Permission',           sortable: true,     field: 'permission'   },
      { /*width: '12',*/    name: 'Permission Groups',    sortable: true,     field: 'permission'   },
      { /*width: '12',*/    name: 'Actions',              sortable: false,    field: ''             },
      //@formatter:on
    ].filter(x => !!x)
    this.currentItemsPerPage = this.itemsPerPageFilter[1]
    this.currentSortBy = this.tableHeaders[2].field
    this.permissions = Object.values(this.PERMISSIONS).filter(({ perm }) => USER_POWER_LEVEL[this._userData.permission] >= USER_POWER_LEVEL[perm])
    this.isEligibleToDelete = USER_POWER_LEVEL[this._userData.permission] >= USER_POWER_LEVEL[UserPermission.ADMIN]
  }

  ngOnDestroy (): void {
    window.removeEventListener('keyup', this.registerModalListeners.bind(this))
  }

  ngOnInit (): void {
    window.addEventListener('keyup', this.registerModalListeners.bind(this))
    this.refreshTable!()
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    if (!this.selectedUser && !this.isCreateUserActive) {
      return
    }
    if (key === 'Escape') {
      this.clearSelections()

    }
  }

  checkPermPower (a: number, b: number, operator: string) {
    switch (operator) {
      case 'ne':
        return a != b
      case 'eq':
        return a == b
      case 'lt':
        return a < b
      case 'lte':
        return a <= b
      case 'gt':
        return a > b
      case 'gte':
        return a >= b
    }
    return false
  }

  filteredTenants (_clusterId?: string) {
    return this.availableTenants
      .filter(tenant => !this.associatedTenants.find(t => t.id == tenant.id))
  }

  async getUser (userId: string) {
    try {
      SpinnerService.spin('mini')
      const user = await this._users.getUser(userId)
      if (user) {
        this.selectedUser = user
        this.currentPermissions = [...user.permissions.map(n => this.availablePermissions.find(p => p.name == n.name)!).filter(Boolean) || []]
        LoggerService.info(user)
      }
    } catch (e) {
      MethodsService.toast('error', 'Error fetching customer ' + userId, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
      this._zone.run(() => {})
    }
  }

  async refreshTable (background: boolean = false): Promise<void> {
    if (this._isRefreshing) {
      return
    }
    this._isRefreshing = true
    try {
      if (!background) {
        SpinnerService.spin('mini')
        this.users = []
        this.clearSelections()
      }

      const [
        { numOfAvailableDocs, users },
        groups
      ] = await Promise.all([
        this.getUsers(),
        this._users_groups.getGroups({
          limit: Number.MAX_SAFE_INTEGER,
          skip: 0,
          sortBy: 'updatedAt',
          sortDirection: -1
        })
      ])

      this.users = users
      this.numOfAvailableDocs = numOfAvailableDocs

      this.availablePermissions = groups.data
    } catch (e) {
      if (!background) {
        MethodsService.toast('error', 'Error fetching customers', e.toString(), 8)
      }
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._zone.run(() => {})
      this._isRefreshing = false
    }
  }

  async updateUser (firstName: string, middleName: string, lastName: string, email: string, permission: UserPermission, phoneNumber: string, country: string) {
    if (!permission || !email || !firstName || !lastName) {
      return MethodsService.dialog(
        'Bad Customer Parameters',
        'Please fill the required fields'
      )
    }

    this.selectedUser.permission = permission
    this.selectedUser.email = email
    this.selectedUser.firstName = firstName
    this.selectedUser.middleName = middleName || ''
    this.selectedUser.lastName = lastName
    this.selectedUser.phoneNumber = phoneNumber
    this.selectedUser.country = country
    this.selectedUser.permissions = this.currentPermissions.map(p => p._id)

    try {
      SpinnerService.spin('mini')
      const updated = await this._users.updateUser(this.selectedUser)
      if (updated) {
        this.selectedUser = undefined
        await this.refreshTable()
      }
    } catch (e) {
      MethodsService.toast('error', 'Error updating customer', e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }

  }

  async createNewUser (firstName: string, middleName: string, lastName: string, email: string, permission: UserPermission, phoneNumber: string, country: string) {
    if (!permission || !email || !firstName || !lastName) {
      return MethodsService.dialog(
        'Bad Customer Parameters',
        'Please fill the required fields'
      )
    }

    const user: Omit<UsersSchema<false>, keyof DefaultCollectionSchema | 'options' | 'auth0Id'> = {
      permission,
      country,
      email,
      firstName,
      middleName,
      lastName,
      phoneNumber,
      permissions: this.currentPermissions.map(p => p._id)
    }
    try {
      SpinnerService.spin('mini')
      const newCustomer = await this._users.createNewUser(user)
      if (newCustomer) {
        await this.refreshTable()
      }
    } catch (e) {
      MethodsService.toast('error', 'Error creating new customer', e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }
  }

  private clearSelections () {
    this.selectedUser = undefined
    this.isCreateUserActive = false
    this.currentPermissions = []
    this.associatedTenants = []
  }

  async deleteUsers () {
    const title = `Delete Confirmation`
    const content = `Delete ${this.selectedTableItems.size} User${this.selectedTableItems.size > 1 ? 's' : ''} ?`
    const deleteConfirmationText = 'Delete'
    if (await MethodsService.confirm(title, content, deleteConfirmationText)) {

      SpinnerService.spin('mini')

      try {
        const success = await this._users.deleteUsers([...this.selectedTableItems])
        if (success) {
          this.selectedTableItems.clear()
          await this.refreshTable()
        }
      } catch (e) {
        LoggerService.error(e)
      }
      SpinnerService.stop('mini')
    }
  }

  private async getUsers (options?: FilterArguments) {
    options = options || {
      filter: this.filterValue,
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sort_by: this.currentSortBy,
      sort_direction: this.isSortReversed ? 'asc' : 'desc'
    }
    return await this._users.getUsers(options)
  }

}
