import { Component, Input, NgZone, OnInit } from '@angular/core'
import { MethodsService } from '../../../../services/methods/methods.service'
import { LoggerService } from '../../../../services/logger/logger.service'
import { SpinnerService } from '../../../../services/spinner/spinner.service'
import { PIInstancesService } from '../../../../services/api/services/pi-instances.service'
import { GlobalNotification, GlobalState } from '../../../../app.state'
import { PiEventConsoleService } from '../../../../services/api/services/pi-event-console.service'

interface EventGroupData {
  metadata?: ExtendedEventGroupSchema
  beacons?: AffectedBeaconsResult
  pages?: { count: number, pages: SingleEventGroupPage[] }
  scripts?: EventGroupScripts
  scriptDestinations?: EventGroupScriptEndpointsResult
  countries?: EventGroupCountriesResult
  browsers?: EventGroupBrowsersResult
  requests: { key: string, name: string, endpoint: string, data: string, ms?: number }[]
}

@Component({
  selector: 'event-group-data',
  templateUrl: './event-group-data.component.html',
  styleUrls: ['./event-group-data.component.scss'],
})
export class EventGroupDataComponent implements OnInit {

  @Input() tenantId: string
  @Input() eventGroupId: string

  link: string

  readonly title = 'CPC Event Groups'

  //filter
  readonly timeFilter = MethodsService.timeObject()
  currentTime = this.timeFilter[5]
  filtersData: string = ''

  //data
  data: EventGroupData = { requests: [] }

  //state
  rarelySeenPages: boolean = false
  _isRefreshing: boolean = false

  //imports
  numberToSuffix = MethodsService.numberToSuffix
  copyToClipboard = MethodsService.copyToClipboard
  location = location

  constructor (private _api: PiEventConsoleService, private _clusters: PIInstancesService, private _zone: NgZone, private _state: GlobalState) {
  }

  ngOnInit (): void {
    this.link = `${location.protocol}//${location.hostname}${location.port ? `:${location.port}` : ''}${location.pathname}?tenantId=${this.tenantId}&eventGroupId=${this.eventGroupId}`
    this.data.requests = [
      {
        key: 'metadata',
        name: 'Metadata',
        endpoint: `/tenants/${this.tenantId}/event_groups/${this.eventGroupId}`,
        data: ''
      },
      {
        key: 'beacons',
        name: 'Beacons',
        endpoint: `/tenants/${this.tenantId}/event_groups/${this.eventGroupId}/beacons`,
        data: ''
      },
      {
        key: 'pages',
        name: 'Pages',
        endpoint: `/tenants/${this.tenantId}/event_groups/${this.eventGroupId}/pages`,
        data: ''
      },
      {
        key: 'scripts',
        name: 'Scripts',
        endpoint: `/tenants/${this.tenantId}/event_groups/${this.eventGroupId}/scripts`,
        data: ''
      },
      {
        key: 'scriptDestinations',
        name: 'Destination URLs',
        endpoint: `/tenants/${this.tenantId}/event_groups/${this.eventGroupId}/destination_urls`,
        data: ''
      },
      {
        key: 'countries',
        name: 'Countries',
        endpoint: `/tenants/${this.tenantId}/event_groups/${this.eventGroupId}/countries`,
        data: ''
      },
      {
        key: 'browsers',
        name: 'Browsers',
        endpoint: `/tenants/${this.tenantId}/event_groups/${this.eventGroupId}/browsers`,
        data: ''
      },
    ]
    this.refreshTable!(false, true)
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  async refreshTable (background: boolean = false, init: boolean = false): Promise<void> {
    if (this._isRefreshing) {
      return
    }
    this._isRefreshing = true

    try {
      if (!background) {
        this.clearState()
        SpinnerService.spin('mini')
      }
      await this.fetchData()
    } catch (e) {
      if (!background) {
        MethodsService.toast('error', 'Error fetching event groups', e.toString(), 8)
      }
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._zone.run(() => {})
      this.ref_hljs()
      this._isRefreshing = false
    }
  }

  getOptions () {
    return {
      fromDate: this.currentTime.time(),
      toDate: Date.now(),
    }
  }

  async fetchData () {
    const options = this.getOptions()
    this.filtersData = JSON.stringify(options, null, 2)
    const startTime = performance.now()
    await Promise.all([
      this._api.getEventGroupMetadata(this.tenantId, this.eventGroupId, {
        ...options,
        ...(this.rarelySeenPages ? { includeRarelySeenPages: true } : {})
      })
        .then(res => this.addRequestData('metadata', res.metadata, performance.now() - startTime)),

      this._api.getEventGroupBeacons(this.tenantId, this.eventGroupId, {
        ...options,
        sortBy: 'timestamp',
        sortDirection: -1
      })
        .then(res => this.addRequestData('beacons', res.beacons, performance.now() - startTime)),

      this._api.getEventGroupPages(this.tenantId, this.eventGroupId, {
        ...options,
        sortBy: 'impactedBeacons',
        sortDirection: -1,
        ...(this.rarelySeenPages ? { includeRarelySeenPages: true } : {})
      })
        .then(res => this.addRequestData('pages', res, performance.now() - startTime)),

      this._api.getEventGroupScripts(this.tenantId, this.eventGroupId, {
        ...options,
        sortBy: 'timesSeen',
        sortDirection: -1
      })
        .then(res => this.addRequestData('scripts', res, performance.now() - startTime)),

      this._api.getScriptDestinations(this.tenantId, this.eventGroupId, {
        ...options,
        sortBy: 'timesSeen',
        sortDirection: -1
      })
        .then(res => this.addRequestData('scriptDestinations', res, performance.now() - startTime)),

      this._api.getEventGroupCountries(this.tenantId, this.eventGroupId, { ...options })
        .then(res => this.addRequestData('countries', res, performance.now() - startTime)),

      this._api.getEventGroupBrowsers(this.tenantId, this.eventGroupId, { ...options })
        .then(res => this.addRequestData('browsers', res, performance.now() - startTime)),
    ])

  }

  ref_hljs () {
    setTimeout(() => {
      this._zone.run(() => {})
      document.querySelectorAll('pre code').forEach((block) => hljs.highlightBlock(block))
    }, 10)

  }

  private clearState () {
    const requests = this.data.requests
    this.data = { requests }
  }

  private addRequestData<T extends keyof Omit<EventGroupData, 'requests'>> (key: T, data: EventGroupData[T], ms?: number) {
    this.data[key] = data
    const reqObject = this.data.requests.find(r => r.key === key)!
    const copy = JSON.parse(JSON.stringify(data))
    MethodsService.recursiveDeleteKeysWithPrefix(copy, '_')
    reqObject.data = JSON.stringify(copy, null, 2)
    ms && (reqObject.ms = ms)
  }

}

