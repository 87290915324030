import { Component, Input } from '@angular/core'

@Component({
  selector: 'pi-text-id',
  templateUrl: './pi-text-id.component.html',
})
export class PITextIdComponent {
  @Input() public id: hasToString
  @Input() public icon?: string

}
