import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { PiTenantsProcessorService } from '../processors/pi-tenants-processor.service'
import { GlobalState } from '../../../app.state'
import { TenantName } from '../../data/data.service'

@Injectable()
export class PITenantsService extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async deleteCustomers (customerIds: string[]): Promise<boolean> {
    const body = JSON.stringify({ customerIds })
    const response = await this.post(`api/admin_api/delete_customers`, body, true, 'application/json')
    return response.success
  }

  async getCustomer (customerId: string): Promise<CustomersSchema & { clusterId?: string }> {
    const response = await this.get(`api/admin_api/customer/${customerId}`) as ResultObject<CustomersSchema>
    return PiTenantsProcessorService.process(response.data) as CustomersSchema
  }

  async updateCustomer (customer: CustomersSchema): Promise<boolean> {
    const body = JSON.stringify(customer)
    const response: ResultObject = await this.post(`api/admin_api/update_customer`, body, true, 'application/json')
    return response.success
  }

  async createNewCustomer (customer: Partial<CustomersSchema & { clusterId?: string }>): Promise<boolean> {
    const body = JSON.stringify(customer)
    const response: ResultObject = await this.post(`api/admin_api/create_customer`, body, true, 'application/json')
    return response.success
  }

  async getCustomers (options: FilterArguments): Promise<IfDefined<CountedResultObject<(CustomersSchema & { clusterId?: string, akamaiAccountName?: string })[]>>> {
    const queryString = MethodsService.objectToQueryString(options)
    const response = await this.get(`api/admin_api/customers?${queryString}`) as CountedResultObject<CustomersSchema[]>
    return response.success ? {
      success: response.success,
      count: response.count,
      data: PiTenantsProcessorService.process(response.data) as CustomersSchema[]
    } : undefined
  }

  async copyTenant (tenantId: string, masterEnv: CxEnvironment.DEV | CxEnvironment.STAGING): Promise<ResultObject> {
    const body = JSON.stringify({ masterEnv })
    return (
      await this.post(`api/admin_api/customer/copy/${tenantId}`, body, true, 'application/json')
    )
  }

  async getMappedCustomers (): Promise<MappedTenants> {
    const map: MappedTenants = []
    const { data } = await this.getCustomers({ limit: Number.MAX_SAFE_INTEGER, skip: 0 })
    data.forEach(({ name, _id, website, clusterId = '', akamaiCustomerId = '', akamaiAccountName = '' }) => {
      map.push({
        id: _id,
        _name: name,
        akamaiCustomerId: akamaiCustomerId,
        akamaiAccountName: akamaiAccountName,
        name: TenantName(_id, name, clusterId),
        website,
        clusterId
      })
    })
    return map.sort(({ name: a }, { name: b }) => a > b ? 1 : a < b ? -1 : 0)
  }

  async getInstancelessCustomers (): Promise<string[]> {
    return (await this.get(`api/admin_api/customers_instance_less`) as ResultObject<string[]>).data
  }

  async bundleTenants (data: BundleTenantsRequest): Promise<BundleTenantsResponse> {
    const body = JSON.stringify(data)
    const response = await this.post<BundleTenantsResponse>(`api/admin_api/customer/bundle_tenants`, body, true, 'application/json')
    return response.data
  }

}

