import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { GlobalState } from '../../../app.state'
import { MethodsService } from '../../methods/methods.service'
import { AnalyticsProcessorService } from '../processors/audience-hijacking-analytics.processor.service'

@Injectable()
export class AudienceHijackingAnalyticsService<T extends AnalyticsIntegrationsSchema = AnalyticsIntegrationsSchema> extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async getAllIntegrations (options?: FilterArguments & {paginationSkip?: number}): Promise<IfDefined<CountedResultObject<T[]>>> {
    const queryString = options ? '?' + MethodsService.objectToQueryString(options) : ''
    const response = await this.get(`api/admin_api/integration_objects${queryString}`) as IfDefined<CountedResultObject<T[]>>
    const offset = (options.paginationSkip - 1) * options.limit
    let result = AnalyticsProcessorService.process(response.data, options.filter)
    const lengthAfterSearchFilter = result.length
    result = result.slice(offset, offset + options.limit)
    return response.success && {
      success: response.success,
      data: result as T[],
      count: Math.min(response.data ? response.data.length : 0, lengthAfterSearchFilter)
    }
  }

  async createNewIntegration (body: NewAnalyticsIntegrationsOptions): Promise<boolean> {
    const response = await this.post(`api/admin_api/integration_object/create`, JSON.stringify(body), true, 'application/json')
    if (response.success) {
      return response.success
    }
    throw Error(response.data)
  }

  async updateIntegration (integrationId: string, body: UpdateAnalyticsIntegrationsOptions): Promise<boolean> {
    const response = await this.post(`api/admin_api/integration_object/update`, JSON.stringify({ integrationId, ...body }), true, 'application/json')
    if (response.success) {
      return response.success
    }
    throw Error(response.data)
  }

  async deleteIntegrations (integrationIds: string[]): Promise<boolean> {
    const response = await this.post(`api/admin_api/integration_object/delete`, JSON.stringify({ integrationIds }), true, 'application/json')
    if (response.success) {
      return response.success
    }
    throw Error(response.data)
  }

  // private searchFilter (data: T[], filter: string): T[] {
  //   const filterValLowercase = filter.toLowerCase()
  //   return data.filter(o =>
  //     o.integrationId.toLowerCase().includes(filterValLowercase) ||
  //     o.provider.toLowerCase().includes(filterValLowercase) ||
  //     o.name.toLowerCase().includes(filterValLowercase)
  //   )
  // }
}

