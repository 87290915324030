import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { MethodsService } from '../../services/methods/methods.service'
import { LoggerService } from '../../services/logger/logger.service'
import { GlobalNotification, GlobalState } from '../../app.state'
import { AudienceHijackingRulesService } from '../../services/api/services/audience-hijacking-rules.service'

@Component({
  selector: 'pi-audience-hijacking-rules',
  templateUrl: './audience-hijacking-rules.component.html',
  styleUrls: ['./audience-hijacking-rules.component.scss']
})

export class PIAudienceHijackingRulesComponent extends TableWithFilters implements OnInit, OnDestroy {
  readonly title = 'Audience Hijacking Rules'

  latestSchemaVersion?: AHSchemaVersion

  rules: AudienceHijackingRulesSchema[] = []
  selectedRule: AudienceHijackingRulesSchema

  //table
  readonly tableHeaders: TableHeader[]

  filterValue: string = ''

  currentSortBy: string

  paginationName = 'Audience Hijacking Rules'

  textToClip = MethodsService.copyToClipboard

  normalizeString = MethodsService.normalizeString

  _isRefreshing: boolean = false

  constructor (private _rules: AudienceHijackingRulesService, private _zone: NgZone, private _state: GlobalState) {
    super()
    this.currentItemsPerPage = this.itemsPerPageFilter[1]
    this.tableHeaders = [
      //@formatter:off
      { name: 'Created',        sortable: true,   field: 'createdAt'      },
      { name: 'Updated',        sortable: true,   field: 'updatedAt'      },
      { name: 'Rule ID',        sortable: true,   field: 'ahRuleId'       },
      { name: 'Name',           sortable: true,   field: 'name'           },
      { name: 'Feature Type',   sortable: true,   field: 'featureType'    },
      { name: 'Category',       sortable: true,   field: 'category'       },
      { name: 'Schema Version', sortable: true,   field: 'schemaVersion'  },
      { name: 'Research Mode',  sortable: true,   field: 'researchMode'   },
      { name: 'Versions',       sortable: false                           },
      { name: '',        sortable: false                           },
      //@formatter:on
    ].filter(x => !!x)
    this.currentSortBy = this.tableHeaders[0].field
  }

  ngOnDestroy (): void {
    window.removeEventListener('keyup', this.registerModalListeners.bind(this))
  }

  ngOnInit (): void {
    window.addEventListener('keyup', this.registerModalListeners.bind(this))
    this.refreshTable!()
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    if (!this.selectedRule) {
      return
    }
    if (key === 'Escape') {
      this.resetSelected()
    }
  }

  async refreshTable (background: boolean = false): Promise<void> {
    if (this._isRefreshing) {
      return
    }
    this._isRefreshing = true
    try {
      if (!background) {
        SpinnerService.spin('mini')
        this.selectedRule = undefined
      }
      const [{ data, count }, latestSchemaVersion] = await Promise.all([
        this.getVersions(),
        this._rules.getLatestSchemaVersion()
      ])

      //validate
      if (data && typeof count === 'number' && typeof latestSchemaVersion == 'number') {
        this.rules = data
        this.numOfAvailableDocs = count
        this.latestSchemaVersion = latestSchemaVersion
        if (!background) {
          LoggerService.info('AH Versions:', this.rules)
        }
      } else {
        throw Error('Bad Response')
      }
    } catch (e) {
      if (!background) {
        MethodsService.toast('error', 'Error fetching versions', e.toString(), 8)
      }
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._zone.run(() => {})
      this._isRefreshing = false
    }
  }

  openRule (rule: AudienceHijackingRulesSchema) {
    this.selectedRule = rule
    this.ref_hljs(this._zone)
  }

  resetSelected () {
    this.selectedRule = undefined
  }


  private async getVersions (options?: FilterArguments): Promise<CountedResultObject<AudienceHijackingRulesSchema[]>> {
    options = options || {
      filter: this.filterValue,
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sort_by: this.currentSortBy,
      sort_direction: this.isSortReversed ? 'asc' : 'desc'
    }
    return await this._rules.getAllVersions(options)
  }

}
