import { MethodsService } from '../../methods/methods.service'

export class PICollectedInputsProcessorService {
  static process<T extends AggregatedCollectedInput> (inputOrInputs: T | T[]): T | T[] {
    return Array.isArray(inputOrInputs) ?
      inputOrInputs.map(PICollectedInputsProcessorService.processModule) as T[] :
      PICollectedInputsProcessorService.processModule(inputOrInputs) as T
  }

  private static processModule (rwaspModule: AggregatedCollectedInput): AggregatedCollectedInput {
    rwaspModule.last_seen = MethodsService.cxDateFormat(Math.max(
      ...rwaspModule.selectors.map(doc => new Date(doc.updatedAt).getTime())
      )
    )

    rwaspModule.first_seen = MethodsService.cxDateFormat(Math.min(
      ...rwaspModule.selectors.map(doc => new Date(doc.createdAt).getTime())
      )
    )

    rwaspModule._accept_action = !!rwaspModule.suggested_actions.find(sa => sa.action == CollectedInputAction.ACCEPT)

    rwaspModule.action = new Set([rwaspModule.selectors.map(d => d.action)]).size > 1 ? undefined : rwaspModule.selectors[0].action

    rwaspModule.selectors = rwaspModule.selectors.map(sel => {
      sel.suggested_action = rwaspModule.suggested_actions.find(sa => sa.hash == sel.hash)?.action
      return sel
    })

    return rwaspModule
  }

}

