import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { AppModule } from './app/app.module'
import { SpinnerService } from './app/services/spinner/spinner.service'
import { LoggerService } from './app/services/logger/logger.service'
import { ENV_FULL_TO_SHORT_RESOLVER } from './app/services/api/processors/pi-instances-processor.service'

const TITLE = 'IBP AMP'
const shortEnv = ENV_FULL_TO_SHORT_RESOLVER[ENV]
const isLocalhost = location.hostname == 'localhost';

(async () => {

  try {
    SpinnerService.spin('full')

    implementFavIcon()
    setDocumentTitle()

    if (!isLocalhost) {
      enableProdMode()
    }

    await platformBrowserDynamic().bootstrapModule(AppModule)
  } catch (e) {
    LoggerService.error(e)
  }

})()

function setDocumentTitle () {
  if (isLocalhost) {
    return document.title = `(LOCAL/${shortEnv}) - ${TITLE}`
  }

  if (ENV !== CxEnvironment.PRODUCTION) {
    return document.title = `(${shortEnv}) - ${TITLE}`
  }

}

function implementFavIcon () {
  const favico = document.getElementById('favico') as Nullable<HTMLLinkElement>
  if (!favico) {
    return
  }

  switch (ENV) {
    case CxEnvironment.LOCAL:
    case CxEnvironment.DEV: {
      return favico.href = 'favicon_d.ico'
    }

    case CxEnvironment.STAGING: {
      return favico.href = 'favicon_s.ico'
    }

    default: {
      return favico.href = 'favicon.ico'
    }

  }
}
