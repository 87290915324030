import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { MethodsService } from '../../services/methods/methods.service'
import { LoggerService } from '../../services/logger/logger.service'
import { GlobalNotification, GlobalState } from '../../app.state'
import { GIT_REPO } from '../../services/data/data.service'
import { UrlNormalizerService } from '../../services/api/services/url-normalizer.service'

const cachedVersions: Map<string, Array<any>> = new Map()

@Component({
  selector: 'pi-audience-hijacking-versions',
  templateUrl: './url-normalizer-versions.component.html',
  styleUrls: ['./url-normalizer-versions.component.scss']
})

export class PIURLNormalizerVersionsComponent<T extends ExtendedURLNormalizerVersionsSchema = ExtendedURLNormalizerVersionsSchema> extends TableWithFilters implements OnInit, OnDestroy {
  readonly GIT_REPO = GIT_REPO
  readonly ENV = ENV

  readonly title = 'URL Normalizer Versions'

  versions: T[] = []
  metadata?: URLNormalizerVersionsMetadata

  selectedVersion: T
  selectedData: Array<any> = []
  selectedJSONData: string

  //table
  readonly tableHeaders: TableHeader[]

  filterValue: string = ''

  currentSortBy: string

  paginationName = 'URL Normalizer Versions'

  textToClip = MethodsService.copyToClipboard
  numberToSuffix = MethodsService.numberToSuffix
  normalizeString = MethodsService.normalizeString

  _isRefreshing: boolean = false

  constructor (private _un_versions: UrlNormalizerService, private _zone: NgZone, private _state: GlobalState) {
    super()
    this.currentItemsPerPage = this.itemsPerPageFilter[1]
    this.tableHeaders = [
      //@formatter:off
      { name: 'Created',                sortable: true,   field: 'createdAt'          },
      { name: 'Updated',                sortable: true,   field: 'updatedAt'          },
      { name: 'Version',                sortable: true,   field: 'paddedVersion'      },
      { name: 'JIRA',                   sortable: false,                              },
      { name: 'Default',                sortable: true,   field: 'isDefault'          },
      { name: 'Rollout',                sortable: true,   field: 'isRollout'          },
      { name: 'Status',                 sortable: true,   field: 'isApproved'         },
      { name: 'Available',              sortable: true,   field: 'available'          },
      { name: 'Actions',                sortable: false                               },
      //@formatter:on
    ].filter(x => !!x)
    this.currentSortBy = 'paddedVersion'
  }

  ngOnDestroy (): void {
    window.removeEventListener('keyup', this.registerModalListeners.bind(this))
  }

  ngOnInit (): void {
    window.addEventListener('keyup', this.registerModalListeners.bind(this))
    this.refreshTable!()
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    if (!this.selectedVersion) {
      return
    }
    if (key === 'Escape') {
      this.selectedVersion = undefined
    }
  }

  async viewRules (version: T) {
    this.selectedVersion = version

    const isCached = cachedVersions.has(version.version)
    this.selectedData = isCached ?
      cachedVersions.get(version.version) :
      await this._un_versions.getVersionData(version.version)

    if (!isCached) {
      cachedVersions.set(version.version, this.selectedData)
    }

    this.selectedJSONData = JSON.stringify(this.selectedData, null, 2)
    this.ref_hljs(this._zone)
  }

  resetSelected () {
    this.selectedData = undefined
    this.selectedVersion = undefined
    this.selectedJSONData = undefined
  }

  async refreshTable (background: boolean = false): Promise<void> {
    if (this._isRefreshing) {
      return
    }
    this._isRefreshing = true
    try {
      if (!background) {
        SpinnerService.spin('mini')
        this.selectedVersion = undefined
      }
      const [{ data, count }, metadata] = await Promise.all([
        this.getVersions(),
        this._un_versions.getMetadata(),
      ])

      this.versions = data || []
      this.numOfAvailableDocs = count ?? 0
      this.metadata = metadata

    } catch (e) {
      if (!background) {
        MethodsService.toast('error', 'Error fetching versions', e.toString(), 8)
      }
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._zone.run(() => {})
      this._isRefreshing = false
    }
  }

  async setAvailable (version: T) {
    const message = await MethodsService.confirm(
      `Version Activation - v${version.version}`,
      `You are about to activate URL Normalizer Version v${version.version}.<br>Proceed?`,
      `ACTIVATE`,
    )

    if (!message) {
      return
    }

    try {
      SpinnerService.spin()
      const success = await this._un_versions.setAvailable(version.version)
      if (!success) {
        throw Error('')
      }
      await this.refreshTable()
    } catch (e) {
      MethodsService.toast('error', `Error activating version ${version.version}.`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop()
      this._zone.run(() => {})
    }
  }

  async setUnavailable (version: T) {
    if (version.isDefault || version.isRollout) {
      return MethodsService.dialog(
        `Action not allowed - v${version.version}`,
        `Cannot set Default/Rollout version Unavailable<br>Please set another version as Default/Rollout first.`
      )
    }

    const prompt = await MethodsService.prompt(
      `Version Deactivation`,
      `You are about to deactivate URL Normalizer Version v${version.version}.<br>Proceed?`,
      `eg: 'PAGE-1234 - performance issue'..`,
      { title: 'Input Error', message: 'Must enter "page-xxxx"' },
      'DEACTIVATE',
      (str: string) => /(page-(\d+)\W+)?(.*)/i.test(str)
    )

    if (!prompt) {
      return
    }

    const [_, __, page, comment = ''] = /(page-(\d+)\W+)?(.*)/i.exec(prompt.input) || []

    try {
      SpinnerService.spin()
      const success = await this._un_versions.setUnavailable(version.version, comment, page ? [page] : [])
      if (!success) {
        throw Error('')
      }
      await this.refreshTable()
    } catch (e) {
      MethodsService.toast('error', `Error setting version v${version.version} unavailable.`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop()
      this._zone.run(() => {})
    }
  }

  async setDefault (version: T) {
    let defaultConfirmMsg = `You are about to set v${version.version} as the Default/Latest Version.<br>Proceed?`
    if (ENV == CxEnvironment.DEV || ENV == CxEnvironment.STAGING) {
      defaultConfirmMsg += `<br><br><b>NOTE:</b> This might override the previous version if it wasn't approved on Master`
    }
    if (!await MethodsService.confirm(
      `Default Version`,
      defaultConfirmMsg,
      `PROCEED`
    )) {
      return
    }

    try {
      SpinnerService.spin()
      const success = await this._un_versions.setDefault(version.version)
      if (!success) {
        throw Error('')
      }
      await this.refreshTable()
    } catch (e) {
      MethodsService.toast('error', `Error setting version v${version.version} as Default`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop()
      this._zone.run(() => {})
    }
  }

  async setRollout (version: T) {
    let rolloutConfirmMsg = `You are about to set v${version.version} as the Rollout Version.<br>Proceed?`
    if (ENV !== CxEnvironment.PRODUCTION) {
      rolloutConfirmMsg += `<br><br><b>NOTE:</b> This might override the previous version if it wasn't approved on Master`
    }
    if (!await MethodsService.confirm(
      `Default Rollout`,
      rolloutConfirmMsg,
      `PROCEED`
    )) {
      return
    }

    try {
      SpinnerService.spin()
      const success = await this._un_versions.setRollout(version.version)
      if (!success) {
        throw Error('')
      }
      await this.refreshTable()
    } catch (e) {
      MethodsService.toast('error', `Error setting version v${version.version} as Rollout`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop()
      this._zone.run(() => {})
    }
  }

  async removePage ({ item: version, page }: TableJiraEmitValue<T>) {
    try {
      SpinnerService.spin()
      const un_version = await this._un_versions.removePages(version.version, [page])
      if (!un_version) {
        throw Error('')
      }
      this.versions[this.versions.indexOf(this.versions.find(v => v.version == un_version.version))] = un_version as T
    } catch (e) {
      MethodsService.toast('error', `Error adding PAGE-${page} to version ${version.version}`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop()
      this._zone.run(() => {})
    }
  }

  async addPage ({ item: version, page }: TableJiraEmitValue<T>) {
    try {
      SpinnerService.spin()
      const un_version = await this._un_versions.addPages(version.version, page.split(','))
      if (!un_version) {
        throw Error('')
      }
      this.versions[this.versions.indexOf(this.versions.find(v => v.version == un_version.version))] = un_version as T
    } catch (e) {
      MethodsService.toast('error', `Error adding PAGES ${page.toString()} to version ${version.version}`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop()
      this._zone.run(() => {})
    }
  }

  private async getVersions (options?: FilterArguments): Promise<CountedResultObject<T[]>> {
    options = options || {
      filter: this.filterValue,
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sort_by: this.currentSortBy,
      sort_direction: this.isSortReversed ? 'asc' : 'desc'
    }
    return await this._un_versions.getAllVersions(options) as CountedResultObject<T[]>
  }
}
