import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { LoggerService } from '../../services/logger/logger.service'
import { MethodsService } from '../../services/methods/methods.service'
import { UserDataService } from '../../services/user-data/user-data.service'
import { GlobalNotification, GlobalState } from '../../app.state'
import { PITenantsService } from '../../services/api/services/pi-tenants.service'
import { PITrafficMirroringService } from '../../services/api/services/traffic-mirroring.service'

@Component({
  selector: '',
  templateUrl: './traffic-mirroring.component.html',
  styleUrls: ['./traffic-mirroring.component.scss']
})
export class PITrafficMirroringComponent<T extends EnrichedTrafficMirroringSchema = EnrichedTrafficMirroringSchema> extends TableWithFilters implements OnInit, OnDestroy {

  readonly title = 'PI Edge Mirroring'
  readonly paginationName = 'Edge Mirrors'

  readonly ALLOWED_SOURCE_ENV: TrafficMirroringBaseEnvironment[] = ENV == CxEnvironment.PRODUCTION ? [CxEnvironment.PRODUCTION] : [CxEnvironment.STAGING]
  readonly ALLOWED_TARGET_ENV: TrafficMirroringTargetEnvironment[] = ENV == CxEnvironment.PRODUCTION ? [CxEnvironment.DEV, CxEnvironment.STAGING] : [CxEnvironment.DEV]

  readonly DISABLED_BANNER = ENV == CxEnvironment.DEV || ENV == CxEnvironment.LOCAL

  Number = Number
  parseInt = parseInt

  filterValue: string = ''

  mirrors: T[] = []

  availableTenants: MappedTenants
  selectedTenant: MappedTenants

  currentSourceTenants: MappedTenants

  //metadata
  metadata: TrafficMirroringMetadataResponse = edgeWorkerMetadataItem()
  currentTenantsDuplications: TrafficMirroringAvailableTenants[] = []
  selectedTenantsDuplications: TrafficMirroringAvailableTenants[] = []

  UsersSchema
  currentAllowedTargetEnvs: TrafficMirroringTargetEnvironment[] = []

  //modals
  isCreateMirrorActive: boolean = false
  selectedMirror: T

  isEligibleToDelete: boolean = false
  isEligibleToSyncEdgeKv: boolean = false
  isEligibleToBundleWithNewToken: boolean = false
  isEligibleToDeleteUnusedTokens: boolean = false
  isEligibleToCreate: boolean = false
  isEligibleToUpdate: boolean = false
  //table
  readonly tableHeaders: TableHeader[]
  currentSortBy: string
  _isRefreshing: boolean = false

  textToClip = MethodsService.copyToClipboard

  normalizeString = MethodsService.normalizeString

  upperCasedString = MethodsService.upperCasedString

  private _debounce: void | number
  private debounce_timeout: number = 1000

  constructor (private _userData: UserDataService, private _customers: PITenantsService, private _mirror: PITrafficMirroringService, private _zone: NgZone, private _state: GlobalState) {
    super()
    this.isEligibleToDelete = this._userData.hasPermissions('/traffic_mirroring/delete', UserPermission.ADMIN)
    this.isEligibleToSyncEdgeKv = this._userData.hasPermissions('/traffic_mirroring/sync_edgekv', UserPermission.ADMIN)
    this.isEligibleToCreate = this._userData.hasPermissions('/traffic_mirroring/create', UserPermission.ADMIN)
    this.isEligibleToUpdate = this._userData.hasPermissions('/traffic_mirroring/update', UserPermission.ADMIN)
    this.isEligibleToBundleWithNewToken = this._userData.hasPermissions('/traffic_mirroring/bundle_with_new_token', UserPermission.OWNER)
    this.isEligibleToDeleteUnusedTokens = this._userData.hasPermissions('/traffic_mirroring/delete_unused_tokens', UserPermission.OWNER)
    this.currentItemsPerPage = this.itemsPerPageFilter[1]
    this.tableHeaders = [
      //@formatter:off
      { /*width: '12',*/  name: 'Created',              sortable: true,     field: 'createdAt'              },
      { /*width: '12',*/  name: 'Updated',              sortable: true,     field: 'updatedAt'              },
      // { /*width: '12',*/  name: 'Mirroring ID',         sortable: true,     field: '_id'                    },
      { /*width: '12',*/  name: 'Tenant ID',            sortable: true,     field: 'customerId'             },
      { /*width: '12',*/  name: 'Tenant Name',          sortable: true,     field: 'tenant.name'            },
      // { /*width: '12',*/  name: 'Source Env',           sortable: true,     field: 'environment'            },
      // { /*width: '12',*/  name: 'Source Instance',      sortable: true,     field: 'instance.clusterId'     },
      { /*width: '12',*/  name: 'Source',               sortable: true,     field: 'instance.clusterId'     },
      { /*width: '12',*/  name: 'Target',               sortable: true,     field: 'targetEnvironment'      },
      // { /*width: '12',*/  name: 'Target Instance',      sortable: false,                                    },
      // { /*width: '12',*/  name: 'Target',               sortable: false,                                    },
      // { /*width: '12',*/  name: 'Note',                 sortable: true,     field: 'comment'                },
      { /*width: '12',*/  name: '/collect',             sortable: true,     field: 'mirroringPaths.collect' },
      { /*width: '12',*/  name: '/ah',                  sortable: true,     field: 'mirroringPaths.ah'      },
      { /*width: '12',*/  name: '/av',                  sortable: true,     field: 'mirroringPaths.av'      },
      { /*width: '12',*/  name: '/ie',                  sortable: true,     field: 'mirroringPaths.ie'      },
      { /*width: '12',*/  name: '/sh',                  sortable: true,     field: 'mirroringPaths.sh'      },
      // { /*width: '12',*/  name: 'Duplication Tenants',  sortable: true,     field: 'duplicationIdsSize'     },
      { /*width: '12',*/  name: 'Enabled',              sortable: true,     field: 'active'                 },
      { /*width: '12',*/  name: 'Actions',              sortable: false                                     },
      //@formatter:on
    ].filter(x => !!x)

    this.currentSortBy = this.tableHeaders.find(f => f.field == 'updatedAt')?.field || this.tableHeaders[0].field
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    if (!this.selectedMirror && !this.isCreateMirrorActive) {
      return
    }
    if (key === 'Escape') {
      this.clearSelections()
    }
  }

  ngOnDestroy (): void {
    window.removeEventListener('keyup', this.registerModalListeners.bind(this))
  }

  onFilterChange (item: any) {
    if (this._debounce) {
      this._debounce = clearTimeout(this._debounce)
    }
    this._debounce = setTimeout(() => this.refreshTable(), this.debounce_timeout) as any as number
  }

  ngOnInit (): void {
    window.addEventListener('keyup', this.registerModalListeners.bind(this))
    this.refreshTable!()
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  selectMirror (mirror: T) {
    this.selectedTenant = [
      this.availableTenants.find(t => t.id == mirror.customerId) ||
      { id: mirror.customerId, name: '', _name: '', website: '', clusterId: mirror.instance?.clusterId }
    ]
    this.currentAllowedTargetEnvs = this.ALLOWED_TARGET_ENV.filter(e => e != mirror.environment as string)
    this.currentTenantsDuplications = this.metadata[mirror.targetEnvironment]
    this.selectedTenantsDuplications = [...mirror.duplicationsIds]
    this.selectedMirror = mirror
  }

  openCreateMirrorModal () {
    const defaultSource = this.ALLOWED_SOURCE_ENV[0]
    const defaultTarget = this.ALLOWED_TARGET_ENV[0]
    this.currentAllowedTargetEnvs = [...this.ALLOWED_TARGET_ENV]
    this.currentTenantsDuplications = this.metadata[defaultTarget]
    this.currentSourceTenants = [...this.availableTenants]
    this.isCreateMirrorActive = true

  }

  setAvailableTargetEnv (env: string, targetEnv: string) {
    this.currentAllowedTargetEnvs = this.ALLOWED_TARGET_ENV.filter(e => e != env)
    const _target = this.currentAllowedTargetEnvs.includes(targetEnv as TrafficMirroringTargetEnvironment) ? targetEnv : this.currentAllowedTargetEnvs[0]
    this.currentTenantsDuplications = !this.selectedTenant ? this.metadata[_target] : this.metadata[_target].filter(d => d.customerId !== this.selectedTenant[0].id)
    this.selectedTenantsDuplications = !this.selectedTenant ? this.selectedTenantsDuplications : this.selectedTenantsDuplications?.filter(d => d.customerId !== this.selectedTenant[0]?.id) || []
    this.currentSourceTenants = [...this.availableTenants]
  }

  async createNewMirror (options: TrafficMirroringNewProperties) {
    const {
      active,
      mirrorSourceCustomer,
      comment,
      environment,
      targetEnvironment,
      collect,
      ie,
      av,
      ah,
      sh
    } = options

    if (!this.selectedTenant?.length) {
      return MethodsService.toast('error', `Input Error`, `Missing Customer ID`, 8)
    }

    const customerId = this.selectedTenant[0].id
    const customerDuplicationIds = this.selectedTenantsDuplications?.map(({ customerId }) => customerId) || []

    const mirroringPaths: TrafficMirroringPaths = { collect, ie, av, ah, sh }

    if (!this.validateAndNormalizePathsRate(mirroringPaths)) {
      return MethodsService.toast('error', `Input Error`, `Path rate must be a number between 0-100`, 8)
    }

    const newGroupData: TrafficMirroringNewArguments = {
      active,
      comment,
      customerId,
      environment,
      mirroringPaths,
      targetEnvironment,
      mirrorSourceCustomer,
      customerDuplicationIds
    }

    try {
      SpinnerService.spin('mini')
      const newGroup = await this._mirror.createNewMirror(newGroupData)
      if (newGroup) {
        await this.refreshTable()
      }
    } catch (e) {
      MethodsService.toast('error', `Error creating new permission group ${name}`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }

  }

  async deleteMirror () {
    const title = `Delete Confirmation`
    const content = `Delete ${this.selectedTableItems.size} Traffic Mirror${this.selectedTableItems.size > 1 ? 's' : 'ing'} ?`
    const deleteConfirmationText = 'Delete'
    if (await MethodsService.confirm(title, content, deleteConfirmationText)) {

      SpinnerService.spin('mini')

      try {
        const success = await this._mirror.deleteMirrors([...this.selectedTableItems])
        if (success) {
          this.selectedTableItems.clear()
          await this.refreshTable()
        }
      } catch (e) {
        LoggerService.error(e)
      }
      SpinnerService.stop('mini')
    }
  }

  async updateMirror (options: TrafficMirroringUpdateProperties) {
    const {
      active,
      mirrorSourceCustomer,
      comment,
      environment,
      targetEnvironment,
      collect,
      ie,
      av,
      ah,
      sh
    } = options

    const curr = this.selectedMirror

    const updateMirrorData: Partial<TrafficMirroringUpdateArguments> = {}

    const currDups = (curr.customerDuplicationIds || []).sort()
    const selectedDups = this.selectedTenantsDuplications?.map(({ customerId }) => customerId).sort() || []

    if (currDups.toString() != selectedDups.toString()) {
      updateMirrorData.customerDuplicationIds = selectedDups
    }

    if (curr.active != active) {
      updateMirrorData.active = active
    }

    if (curr.mirrorSourceCustomer != mirrorSourceCustomer) {
      updateMirrorData.mirrorSourceCustomer = mirrorSourceCustomer
    }

    if (curr.comment != comment) {
      updateMirrorData.comment = comment
    }

    if (curr.environment != environment) {
      updateMirrorData.environment = environment
    }

    if (curr.targetEnvironment != targetEnvironment) {
      updateMirrorData.targetEnvironment = targetEnvironment
    }

    let mirroringPathChanged = false

    for (const [path, rate] of Object.entries({ collect, ie, av, ah, sh })) {
      const normalizedRate = this.normalizeRate(rate)
      if (curr.mirroringPaths[path] !== normalizedRate) {
        mirroringPathChanged = true
        break
      }
    }

    if (mirroringPathChanged) {
      const mirroringPaths: TrafficMirroringPaths = { collect, ie, av, ah, sh }
      if (!this.validateAndNormalizePathsRate(mirroringPaths)) {
        return MethodsService.toast('error', `Input Error`, `Path rate must be a number between 0-100`, 8)
      }
      updateMirrorData.mirroringPaths = mirroringPaths
    }

    try {
      SpinnerService.spin('mini')
      const newMirror = await this._mirror.updateMirror(this.selectedMirror._id, updateMirrorData)
      if (newMirror) {
        await this.refreshTable(false, true)
      }
    } catch (e) {
      MethodsService.toast('error', `Error updating Mirror ${this.selectedMirror._id}`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }

  }

  clearSelections () {
    this.selectedMirror = undefined
    this.isCreateMirrorActive = false
    this.selectedTenant = undefined
    this.selectedTenantsDuplications = undefined
    this.selectedTableItems.clear()
  }

  async refreshTable (background: boolean = false, ignoreSelected: boolean = false): Promise<void> {
    if (this._isRefreshing || (!ignoreSelected && this.selectedMirror)) {
      return
    }
    this._isRefreshing = true
    try {
      if (!background) {
        this.mirrors = []
        this.clearSelections()
        this.metadata = edgeWorkerMetadataItem()
        SpinnerService.spin('mini')
      }
      const [
        { data, count },
        metadata,
        mappedCustomers,
      ] = await Promise.all([
        this.getMirrors(),
        this._mirror.getMetadata(),
        this._customers.getMappedCustomers()
      ].filter(x => !!x) as PromiseLike<any>[])

      this.numOfAvailableDocs = count
      this.mirrors = data

      console.log(metadata)
      this.metadata = metadata

      this.availableTenants = mappedCustomers

    } catch (e) {
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._zone.run(() => {})
      this._isRefreshing = false
    }

  }

  async setActive (mirror: T, active: boolean) {
    const message = await MethodsService.confirm(
      `Traffic Mirroring`,
      `You are about to <b>${active ? 'Enable' : 'Disable'}</b> Traffic Mirroring for:<br>Tenant: ${mirror.customerId} - ${mirror.tenant.name}<br>Environment: From ${mirror._env} to ${mirror._targetEnv}<br><br>Proceed?`,
      'Confirm'
    )

    if (!message) {
      return
    }

    try {
      SpinnerService.spin('mini')
      const newMirror = await this._mirror.updateMirror(mirror._id, { active })
      if (newMirror) {
        await this.refreshTable(false, true)
      }
    } catch (e) {
      MethodsService.toast('error', `Error updating Mirror ${this.selectedMirror._id}`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }
  }

  async bundleWithNewToken () {
    const message = await MethodsService.confirm(
      `EdgeKV Syncing`,
      `You are about create a new EdgeWorker Code Bundle with refreshed Token. <br>This will create a new EdgeWorker Version. <br><br>Proceed?`,
      'Confirm'
    )

    if (!message) {
      return
    }

    try {
      SpinnerService.spin('mini')
      const success = await this._mirror.bundleWithNewToken()
      if (success) {
        MethodsService.toast('success', 'Bundle Success', `New EdgeWorker Version is now being deployed!`, 8)
        await this.refreshTable(false, true)
      }
    } catch (e) {
      MethodsService.toast('error', `Error syncing EdgeKV`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }
  }

  async deleteUnusedTokens () {
    const message = await MethodsService.confirm(
      `Remove Unused Tokens`,
      `You are about to delete all tokens that are currently not in use.
        <br>This process may take several minutes to finish. (See EdgeKV Tokens Limitations) <br>
        <br>Note that any other EdgeKV versions token will not work. <br><br>Proceed?`,
      'Confirm'
    )

    if (!message) {
      return
    }

    try {
      SpinnerService.spin('mini')
      const deletedTokens = await this._mirror.deleteUnusedTokens()
      if (deletedTokens) {
        MethodsService.toast('success', 'Deletion Success', `All Unused Tokens ${deletedTokens.length} has been removed`, 8)
        console.log('deleted tokens:', deletedTokens)
        await this.refreshTable(false, true)
      }
    } catch (e) {
      MethodsService.toast('error', `Error syncing EdgeKV`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }
  }

  async syncEdgeKV () {
    const message = await MethodsService.confirm(
      `EdgeKV Syncing`,
      `You are about to sync EdgeKV DB with MongoDB <br><br>Proceed?`,
      'Confirm'
    )

    if (!message) {
      return
    }

    try {
      SpinnerService.spin('mini')
      const success = await this._mirror.syncEdgeKV()
      if (success) {
        MethodsService.toast('success', 'Sync Success', `EdgeKV is now synced with MongoDB`, 8)
        await this.refreshTable(false, true)
      }
    } catch (e) {
      MethodsService.toast('error', `Error syncing EdgeKV`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }
  }

  private async getMirrors (options?: FilterArguments) {
    options = options || {
      filter: this.filterValue,
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sort_by: this.currentSortBy,
      sort_direction: this.isSortReversed ? 'asc' : 'desc'
    }

    return await this._mirror.getMirrors(options)
  }

  private validateRate (rate: Expected<number>): boolean {
    if (typeof rate != 'number' || isNaN(rate)) {
      return false
    }

    if (rate < 0 || 1 < rate) {
      return false
    }

    return true
  }

  private normalizeRate (rate: Expected<number>): number {
    return parseFloat((rate / 100).toFixed(2))
  }

  private validateAndNormalizePathsRate (paths: TrafficMirroringPaths): boolean {
    for (const [path, rate] of Object.entries(paths)) {
      const normalizedRate = this.normalizeRate(rate)
      paths[path] = normalizedRate
      if (!this.validateRate(normalizedRate)) {
        return false
      }
    }
    return true
  }

  openBundleURL () {
    window.open(this.metadata.edgeworker.bundle_url, '_blank')
  }

  openEdgeWorkerIDURL () {
    window.open(this.metadata.edgeworker.edgeworker_id_url, '_blank')
  }

  openDocumentationPage () {
    window.open('https://collaborate.akamai.com/confluence/pages/viewpage.action?pageId=956439783', '_blank')
  }

  openGITCodeURL () {
    const version = this.metadata.edgeworker.version.split('.').slice(0, 3).join('.')
    window.open(`https://git.source.akamai.com/projects/PI-CORE/repos/pi-traffic-mirroring/browse?at=refs%2Fheads%2Fv${decodeURIComponent(version)}`, '_blank')
  }
}

function edgeWorkerMetadataItem (): TrafficMirroringMetadataResponse {
  return {
    [CxEnvironment.DEV]: [],
    [CxEnvironment.STAGING]: [],
    edgeworker: {
      id: 0,
      version: '',
      active_tokens: [],
      bundle_url: '',
      edgeworker_id_url: '',
      edgekv_token_name: '',
      version_created_at: null,
      edgekv_token_expiration: null
    },
    _ref: true
  }
}
