import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { GlobalState } from '../../../app.state'
import { PIDeploymentImagesProcessor } from '../processors/deployment-images-processor.service'

@Injectable()
export class PIDeploymentImagesService<T extends ExtendedDeploymentImagesSchema = ExtendedDeploymentImagesSchema> extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async getMetadata (): Promise<PiDeploymentsMetadata> {
    const response = await this.get(`api/admin_api/deployment_image/metadata`) as ResultObject<PiDeploymentsMetadata>
    return response.data as PiDeploymentsMetadata
  }

  async getAllReleaseImageHashes (): Promise<DeploymentReleaseImageHashes> {
    const response = await this.get(`api/admin_api/deployment_image/all`) as ResultObject<DeploymentReleaseImageHashes>
    return response.data as DeploymentReleaseImageHashes
  }

  async getAvailableVersions (): Promise<DockerImagesAvailableVersions> {
    const response = await this.get(`api/admin_api/deployment_image/versions`) as ResultObject<DockerImagesAvailableVersions>
    return response.data as DockerImagesAvailableVersions
  }

  async getAllDockers (options: FilterArguments): Promise<CountedResultObject<T[]>> {
    const queryString = MethodsService.objectToQueryString(options)
    const response = await this.get(`api/admin_api/deployment_images?${queryString}`) as CountedResultObject<T[]>
    return {
      count: response.count,
      success: response.success,
      data: PIDeploymentImagesProcessor.process(response.data || []) as T[]
    }
  }

  async addPages (_id: string, pages: string[], updatedAt: number): Promise<T> {
    const body = JSON.stringify({ _id, pages, updatedAt })
    const response = await this.post(`api/admin_api/deployment_image/add_pages`, body, true, 'application/json')
    return response.data as T
  }

  async removePages (_id: string, pages: string[], updatedAt: number): Promise<T> {
    const body = JSON.stringify({ _id, pages, updatedAt })
    const response = await this.post(`api/admin_api/deployment_image/remove_pages`, body, true, 'application/json')
    return response.data as T
  }

  async setActive (_id: string, updatedAt: number): Promise<boolean> {
    const body = JSON.stringify({ _id, updatedAt })
    const response = await this.post(`api/admin_api/deployment_image/set_active`, body, true, 'application/json')
    return response.success
  }

  async setInactive (_id: string, comment: string, pages: string[], updatedAt: number): Promise<boolean> {
    const body = JSON.stringify({ _id, comment, pages, updatedAt })
    const response = await this.post(`api/admin_api/deployment_image/set_inactive`, body, true, 'application/json')
    return response.success
  }

  async deleteFeatureImages (_ids: string[]): Promise<boolean> {
    const body = JSON.stringify({ _ids })
    const response = await this.post(`api/admin_api/deployment_image/remove_features`, body, true, 'application/json')
    return response.success
  }

}

