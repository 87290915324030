import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { PITenantsService } from '../../services/api/services/pi-tenants.service'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { MethodsService, NUMERIC_SORT_ARRAY } from '../../services/methods/methods.service'
import { LoggerService } from '../../services/logger/logger.service'
import { AkamaiCustomersService } from '../../services/api/services/akamai-customers.service'
import { PIInstancesService } from '../../services/api/services/pi-instances.service'
import { UserDataService } from '../../services/user-data/user-data.service'
import { USER_POWER_LEVEL } from '../../shared/user-power'
import { isInstanceActive } from '../../services/api/processors/pi-instances-processor.service'
import { GlobalNotification, GlobalState } from '../../app.state'
import { AgentVersionsService } from '../../services/api/services/agent-versions.service'

@Component({
  selector: '',
  templateUrl: './pi-tenants.component.html',
  styleUrls: ['./pi-tenants.component.scss']
})
export class PITenantsComponent extends TableWithFilters implements OnInit, OnDestroy {
  readonly title = 'Tenants Management'
  readonly ENV = ENV

  readonly DEFAULT_MAJOR_VERSION: Exclude<EXPLICIT_BUNDLE_MAJOR_VERSION, EXPLICIT_BUNDLE_MAJOR_VERSION.V0 | EXPLICIT_BUNDLE_MAJOR_VERSION.V1> = EXPLICIT_BUNDLE_MAJOR_VERSION.V3

  readonly BUNDLING_VERSIONS: { name: string, id: EXPLICIT_BUNDLE_MAJOR_VERSION, active: boolean }[] = [
    { id: EXPLICIT_BUNDLE_MAJOR_VERSION.V0, name: 'noscript', active: true },
    { id: EXPLICIT_BUNDLE_MAJOR_VERSION.V1, name: 'firewall', active: false },
    { id: EXPLICIT_BUNDLE_MAJOR_VERSION.V2, name: 'Rwasp', active: true },
    { id: EXPLICIT_BUNDLE_MAJOR_VERSION.V3, name: 'Agent', active: true },
  ].filter(({ active }) => active)

  readonly numericTypes = NUMERIC_SORT_ARRAY

  readonly BundleEnvironments: BundleEnvironment[] = [
    BundleEnvironment.DRY, BundleEnvironment.WET, BundleEnvironment.ROLLOUT
  ].sort()

  readonly versionBundleEnvironments: { id: BundleEnvironment, name: string }[] = [
    { id: BundleEnvironment.DRY, name: 'Dry' },
    { id: BundleEnvironment.WET, name: 'Wet' },
    { id: BundleEnvironment.ROLLOUT, name: 'Rollout' }
  ]

  filterValue: string = ''

  versionFilterBundleEnv = [this.versionBundleEnvironments[0]]
  versionFilterIsStagingEnv = false

  paginationName = 'Tenants'

  customers: (CustomersSchema & { clusterId?: string })[] = []
  selectedCustomer: CustomersSchema & { clusterId?: string }

  clusters: MappedClusters = []
  availableInstances: MappedClusters = []
  activeInstances: MappedClusters = []

  akamaiCustomers: MappedAkamaiCustomers = []

  //state
  isCreateCustomerActive = false

  isEligibleToDelete: boolean = false

  isStagingVersions: boolean = false

  isTenantsVersionsModalActive: boolean = false

  allTenants: MappedTenants = []

  selectedTenants: MappedTenants = []

  selectedBundleEnvironment: BundleEnvironment

  selectedPushToStaging: boolean = false

  selectedBundleMajorVersion: Exclude<EXPLICIT_BUNDLE_MAJOR_VERSION, EXPLICIT_BUNDLE_MAJOR_VERSION.V1> = this.DEFAULT_MAJOR_VERSION

  selectedTenantBundleMajorVersion: Exclude<EXPLICIT_BUNDLE_MAJOR_VERSION, EXPLICIT_BUNDLE_MAJOR_VERSION.V1> = this.DEFAULT_MAJOR_VERSION

  versions: Record<Exclude<EXPLICIT_BUNDLE_MAJOR_VERSION, EXPLICIT_BUNDLE_MAJOR_VERSION.V1>, Record<EligibleEnv, LatestVersionEnvVersion[]>> = {
    [EXPLICIT_BUNDLE_MAJOR_VERSION.V0]: {
      [CxEnvironment.DEV]: [],
      [CxEnvironment.STAGING]: [],
      [CxEnvironment.PRODUCTION]: []
    },
    [EXPLICIT_BUNDLE_MAJOR_VERSION.V2]: {
      [CxEnvironment.DEV]: [],
      [CxEnvironment.STAGING]: [],
      [CxEnvironment.PRODUCTION]: []
    },
    [EXPLICIT_BUNDLE_MAJOR_VERSION.V3]: {
      [CxEnvironment.DEV]: [],
      [CxEnvironment.STAGING]: [],
      [CxEnvironment.PRODUCTION]: []
    }
  }
  bundleResponse?: BundleTenantsResponse & { _updated: (MappedTenants & BundleTenantsResponse['updated_tenants']), _failed: { error: string, tenants: MappedTenants }[] }

  selectedVersion: LatestVersionEnvVersion[] = []
  selectedTenantVersion: LatestVersionEnvVersion[] = []

  log (...a) {
    console.log(...a)
  }

  textToClip = MethodsService.copyToClipboard

  //table
  readonly tableHeaders: TableHeader[]

  currentSortBy: string

  _isRefreshing: boolean = false

  constructor (private _userData: UserDataService, private _customers: PITenantsService, private _akamaiCustomers: AkamaiCustomersService, private _versions: AgentVersionsService, private _clusters: PIInstancesService, private _zone: NgZone, private _state: GlobalState) {
    super()
    this.currentItemsPerPage = this.itemsPerPageFilter[1]
    this.tableHeaders = [
      //@formatter:off
      USER_POWER_LEVEL[this._userData.permission] >= USER_POWER_LEVEL[UserPermission.ADMIN] ?
        { /*width: '4', */    name: '',           sortable: false,    field: ''           } : undefined,
      { /*width: '12',*/    name: 'ID',           sortable: true,     field: '_id'                                    },
      { /*width: '8', */    name: 'Created',      sortable: true,     field: 'createdAt'                              },
      { /*width: '8', */    name: 'Modified',     sortable: true,     field: 'updatedAt'                              },
      { /*width: '12',*/    name: 'Name',         sortable: true,     field: 'name'                                   },
      { /*width: '12',*/    name: 'Akamai ACC',   sortable: false                                                     },
      { /*width: '12',*/    name: 'Instance',     sortable: false                                                     },
      { /*width: '12',*/    name: 'Dry Bundle',          sortable: true,     field: 'engineVersions.dry.padded_version'      },
      { /*width: '12',*/    name: 'Rollout Bundle',      sortable: true,     field: 'engineVersions.rollout.padded_version'  },
      { /*width: '12',*/    name: 'Wet Bundle',          sortable: true,     field: 'engineVersions.wet.padded_version'      },
      { /*width: '12',*/    name: 'Actions',     sortable: false,                                                            },
      //@formatter:on
    ].filter(x => !!x)
    this.currentSortBy = this.tableHeaders[2].field
    this.isEligibleToDelete = USER_POWER_LEVEL[this._userData.permission] >= USER_POWER_LEVEL[UserPermission.ADMIN]
  }

  ngOnDestroy (): void {
    window.removeEventListener('keyup', this.registerModalListeners.bind(this))
  }

  ngOnInit (): void {
    window.addEventListener('keyup', this.registerModalListeners.bind(this))
    const url = new URL(location.href)
    const filter = url.searchParams.get('filter')
    if (filter) {
      this.filterValue = filter
    }
    this.refreshTable!()
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    if (!this.selectedCustomer && !this.isCreateCustomerActive && !this.isTenantsVersionsModalActive) {
      return
    }

    switch (key) {
      case 'Escape': {
        if (this.isTenantsVersionsModalActive) {
          this.toggleTenantsVersions(false)
        } else {
          this.selectedCustomer = this.isCreateCustomerActive = undefined
        }
      }
    }

  }

  getCluster (clusterId: string): string {
    const matched = this.clusters.find(a => a.id == clusterId)
    return matched?.name || ''
  }

  getAkmUser (akamaiCustomerId: string) {
    const matched = this.akamaiCustomers.find(a => a.id == akamaiCustomerId)
    return matched?.name || ''
  }

  private clearState () {
    this.selectedCustomer = undefined
    this.isCreateCustomerActive = false
  }

  async refreshTable (background: boolean = false): Promise<void> {
    if (this._isRefreshing) {
      return
    }
    this._isRefreshing = true

    try {
      if (!background) {
        SpinnerService.spin('mini')
      }
      const [customerResponse, akamaiIds, clusters, allTenants, versions]: any = await Promise.all([
        this.getCustomers(),
        this._akamaiCustomers.getMappedCustomers(),
        this._clusters.getMappedClusters(),
        this._customers.getMappedCustomers(),
        this._versions.getAvailableBundlingVersions()
      ])

      this.akamaiCustomers = akamaiIds
      this.clusters = clusters
      this.activeInstances = this.clusters.filter(c => isInstanceActive(c.status))
      this.allTenants = allTenants

      ;(versions as LatestVersion[]).forEach(({ envs, major }) => envs.forEach(({ env, versions }) => {

        versions = versions.map(v => {
          v.name = `v${v.version}${v.isDefault ? ' * ' : ''}`
          v._modules = (v.modules || [])
            .map(({ module, version }) => `${module}: ${version}`)
            .sort()
            .join('\n')
          return v
        }).sort((a, b) => a.isDefault ? -1 : a.paddedVersion > b.paddedVersion ? -1 : a.paddedVersion < b.paddedVersion ? 1 : 0)

        this.versions[major][env] = versions
      }))

      if (customerResponse) {
        this.customers = customerResponse.data
        this.numOfAvailableDocs = customerResponse.count

        if (!background) {
          LoggerService.info(customerResponse)
        }
      } else {
        throw Error('Bad Response')
      }
    } catch (e) {
      if (!background) {
        MethodsService.toast('error', 'Error fetching customers', e.toString(), 8)
      }
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._zone.run(() => {})
      this._isRefreshing = false
    }
  }

  async getCustomer (customerId: string) {
    try {
      SpinnerService.spin('mini')
      const customer = await this._customers.getCustomer(customerId)
      LoggerService.info(customer)
      if (customer) {
        this.selectedCustomer = customer
        this.availableInstances = this.clusters.filter(cl => isInstanceActive(cl.status) || cl.id == customer.clusterId)
      }
    } catch (e) {
      MethodsService.toast('error', 'Error fetching customer ' + customerId, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
      this._zone.run(() => {})
    }
  }

  async updateCustomer (akamaiCustomerId: string, name: string, website: string, domains: string, incidentNumberPrevalenceThreshold: string, incidentRatioPrevalenceThreshold: string, clusterId?: string) {
    if (!name || !website || !domains) {
      return MethodsService.dialog(
        'Bad Customer Parameters',
        'Please fill the required fields'
      )
    }

    const updateObject: any = {
      name,
      website,
      akamaiCustomerId,
      domains: domains.split(',').map(domain => domain.trim())
    }

    if (clusterId) {
      updateObject.clusterId = clusterId
    }

    const update = MethodsService.removeSameValues(this.selectedCustomer, updateObject, ['_id'])

    if (Object.keys(update).length <= 1) {
      return MethodsService.dialog('Invalid Action', 'Nothing to save')
    }

    try {
      SpinnerService.spin('mini')
      const updated = await this._customers.updateCustomer(update)
      if (updated) {
        this.clearState()
        await this.refreshTable()
      }
    } catch (e) {
      MethodsService.toast('error', 'Error updating customer', e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }

  }

  async createNewCustomer (akamaiCustomerId: string, clusterId: string, name: string, website: string, domains: string, incidentNumberPrevalenceThreshold: string, incidentRatioPrevalenceThreshold: string) {
    if (!name || !website || !domains) {
      return MethodsService.dialog(
        'Bad Customer Parameters',
        'Please fill the required fields'
      )
    }

    // Ensure website has protocol
    website = website.match(/^https?:\/\//) ? website : `https://${website}`

    const customer: Partial<CustomersSchema & { clusterId?: string }> = {
      akamaiCustomerId,
      clusterId,
      name,
      website,
      domains: domains.split(',')
    }
    try {
      SpinnerService.spin('mini')
      const newCustomer = await this._customers.createNewCustomer(customer)
      if (newCustomer) {
        this.clearState()
        await this.refreshTable()
      }
    } catch (e) {
      MethodsService.toast('error', 'Error creating new customer', e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }
  }

  async deleteCustomers () {
    const title = `Delete Confirmation`
    const content = `Delete ${this.selectedTableItems.size} Customer${this.selectedTableItems.size > 1 ? 's' : ''} ?`
    const deleteConfirmationText = 'Delete'
    if (await MethodsService.confirm(title, content, deleteConfirmationText)) {

      SpinnerService.spin('mini')

      try {
        const success = await this._customers.deleteCustomers([...this.selectedTableItems])
        if (success) {
          this.selectedTableItems.clear()
          await this.refreshTable()
        }
      } catch (e) {
        LoggerService.error(e)
      }
      SpinnerService.stop('mini')
    }
  }

  async updateTenantVersion (bundleEnv: BundleEnvironment, stageToProd: boolean = false) {
    if (!bundleEnv || (!stageToProd && (typeof this.selectedTenantBundleMajorVersion != 'number' || !this.selectedTenantVersion[0]))) {
      console.log(this.selectedCustomer._id, bundleEnv, this.selectedTenantBundleMajorVersion, this.selectedTenantVersion)
      return
    }

    const version = stageToProd ?
      this.selectedCustomer.engineVersions.dry.version :
      this.selectedTenantVersion[0].version

    const action = stageToProd ?
      `Push dry bundle v${version} into production environment (wet)` :
      `Bundle v${version} into ${bundleEnv} environment`

    if (!await MethodsService.confirm(
      `${ENV.toUpperCase()} - Version Approval`,
      `You are about to ${action}.<br>Proceed?`,
      `PROCEED`,
    )) {
      return
    }

    try {
      SpinnerService.spin()

      const req: BundleTenantsRequest = {
        env: ENV as EligibleEnv,
        tenants: [this.selectedCustomer._id],
      }

      if (stageToProd) {
        req.stageToProd = true
      } else {
        req.bundle = {
          bundleEnv: bundleEnv,
          version: this.selectedTenantVersion[0].version,
        }
        if (parseInt(this.selectedTenantVersion[0].version) == EXPLICIT_BUNDLE_MAJOR_VERSION.V0) {
          req.bundle.noscript = true
        }
      }

      const res = await this._customers.bundleTenants(req)

      if (res.updated_tenants.find(updated => updated.tenant_id == this.selectedCustomer._id)) {
        const version = res.updated_tenants[0].version || this.selectedTenantVersion[0].version
        MethodsService.toast('success', `Update Succeeded`, `Updated ${(stageToProd ? BundleEnvironment.WET : bundleEnv).toUpperCase()} v${version} bundle for Tenant ${this.selectedCustomer._id} at ${ENV} environment!`, 8)
        this.clearState()
        this.clearVersionsState()
        return await this.refreshTable()
      }

      const error =
        res.failed_tenants.find(t => t.tenant == this.selectedCustomer._id)?.error

      if (error) {
        throw Error(error)
      }

    } catch (e) {
      MethodsService.toast('error', 'Failed updating tenant version', e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop()
    }

  }

  clearVersionsState () {
    this.selectedTenants = []
    this.selectedVersion = []
    this.bundleResponse = undefined
    this.selectedBundleEnvironment = undefined
    this.isTenantsVersionsModalActive = false
    this.selectedBundleMajorVersion = this.DEFAULT_MAJOR_VERSION

    this.selectedTenantVersion = []
    this.selectedTenantBundleMajorVersion = this.DEFAULT_MAJOR_VERSION
  }

  toggleTenantsVersions (active: boolean) {
    if (!active) {
      return this.clearVersionsState()
    }

    this.selectedTenants = [...this.selectedTableItems].filter(id => !!this.customers.find(c => c._id == id))
      .map(tenant_id => {
        const tenant = this.allTenants.find(t => t.id == tenant_id)!
        return {
          id: tenant.id,
          _name: tenant._name,
          name: tenant.name,
          website: tenant.website,
          clusterId: tenant.clusterId
        }
      })

    this.isTenantsVersionsModalActive = true
  }

  getModules (version: string) {

    for (const major in this.versions) {
      for (const env in this.versions[major]) {
        const find = this.versions[major][env].find(v => v.version == version)
        if (find) {
          return find._modules
        }
      }
    }

  }

  getDefaultVersion (dis: string, versions: LatestVersionEnvVersion[]): LatestVersionEnvVersion[] {
    return this[dis] = versions.length == 1 ? versions : [versions.find(v => v.isDefault)].filter(x => !!x)
  }

  async bundleTenants () {
    if (!(this.selectedTenants.length && this.selectedBundleEnvironment && (this.selectedPushToStaging || (this.selectedBundleMajorVersion != null && this.selectedVersion && this.selectedVersion[0])))) {
      return console.log(this.selectedBundleEnvironment, this.selectedTenants, this.selectedVersion, this.selectedBundleMajorVersion)
    }

    const action = this.selectedPushToStaging ?
      `Push ${this.selectedTenants.length} tenants from their dry environment into production (wet)` :
      `Bundle ${this.selectedTenants.length} tenants with v${this.selectedVersion[0].version} into ${this.selectedBundleEnvironment} environment`

    if (!await MethodsService.confirm(
      `${this.ENV.toUpperCase()} - Version Approval`,
      `You are about to ${action}.<br>Proceed?`,
      `PROCEED`,
    )) {
      return
    }

    try {
      SpinnerService.spin()

      const req: BundleTenantsRequest = {
        env: this.ENV as EligibleEnv,
        tenants: this.selectedTenants.map(tenants => tenants.id)
      }

      if (this.selectedPushToStaging) {
        req.stageToProd = true
      } else {
        req.bundle = {
          bundleEnv: this.selectedBundleEnvironment,
          version: this.selectedVersion[0].version,
        }
        if (parseInt(this.selectedVersion[0].version[0]) == EXPLICIT_BUNDLE_MAJOR_VERSION.V0) {
          req.bundle.noscript = true
        }
      }

      const res = await this._customers.bundleTenants(req)

      const _failed: { error: string, tenants: MappedTenants }[] = []

      for (const { tenant, error } of res.failed_tenants) {
        const e = _failed.find(_ => _.error == error)
        if (e) {
          e.tenants.push(this.allTenants.find(t => t.id == tenant))
        } else {
          _failed.push({
            error,
            tenants: [this.allTenants.find(t => t.id == tenant)]
          })
        }

      }

      this.bundleResponse = {
        ...res,
        _failed,
        _updated: res.updated_tenants.map(tenant => ({
          ...tenant,
          ...this.allTenants.find(t => t.id == tenant.tenant_id)
        }))
      }
      this.ref_hljs(this._zone)
    } catch (e) {
      MethodsService.toast('error', 'Failed updating tenant version', e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop()
    }

  }

  private async getCustomers (options?: FilterArguments) {
    options = options || {
      filter: this.filterValue,
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sort_by: this.currentSortBy,
      sort_direction: this.isSortReversed ? 'asc' : 'desc'
    }

    if (this.versionFilterIsStagingEnv) {
      options.staging_versions = true
    }

    if (this.selectedNumericFilterType.id == 0 && this.numericFilterSearch) {
      options.version_match = [this.currentNumericType.data, this.numericFilterSearch]
    }

    if (this.selectedNumericFilterType.id == 1 && (this.numericFilterSearchLow || this.numericFilterSearchHigh)) {
      options.version_range = [this.numericFilterSearchLow || null, this.numericFilterSearchHigh || null]
    }

    if (options.version_match || options.version_range) {
      if (this.versionFilterBundleEnv?.length) {
        options.version_bundle_env = this.versionFilterBundleEnv.map(v => v.id.toLowerCase())
      } else {
        delete options.version_match
        delete options.version_range
      }
    }

    return await this._customers.getCustomers(options)
  }

  exportJSON () {
    const filename = `${Date.now()}_${this.selectedTenants.length}_${this.ENV}_${this.selectedBundleEnvironment}.json`

    const res: any = { ...this.bundleResponse }

    const _export = JSON.stringify({
      updated_tenants: res._updated.map(t => ({
        id: t.id,
        name: t._name,
        version: t.version,
        website: t.website,
        instance_id: t.clusterId
      })),
      failed_tenants: res._failed.map(t => {
        t.tenants = t.tenants.map(t => ({
          id: t.id,
          name: t._name,
          website: t.website,
          instance_id: t.clusterId
        }))
        return t
      }),
    }, null, 2)

    MethodsService.downloadAsFile(_export, filename)
  }

  async copyTenant (env: CxEnvironment.DEV | CxEnvironment.STAGING) {
    const tenantId = this.selectedCustomer._id

    const title = `Copy Confirmation`
    const content = `Copy Tenant <b>${this.selectedCustomer.name} (${tenantId})</b> to ${env} environment?<br>note: This will override any existing with this ID and will remove his associations (${env} env).`
    const deleteConfirmationText = 'Copy'
    if (await MethodsService.confirm(title, content, deleteConfirmationText)) {

      SpinnerService.spin('mini')

      try {
        const { success, errors, error } = await this._customers.copyTenant(tenantId, env)
        if (!success) {
          return MethodsService.toast('error', `Failed copying Tenant ${tenantId}`, (error || errors || '').toString(), 8)
        }
        MethodsService.toast('success', `Copy Succeeded!`, `Tenant ${tenantId} is now available at the master ${env} environment!`, 8)
      } catch (e) {
        MethodsService.toast('error', `Failed copying Tenant ${tenantId}`, e.toString(), 8)
      } finally {
        SpinnerService.stop('mini')
      }
    }

  }

}
