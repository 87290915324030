import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'cx-browser',
  templateUrl: './cx-browser.component.html',
  styleUrls: ['./cx-browser.component.scss'],
})
export class CxBrowserComponent implements OnInit {

  @Input() browser: 'chrome' | 'firefox' | 'safari' | 'opera' | 'edge' | 'explorer' | string

  @Input() class?: string

  @Input() classFunction?: Function

  @Input() title?: boolean = false

  @Input() showLabel?: boolean = true

  @Input() private sub?: boolean = false

  browserCode: string

  dataReady: boolean = false

  browserName: string

  browserShortName: string

  static readonly BROWSER_CODE_TO_NAME: [string /*code*/, string /*name*/, string? /*name*/][] = [
    //@formatter:off
    ['chrome webview',              'Chrome WebView',    'Chrome WV'],
    ['chrome',                      'Google Chrome'],
    ['chromium',                    'Chromium'],
    ['firefox',                     'Mozilla Firefox',          'Firefox'],
    // ['opera touch',                 'Opera Touch',              'Opera'],
    ['opera',                       'Opera'],
    ['explorer',                    'Internet Explorer'],
    ['edge',                        'Microsoft Edge'],
    // ['mobile safari',               'Apple Safari Mobile'],
    ['safari',                      'Apple Safari'],
    ['brave',                       'Brave'],
    ['silk',                        'Amazon Silk'],
    ['android',                     'Android'],
    ['phantom',                     'PhantomJS'],
    ['slimjet',                     'Slimjet'],
    ['maxthon',                     'Maxthon'],
    ['slimbrowser',                 'SlimBrowser',              'Slim'],
    ['netscape',                    'Netscape'],
    ['ucbrowser',                   'UC Browser'],
    ['facebook',                    'Facebook Crawler'],
    ['yandex',                      'Yandex Crawler'],
    ['line',                        'Line App Crawler'],
    ['vivaldi',                     'Vivaldi']
    //@formatter:on
  ]

  static isKnownBrowser = (browser: string): boolean => !!CxBrowserComponent.BROWSER_CODE_TO_NAME.find(([code, name]) => browser.indexOf(code) > -1)

  constructor () {
  }

  ngOnInit (): void {
    this.class = this.class || ''
    const [browserCode, browserName, browserShortName] = this.getBrowserData()
    this.browserCode = browserCode
    this.browserName = browserName
    this.browserShortName = browserShortName
    this.dataReady = true
  }

  private getBrowserData (): [string, string, string] {
    const browser = this.browser.toLowerCase()
    for (const [code, name, shortName] of CxBrowserComponent.BROWSER_CODE_TO_NAME) {
      if (browser.indexOf(code) > -1) {
        const modifiedCode = code.replace(/webview|touch|mobile|crawler|\s*/gi, '').trim()
        const shortNameString = shortName || modifiedCode.charAt(0).toLocaleUpperCase() + modifiedCode.slice(1, modifiedCode.length)
        return [modifiedCode, name, shortNameString]
      }
    }
    return ['?', 'Unknown', '?']
  }

}
