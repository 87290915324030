import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { MethodsService, NUMERIC_SORT_ARRAY } from '../../services/methods/methods.service'
import { LoggerService } from '../../services/logger/logger.service'
import { CxConsts } from '../../shared/typings/chameleonx.typings'
import { GlobalNotification, GlobalState } from '../../app.state'
import { GIT_REPO, JIRA_TICKET_PREFIX } from '../../services/data/data.service'
import { PIDeploymentImagesService } from '../../services/api/services/pi-deployment-images.service'
import {
  ENV_DEPLOY_FULL_TO_DEPLOY_RESOLVER,
  ENV_DEPLOY_TO_DEPLOY_FULL_RESOLVER
} from '../../services/api/processors/pi-instances-processor.service'
import { UserDataService } from '../../services/user-data/user-data.service'

@Component({
  selector: 'pi-deployment-images',
  templateUrl: './pi-deployment-images.component.html',
  styleUrls: ['./pi-deployment-images.component.scss']
})

export class PIDeploymentImagesComponent<T extends ExtendedDeploymentImagesSchema = ExtendedDeploymentImagesSchema> extends TableWithFilters implements OnInit, OnDestroy {
  readonly JIRA_TICKET_PREFIX = JIRA_TICKET_PREFIX
  readonly GIT_REPO = GIT_REPO

  readonly title = 'PI Images'
  readonly numericTypes = NUMERIC_SORT_ARRAY

  readonly DISABLED_BANNER = ENV != CxEnvironment.PRODUCTION

  dockers: ExtendedDeploymentImagesSchema[] = []
  featureImages: ExtendedDeploymentImagesSchema[] = []
  selectedDocker: ExtendedDeploymentImagesSchema

  //table
  readonly tableHeaders: TableHeader[]

  filterValue: string = ''

  currentSortBy: string

  paginationName = 'Docker Images'

  textToClip = MethodsService.copyToClipboard

  normalizeString = MethodsService.normalizeString

  module_icons = CxConsts.rwasp_modules_icon

  //repositories filter
  availableRepositories: string[] = []
  currentRepositories = this.availableRepositories

  //environments filter
  availableDeploymentEnvironments: string[] = []
  currentDeploymentEnvironments = this.availableDeploymentEnvironments

  //operational filter
  availableOperationalEnvironments: string[] = []
  currentOperationalEnvironments = this.availableOperationalEnvironments

  //feature branches select
  featureBranchFilterOptions: { id: DockerImageFilterReleaseTypes, name: string }[] = [
    { id: 'both', name: MethodsService.normalizeString('both') },
    { id: 'feature_only', name: MethodsService.normalizeString('feature_only') },
    { id: 'version_only', name: MethodsService.normalizeString('version_only') },
  ]
  currentFeatureBranchFilterOption = [this.featureBranchFilterOptions[0]]

  _isRefreshing: boolean = false
  private _debounce: void | number
  private debounce_timeout: number = 1000

  isEligibleToDelete = false

  constructor (private _userData: UserDataService, private _images: PIDeploymentImagesService, private _zone: NgZone, private _state: GlobalState) {
    super()
    this.currentItemsPerPage = this.itemsPerPageFilter[1]
    this.isEligibleToDelete = this._userData.hasPermissions('/deployment_image/remove_features', UserPermission.ADMIN)
    this.tableHeaders = [
      //@formatter:off
      this.isEligibleToDelete ?
        { /*width: '4', */    name: '',           sortable: false,    field: ''           } : undefined,
      { name: 'Created',      width: 8,    sortable: true,   field: 'createdAt'          },
      { name: 'Updated',      width: 8,    sortable: true,   field: 'updatedAt'          },
      { name: 'Image',        width: 14,   sortable: true,   field: 'image'              },
      { name: 'Repository',   width: 13,   sortable: true,   field: 'repository'         },
      { name: 'Images Env',                sortable: true,   field: 'imageEnvironment'   },
      { name: 'Version',                   sortable: true,   field: 'version'            },
      { name: 'JIRA',          width: 17,  sortable: false                               },
      { name: 'Envs / Feature',width: 12,  sortable: false                               },
      { name: 'Releases',      width: 6,   sortable: true,   field: 'numOfReleases'    },
      { name: 'Clusters',      width: 6,   sortable: true,   field: 'numOfClusters'      },
      { name: 'Active',        width: 5,   sortable: true,   field: 'active'             },
      //@formatter:on
    ].filter(x => !!x)
    this.currentSortBy = this.tableHeaders[1].field

  }

  ngOnDestroy (): void {
    window.removeEventListener('keyup', this.registerModalListeners.bind(this))
  }

  openPRs (urls: string[]) {
    urls.forEach(url => window.open(url, '_blank'))
  }

  ngOnInit (): void {
    window.addEventListener('keyup', this.registerModalListeners.bind(this))
    this.refreshTable!()
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    if (!this.selectedDocker) {
      return
    }
    if (key === 'Escape') {
      this.selectedDocker = undefined
    }
  }

  onFilterChange (item: any) {
    if (this._debounce) {
      this._debounce = clearTimeout(this._debounce)
    }
    this._debounce = setTimeout(() => this.refreshTable(), this.debounce_timeout) as any as number
  }

  async setAvailable (docker: T) {
    const message = await MethodsService.confirm(
      `Version Activation`,
      `You are about to activate image <b>${docker.image}</b>.<br>Proceed?`,
      `ACTIVATE`,
    )

    if (!message) {
      return
    }

    try {
      SpinnerService.spin()
      const updatedAt = new Date(docker.updatedAt).getTime()
      const success = await this._images.setActive(docker._id, updatedAt)
      if (!success) {
        throw Error('')
      }
      await this.refreshTable()
    } catch (e) {
      MethodsService.toast('error', `Error activating image ${docker.image}.`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop()
      this._zone.run(() => {})
    }
  }

  async setUnavailable (docker: T) {
    const prompt = await MethodsService.prompt(
      `Version Deactivation`,
      `You are about to deactivate image <b>${docker.image}</b>.<br>Proceed?`,
      `eg: 'PAGE-1234 - performance issue'..`,
      { title: 'Input Error', message: 'Must enter "page-xxxx"' },
      'DEACTIVATE',
      (str: string) => /(page-(\d+)\W+)?(.*)/i.test(str)
    )

    if (!prompt) {
      return
    }

    const [_, __, page, comment = ''] = /(page-(\d+)\W+)?(.*)/i.exec(prompt.input) || []

    try {
      SpinnerService.spin()
      const updatedAt = new Date(docker.updatedAt).getTime()
      const success = await this._images.setInactive(docker._id, comment, page ? [page] : [], updatedAt)
      if (!success) {
        throw Error('')
      }
      await this.refreshTable()
    } catch (e) {
      MethodsService.toast('error', `Error disabling image ${docker.image}`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop()
      this._zone.run(() => {})
    }
  }

  async refreshTable (background: boolean = false): Promise<void> {
    if (this._isRefreshing) {
      return
    }
    this._isRefreshing = true
    try {
      if (!background) {
        SpinnerService.spin('mini')
        this.selectedTableItems.clear()
        this.selectedDocker = undefined
      }

      const [{ data, count }, { repositories, deploymentEnvironments, operationalEnvironments }] = await Promise.all([
        this.getDockerImages(),
        this._images.getMetadata()
      ])

      this.availableRepositories = [...new Set(Object.values(repositories).map(Object.keys).flat())].sort()
      this.availableDeploymentEnvironments = deploymentEnvironments.map(s => ENV_DEPLOY_TO_DEPLOY_FULL_RESOLVER[s]).sort().filter(Boolean)
      this.availableOperationalEnvironments = operationalEnvironments.map(s => MethodsService.normalizeString(s)).sort()

      //validate
      if (data && typeof count === 'number') {
        this.numOfAvailableDocs = count
        this.dockers = data
        this.featureImages = data.filter(image => image.isFeatureBranch)
        if (!background) {
          LoggerService.info('Docker Images:', this.dockers)
        }
      } else {
        throw Error('Bad Response')
      }
    } catch (e) {
      if (!background) {
        MethodsService.toast('error', 'Error fetching modules', e.toString(), 8)
      }
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._zone.run(() => {})
      this._isRefreshing = false
    }
  }

  async removePage ({ item: docker, page }: TableJiraEmitValue<T>) {
    try {
      SpinnerService.spin()
      const updatedAt = new Date(docker.updatedAt).getTime()
      const doc = await this._images.removePages(docker._id, [page], updatedAt)
      this.dockers[this.dockers.indexOf(this.dockers.find(d => d.image == doc.image))].git = doc.git
      this.dockers[this.dockers.indexOf(this.dockers.find(d => d.image == doc.image))].updatedAt = doc.updatedAt
    } catch (e) {
      MethodsService.toast('error', `Error deleting PAGE-${page} for image ${docker.image}`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop()
      this._zone.run(() => {})
    }
  }

  async deleteFeatureImages () {
    const _ids = Array.from(this.selectedTableItems)

    const title = `Delete Confirmation`
    const content = `Delete ${_ids.length} Feature Image${_ids.length > 1 ? 's' : ''} ?`
    const deleteConfirmationText = 'Delete'
    if (!(await MethodsService.confirm(title, content, deleteConfirmationText))) {
      return
    }

    try {
      SpinnerService.spin()
      const success = await this._images.deleteFeatureImages(_ids)
      if (!success) {
        throw Error('')
      }
      MethodsService.toast('success', 'Deletion Success', `Successfully deleted ${_ids.length} feature images`, 8)
      await this.refreshTable()
    } catch (e) {
      MethodsService.toast('error', `Error deleting feature images`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop()
      this._zone.run(() => {})
    }

  }

  async addPage ({ item: docker, page }: TableJiraEmitValue<T>) {
    try {
      SpinnerService.spin()
      const updatedAt = new Date(docker.updatedAt).getTime()
      const doc = await this._images.addPages(docker._id, page.split(','), updatedAt)
      this.dockers[this.dockers.indexOf(this.dockers.find(d => d.image == doc.image))].git = doc.git
      this.dockers[this.dockers.indexOf(this.dockers.find(d => d.image == doc.image))].updatedAt = doc.updatedAt
    } catch (e) {
      MethodsService.toast('error', `Error adding PAGEs ${page} for image ${docker.image}`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop()
      this._zone.run(() => {})
    }

  }

  private async getDockerImages (options?: FilterArguments): Promise<CountedResultObject<ExtendedDeploymentImagesSchema[]>> {
    options = options || {
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sort_by: this.currentSortBy,
      sort_direction: this.isSortReversed ? 'asc' : 'desc',
      release_type: this.currentFeatureBranchFilterOption[0]?.id || this.featureBranchFilterOptions[0].id
    }

    if (this.filterValue.length) {
        options.filter = this.filterValue
    }

    if (this.currentRepositories?.length) {
      options.repositories = [...new Set(this.currentRepositories)].sort()
    }

    if (this.currentDeploymentEnvironments?.length) {
      options.deployment_envs = this.currentDeploymentEnvironments.map(s => ENV_DEPLOY_FULL_TO_DEPLOY_RESOLVER[s])
    }

    if (this.currentOperationalEnvironments?.length) {
      options.operational_envs = this.currentOperationalEnvironments.map(s => s.toUpperCase())
    }

    if (this.selectedNumericFilterType.id == 0 && this.numericFilterSearch) {
      options.version_match = [this.currentNumericType.data, this.numericFilterSearch]
    }

    if (this.selectedNumericFilterType.id == 1 && (this.numericFilterSearchLow || this.numericFilterSearchHigh)) {
      options.version_range = [this.numericFilterSearchLow || null, this.numericFilterSearchHigh || null]
    }

    return await this._images.getAllDockers(options)
  }

}
