export class UsersProcessorService {
  static process<T extends UsersSchema> (customerOrCustomers: T | T[]): T | T[] {
    return Array.isArray(customerOrCustomers) ?
      customerOrCustomers.map(UsersProcessorService.processUser) as T[] :
      UsersProcessorService.processUser(customerOrCustomers) as T
  }

  private static processUser (user: UsersSchema): UsersSchema {
    (user as any)._permissionsText =
      `Permissions:\n` +
      `${user.permissions.map(p => p.name).join('\n')}`;

    (user as any)._fullname = [user.firstName, user.middleName, user.lastName].filter(Boolean).join(' ');

    (user as any)._perm_fullname = `[${user.permission}] - ${[user.firstName, user.middleName, user.lastName].filter(Boolean).join(' ')}`

    return user
  }

}

