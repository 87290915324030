export class UsersPermissionsGroupProcessorService {
  static process<T extends ExtendedUsersPermissionsGroupSchema> (customerOrCustomers: T | T[]): T | T[] {
    return Array.isArray(customerOrCustomers) ?
      customerOrCustomers.map(UsersPermissionsGroupProcessorService.processGroup) as T[] :
      UsersPermissionsGroupProcessorService.processGroup(customerOrCustomers) as T
  }

  private static processGroup (group: ExtendedUsersPermissionsGroupSchema): ExtendedUsersPermissionsGroupSchema {
    (group as any)._usersText =
      `Users:\n` +
      `${group.users.map(p => [p.firstName, p.middleName, p.lastName].filter(Boolean).join(' ')).join('\n')}`

    return group
  }

  static enrichMetadata (metadata: UsersPermissionsGroupMetadata[]): EnrichedUsersPermissionsGroupMetadata[] {
    return metadata.map(m => ({
      ...m,
      text: `[${m.topic}] ${m.info} - ${m.path}`
    }))
  }

  static filterMetadata<T extends UsersPermissionsGroupMetadata> (metadata: T[]): T[] {
    return metadata.filter(m => m.autoPermission != UserPermission.READ_ONLY)
  }

  static enrichGroupsWithMetadata (groups: ExtendedUsersPermissionsGroupSchema[], metadata: UsersPermissionsGroupMetadata[]): void {
    groups.forEach(g => (g as any)._permissionsText =
      g.permissions.map(p => {
        const m = metadata.find(({ path }) => p == path)!
        return `[${m.topic}] ${m.info}`
      }).join('\n')
    )
  }
}

