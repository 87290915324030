import { AfterViewInit, Component, Input, OnInit } from '@angular/core'
import { DataService } from '../../../services/data/data.service'
import { MethodsService } from '../../../services/methods/methods.service'

@Component({
  selector: 'cx-pie-chart',
  templateUrl: './cx-pie-chart.component.html',
  styleUrls: ['./cx-pie-chart.component.scss']
})

export class CxPieChartComponent implements OnInit, AfterViewInit {

  public readonly pieChartType: Chart.ChartType = 'pie'
  public readonly UNIQUE_CHART_ID = MethodsService.generateUniqueID('letters')
  public readonly UNIQUE_CANVAS_ID = MethodsService.generateUniqueID('letters')

  @Input() public pieChartLabels: string[]
  @Input() public pieChartData: number[]
  @Input() public pieChartLegend: boolean
  @Input() public pieChartLegendPosition?: Chart.PositionType
  @Input() public pieChartColors?: Chart.ChartArcOptions[]
  @Input() public style?: Obj<string>

  private pieChartOptions: Chart.ChartOptions

  public isReady = false

  constructor () {
    this.pieChartColors = this.pieChartColors || [
      {
        backgroundColor: [...DataService.chartsColors.pie_colors_dark.map(color => MethodsService.hexToRGBA(color))]
      }
    ]
  }

  ngOnInit () {
    this.style = this.style || {}
    this.pieChartOptions = {
      responsive: true,
      legend: {
        labels: {
          boxWidth: 10,
          fontFamily: '\'Open Sans\', sans-serif',
          usePointStyle: true
        },
        position: 'top'
      }
    }
    this.pieChartOptions.legend.position = this.pieChartLegendPosition || this.pieChartOptions.legend.position
    this.isReady = true
  }

  ngAfterViewInit (): void {
  }

}
