import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { GlobalState } from '../../../app.state'
import { AgentLibrariesProcessor } from '../processors/agent-libraries-processor.service'

@Injectable()
export class AgentLibrariesService extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async getAllModules (options: FilterArguments): Promise<CountedResultObject<AgentLibModulesSchema[]>> {
    const queryString = MethodsService.objectToQueryString(options)
    const response = await this.get(`api/admin_api/agent_libs?${queryString}`) as CountedResultObject<AgentLibModulesSchema[]>
    return {
      count: response.count,
      success: response.success,
      data: AgentLibrariesProcessor.process(response.data || []) as AgentLibModulesSchema[]
    }
  }

  async addPages (module: string, version: string, pages: string[]): Promise<AgentLibModulesSchema> {
    const body = JSON.stringify({ module, version, pages })
    const response = await this.post(`api/admin_api/agent_libs/add_pages`, body, true, 'application/json')
    return AgentLibrariesProcessor.process(response.data as AgentLibModulesSchema) as AgentLibModulesSchema
  }

  async removePages (module: string, version: string, pages: string[]): Promise<AgentLibModulesSchema> {
    const body = JSON.stringify({ module, version, pages })
    const response = await this.post(`api/admin_api/agent_libs/remove_pages`, body, true, 'application/json')
    return AgentLibrariesProcessor.process(response.data as AgentLibModulesSchema) as AgentLibModulesSchema
  }

}

