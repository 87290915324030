import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { MethodsService } from '../../services/methods/methods.service'
import { LoggerService } from '../../services/logger/logger.service'
import { UserDataService } from '../../services/user-data/user-data.service'
import { GlobalNotification, GlobalState } from '../../app.state'
import { PiIntelligenceService } from '../../services/api/services/pi-intelligence.service'
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap'
import { PIInstancesService } from '../../services/api/services/pi-instances.service'
import { canPerformActionOnInstance } from '../../services/api/processors/pi-instances-processor.service'

const enum TabScopes {
  BLACKLIST_DOMAIN,
  VENDORS,
  TRUSTED_DOMAIN,
  VENDOR_HUNTING
}

const enum PaginationNames {
  BLACKLIST_DOMAIN = 'Blacklisted Domains',
  VENDORS = 'Vendors',
  TRUSTED_DOMAIN = 'Trusted Domains',
  VENDOR_HUNTING = 'Vendor Hunting'
}

const VendorCategories = [
  'A/B Testing', 'APIs', 'Accessibility', 'Ads', 'Advertising', 'Analytics', 'Anti-Fraud', 'Aviation', 'Behavioral', 'Biometrics', 'Bot Detection', 'CDN', 'Certificates', 'Chat', 'Cloud', 'Content', 'Cryptography', 'Cryptomining', 'Customer Experience', 'Data', 'Financial', 'Fingerprinting', 'Healthcare', 'Help', 'Human Resources', 'IT', 'Internet', 'Investment', 'MarketPlace', 'Marketing', 'Medical', 'Monitoring', 'Network', 'Optimization', 'Performance', 'Security', 'Social', 'Social Networking', 'Tag Management', 'Technology', 'Web'
]

@Component({
  selector: '',
  templateUrl: './pi-intelligence.component.html',
  styleUrls: ['./pi-intelligence.component.scss']
})

export class PIIntelligenceComponent extends TableWithFilters implements OnInit, OnDestroy {

  readonly title = 'PI Intelligence Feeds'

  public filterValue: string = ''

  paginationName = PaginationNames.BLACKLIST_DOMAIN

  //table headers
  tableHeaders: TableHeader[]
  readonly blackListTableHeaders: TableHeader[]
  readonly trustedDomainTableHeaders: TableHeader[]
  readonly vendorsTableHeaders: TableHeader[]
  readonly vendorHuntingTableHeaders: TableHeader[]

  //table
  tableValues: StandardObject[]
  blackListTableValues: DomainBlacklistSchema[]
  trustedDomainTableValues: TrustedDomainsSchema[]
  vendorsTableValues: VendorsSchema[]
  vendorHuntingTableValues: AggregatedResourcesSchema[]

  currentScope: TabScopes
  activeIdString: string

  currentSortBy: string

  showCreateBlacklistedDomainModal: boolean = false
  showCreateVendorModal: boolean = false
  showCreateTrustedDomainModal: boolean = false

  selectedBlacklistedDomain?: DomainBlacklistSchema
  selectedTrustedDomain?: TrustedDomainsSchema
  selectedVendor?: VendorsSchema
  selectedVendorHuntingDomains?: AggregatedResourcesSchema

  activeClusters: CustomerMappingSchema[] = []
  selectedCluster: string
  minTimesSeen: number

  _isRefreshing: boolean = false

  sliceString = MethodsService.sliceString

  constructor (private _userData: UserDataService, private _intelligenceFeed: PiIntelligenceService, private _clusters: PIInstancesService, private _zone: NgZone, private _state: GlobalState) {
    super()
    this.currentItemsPerPage = this.itemsPerPageFilter[1]
    this.blackListTableHeaders = [
      //@formatter:off
      // USER_POWER_LEVEL[this._userData.permission] >= USER_POWER_LEVEL[UserPermission.ADMIN] ?
        // { /*width: '4', */    name: '',             sortable: false,    field: ''                 } : undefined,
        { width: '8',     name: 'Created',      sortable: true,     field: 'createdAt'        },
        { width: '8',     name: 'Modified',     sortable: true,     field: 'updatedAt'        },
        { width: '12',    name: 'Domain',       sortable: true,     field: 'domain'           },
        { width: '12',    name: 'URLs',         sortable: false,    field: 'urls'             },
        { width: '12',    name: 'Tags',         sortable: false,    field: 'tags'             },
        { width: '12',    name: 'Sources',      sortable: false,    field: 'sources'          }
      //@formatter:on
    ].filter(x => !!x)

    this.vendorsTableHeaders = [
      //@formatter:off
      // USER_POWER_LEVEL[this._userData.permission] >= USER_POWER_LEVEL[UserPermission.ADMIN] ?
      //   { /*width: '4', */    name: '',                   sortable: false,    field: ''                 } : undefined,
        { width: '8',     name: 'Created',            sortable: true,     field: 'createdAt'        },
        { width: '8',     name: 'Modified',           sortable: true,     field: 'updatedAt'        },
        { width: '12',    name: 'Name',               sortable: true,     field: 'name'             },
        { width: '12',    name: 'Domain',             sortable: true,     field: 'domain'           },
        { width: '12',    name: 'Website',            sortable: true,     field: 'website'          },
        { width: '12',    name: 'Category',           sortable: true,     field: 'category'         },
        { width: '12',    name: 'Description',        sortable: true,     field: 'vendorDescription'},
        { width: '12',    name: 'Outbound Domains',   sortable: false,    field: 'outboundDomains'  },
        { width: '12',    name: 'Tags',               sortable: false,    field: 'tags'             }
      //@formatter:on
    ].filter(x => !!x)

    this.trustedDomainTableHeaders = [
      //@formatter:off
      // USER_POWER_LEVEL[this._userData.permission] >= USER_POWER_LEVEL[UserPermission.ADMIN] ?
      //   { /*width: '4', */    name: '',                   sortable: false,    field: ''                 } : undefined,
        { width: '8',     name: 'Created',            sortable: true,     field: 'createdAt'        },
        { width: '8',     name: 'Modified',           sortable: true,     field: 'updatedAt'        },
        { width: '12',    name: 'Name',               sortable: true,     field: 'name'             },
        { width: '12',    name: 'Domains',            sortable: false,    field: 'domains'          },
        { width: '12',    name: 'Is Approved',        sortable: true,     field: 'isApproved'       },
        { width: '12',    name: 'Approved By',        sortable: true,     field: 'approvedBy'       },
      //@formatter:on
    ].filter(x => !!x)

    this.vendorHuntingTableHeaders = [
      //@formatter:off
      // USER_POWER_LEVEL[this._userData.permission] >= USER_POWER_LEVEL[UserPermission.ADMIN] ?
      //   { /*width: '4', */    name: '',                   sortable: false,    field: ''                 } : undefined,
        { width: '8',     name: 'First Seen',            sortable: true,     field: 'firstSeen'      },
        { width: '8',     name: 'Last Seen',             sortable: true,     field: 'lastSeen'       },
        { width: '12',    name: 'Domain',                sortable: true,     field: 'domain'         },
        { width: '12',    name: 'Domain Score',          sortable: true,     field: 'domainScore'    },
        { width: '12',    name: 'Times Seen',            sortable: true,     field: 'timesSeen'      },
      //@formatter:on
    ].filter(x => !!x)

    // Init blacklisted domains
    this.tableHeaders = this.blackListTableHeaders
    this.currentScope = TabScopes.BLACKLIST_DOMAIN

    this.currentSortBy = this.tableHeaders[2].field

    this.minTimesSeen = 100
    // this.isEligibleToDelete = USER_POWER_LEVEL[this._userData.permission] >= USER_POWER_LEVEL[UserPermission.ADMIN]
  }

  ngOnDestroy (): void {
    window.removeEventListener('keyup', this.registerModalListeners.bind(this))
  }

  ngOnInit (): void {
    window.addEventListener('keyup', this.registerModalListeners.bind(this))
    let nextId
    switch (true) {
      case location.hash.includes(`#vendors`):
        nextId = 'vendors-tab'
        break
      case location.hash.includes(`#vendor_hunting`):
        nextId = 'vendor-hunting-tab'
        break
      case location.hash.includes(`#trusted_domains`):
        nextId = 'trusted-domains-tab'
        break
      case location.hash.includes(`#blacklist_domains`):
        nextId = 'blacklist-tab'
        break
    }

    if (nextId) {
      this.onTabChange({ nextId } as NgbTabChangeEvent)
    }

    const url = new URL(location.href)
    const filter = url.searchParams.get('filter')
    if (filter) {
      this.filterValue = filter
    }

    this.refreshTable!()

    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    if (!this.selectedBlacklistedDomain && !this.selectedVendor && !this.showCreateBlacklistedDomainModal && !this.showCreateVendorModal) {
      return
    }
    if (key === 'Escape') {
      this.clearState()
    }
  }

  private clearState () {
    this.selectedBlacklistedDomain = undefined
    this.selectedVendor = undefined
    this.selectedTrustedDomain = undefined
    this.selectedVendorHuntingDomains = undefined

    this.showCreateBlacklistedDomainModal = false
    this.showCreateVendorModal = false
    this.showCreateTrustedDomainModal = false
  }

  async loadRelevantData (): Promise<CountedResultObject<StandardObject>> {
    let results
    switch (this.currentScope) {
      case TabScopes.BLACKLIST_DOMAIN:
        results = await this.getDomainBlacklist()
        this.blackListTableValues = results.data as DomainBlacklistSchema[]
        return results
      case TabScopes.VENDORS:
        results = await this.getVendors()
        this.vendorsTableValues = results.data as VendorsSchema[]
        return results
      case TabScopes.TRUSTED_DOMAIN:
        results = await this.getTrustedDomains()
        this.trustedDomainTableValues = results.data as TrustedDomainsSchema[]
        return results
      case TabScopes.VENDOR_HUNTING:
        results = await this.getVendorHunting()
        this.vendorHuntingTableValues = results.data as AggregatedResourcesSchema[]
        return results
    }
  }

  async refreshTable (background: boolean = false): Promise<void> {
    if (this._isRefreshing) {
      return
    }
    this._isRefreshing = true

    try {
      if (!background) {
        SpinnerService.spin('mini')
      }

      if (!this.selectedCluster || !this.activeClusters) {
        await this.updateClusters!()
      }

      const feedResponse = await this.loadRelevantData()

      if (feedResponse) {
        // this.customers = customerResponse.data
        this.numOfAvailableDocs = feedResponse.count
        if (!background) {
          LoggerService.info(feedResponse)
        }
      } else {
        throw Error('Bad Response')
      }
    } catch (e) {
      if (!background) {
        MethodsService.toast('error', 'Error fetching customers', e.toString(), 8)
      }
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._zone.run(() => {})
      this._isRefreshing = false
    }
  }

  stringToArray (strList: string): string[] {
    if (strList.length) {
      return strList.split(',').map(item => item.trim())
    }
    return []
  }

  addEllipsisToString (str: string, maxLength: number = 40): string {
    if (str.length > maxLength) {
      return `${str.slice(0, maxLength)} ...`
    }
    return str
  }

  onTabChange ($event: NgbTabChangeEvent) {

    switch ($event.nextId) {
      case 'blacklist-tab':
        this.paginationName = PaginationNames.BLACKLIST_DOMAIN
        this.tableHeaders = this.blackListTableHeaders
        this.currentSortBy = this.tableHeaders[2].field
        this.currentScope = TabScopes.BLACKLIST_DOMAIN
        this.activeIdString = 'blacklist-tab'
        break
      case 'vendors-tab':
        this.paginationName = PaginationNames.VENDORS
        this.tableHeaders = this.vendorsTableHeaders
        this.currentSortBy = this.tableHeaders[2].field
        this.currentScope = TabScopes.VENDORS
        this.activeIdString = 'vendors-tab'
        break
      case 'trusted-domains-tab':
        this.paginationName = PaginationNames.TRUSTED_DOMAIN
        this.tableHeaders = this.trustedDomainTableHeaders
        this.currentSortBy = this.tableHeaders[2].field
        this.currentScope = TabScopes.TRUSTED_DOMAIN
        this.activeIdString = 'trusted-domains-tab'
        break
      case 'vendor-hunting-tab':
        this.paginationName = PaginationNames.VENDOR_HUNTING
        this.tableHeaders = this.vendorHuntingTableHeaders
        this.currentSortBy = this.tableHeaders[4].field
        this.currentScope = TabScopes.VENDOR_HUNTING
        this.activeIdString = 'vendor-hunting-tab'
        break
    }

    this.refreshTable()
  }

  async getBlacklistedDomainDetails (domain: string, event: Event) {
    if (event) {
      const tagName = (event.target as HTMLElement).tagName.toLowerCase()
      if (tagName == 'span' || tagName == 'input') {
        return
      }
    }
    try {
      SpinnerService.spin('mini')
      const blacklistedDomainData = await this.getBlacklistedDomain(domain) as DomainBlacklistSchema
      LoggerService.info(blacklistedDomainData)
      if (blacklistedDomainData) {
        this.selectedBlacklistedDomain = blacklistedDomainData
      }
    } catch (e) {
      MethodsService.toast('error', 'Error fetching blacklisted domain ' + domain, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
      this._zone.run(() => {})
    }
  }

  async getTrustedDomainDetails (_id: string, event: Event) {
    if (event) {
      const tagName = (event.target as HTMLElement).tagName.toLowerCase()
      if (tagName == 'span' || tagName == 'input') {
        return
      }
    }
    try {
      SpinnerService.spin('mini')
      const trustedDomainData = await this.getTrustedDomain(_id) as TrustedDomainsSchema
      LoggerService.info(trustedDomainData)
      if (trustedDomainData) {
        this.selectedTrustedDomain = trustedDomainData
      }
    } catch (e) {
      MethodsService.toast('error', 'Error fetching trusted domain ', e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
      this._zone.run(() => {})
    }
  }

  async getVendorDetails (domain: string, event: Event) {
    if (event) {
      const tagName = (event.target as HTMLElement).tagName.toLowerCase()
      if (tagName == 'span' || tagName == 'input') {
        return
      }
    }
    try {
      SpinnerService.spin('mini')
      const vendorData = await this.getVendor(domain) as VendorsSchema
      LoggerService.info(vendorData)
      if (vendorData) {
        this.selectedVendor = vendorData
      }
    } catch (e) {
      MethodsService.toast('error', 'Error fetching vendor ' + domain, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
      this._zone.run(() => {})
    }
  }

  onClusterSelection (clusterId) {
    this.selectedCluster = clusterId
    this.refreshTable!()
  }

  //---------------
  // Data Accessors

  private async updateClusters (options?: FilterArguments): Promise<void> {
    options = options || {
      filter: this.filterValue,
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sort_by: this.currentSortBy,
      sort_direction: this.isSortReversed ? 'asc' : 'desc'
    }
    const clusters = await this._clusters.getAllClusters(options)
    this.activeClusters = clusters.data.filter(cluster => canPerformActionOnInstance(cluster.status))
    this.selectedCluster = this.activeClusters[0].clusterId
  }

  async getDomainBlacklist (options?: FilterArguments): Promise<CountedResultObject<StandardObject>> {
    options = options || {
      filter: this.filterValue,
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sort_by: this.currentSortBy,
      sort_direction: this.isSortReversed ? 'asc' : 'desc'
    }
    return this._intelligenceFeed.getBlacklistDomains(options)
  }

  async getBlacklistedDomain (domain: string): Promise<DomainBlacklistSchema> {
    const response = await this._intelligenceFeed.getBlacklistDomain(domain)
    return response.data as DomainBlacklistSchema
  }

  async getVendors (options?: FilterArguments): Promise<CountedResultObject> {
    options = options || {
      filter: this.filterValue,
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sort_by: this.currentSortBy,
      sort_direction: this.isSortReversed ? 'asc' : 'desc'
    }
    return this._intelligenceFeed.getVendors(options)
  }

  async getVendor (domain: string): Promise<VendorsSchema> {
    const response = await this._intelligenceFeed.getVendor(domain)
    return response.data as VendorsSchema
  }

  async getTrustedDomains (options?: FilterArguments): Promise<CountedResultObject> {
    options = options || {
      filter: this.filterValue,
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sort_by: this.currentSortBy,
      sort_direction: this.isSortReversed ? 'asc' : 'desc'
    }
    return this._intelligenceFeed.getTrustedDomains(options)
  }

  async getTrustedDomain (_id: string): Promise<TrustedDomainsSchema> {
    const response = await this._intelligenceFeed.getTrustedDomain(_id)
    return response.data as TrustedDomainsSchema
  }

  async getVendorHunting (options?: FilterArguments): Promise<CountedResultObject> {
    options = options || {
      filter: this.filterValue,
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sort_by: this.currentSortBy,
      sort_direction: this.isSortReversed ? 'asc' : 'desc'
    }
    return this._intelligenceFeed.getVendorHuntingDomains(options, this.selectedCluster, this.minTimesSeen)
  }

  // Create Update Ops
  async createOrUpdateBlacklistedDomain (domain: string, urls: string[] = [], tags: string[] = [], sources: string[] = [], isApproved: boolean = false) {
    if (!domain) {
      return MethodsService.dialog(
        'Missing or bad Parameters',
        'Missing domain to update or create'
      )
    }

    const website = `http://${domain}`
    urls = urls.length && urls[0].length && urls || [website]
    sources = sources.length && sources[0].length && sources || ['Akamai Internal']

    const blacklistedDomain = { domain, sources, urls, tags, isApproved }

    try {
      SpinnerService.spin('mini')
      const updated = await this._intelligenceFeed.createOrUpdateBlacklistDomain(blacklistedDomain)
      if (updated.success) {
        this.clearState()
        await this.refreshTable()
      } else {
        throw new Error(updated.data.toString())
      }
    } catch (e) {
      MethodsService.toast('error', 'Error updating blacklisted domain', e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }

  }

  async createOrUpdateVendor (name: string, vendorDescription: string, domain: string, website: string, category: string, outboundDomains: string[] = [], tags: string[] = [], isApproved: boolean = false) {
    if (!domain || !name) {
      return MethodsService.dialog(
        'Missing or bad Parameters',
        'Missing domain to update or create'
      )
    }

    // Add domain to outbound domains if empty
    outboundDomains = outboundDomains.length && outboundDomains[0].length && outboundDomains || [domain]
    tags = tags.length && tags[0].length && tags || [category]

    website = website || `http://${domain}`

    const vendor = { name, vendorDescription, domain, outboundDomains, website, category, tags, isApproved }

    try {
      SpinnerService.spin('mini')
      const updated = await this._intelligenceFeed.createOrUpdateVendor(vendor)
      if (updated.success) {
        this.clearState()
        await this.refreshTable()
      } else {
        throw new Error(updated.data.toString())
      }
    } catch (e) {
      MethodsService.toast('error', 'Error updating blacklisted domain', e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }

  }

  async createOrUpdateTrustedDomain (name: string, domains: string[] = [], isApproved: boolean = false) {
    if (!name || !domains) {
      return MethodsService.dialog(
        'Missing or bad Parameters',
        'Missing name or domains to update or create'
      )
    }

    const trustedDomains = { name, domains, isApproved }

    try {
      SpinnerService.spin('mini')
      const updated = await this._intelligenceFeed.createOrUpdateTrustedDomain(trustedDomains)
      if (updated.success) {
        this.clearState()
        await this.refreshTable()
      } else {
        throw new Error(updated.data.toString())
      }
    } catch (e) {
      MethodsService.toast('error', 'Error updating blacklisted domain', e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }

  }

}

