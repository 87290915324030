export class AudienceHijackingRulesProcessor {
  static process<T extends AudienceHijackingRulesSchema> (docOrDocs: T | T[]): T | T[] {
    return Array.isArray(docOrDocs) ?
      docOrDocs.map(AudienceHijackingRulesProcessor.processVersion) as T[] :
      AudienceHijackingRulesProcessor.processVersion(docOrDocs) as T
  }

  private static processVersion<T extends AudienceHijackingRulesSchema> (rules: T): T {
    rules.versions = rules.paddedVersions
      .sort()
      .reverse()
      .map(v => v.split('.').map(i => parseInt(i)).join('.'))
      .map(v => `v${v}`);

    (rules as any)._pattern = JSON.stringify(rules.pattern, null, 2)

    return rules
  }

}
