import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { GlobalState } from '../../../app.state'
import { PiQueuePanelProcessorService } from '../processors/pi-queue-panel-processor.service'

@Injectable()
export class PiQueuePanelService extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async getQueues (options: FilterArguments<ScaledResourceStatusEtlFilters>): Promise<QueuePanelItem[]> {
    const queryString = MethodsService.objectToQueryString(options)
    const response = await this.get(`api/admin_api/queue_panel/queues?${queryString}`)
    return response.success && PiQueuePanelProcessorService.process(response.data) as QueuePanelItem[]
  }

  async peekQueueMessages (instanceId: string, queueName: string): Promise<string[]> {
    const queryString = MethodsService.objectToQueryString({ instanceId, queueName })
    const response = await this.get(`api/admin_api/queue_panel/peek_one?${queryString}`)
    return response.success && response.data
  }

  async clearQueues (queues: Pick<QueuePanelItem, 'queueName' | 'instanceId'>[]): Promise<boolean> {
    const response = await this.post(`api/admin_api/queue_panel/clear_queues`, JSON.stringify({ queues }), true, 'application/json')
    if (response.success) {
      return response.success
    }
    throw Error(response.data)
  }

  async sendCommand (commands: ResourceScalerCommandRequest): Promise<ResultObject<ResourceScalerCommandResponse>> {
    return this.post(`api/admin_api/queue_panel/command`, JSON.stringify(commands), true, 'application/json')
  }

}

