import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { GlobalState } from '../../../app.state'
import { AudienceHijackingVersionsProcessor } from '../processors/audience-hijacking-versions-processor.service'

@Injectable()
export class AudienceHijackingVersionsService extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async getCDNData (): Promise<AHCDNData> {
    const response = await this.get(`api/admin_api/ah_versions/cdn_data`) as ResultObject<AHCDNData>
    return response.data
  }

  async getAllVersions (options: FilterArguments): Promise<CountedResultObject<AudienceHijackingVersionsSchema[]>> {
    const queryString = MethodsService.objectToQueryString(options)
    const response = await this.get(`api/admin_api/ah_versions?${queryString}`) as CountedResultObject<AudienceHijackingVersionsSchema[]>
    return {
      count: response.count,
      success: response.success,
      data: AudienceHijackingVersionsProcessor.process(response.data) as AudienceHijackingVersionsSchema[]
    }
  }

  async setAvailable (hash: number, environment: EligibleEnv) {
    const body = JSON.stringify({ hash, environment })
    const response = await this.post(`api/admin_api/ah_versions/set_available`, body, true, 'application/json')
    return response.success
  }

  async setUnavailable (hash: number, environment: EligibleEnv, comment: string, pages: string[]) {
    const body = JSON.stringify({ hash, environment, comment, pages })
    const response = await this.post(`api/admin_api/ah_versions/set_unavailable`, body, true, 'application/json')
    return response.success
  }

  async setDefault (hash: number, environment: EligibleEnv) {
    const body = JSON.stringify({ hash, environment })
    const response = await this.post(`api/admin_api/ah_versions/set_default`, body, true, 'application/json')
    return response.success
  }

  async setDefaultRollout (hash: number, environment: EligibleEnv) {
    const body = JSON.stringify({ hash, environment })
    const response = await this.post(`api/admin_api/ah_versions/set_default_rollout`, body, true, 'application/json')
    return response.success
  }

  async addPages (hash: number, pages: string[]): Promise<AudienceHijackingVersionsSchema> {
    const body = JSON.stringify({ hash, pages })
    const response = await this.post(`api/admin_api/ah_versions/add_pages`, body, true, 'application/json')
    return AudienceHijackingVersionsProcessor.process(response.data as AudienceHijackingVersionsSchema) as AudienceHijackingVersionsSchema
  }

  async removePages (hash: number, pages: string[]): Promise<AudienceHijackingVersionsSchema> {
    const body = JSON.stringify({ hash, pages })
    const response = await this.post(`api/admin_api/ah_versions/remove_pages`, body, true, 'application/json')
    return AudienceHijackingVersionsProcessor.process(response.data as AudienceHijackingVersionsSchema) as AudienceHijackingVersionsSchema
  }

}

