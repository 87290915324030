import { Component, Input, NgZone, OnInit } from '@angular/core'
import { PIInstancesService } from '../../../../services/api/services/pi-instances.service'
import { GlobalState } from '../../../../app.state'
import { PiEventConsoleService } from '../../../../services/api/services/pi-event-console.service'
import { MethodsService } from '../../../../services/methods/methods.service'

@Component({
  selector: 'event-group-header',
  templateUrl: './event-group-header.component.html',
  styleUrls: ['./event-group-header.component.scss'],
})
export class EventGroupHeaderComponent implements OnInit {

  @Input() tenantId: string
  @Input() eventGroupId: string
  @Input() currentTime: { text: string, absTime: Function, time: Function }
  @Input() metadata: ExtendedEventGroupSchema
  stringifiedMetadata: string

  readonly title = ''

  severityColor: string

  //state
  _isRefreshing: boolean = false
  requestCollapsed: boolean = true

  //imports
  cxDateFormat = MethodsService.cxDateFormat
  upperCasedString = MethodsService.upperCasedString
  numberToSuffix = MethodsService.numberToSuffix
  integerWithCommas = MethodsService.integerWithCommas
  joinWithAnd = MethodsService.joinWithAnd

  constructor (private _api: PiEventConsoleService, private _clusters: PIInstancesService, private _zone: NgZone, private _state: GlobalState) {
  }

  ngOnInit (): void {
    this.stringifiedMetadata = JSON.stringify(this.metadata, null, 2)
    this.setSeverity()
    this.ref_hljs()
  }

  setSeverity () {
    switch (this.metadata.ui.severity) {
      case 'HIGH':
      case 'CRITICAL':
        this.severityColor = 'danger'
        break
      case 'MEDIUM':
        this.severityColor = 'warning'
        break
      case 'LOW':
      case 'NOTE':
        this.severityColor = 'info'
        break
    }
  }

  ref_hljs () {
    setTimeout(() => {
      this._zone.run(() => {})
      document.querySelectorAll('pre code').forEach((block) => hljs.highlightBlock(block))
    }, 10)

  }

}
