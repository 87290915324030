import { AfterViewInit, Component, Input, OnInit } from '@angular/core'
import { DataService } from '../../../services/data/data.service'
import { MethodsService } from '../../../services/methods/methods.service'
import { CxConsts } from '../../typings/chameleonx.typings'

@Component({
  selector: 'cx-tag',
  templateUrl: './cx-tag.component.html',
  styleUrls: ['./cx-tag.component.scss']
})
export class CxTagComponent implements OnInit, AfterViewInit {
  @Input() text: string

  @Input() color?: string = '#0c70bc'

  @Input() class?: string = ''

  @Input() iconClass?: string = CxConsts.IconClasses.TAG

  @Input() clickFunction?: (text: string) => any

  @Input() hoverColor?: string

  @Input() iconEnabled?: boolean = true

  private element: HTMLLabelElement
  private iconElement: HTMLElement

  isHovering: boolean = false

  readonly uniqueId = MethodsService.generateUniqueID('letters')
  readonly uniqueIconId = MethodsService.generateUniqueID('letters')

  constructor () {

  }

  ngOnInit (): void {
    if (DataService.colors.cx[this.color]) {
      this.color = DataService.colors.cx[this.color]
    }

    if (this.hoverColor && DataService.colors.cx[this.hoverColor]) {
      this.hoverColor = DataService.colors.cx[this.hoverColor]
    }
  }

  ngAfterViewInit (): void {
    this.element = document.getElementById(this.uniqueId) as HTMLLabelElement
    this.iconElement = document.getElementById(this.uniqueIconId) as HTMLElement
    if (this.clickFunction || this.hoverColor) {
      const hoverColor = this.hoverColor || MethodsService.hexToRGBA(this.color, 0.8)
      const onClick = `${this.clickFunction ? '#' + this.uniqueId + '.cx-tag-click {cursor: pointer;}' : ''}`
      const onHoverLabel = `#${this.uniqueId}.cx-tag-hover, #${this.uniqueIconId}.cx-tag-hover {transition: color 0.25s ease;color: ${hoverColor}!important; border: 1px solid ${hoverColor}!important;}`
      const onHoverIcon = `#${this.uniqueIconId}.cx-tag-hover-icon {transition: color 0.25s ease;color: ${hoverColor}!important;}`
      const style = document.createElement('style')
      style.innerText = onClick + onHoverLabel + onHoverIcon
      this.element.appendChild(style)
      this.element.addEventListener('mouseenter', () => this.isHovering = true)
      this.element.addEventListener('mouseleave', () => this.isHovering = false)
    }
  }

  clickEvent (): void {
    if (this.clickFunction) {
      this.clickFunction(this.text)
    }
  }

}
