import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { LoggerService } from '../../services/logger/logger.service'
import { MethodsService } from '../../services/methods/methods.service'
import { UserDataService } from '../../services/user-data/user-data.service'
import { GlobalNotification, GlobalState } from '../../app.state'
import { PiQueuePanelService } from '../../services/api/services/pi-queue-panel.service'

@Component({
  selector: '',
  templateUrl: './pi-queue-panel.component.html',
  styleUrls: ['./pi-queue-panel.component.scss']
})
export class PIQueuePanelComponent<T extends QueuePanelItem = QueuePanelItem> extends TableWithFilters implements OnInit, OnDestroy {
  readonly title = 'PI Queue Panel'
  readonly paginationName = 'Queues'

  filterValue: string = ''

  queues: T[] = []
  filtered: T[] = []

  filteredTotalItems: number = 0

  //modals
  selectedQueue: T
  selectedQueueMessages: QueueMessagePeek[]

  //table
  readonly tableHeaders: TableHeader[]
  currentSortBy: string

  //filters
  availableDeployments: { id: string, text: string }[] = []
  currentDeployments: { id: string, text: string }[] = []

  availableInstances: { id: string, text: string }[] = []
  currentInstances: { id: string, text: string }[] = []

  availableSources: { id: string, text: string }[] = [
    { id: 'both', text: 'Both' },
    { id: 'tracked', text: 'Tracked' },
    { id: 'untracked', text: 'Untracked' }
  ]
  currentSources: { id: string, text: string }[] = [this.availableSources[1]]

  //@Override
  readonly timeFilter = MethodsService.timeObject().slice(0, 6)
  currentTime = this.timeFilter[1]

  _isRefreshing: boolean = false

  textToClip = MethodsService.copyToClipboard
  integerWithCommas = MethodsService.integerWithCommas

  private _debounce: void | number
  private debounce_timeout: number = 200

  constructor (
    private _userData: UserDataService,
    private _queues: PiQueuePanelService,
    private _zone: NgZone,
    private _state: GlobalState,
  ) {
    super()
    this.currentItemsPerPage = this.itemsPerPageFilter[1]
    this.tableHeaders = [
      //@formatter:off
      // { /*width: '4', */  name: '',                         sortable: false,    field: ''           },
      { /*width: '12',*/  name: 'Last Sample',              sortable: true,     field: 'updatedAt'  },
      { /*width: '12',*/  name: 'Instance ID',              sortable: true,     field: 'instanceId' },
      { /*width: '12',*/  name: 'Namespace',                sortable: true,     field: 'namespace'  },
      { /*width: '12',*/  name: 'Deployment',               sortable: true,     field: 'deployment' },
      { /*width: '12',*/  name: 'Queue',                    sortable: true,     field: 'queueName'  },
      { /*width: '12',*/  name: 'Last 24 Hours',            sortable: false,    field: '$last'      },
      { /*width: '12',*/  name: 'Queue Size',               sortable: true,     field: 'count'      },
      { /*width: '12',*/  name: 'Pods',                     sortable: true,     field: 'pods'       },
      { /*width: '12',*/  name: 'Actions',                  sortable: false,                        },
      //@formatter:on
    ].filter(x => !!x)

    this.currentSortBy = this.tableHeaders.find(f => f.field == 'count')!.field
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    if (!this.selectedQueueMessages) {
      return
    }
    if (key === 'Escape') {
      this.clearSelections()
    }
  }

  ngOnDestroy (): void {
    window.removeEventListener('keyup', this.registerModalListeners.bind(this))
  }

  onFilterChange (item: any, useSort = false) {
    if (this._debounce) {
      this._debounce = clearTimeout(this._debounce)
    }
    this._debounce = setTimeout(() => useSort ? this.sortTable() : this.refreshTable(), this.debounce_timeout) as any as number
  }

  ngOnInit (): void {
    window.addEventListener('keyup', this.registerModalListeners.bind(this))
    this.refreshTable!()
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  async clearQueues (queue?: T) {
    const title = `Clear Queues Confirmation`
    const content = `Clear ${this.selectedTableItems.size || `${queue.queueName} @ ${queue.instanceId}`} Queue${this.selectedTableItems.size > 1 ? 's' : ''} ?`
    const deleteConfirmationText = 'Clear'
    if (await MethodsService.confirm(title, content, deleteConfirmationText)) {

      SpinnerService.spin('mini')

      try {
        const queues: Pick<QueuePanelItem, 'queueName' | 'instanceId'>[] = []

        if (queue) {
          queues.push({
            queueName: queue.queueName,
            instanceId: queue.instanceId
          })
        } else {
          this.selectedTableItems.forEach(_id => {
            const [instanceId, queueName] = _id.split('#')
            queues.push({ instanceId, queueName })
          })
        }

        const success = await this._queues.clearQueues(queues)
        if (success) {
          this.selectedTableItems.clear()
          await this.refreshTable()
        }
      } catch (e) {
        LoggerService.error(e)
      }
      SpinnerService.stop('mini')
    }
  }

  async peekMessages (queue: T) {
    SpinnerService.spin('mini')
    try {
      const messages = await this._queues.peekQueueMessages(queue.instanceId, queue.queueName)
      if (messages?.length) {
        this.selectedQueue = queue
        this.selectedQueueMessages = this.parseMessages(messages)
      } else {
        MethodsService.dialog('Queue is empty', `No messages available <i class="mdi mdi-emoticon-sad font-size-24"></i>`)
      }
    } catch (e) {
      LoggerService.error(e)
    }
    SpinnerService.stop('mini')
    this.ref_hljs(this._zone)
  }

  async resizeCommand (queue: T) {
    const prompt = await MethodsService.prompt(`Rescale Deployment`,
      `${queue.namespace}.${queue.deployment} @ ${queue.instanceId}`,
      `${queue.pods}`,
      { title: 'Input Error', message: 'Must be a positive Integer larger than 1"' },
      'Rescale',
      (str: string) => /\d+/.test(str) && parseFloat(str) == parseInt(str) && parseInt(str) >= 2)

    if (!prompt) {
      return
    }

    const command: ResourceScalerCommand = {
      type: RESOURCE_SCALER_COMMAND.RESIZE,
      data: {
        namespace: queue.namespace,
        deployment: queue.deployment,
        replicas: parseInt(prompt.input)
      }
    }

    try {
      SpinnerService.spin('mini')
      const response = await this._queues.sendCommand({
        commands: [{ instanceId: queue.instanceId, command }]
      })
      if (response.data?.failed.length) {
        MethodsService.toast(
          'error',
          'Failed sending command',
          response.data.failed.map(e => e.error).join('\n')
        )
      } else {
        MethodsService.toast(
          'success',
          'Command queued successfully!'
        )
      }
    } catch (e) {
      LoggerService.error(e)
    }
    SpinnerService.stop('mini')

  }

  async rollingUpdateCommand (queue: T) {
    const confirm = await MethodsService.confirm(`Rollout Deployment`,
      `This will start rolling update for<br>${queue.namespace}.${queue.deployment} @ ${queue.instanceId}<br>Continue?`,
      `ROLLOUT`
    )

    if (!confirm) {
      return
    }

    const command: ResourceScalerCommand = {
      type: RESOURCE_SCALER_COMMAND.ROLLING_UPDATE,
      data: {
        namespace: queue.namespace,
        deployment: queue.deployment,
      }
    }

    try {
      SpinnerService.spin('mini')
      const response = await this._queues.sendCommand({
        commands: [{ instanceId: queue.instanceId, command }]
      })
      if (response.data?.failed.length) {
        MethodsService.toast(
          'error',
          'Failed sending command',
          response.data.failed.map(e => e.error).join('\n')
        )
      } else {
        MethodsService.toast(
          'success',
          'Command queued successfully!'
        )
      }
    } catch (e) {
      LoggerService.error(e)
    }
    SpinnerService.stop('mini')

  }

  sortTable () {
    this.filtered = [...this.queues]

    if (this.currentSources[0].id != 'both') {
      this.filtered = this.filtered.filter(v => this.currentSources[0].id == 'tracked' ? v.isTracked : !v.isTracked)
    }

    this.filtered.sort((a, b) => {
      const _a = a[this.currentSortBy]
      const _b = b[this.currentSortBy]

      if (typeof _a == 'number' || typeof _b == 'number') {
        if (typeof _a == 'number' && typeof _b == 'number') {
          return this.isSortReversed ?
            _a - _b :
            _b - _a
        }
        if (typeof _a == 'number') {
          return this.isSortReversed ? 1 : -1
        }
        if (typeof _b == 'number') {
          return this.isSortReversed ? -1 : 1
        }

      }

      if (_a instanceof Date || _b instanceof Date) {
        if (_a instanceof Date && _b instanceof Date) {
          return this.isSortReversed ?
            _a.getTime() - _b.getTime() :
            _b.getTime() - _a.getTime()
        }
        if (_a instanceof Date) {
          return this.isSortReversed ? 1 : -1
        }
        if (_b instanceof Date) {
          return this.isSortReversed ? -1 : 1
        }
      }

      return this.isSortReversed ?
        _a > _b ? 1 : _a < _b ? -1 : 0 :
        _a > _b ? -1 : _a < _b ? 1 : 0
    })

    if (this.filterValue) {
      this.filtered = this.filtered.filter(v =>
        v.queueName.includes(this.filterValue) ||
        v.instanceId.includes(this.filterValue) ||
        v.deployment?.includes(this.filterValue)
      )
    }

    const deps = this.currentDeployments.map(d => d.id)
    const instances = this.currentInstances.map(i => i.id)

    if (this.currentDeployments.length && this.currentDeployments.length != this.availableDeployments.length) {
      this.filtered = this.filtered.filter(v => deps.includes(v.deployment))
    }

    if (this.currentInstances.length && this.currentInstances.length != this.availableInstances.length) {
      this.filtered = this.filtered.filter(v => instances.includes(v.instanceId))
    }

    this.numOfAvailableDocs = this.filtered.length

    this.filtered = this.filtered.slice((this.currentPage - 1) * this.currentItemsPerPage, this.currentItemsPerPage * this.currentPage)

    this.tableHeaders.find(t => t.field == '$last')!.name = this.currentTime.text

    this.ref_hljs(this._zone)
  }

  async refreshTable (background: boolean = false, ignoreSelected: boolean = false): Promise<void> {
    if (this._isRefreshing || (!ignoreSelected && this.selectedQueueMessages)) {
      return
    }
    this._isRefreshing = true
    try {
      if (!background) {
        this.queues = []
        this.clearSelections()
        SpinnerService.spin('mini')
      }
      this.queues = await this.getAllQueues()

      this.availableInstances = [...new Set(this.queues.map(q => q.instanceId))].sort()
        .map(i => ({ id: i, text: i }))
      this.availableDeployments = [...new Set(this.queues.map(q => q.deployment).filter(Boolean))].sort()
        .map(d => ({ id: d, text: d }))

    } catch (e) {
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this.sortTable()
      this.ref_hljs(this._zone)
      this._isRefreshing = false
    }

  }

  private clearSelections () {
    this.selectedQueue = undefined
    this.selectedQueueMessages = undefined
  }

  private async getAllQueues (): Promise<T[]> {
    const options: FilterArguments<ScaledResourceStatusEtlFilters> = {
      fromDate: this.currentTime.time()
    }

    return await this._queues.getQueues(options) as any as T[]
  }

  private parseMessages (messages: string[]): QueueMessagePeek[] {
    const res: QueueMessagePeek[] = []
    for (const message of messages) {
      const data = MethodsService.htmlDecode(message)
      try {
        const parsed = JSON.parse(data)
        res.push({ type: 'json', data: JSON.stringify(parsed, null, 2) })
      } catch {
        res.push({ type: 'text', data })
      }
    }

    return res
  }

}
