import { Component, OnDestroy, OnInit } from '@angular/core'
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap'
import { SidebarService } from '../sidebar/sidebar.service'
import { UserDataService } from '../../services/user-data/user-data.service'
import { AuthService } from '../../services/auth/auth.service'
import { Router } from '@angular/router'
import { CxConsts } from '../../shared/typings/chameleonx.typings'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { GlobalNotification, GlobalState } from '../../app.state'
import { LoggerService } from '../../services/logger/logger.service'
import { MethodsService } from '../../services/methods/methods.service'
import { dummyRoute } from '../../app-routing.module'
import { ENV_TO_FREE_STRING } from '../../services/api/processors/pi-instances-processor.service'

type BackgroundRefresh = {
  ms: number
  text: string
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit, OnDestroy {
  isSidebarCollapsed: boolean

  readonly _isLocalhost = location.hostname == 'localhost'

  readonly _routes = MethodsService.enumToObj(CxConsts.cxRoutingTable) as unknown as CxConsts.cxRoutingTable

  _bgRefreshTimes: BackgroundRefresh[] = [
    //@formatter:off
    { ms: -1,               text: 'No Refresh' },
    { ms: 1000 * 5,         text: 'Every 5 Seconds' },
    { ms: 1000 * 10,        text: 'Every 10 Seconds' },
    { ms: 1000 * 30,        text: 'Every 30 Seconds' },
    { ms: 1000 * 60 * 1,    text: 'Every 1 Minute' },
    { ms: 1000 * 60 * 5,    text: 'Every 5 Minutes' }
    //@formatter:on
  ]

  private readonly BG_REFRESH_STORAGE_KEY = '__cxbgr' + ENV[0]

  private _currentBgRefresh: BackgroundRefresh

  private _bgRefreshInterval?: number

  public readonly panelText = `IBP - Admin Panel <span class="text-uppercase bold-text">${this._isLocalhost ? 'local' : ENV == CxEnvironment.PRODUCTION ? '' : ENV_TO_FREE_STRING[ENV]}</span>`

  constructor (private _state: GlobalState, public _user: UserDataService, private _auth: AuthService, public _router: Router, private _sidebar: SidebarService) {
    this.isSidebarCollapsed = this._sidebar.isCollapsed
    this._state.subscribe(GlobalNotification.SIDEBAR_COLLAPSED, collapsed => this.isSidebarCollapsed = collapsed)
    this.loadBgRefresh()
  }

  ngOnInit () {
    this._setBgInterval()
    this.changeNavColorBasedOnEnv(ENV)
  }

  ngOnDestroy (): void {
    clearInterval(this._bgRefreshInterval)
  }

  private _setBgInterval () {
    clearInterval(this._bgRefreshInterval)
    if (this._currentBgRefresh.ms > -1) {
      this._bgRefreshInterval = setInterval(
        () => {
          if (!document.hidden) {
            this._state.notify(GlobalNotification.BACKGROUND_REFRESH, null)
          }
        },
        this._currentBgRefresh.ms) as any as number
    }
  }

  private loadBgRefresh () {
    const storageItem = localStorage && localStorage.getItem(this.BG_REFRESH_STORAGE_KEY)
    this._currentBgRefresh = storageItem && JSON.parse(atob(storageItem)) || this._bgRefreshTimes[0]
  }

  saveBgRefreshTime (item: BackgroundRefresh) {
    if (item.ms == this._currentBgRefresh.ms) {
      return
    }
    this._currentBgRefresh = item
    if (localStorage) {
      localStorage.setItem(this.BG_REFRESH_STORAGE_KEY, btoa(JSON.stringify(item)))
    }
    this._setBgInterval()
  }

  public changeNavColorBasedOnEnv (env: CxEnvironment) {
    const localOverride = 'navbar-local-override'
    const devOverride = 'navbar-dev-override'
    const stagingOverride = 'navbar-staging-override'

    // Remove existing
    const navbarWrapper = document.querySelector('.navbar')
    const navbarMenuWrapper = document.querySelector('.navbar-brand-wrapper')
    navbarWrapper.classList.remove(localOverride, devOverride, stagingOverride)
    navbarMenuWrapper.classList.remove(localOverride, devOverride, stagingOverride)

    switch (true) {
      case !!this._isLocalhost: {
        navbarWrapper.classList.add(localOverride)
        navbarMenuWrapper.classList.add(localOverride)
        break
      }
      case env === CxEnvironment.DEV:
        navbarWrapper.classList.add(devOverride)
        navbarMenuWrapper.classList.add(devOverride)
        break
      case env === CxEnvironment.STAGING:
        navbarWrapper.classList.add(stagingOverride)
        navbarMenuWrapper.classList.add(stagingOverride)
        break
    }
  }

  public toggle () {
    this._sidebar.toggleSidebar()
  }

  public async signOut () {
    try {
      SpinnerService.spin('full')
      await this._auth.signOut()
      await this._router.navigate([CxConsts.cxRoutingTable.LOGIN])
    } catch (e) {
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('full')
    }
  }

  private async softRefresh () {
    const route = this._router.url
    await this._router.navigate([dummyRoute])
    await this._router.navigate([route])
  }

}
