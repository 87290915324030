import { AfterContentInit, Component, Input } from '@angular/core'

function atlasDashboardAppLink (clusterId: string, groupId: string): string {
  return `https://cloud.mongodb.com/v2/${groupId}#/clusters/detail/${clusterId}`
}

@Component({
  selector: 'pi-database-id',
  styles: [` i {
      color: #00684A;
  }`],
  templateUrl: './pi-database-id.component.html'
})
export class PIDatabaseIdComponent implements AfterContentInit {
  @Input() public id: string
  @Input() public group: string
  @Input() public name?: string

  atlasLink: string

  ngAfterContentInit () {
    this.atlasLink = atlasDashboardAppLink(this.id, this.group)
  }

}
