export const AZURE_REGIONS: Record<string, string> = {
  eastasia: 'CN',
  southeastasia: 'MY',
  centralus: 'US',
  eastus: 'US',
  eastus2: 'US',
  westus: 'US',
  northcentralus: 'US',
  southcentralus: 'US',
  northeurope: 'IE',
  westeurope: 'DE',
  japanwest: 'JP',
  japaneast: 'JP',
  brazilsouth: 'BR',
  australiaeast: 'AU',
  australiasoutheast: 'AU',
  southindia: 'IN',
  centralindia: 'IN',
  westindia: 'IN',
  canadacentral: 'CA',
  canadaeast: 'CA',
  uksouth: 'GB',
  ukwest: 'GB',
  westcentralus: 'US',
  westus2: 'US',
  koreacentral: 'KR',
  koreasouth: 'KR',
  francecentral: 'FR',
  francesouth: 'FR',
  australiacentral: 'AU',
  australiacentral2: 'AU',
  uaecentral: 'AU',
  uaenorth: 'AU',
  southafricanorth: 'ZA',
  southafricawest: 'ZA',
  switzerlandnorth: 'CH',
  switzerlandwest: 'CH',
  germanynorth: 'DE',
  germanywestcentral: 'DE',
  norwaywest: 'NO',
  norwayeast: 'NO'
}
