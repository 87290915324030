export class PiMongoDBAnalyticsProcessorService {
  static processCollections<T extends ExtendedCollectionStatsTimeSeries> (data: T[]): T[] {
    return data.map(this.parseCollection)
  }

  static processCollectionsIndexes<T extends ExtendedCollectionIndexStatsTimeSeries> (data: T[]): T[] {
    return data.map(this.parseIndexCollection)
  }

  private static parseCollection<T extends ExtendedCollectionStatsTimeSeries> (data: T): T {
    return data
  }

  private static parseIndexCollection<T extends ExtendedCollectionIndexStatsTimeSeries> (data: T): T {
    if (data.indexOptions) {
      data._options_text = Object.entries(data.indexOptions).map(([key, value]) => `${key}: ${typeof value == 'object' ? beautifyObjectToString(value) : value.toString()}`)
        .sort()
        .join('\n')
    }

    return data
  }

}

function beautifyObjectToString (item: any) {
  return Object.entries(item).map(([key, value]) => `${key}: ${JSON.stringify(value)}`).sort().join('\n')
}
