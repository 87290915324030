import { AfterContentInit, Component, Input } from '@angular/core'
import { GIT_REPO } from '../../../services/data/data.service'

@Component({
  selector: 'pi-version',
  templateUrl: './pi-version.component.html',
})
export class PIVersionComponent implements AfterContentInit {
  @Input() public version: string
  @Input() public src?: string
  @Input() public repo?: string
  @Input() public fallbackText = '-'

  text: string

  ngAfterContentInit () {
    if (!this.version) {
      return this.text = this.fallbackText
    }
    this.version = this.version.trim()
    if (this.repo) {
      this.src = GIT_REPO(this.repo, this.version.startsWith('v') ? this.version : `v${this.version}`)
    }
    this.text = this.version.replace(/^v/, '')
  }

}
