import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { GlobalState } from '../../../app.state'
import { PIDatabasesProcessor } from '../processors/pi-databases-processor.service'

@Injectable()
export class PIDatabasesService extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async getJobInfo (podName: string): Promise<{ logs: string, status: DB_MIGRATION_STATUS }> {
    const data = (
      await this.get(`api/admin_api/database/info/${podName}`) as ResultObject<{ logs: string, status: DB_MIGRATION_STATUS }>
    ).data

    return {
      logs: data.logs,
      status: data.status
    }
  }

  async getDatabase (id: string, groupId: string, clusterName: string): Promise<GetDatabaseClusterResponse> {
    const response = await this.get(`api/admin_api/database/${id}/${groupId}/${clusterName}`) as ResultObject<GetDatabaseClusterResponse>
    return PIDatabasesProcessor.processOne(response.data) as GetDatabaseClusterResponse
  }

  async getAllDatabases (options: SortingArguments): Promise<IfDefined<DatabaseClusterData[]>> {
    const queryString = MethodsService.objectToQueryString(options)
    const response = await this.get(`api/admin_api/databases?${queryString}`)
    return response.success && PIDatabasesProcessor.processAll(response.data as DatabaseClusterData[]) as DatabaseClusterData[]
  }

  async createJob (body: MigrateCustomerArguments): Promise<boolean> {
    const response = await this.post(`api/admin_api/database/job/create`, JSON.stringify(body), true, 'application/json')
    return response.success
  }

  async deleteJob (jobName: string): Promise<boolean> {
    const response = await this.post(`api/admin_api/database/job/${jobName}`, null, true, 'application/json')
    return response.success
  }

}

