import { MethodsService } from '../../methods/methods.service'

const ENVS: EligibleEnv[] = [CxEnvironment.DEV, CxEnvironment.STAGING, CxEnvironment.PRODUCTION]

export class AgentVersionsProcessor {
  static process<T extends AgentVersionsSchema> (docOrDocs: T | T[]): T | T[] {
    return Array.isArray(docOrDocs) ?
      docOrDocs.map(AgentVersionsProcessor.processVersion) as T[] :
      AgentVersionsProcessor.processVersion(docOrDocs) as T
  }

  private static processVersion<T extends AgentVersionsSchema> (agentVersion: T): T {
    //@ts-ignore
    agentVersion._git = <AgentGitInfo>{
      pages: [...new Set([...(agentVersion.prod?.git?.pages.length && agentVersion.prod?.git?.pages || []), ...(agentVersion.staging?.git?.pages.length && agentVersion.staging?.git?.pages || []), ...(agentVersion.dev?.git?.pages.length && agentVersion.dev?.git?.pages || [])])].sort(),
      branch: [agentVersion.prod?.git?.branch, agentVersion.staging?.git?.branch, agentVersion.dev?.git?.branch].filter(x => !!x).join(' & '),
      commitUrl: agentVersion.prod?.git?.commitUrl || agentVersion.staging?.git?.commitUrl || agentVersion.dev?.git?.commitUrl,
      repository: agentVersion.prod?.git?.repository || agentVersion.staging?.git?.repository || agentVersion.dev?.git?.repository,
      commitMessage: (agentVersion.prod?.git?.commitMessage || agentVersion.staging?.git?.commitMessage || agentVersion.dev?.git?.commitMessage).replace(/([\w\s]+PAGE-\d+)/gm, `\n$1`),
    }

    ENVS.forEach(env => {
      if (agentVersion[env] && agentVersion[env].disapproved) {
        (agentVersion[env] as any)._disapproved = get_disapproved_message(agentVersion[env].disapproved)
      }
    })

    //@ts-ignore
    agentVersion._versions = agentVersion.modules
      .map(({ module, version }) => `${module}: ${version}`)
      .sort()
      .join('\n')

    //@ts-ignore
    agentVersion._action = ENVS.some(env => agentVersion[env]?.available && !agentVersion[env].isDefault)

    return agentVersion
  }

}

export function get_disapproved_message (data: DisapproveMetadata): string {
  let text = 'Disapproved:\n'
  const { timestamp, comment, pages, user } = data
  if (timestamp) {
    text += `at ${MethodsService.cxDateFormat(data.timestamp).dts}\n`
  }
  if (user) {
    const { email, name } = data.user
    text += `By ${name} (${email})\n`
  }
  if (comment) {
    text += `Reason: ${comment}\n`
  }
  if (pages && pages.length) {
    text += `Pages: ${pages.map(p => `PAGE-${p}`).join(', ')}`
  }

  text += '\nClick to Activate'

  return text
}
