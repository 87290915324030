import { COUNTRY_CODES_TO_FULL } from '../../../shared/components/cx-world-map/country-codes'
import { CxConsts } from '../../../shared/typings/chameleonx.typings'

export class PiEventConsoleProcessorService {

  static processEventGroup<T extends ExtendedEventGroupSchema> (data: T[]): T[] {
    return data.map(this.parseEventGroup)
  }

  static processEventGroupMetadata<T extends ExtendedEventGroupSchema> (data: T): T {
    data._status = data.ui?.status || '-'

    if (data.source?.score) {
      data._source_risk = getRisk(data.source?.score)
      data._source_risk_color = data._source_risk == 'LOW' ? 'green' : data._source_risk == 'MEDIUM' ? 'warning' : 'danger'
    }

    if (data.destination.score) {
      data._destination_risk = getRisk(data.destination?.score)
      data._destination_risk_color = data._destination_risk == 'LOW' ? 'green' : data._destination_risk == 'MEDIUM' ? 'warning' : 'danger'
    }

    if (data.source?.scoreDetails) {
      data._sourceVendorScoreDetails = Object.entries(data.source.scoreDetails)
        .filter(([key, value]) => Boolean(value))
        .map(([key, value]) => {
          return {
            info: DOMAIN_SCORE_INFO[key],
            severity: value.toLowerCase() == 'ok' ? 'GOOD' : value?.toUpperCase(),
            badge: getVendorDetailsBadge(value),
          }
        })
    }

    if (data.destination?.scoreDetails) {
      data._destinationVendorScoreDetails = Object.entries(data.destination.scoreDetails)
        .filter(([key, value]) => Boolean(value))
        .map(([key, value]) => {
          return {
            info: DOMAIN_SCORE_INFO[key],
            severity: value.toLowerCase() == 'ok' ? 'GOOD' : value?.toUpperCase(),
            badge: getVendorDetailsBadge(value),
          }
        })
    }

    data.impactedData?.fields?.sort((a, b) => b.pages - a.pages)

    const { isDefect, defectsInfo } = findDefects(data)
    if (isDefect) {
      data._isDefect = true
      data._numOfDefects = defectsInfo.length
      data._defectsInfo = defectsInfo.join('\n')
    }

    return data
  }

  static processEventGroupPages<T extends SingleEventGroupPage> (data: T[]): T[] {
    return data.map(this.processEventGroupPage)
  }

  static processEventGroupScriptDestinations<T extends EventGroupScriptEndpointObject> (data: T[]): T[] {
    return data.map(this.processEventGroupScriptDestination)
  }

  private static processEventGroupScriptDestination<T extends EventGroupScriptEndpointObject> (data: T): T {
    return data
  }

  static processEventGroupBrowsersUniqueDevices (data: EventGroupBrowsersResult): EventGroupBrowsersResult['_uniqueDevices'] {
    const devicesObject: Record<string, { icon: string, browsers: Set<string> }> = {}
    data.browsers.forEach(({ device, browser }) => {
      devicesObject[device] = devicesObject[device] || {
        icon: getDeviceIcon(device as UserAgentDevice),
        browsers: new Set()
      }
      devicesObject[device].browsers.add(browser)
    })

    data._uniqueDevices = Object.entries(devicesObject).map(([device, value]) => ({
      device, count: value.browsers.size, icon: value.icon, browsers: Array.from(value.browsers)
    }))

    const otherDevices = data._uniqueDevices.filter(device => !device.icon || device.icon.includes('washing-machine'))
    const otherDevicesBrowsers = otherDevices.reduce((acc, device) => {
      device.browsers.forEach(b => acc.add(b))
      return acc
    }, new Set<string>())

    data._uniqueDevices = data._uniqueDevices.filter(device => device.icon && !device.icon.includes('washing-machine')).concat({
      icon: 'mdi mdi-washing-machine',
      device: 'Others',
      browsers: Array.from(otherDevicesBrowsers).sort(),
      count: otherDevicesBrowsers.size
    })

    return data._uniqueDevices
  }

  static processEventGroupBeacons<T extends AffectedBeaconsResult> (data: T): T {
    data.recentBeacons.forEach(beacon => {
      beacon._device_icon = getDeviceIcon(beacon.device as UserAgentDevice)
      beacon._browser_icon = getBrowserIcon(beacon.browser)
      beacon._country = COUNTRY_CODES_TO_FULL[beacon.countryCode] || 'Unknown'
    })
    data._ts = data.timeSeries.map(({ timestamp, beacons }) => ({ timestamp: new Date(timestamp), value: beacons }))
    return data
  }

  static processEventGroupScripts<T extends ExtendedEventGroupScriptsSchema> (data: T[]): T[] {
    return data.map(this.processEventGroupScript)
  }

  static processEventGroupCountries<T extends CountryItem> (data: T[]): T[] {
    return data.map(this.processEventGroupCountry)
  }

  private static processEventGroupPage<T extends SingleEventGroupPage> (data: T): T {
    data._isRead = !!data.fields?.length
    data._isSend = (data.data || []).some(f => f.scripts.some(a => a.reflectedCount > 0))
    return data
  }

  static processEventGroupBrowsers<T extends BrowserDeviceItem> (data: T[]): T[] {
    return data.map(this.processEventGroupBrowser)
  }

  private static processEventGroupCountry<T extends CountryItem> (data: T): T {
    data._country = COUNTRY_CODES_TO_FULL[data.countryCode] || 'Unknown'
    return data
  }

  private static processEventGroupBrowser<T extends BrowserDeviceItem> (data: T): T {
    data._device_icon = getDeviceIcon(data.device as UserAgentDevice)
    data._browser_icon = getBrowserIcon(data.browser)
    return data
  }

  private static parseEventGroup<T extends ExtendedEventGroupSchema> (data: T): T {
    data._status = data.ui?.status || '-'

    if (data.source?.score && data.source.score != -1) {
      data._source_risk = getRisk(data.source?.score)
      data._source_risk_color = data._source_risk == 'LOW' ? 'cx-green' : data._source_risk == 'MEDIUM' ? 'cx-yellow' : 'cx-maroon'
    }

    if (data.destination.score && data.destination.score != -1) {
      data._destination_risk = getRisk(data.destination?.score)
      data._destination_risk_color = data._destination_risk == 'LOW' ? 'cx-green' : data._destination_risk == 'MEDIUM' ? 'cx-yellow' : 'cx-maroon'
    }

    const { isDefect, defectsInfo } = findDefects(data)
    if (isDefect) {
      data._isDefect = true
      data._numOfDefects = defectsInfo.length
      data._defectsInfo = defectsInfo.join('\n')
    }

    return data
  }

  private static processEventGroupScript<T extends ExtendedEventGroupScriptsSchema> (data: T): T {
    if (data.explainability) {
      data._explainabilityString = JSON.stringify(data.explainability.filter(x => x.flags?.length && !!x.metadata), null, 2)
    }
    if (data.callChain) {
      data._callChainString = JSON.stringify(data.callChain, null, 2)
    }

    return data
  }

}

function getRisk (risk: number): 'LOW' | 'MEDIUM' | 'HIGH' {
  if (risk <= 40) {
    return 'LOW'
  } else if (risk <= 75) {
    return 'MEDIUM'
  } else {
    return 'HIGH'
  }
}

function getDeviceIcon (device: UserAgentDevice): string {
  switch (device) {
    case 'desktop':
      return 'fa fa-desktop'
    case 'tablet':
      return 'fa fa-tablet'
    case 'smartphone':
      return 'mdi mdi-cellphone font-size-14'
    case 'television':
      return 'fa fa-television'
  }
  return 'mdi mdi-washing-machine'
}

function getBrowserIcon (browser: string): string {
  const b = browser.toLowerCase()
  switch (true) {
    case b.includes('chrome'):
      return 'fa fa-chrome'
    case b.includes('firefox'):
      return 'fa fa-firefox'
    case b.includes('safari'):
      return 'fa fa-safari'
    case b.includes('edge'):
      return 'fa fa-edge'
    case b.includes('opera'):
      return 'fa fa-opera'
  }
  return 'fa fa-globe'
}

function getVendorDetailsBadge (value: string): string {
  switch (value as EventGroupAPI.DomainScoreOptions) {
    case 'Ok':
      return 'badge badge-success'
    case 'Fair':
      return 'badge badge-warning'
    case 'Poor':
      return 'badge badge-danger'
    case 'Suspicious':
      return 'badge badge-maroon'
  }
}

const DOMAIN_SCORE_INFO = {
  whois: 'Domain registration and ownership',
  reputation: 'Domain traffic information over time',
  serverAnalysis: 'Domain server indicators',
  blacklist: 'Blocklist and threat intelligence feed analysis',
  heuristics: 'Domain heuristics analysis',
}

function getUniqueDevices (browsers: BrowserPercentageResult[]): { device: UserAgentDevice, count: number }[] {
  const map: Map<UserAgentDevice, Set<string>> = new Map()
  browsers.forEach(item => {
    const [device, browser] = item.name.split('_') as [UserAgentDevice, string]
    if (!map.has(device)) {
      map.set(device, new Set())
    }
    map.get(device)!.add(browser)
  })
  return Array.from(map.entries()).map(([device, browsers]) => ({ device, count: browsers.size }))
}

function findDefects (event: ExtendedEventGroupSchema): { isDefect: boolean, defectsInfo: string[] } {
  const defects = {
    isDefect: false,
    defectsInfo: []
  }

  if (event.groupType != 'SUSPICIOUS_NETWORK_ACTIVITY' && !event.sensitiveAreaType) {
    defects.defectsInfo.push(`EG Type ${event.groupType} is expected to have SA Inputs. (detectors: ${event.detectorTypes.toString()})`)
  }
  if (event.groupType != 'SUSPICIOUS_NETWORK_ACTIVITY' && event.ui.riskFactor && !Object.values(CxConsts.TYPES).some(type => type == event.ui.riskFactor as string)) {
    defects.defectsInfo.push(`EG Type ${event.groupType} is expected to have a Risk Factor other than ${event.ui.riskFactor}. eg: PII, CREDIT_CARD etc..`)
  }
  if (!event.ui.riskFactor) {
    defects.defectsInfo.push(`Missing Risk Factor for EG Type ${event.groupType}.`)
  }
  if (event.source?.domain && (!event.source.score)) {
    defects.defectsInfo.push(`Missing Source Domain Score`)
  }
  if (!event.destination.score) {
    defects.defectsInfo.push(`Missing Destination Domain Score`)
  }

  defects.isDefect = !!defects.defectsInfo.length
  defects.defectsInfo = defects.defectsInfo.map(x => `▪ ${x}`)

  return defects
}
