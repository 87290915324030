import { Component, NgZone, OnInit } from '@angular/core'
import { CxConsts } from './shared/typings/chameleonx.typings'
import { Router } from '@angular/router'
import { GlobalNotification, GlobalState } from './app.state'
import { AuthService } from './services/auth/auth.service'
import { SpinnerService } from './services/spinner/spinner.service'
import { MethodsService } from './services/methods/methods.service'
import { UserDataService } from './services/user-data/user-data.service'
import { startAutoScroll } from './services/scroll/scroll'
import { setState } from './shared/state'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ChameleonX RWASP'

  /**@note this replace the old fashioned angular way to implement the modules from angular-cli.json  **/
  private readonly JS_MODULE_FOLDER = 'assets/jsModules/'
  private readonly JS_MODULES = [
    'ag-charts-community/ag-charts-community.min.js',
    'highlight.js/highlight.pack.js',
    'jquery/jquery-3.3.1.min.js',
    'jquery-toast-plugin/jquery.toast.min.js',
    'jquery-confirm/jquery-confirm.min.js',
    'jqvmap/jquery.vmap.min.js',
    'jqvmap/jquery.vmap.world.js',
    'jseditor/jsoneditor.js'
  ]

  isAuthenticated: boolean = false

  errorMessage: IfDefined<string>

  constructor (private _state: GlobalState, private _router: Router, private _auth: AuthService, private _user: UserDataService, private _zone: NgZone) {
    SpinnerService.spin('full')

    startAutoScroll()

    setInterval(() => this._zone.run(() => {}), 50)

    this.loadJavascriptModules()

    this._state.subscribe(GlobalNotification.AUTHENTICATED, isAuthenticated => {
      this.isAuthenticated = isAuthenticated
      this._zone.run(() => {})
    })

    this.suggestedZoomToast()

  }

  async ngOnInit () {
    try {

      const authenticated = await this._auth.isAuthenticated()
      if (!authenticated) {
        return await this._router.navigate([CxConsts.cxRoutingTable.LOGIN])
      }

      await this._auth.verifyAkamaiSSO()

      const route = location.pathname === `/${CxConsts.cxRoutingTable.LOGIN}` && this.isAuthenticated ? `/${CxConsts.DEFAULT_ROUTE}` :
        !this.isAuthenticated ? `/${CxConsts.cxRoutingTable.LOGIN}` : location.pathname

      await this._router.navigate([route])

    } catch (e) {

      await this._router.navigate([CxConsts.cxRoutingTable.LOGIN])
      setState(AppStateKey.ERR_LOGIN, e.toString())

    } finally {

      SpinnerService.stop('full')

    }
  }

  private loadJavascriptModules (): void {
    this.JS_MODULES.forEach(jsModule => MethodsService.loadScript(this.JS_MODULE_FOLDER + jsModule))
  }

  private suggestedZoomToast () {
    const SUGGESTED_DESKTOP_ZOOM = 90
    const SUGGESTED_MOBILE_ZOOM = 50
    const SUGGESTED_DEVIATION = 2

    const currentZoom = MethodsService.getCurrentZoomLevel()
    const isMobile = navigator['userAgentData']?.mobile ?? /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

    const shouldAlertDesktop = !isMobile && (currentZoom > SUGGESTED_DESKTOP_ZOOM + SUGGESTED_DEVIATION || currentZoom < SUGGESTED_DESKTOP_ZOOM - SUGGESTED_DEVIATION)
    const shouldAlertMobile = isMobile && (currentZoom > SUGGESTED_MOBILE_ZOOM + SUGGESTED_DEVIATION || currentZoom < SUGGESTED_MOBILE_ZOOM - SUGGESTED_DEVIATION)

    if (shouldAlertDesktop || shouldAlertMobile) {
      setTimeout(() => {
        MethodsService.toast(
          'info',
          'View Suggestion',
          `Admin Panel ${isMobile ? `for mobile (landscaped)` : ''} is best viewed at ${isMobile ? '50' : '90'}% Zoom.`,
          15
        )
      }, 5000)
    }
  }
}
