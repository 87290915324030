import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { GlobalState } from '../../../app.state'
import { RwaspModulesProcessorService } from '../processors/rwasp-modules-processor.service'

@Injectable()
export class RwaspModulesService extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async getAllModules (options: FilterArguments): Promise<CountedResultObject<BundlerModulesSchema[]>> {
    const queryString = MethodsService.objectToQueryString(options)
    const response = await this.get(`api/admin_api/modules?${queryString}`) as CountedResultObject<BundlerModulesSchema[]>
    return {
      count: response.count,
      success: response.success,
      data: RwaspModulesProcessorService.process(response.data) as BundlerModulesSchema[]
    }
  }

  async getModule (_id: string): Promise<BundlerModulesSchema> {
    const response = await this.get(`api/admin_api/module/${_id}`) as ResultObject<BundlerModulesSchema>
    return RwaspModulesProcessorService.process(response.data) as BundlerModulesSchema
  }

  async setActive (_id: string, active: boolean): Promise<boolean> {
    const body = JSON.stringify({ _id, active })
    const response = await this.post(`api/admin_api/modules/set_active`, body, true, 'application/json')
    return response.success
  }

}

