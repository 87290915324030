import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from '../../services/auth/auth.service'
import { GlobalState } from '../../app.state'
import { deleteState, getState, hasState, setState } from '../../shared/state'
import { CxConsts } from '../../shared/typings/chameleonx.typings'

@Component({
  selector: 'login-screen',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class PILoginComponent implements OnInit {
  errLoginMsg: string = 'incorrect username or password'
  errLogin: boolean = false

  constructor (private _auth: AuthService, private _router: Router, private _state: GlobalState) {
  }

  ngOnInit () {
  }

  errorMessage () {
    this.errLogin = hasState(AppStateKey.ERR_LOGIN)
    if (this.errLogin) {
      this.errLoginMsg = getState(AppStateKey.ERR_LOGIN).replace('Error: ', '')
    }
    return this.errLogin
  }

  ssoLogin () {
    return this._auth.verifyAkamaiSSO()
      .then(() => {
        deleteState(AppStateKey.ERR_LOGIN)
        this._router.navigate([`/${CxConsts.DEFAULT_ROUTE}`])
      })
      .catch(e => setState(AppStateKey.ERR_LOGIN, e.toString()))
  }

}
