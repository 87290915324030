//based on https://www.npmjs.com/package/ngx-trend/v/6.1.1
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations'
import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core'

import { buildLinearPath, buildSmoothPath } from './helpers/DOM.helpers'
import { normalize } from './helpers/math.helpers'
import { generateId } from './helpers/misc.helpers'


@Component({
  selector: 'app-pi-ngx-trend',
  templateUrl: './pi-ngx-trend.component.html',
  styleUrls: ['./pi-ngx-trend.component.scss'],
  animations: [
    trigger('pathAnimaiton', [
      state('inactive', style({ display: 'none' })),
      transition('* => active', [
        style({ display: 'initial' }),
        // We do the animation using the dash array/offset trick
        // https://css-tricks.com/svg-line-animation-works/
        animate(
          '{{ autoDrawDuration }}ms {{ autoDrawEasing }}',
          keyframes([
            style({
              'stroke-dasharray': '{{ lineLength }}px',
              'stroke-dashoffset': '{{ lineLength }}px',
            }),
            style({
              'stroke-dasharray': '{{ lineLength }}px',
              'stroke-dashoffset': 0,
            }),
          ]),
        ),
        // One unfortunate side-effect of the auto-draw is that the line is
        // actually 1 big dash, the same length as the line itself. If the
        // line length changes (eg. radius change, new data), that dash won't
        // be the same length anymore. We can fix that by removing those
        // properties once the auto-draw is completed.
        style({
          'stroke-dashoffset': '',
          'stroke-dasharray': '',
        }),
      ]),
    ]),
  ]
})
export class PINgxTrendComponent implements OnChanges {
  id: number
  @Input() data?: Array<(number | { value: number })>
  @Input() smooth?: boolean
  @Input() autoDraw = false
  @Input() autoDrawDuration = 2000
  @Input() autoDrawEasing = 'ease'
  @Input() width?: number
  @Input() height?: number
  @Input() padding = 8
  @Input() radius = 10
  @Input() stroke = 'black'
  @Input() strokeLinecap = ''
  @Input() strokeWidth = 1
  @Input() gradient: string[] = []
  @Input() preserveAspectRatio?: string
  @Input() svgHeight: string | number = '25%'
  @Input() svgWidth: string | number = '100%'
  @Input() maxData?: string | number
  @Input() minData?: string | number
  @Input() allowOverflow = true
  @ViewChild('pathEl') pathEl!: ElementRef
  gradientTrimmed!: Array<{ idx: number; stopColor: string; offset: number }>
  d: any
  viewBox!: string
  pathStroke: any
  gradientId: string
  lineLength!: number
  animationState = ''

  constructor() {
    this.id = generateId()
    this.gradientId = `ngx-trend-vertical-gradient-${this.id}`
  }
  ngOnChanges(): void {
    // We need at least 2 points to draw a graph.
    if (!this.data || this.data.length < 2) {
      return
    }

    // `data` can either be an array of numbers:
    // [1, 2, 3]
    // or, an array of objects containing a value:
    // [{ value: 1 }, { value: 2 }, { value: 3 }]

    const plainValues = this.data.map(point => {
      if (typeof point === 'number') {
        return point
      }
      return point.value
    });

    if (!this.allowOverflow && (this.minData || this.maxData)) {
      plainValues.forEach((val,i,arr) => {
        if (this.minData && val < this.minData) {
           arr[i] = Number(this.minData)
        }
        if (this.maxData && val > this.maxData) {
          arr[i] = Number(this.maxData)
        }
      })
    }

    // Our view-box needs to be in absolute units, so we'll default to 300x75
    // Our SVG can be a %, though; this is what makes it scalable.
    // By defaulting to percentages, the SVG will grow to fill its parent
    // container, preserving a 1/4 aspect ratio.
    const viewBoxWidth = this.width || 300;
    const viewBoxHeight = this.height || 75;
    this.svgWidth = this.width || '100%';
    this.svgHeight = this.height || '25%';
    this.viewBox = `0 0 ${viewBoxWidth} ${viewBoxHeight}`;
    const root = location.href.split(location.hash || '#')[0];
    this.pathStroke =
      this.gradient && this.gradient.length ? `url('${root}#${this.gradientId}')` : undefined;

    this.gradientTrimmed = this.gradient
      .slice()
      .reverse()
      .map((val, idx) => {
        return {
          idx,
          stopColor: val,
          offset: normalize(idx, 0, this.gradient.length - 1 || 1),
        };
      });

    const normalizedValues = normalizeDataset(
      plainValues,
      this.padding,
      viewBoxWidth - this.padding,
      // NOTE: Because SVGs are indexed from the top left, but most data is
      // indexed from the bottom left, we're inverting the Y min/max.
      viewBoxHeight - this.padding,
      this.padding,
      this.maxData?.toString ? Number(this.maxData) : undefined,
      this.minData?.toString ? Number(this.minData) : undefined
    );

    if (this.autoDraw && this.animationState !== 'active') {
      this.animationState = 'inactive';
      setTimeout(() => {
        this.lineLength = this.pathEl.nativeElement.getTotalLength();
        this.animationState = 'active';
      });
    }

    this.d = this.smooth
      ? buildSmoothPath(normalizedValues, this.radius)
      : buildLinearPath(normalizedValues);
  }
}

function normalizeDataset(
  data: number[],
  minX: number,
  maxX: number,
  minY: number,
  maxY: number,
  maxData?: number,
  minData?: number,
): Array<{ x: number; y: number }> {

  // For the X axis, we want to normalize it based on its index in the array.
  // For the Y axis, we want to normalize it based on the element's value.
  //
  // X axis is easy: just evenly-space each item in the array.
  // For the Y axis, we first need to find the min and max of our array,
  // and then normalize those values between 0 and 1.
  // here at akamai we added an option to set min and max points for y
  const boundariesX = { min: 0, max: data.length - 1 };
  const boundariesY = { min: minData ?? Math.min(...data), max: maxData ?? Math.max(...data) };

  const normalizedData = data.map((point, index) => ({
    x: normalize(index, boundariesX.min, boundariesX.max, minX, maxX),
    y: normalize(point, boundariesY.min, boundariesY.max, minY, maxY),
  }));

  // According to the SVG spec, paths with a height/width of `0` can't have
  // linear gradients applied. This means that our lines are invisible when
  // the dataset is flat (eg. [0, 0, 0, 0]).
  //
  // The hacky solution is to apply a very slight offset to the first point of
  // the dataset. As ugly as it is, it's the best solution we can find (there
  // are ways within the SVG spec of changing it, but not without causing
  // breaking changes).
  if (boundariesY.min === boundariesY.max) {
    normalizedData[0].y += 0.0001;
  }
  if((minData || maxData) && Math.max(...data) == Math.min(...data)){
    normalizedData[0].y += 0.0001;
  }

  return normalizedData;
}
