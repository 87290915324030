import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { GlobalState } from '../../../app.state'

@Injectable()
export class PIToolsService extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async getBinnerMetadata (): Promise<PiToolsBinnerMetadata> {
    const response = await this.get(`api/admin_api/tools/binner_metadata`) as ResultObject<PiToolsBinnerMetadata>
    return response.data as PiToolsBinnerMetadata
  }

  async downloadBinary (req: Omit<BinnerRequest, 'environment'>): Promise<any> {
    const queryString = MethodsService.objectToQueryString(req)
    return await this.get(`api/admin_api/tools/binner?${queryString}`, true, 'arraybuffer')
  }

  async downloadCustomerConfig (tenantId: string, environment: string): Promise<any> {
    const queryString = MethodsService.objectToQueryString({ tenantId, environment })
    return await this.get(`api/admin_api/tools/confy?${queryString}`)
  }

}

