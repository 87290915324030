import { AfterViewInit, Component, Input, OnInit } from '@angular/core'
import { DataService } from '../../../services/data/data.service'
import { MethodsService } from '../../../services/methods/methods.service'
import { COUNTRY_CODES_TO_FULL, COUNTRY_FULL_TO_CODES } from './country-codes'

type WorldMapOptions = {
  map: 'world_en' | 'usa_en' | 'europe_en' | 'germany_en'
  backgroundColor?: string
  borderColor?: string
  borderOpacity?: number // 0-1
  borderWidth?: number
  color?: string
  colors?: { [countryCode: string]: string }
  hoverColor?: string
  hoverColors?: { [countryCode: string]: string }
  hoverOpacity?: number // 0-1
  enableZoom?: boolean
  multiSelectRegion?: boolean
  normalizeFunction?: 'linear' | 'polynomial'
  scaleColors?: string[]
  selectedColor?: string
  selectedRegions?: string[]
  showLabels?: boolean
  showTooltip?: boolean
  onLoad?: (event: Event, map: Obj<any>) => void
  onLabelShow?: (event: Event, label: Obj<any>, countryCode: string) => void
  onRegionOver?: (event: Event, countryCode: string, region: string) => void
  onRegionOut?: (event: Event, countryCode: string, region: string) => void
  onRegionClick?: (event: Event, countryCode: string, region: string) => void
  onRegionSelect?: (event: Event, countryCode: string, region: string) => void
  onRegionDeselect?: (event: Event, countryCode: string, region: string) => void
  onResize?: (event: Event, width: number, height: number) => void
}

type Threshold = {
  threshold?: number
  color: string
  hoverColor: string
}

declare const $: any

@Component({
  selector: 'cx-world-map',
  templateUrl: './cx-world-map.component.html',
  styleUrls: ['./cx-world-map.component.scss']
})
export class CxWorldMapComponent implements OnInit, AfterViewInit {
  @Input() private data: CxDashboardService.CountryRatio[]

  private readonly countryCodes: Obj<string>
  private readonly reversedCountryCodes: Obj<string>

  public readonly uniqueMapId: string

  private readonly mapColors: { [countryCode: string]: string }
  private readonly hoverColors: { [countryCode: string]: string }
  private readonly percentages: { [countryCode: string]: number }
  private readonly options: WorldMapOptions

  private readonly THRESHOLD: { [severity: string]: Threshold } = {
    //@formatter:off
    none:   {                   hoverColor: DataService.colors.global.deep_purple_1,  color: DataService.colors.global.deep_purple_1_light},
    low:    {threshold: 1,      hoverColor: DataService.colors.global.deep_purple_2,  color: DataService.colors.global.deep_purple_2_light},
    med:    {threshold: 10,     hoverColor: DataService.colors.global.deep_purple_3,  color: DataService.colors.global.deep_purple_3_light},
    high:   {threshold: 20,     hoverColor: DataService.colors.global.deep_purple_4,  color: DataService.colors.global.deep_purple_4_light},
      //@formatter:on
  }

  constructor () {
    this.uniqueMapId = MethodsService.generateUniqueID('letters')
    this.countryCodes = COUNTRY_CODES_TO_FULL
    this.reversedCountryCodes = COUNTRY_FULL_TO_CODES
    this.mapColors = {}
    this.percentages = {}
    this.hoverColors = {}
    this.options = this.getDefaultOptions()
  }

  ngOnInit () {
    for (let countryCode of Object.keys(this.countryCodes)) {
      countryCode = countryCode.toLowerCase()
      this.percentages[countryCode] = 0
      this.hoverColors[countryCode] = this.THRESHOLD.none.hoverColor
      this.mapColors[countryCode] = this.THRESHOLD.none.color
    }
    this.process()
  }

  ngAfterViewInit () {
    this.initMap()
  }

  private enableMouseZooming () {
    const map = document.getElementById(this.uniqueMapId)
    const zoomIn = map.querySelector('.jqvmap-zoomin') as HTMLDivElement
    const zoomOut = map.querySelector('.jqvmap-zoomout') as HTMLDivElement
    map.addEventListener('wheel', (event) => {
      if (event.deltaY > 0) {
        zoomOut.click()
      } else {
        zoomIn.click()
      }
      event.preventDefault()
    })
  }

  private process () {
    this.data.forEach((countryData) => {
      const requestedCountry = countryData.country
      let countryCode: string
      if (this.countryCodes[requestedCountry.toUpperCase()]) {
        countryCode = requestedCountry
      } else if (this.reversedCountryCodes[requestedCountry]) {
        countryCode = this.reversedCountryCodes[requestedCountry]
      }
      if (countryCode) {
        const percentage = parseInt(countryData.percentage.toFixed(1))
        const { color, hoverColor } = this.getCountryColor(percentage)
        countryCode = countryCode.toLowerCase()
        this.mapColors[countryCode] = color
        this.hoverColors[countryCode] = hoverColor
        this.percentages[countryCode] = percentage
      }
    })
  }

  private initMap () {
    $(`#${this.uniqueMapId}`).vectorMap(this.options)
    this.enableMouseZooming()
  }

  private getDefaultOptions (): WorldMapOptions {
    return {
      map: 'world_en',
      backgroundColor: null,
      borderColor: '#573dbe',
      borderOpacity: 0.6,
      color: this.THRESHOLD.none.color,
      hoverColor: this.THRESHOLD.none.hoverColor,
      hoverColors: this.hoverColors,
      hoverOpacity: 0,
      enableZoom: true,
      showTooltip: true,
      selectedColor: null,
      normalizeFunction: 'linear',
      colors: this.mapColors,
      onLabelShow: (event, label, countryCode) =>
        !this.countryCodes[countryCode.toUpperCase()] ? undefined :
          label.html(`${this.countryCodes[countryCode.toUpperCase()]}: ${this.percentages[countryCode]}%`)

    }
  }

  private getCountryColor (percentage: number): Threshold {
    return percentage >= this.THRESHOLD.high.threshold ? this.THRESHOLD.high :
      percentage >= this.THRESHOLD.med.threshold ? this.THRESHOLD.med :
        percentage >= this.THRESHOLD.low.threshold ? this.THRESHOLD.low : this.THRESHOLD.none
  }

}
