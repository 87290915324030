import { MethodsService } from '../../methods/methods.service'
import { ENV_SHORT_TO_FULL_RESOLVER, PiInstancesProcessorService } from './pi-instances-processor.service'

export class PiDeploymentsGroupsProcessorService {
  static enrichGroup (data: ProcessedPIOperationsEnvironments[], group: ExtendedDeploymentGroupsSchema): ExtendedDeploymentGroupsSchema {
    (group as any)._full_env = data.find(e => e.id == group.deploymentEnvironment)?.name || group.deploymentEnvironment
    return group
  }

  static process<T extends ExtendedDeploymentGroupsSchema> (groupOrGroups: T | T[]): T | T[] {
    return Array.isArray(groupOrGroups) ?
      groupOrGroups.map(PiDeploymentsGroupsProcessorService.processGroup) as T[] :
      PiDeploymentsGroupsProcessorService.processGroup(groupOrGroups) as T
  }

  private static processGroup (group: ExtendedDeploymentGroupsSchema): ExtendedDeploymentGroupsSchema {
    (group as any)._env = group.deploymentEnvironment ? MethodsService.normalizeString(ENV_SHORT_TO_FULL_RESOLVER[group.deploymentEnvironment]) : '-'

    ;(group as any)._imageHash = group.clusters?.length && new Set(group.clusters.map(g => g.imagesHash)).size == 1 ?
      group.clusters[0].imagesHash :
      null

    if (group.clusters) {
      group.clusters = PiInstancesProcessorService.process(group.clusters) as CustomerMappingSchema[]
    }

    ;(group as any)._clusterIds = [
      'Associated Clusters:',
      ...(group as any).clusters.map(c => c.clusterId)
    ].join('\n')

    return group
  }
}
