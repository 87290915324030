const LOG_LEVEL = 0

function getTime (): string {
  const now = new Date()
    .toISOString()
    .replace('T', ' ')
    .replace('Z', '')
    .trim()
  return `${now}, `
}

export class LoggerService {
  //log level 0
  public static debug (...data: Array<any>) {
    DEBUG && LOG_LEVEL <= 0 && console.debug(getTime(), ...data)
  }

  //log level 1
  public static trace (...data: Array<any>) {
    DEBUG && LOG_LEVEL <= 1 && console.trace(getTime(), ...data)
  }

  //log level 2
  public static info (...data: Array<any>) {
    DEBUG && LOG_LEVEL <= 2 && console.log(getTime(), ...data)
  }

  //log level 3
  public static warn (...data: Array<any>) {
    DEBUG && LOG_LEVEL <= 3 && console.warn(getTime(), ...data)
  }

  //log level 4
  public static error (...data: Array<any>) {
    DEBUG && LOG_LEVEL <= 4 && console.error(getTime(), ...data)
  }

}
