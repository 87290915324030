import { AfterContentInit, Component, Input } from '@angular/core'
import { MethodsService } from '../../../services/methods/methods.service'

@Component({
  selector: 'pi-table-clock',
  templateUrl: './pi-table-clock.component.html',
})
export class PITableClockComponent implements AfterContentInit {
  @Input() public timestamp: Time

  dts: string
  time: string
  dateAgo: string
  _ts: number

  ngAfterContentInit () {
    const { localDts, dateAgo, time, timestamp } = MethodsService.cxDateFormat(this.timestamp)
    this.dts = localDts
    this.time = time
    this.dateAgo = dateAgo
    this._ts = timestamp
  }

}
