export class PiTenantsProcessorService {
  static process<T extends CustomersSchema>(customerOrCustomers: T | T[]): T | T[] {
    return Array.isArray(customerOrCustomers) ?
      customerOrCustomers.map(PiTenantsProcessorService.processCustomer) as T[]:
      PiTenantsProcessorService.processCustomer(customerOrCustomers) as T
  }

  private static processCustomer(customer: CustomersSchema): CustomersSchema {
    return customer
  }

}

