type IntelligenceSchema = DomainBlacklistSchema | VendorsSchema | AggregatedResourcesSchema

export class PiIntelligenceProcessorService {
  static process (intelligenceResults: IntelligenceSchema[]): IntelligenceSchema[] {
    return intelligenceResults.map(PiIntelligenceProcessorService.parse)
  }

  private static parse (schema: IntelligenceSchema): IntelligenceSchema {
    return schema
  }

  static processGPTVendorResult<T extends ExtendedVendorGPTGroupedResult> (doc: T): T {
    if (doc.domainScore) {
      doc._ds_text = JSON.stringify(doc.domainScore.features, null, 2).replace(/^\s+/gim, '·'.repeat(4))
    }

    function customersText (customers: ExtendedCustomer[], s: string): string {
      const c = customers
        .filter(Boolean)
        .sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
        .sort((a, b) => a.akamaiAccountName > b.akamaiAccountName ? 1 : a.akamaiAccountName < b.akamaiAccountName ? -1 : 0)
        .map(r => `[${r.akamaiCustomerId || '"ACCOUNT_ID"'}] ${r.akamaiAccountName || '"ACCOUNT_NAME"'} - ${r.name || '"TENANT_NAME"'}`)
        .join('\n')

      return c ? `*Click to view ${s}*\n` + c : ''

    }

    doc._customers_text = customersText(doc.customers || [], 'customers')
    doc._payment_page_customers_text = customersText(doc.paymentPageCustomers || [], 'Akamai accounts')

    doc.recommendations.forEach((r, i) => {
      const aVendor = (doc.vendorMatchedByDomain || doc.vendorMatchedByOutboundDomain)?.name
      const bVendor = doc.vendorMatchedByVendorName?.name
      switch (r.action) {
        case VendorGPTResultsRecommendationAction.CREATE_VENDOR: {
          r._text = 'Create'
          r._info = `Create new vendor record for ${doc.vendorName}`
          r._color = 'green'
          break
        }
        case VendorGPTResultsRecommendationAction.MERGE_VENDORS: {
          r._text = `Merge ${i == 0 ? 'A' : 'B'} to ${i == 0 ? 'B' : 'A'}`
          r._info = `Merge vendor ${i == 0 ? aVendor : bVendor} into ${i == 0 ? bVendor : aVendor}.\n Afterwards removes ${i == 0 ? aVendor : bVendor}`
          r._color = 'info'
          break
        }
        case VendorGPTResultsRecommendationAction.UPDATE_VENDOR_NAME: {
          r._icon = ''
          r._text = 'Update Vendor Name'
          r._info = `Update Vendor ${aVendor} name to ${doc.vendorName}`
          r._color = 'accent'
          break
        }
        case VendorGPTResultsRecommendationAction.ADD_DOMAIN_TO_EXISTING_VENDOR: {
          r._text = 'Append Domain'
          r._info = `Append Domain ${doc.domain} to the existing vendor ${doc.vendorName}`
          r._color = 'fb'
          break
        }
        case VendorGPTResultsRecommendationAction.UPDATE_VENDOR_TAGS: {
          r._text = 'Update Tags'
          r._info = `Insert
          ${doc.missingTags.length} missing tags:
          ${doc.missingTags.join(', ')}
          into
          ${aVendor}
          vendor`
          r._color = 'primary'
          break
        }
        case VendorGPTResultsRecommendationAction.UPDATE_VENDOR_DESCRIPTION: {
          r._text = 'Update Description'
          r._info = `Update the description for the vendor ${doc.vendorName}`
          r._color = 'dark'
          break
        }

      }
    })
    return doc
  }

}
