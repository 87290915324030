import { MethodsService } from '../../methods/methods.service'

const topic_thresholds = {
  fatal: 500_000,
  high: 200_000,
  medium: 75_000,
  low: 25_000,
}

const topic_partitions_thresholds = {
  fatal: 80_000,
  high: 40_000,
  medium: 20_000,
  low: 10_000,
}

export class PiKafkaPanelProcessorService {
  static process<T extends UIKafkaTopicMetricsResult> (queueOrQueues: T | T[]): KafkaPanelItem | KafkaPanelItem[] {

    return Array.isArray(queueOrQueues) ?
      queueOrQueues.map(PiKafkaPanelProcessorService._process) :
      PiKafkaPanelProcessorService._process(queueOrQueues)
  }

  static processMetadata<T extends KafkaPanelItem> (item: T, metadata: KafkaMetadata): T {
    item._confluent_topic_link = `${metadata.baseUrl}/${metadata.confluentEnvId}/clusters/${metadata.confluentClusterId}/topics/${item.topic}`
    item._confluent_consumer_link = `${metadata.baseUrl}/${metadata.confluentEnvId}/clusters/${metadata.confluentClusterId}/topics/${item.topic}/metrics/consumer-lag/${item.groupId}`
    return item
  }

  private static _process (item: UIKafkaTopicMetricsResult): KafkaPanelItem {
    const panelItem = { ...item } as KafkaPanelItem

    const connectionHealth = getConnectionHealth(item.lastSample)
    panelItem._connectionHealthTitle = connectionHealth.title
    panelItem._connectionHealthColor = connectionHealth._class

    panelItem._messagesCountTrendClass = getTrendClass(item.messagesCountTrend)
    panelItem._messageBehindTrendClass = getTrendClass(item.messagesBehindTrend)
    panelItem._numOfConsumersTrendClass = getTrendClass(item.numOfConsumersTrend)

    panelItem._numOfMessages = MethodsService.integerWithCommas(panelItem.totalMessages)
    panelItem._numOfMessagesAcc = MethodsService.numberToSuffix(Number(panelItem.totalMessages)) ?? '0'
    panelItem._lagAcc = MethodsService.numberToSuffix(Number(panelItem.messagesBehind)) ?? '0'

    panelItem._trendMessagesBehindMaxData = Math.max(...item.trend.map(t => Number(t.messagesBehind)))
    panelItem._trendNumOfMessagesMaxData = Math.max(topic_thresholds.high, panelItem._trendMessagesBehindMaxData, ...item.trend.map(t => Number(t.totalMessagesSinceLastTrend)))

    panelItem._trendLag = item.trend.map(t => {
      const messagesBehind = Number(t.messagesBehind)
      const _lagPercent = messagesBehind === 0 ? 0 :
        messagesBehind >= t.totalMessagesSinceLastTrend ? 1 :
          t.totalMessagesSinceLastTrend > 0 ? Number(t.messagesBehind) / t.totalMessagesSinceLastTrend : 0
      const lagPercent = (_lagPercent > 1 ? 1 :
        _lagPercent < 0 ? 0 :
          _lagPercent) * 100

      return {
        timestamp: t.timestamp,
        lagPercent,
        messagesBehind,

        _txt:
          [
            `Partitions Lag:\n${[...t.partitions].sort((a, b) => a.partition - b.partition).map(p => {
              const msgBehind = Number(p.messagesBehind)
              const _lagPercent = msgBehind === 0 ? 0
                : msgBehind >= p.totalMessagesSinceLastTrend ? 1
                  : p.totalMessagesSinceLastTrend > 0 ? msgBehind / p.totalMessagesSinceLastTrend
                    : 0
              const lagPercent = (_lagPercent > 1 ? 1 :
                _lagPercent < 0 ? 0 :
                  _lagPercent) * 100

              const deviator = MethodsService.numberToSuffix(lagPercent === 100 ? msgBehind : p.totalMessagesSinceLastTrend)

              return `- [${p.partition}]: ${MethodsService.numberToSuffix(msgBehind)} / ${deviator} (${lagPercent.toFixed(1)}%)`
            }).join('\n')}`,
          ].join('\n')
      }
    })
    panelItem._trendMessages = item.trend.map(t => ({
      timestamp: t.timestamp,
      messagesCount: t.totalMessagesSinceLastTrend,
      _txt:
        [
          `partitions:\n${[...t.partitions].sort((a, b) => a.partition - b.partition).map(p =>
            `- [${p.partition}]: ${MethodsService.numberToSuffix(p.totalMessagesSinceLastTrend)} `,
          ).join('\n')}`,
        ].join('\n')
    }))
    panelItem._trendConsumers = item.trend.map(t => ({
      timestamp: t.timestamp,
      numberOfConsumers: t.consumersCount
    }))

    panelItem._lagPercent = panelItem._trendLag.length > 0 ?
      panelItem._trendLag.reduce((c, p) => c += p.lagPercent, 0) / panelItem._trendLag.length :
      0

    panelItem.highestPartitionMessagesBehind = Math.max(
      ...item.trend.map(t => t.partitions).flat().map(p => (Number(p.messagesBehind))).flat()
    )

    if (Number(item.messagesBehind) > topic_thresholds.low && panelItem.highestPartitionMessagesBehind / Number(item.messagesBehind) >= 0.9) {
      panelItem._isPartition90PercentOfLag = true
      panelItem._busyPartition = item.trend.map(t => t.partitions).flat().find(p => Number(p.messagesBehind) == panelItem.highestPartitionMessagesBehind)!.partition
    }

    const lagHealth = getLagHealth(Number(item.messagesBehind), panelItem.highestPartitionMessagesBehind)
    panelItem._lagHealthTitle = lagHealth.title
    panelItem._lagHealthColor = lagHealth._class

    return panelItem
  }

}

const _class = `font-size-20 relative font-weight-bolder mdi mdi-trending-`
const up = `${_class}up color-cx-accent-dark`
const down = `${_class}down color-cx-green top-6px`
const neutral = `${_class}neutral color-cx-accent top-3px`

function getTrendClass (trend: UITrendDirection): string {
  return trend === 'up' ? up :
    trend === 'down' ? down :
      neutral

}

function getLagHealth (current: number, highest_partition: number): { title: string, _class: string } {
  const _p = 'color-'
  if (current >= topic_thresholds.fatal || highest_partition >= topic_partitions_thresholds.fatal) {
    return { _class: _p + 'indigo', title: 'Overloaded' }
  }
  if (current >= topic_thresholds.high || highest_partition >= topic_partitions_thresholds.high) {
    return { _class: _p + 'red', title: 'Under Pressure' }
  }
  if (current >= topic_thresholds.medium || highest_partition >= topic_partitions_thresholds.medium) {
    return { _class: _p + 'yellow', title: 'Loaded' }
  }
  if (current >= topic_thresholds.low || highest_partition >= topic_partitions_thresholds.low) {
    return { _class: _p + 'lemon-green', title: 'Steady' }
  }
  return { _class: _p + 'green', title: 'Healthy' }
}

function getConnectionHealth (lastTimestamp: Time): { title: string, _class: string } {
  const now = Date.now()
  const _l = new Date(lastTimestamp).getTime()
  const diff = now - _l
  const offline_minutes = 1000 * 60 * 30
  const unstable_minutes = 1000 * 60 * 15
  const delay_minutes = 1000 * 60 * 5
  const delayed_minutes = 1000 * 60 * 2.5

  const _p = 'color-'

  if (diff >= offline_minutes) {
    return { _class: _p + 'indigo', title: 'Offline' }
  }

  if (diff >= unstable_minutes) {
    return { _class: _p + 'red', title: 'Unstable' }
  }

  if (diff >= delay_minutes) {
    return { _class: _p + 'yellow', title: 'Delayed' }
  }

  if (diff >= delayed_minutes) {
    return { _class: _p + 'lemon-green', title: 'Alive' }
  }

  return { _class: _p + 'green', title: 'Online' }
}
