import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { LoggerService } from '../../services/logger/logger.service'
import { MethodsService } from '../../services/methods/methods.service'
import { UserDataService } from '../../services/user-data/user-data.service'
import { USER_POWER_LEVEL } from '../../shared/user-power'
import { GlobalNotification, GlobalState } from '../../app.state'
import { UsersPermissionsGroupService } from '../../services/api/services/users-permissions-group.service'
import {
  UsersPermissionsGroupProcessorService
} from '../../services/api/processors/users-permissions-group-processor.service'
import { UsersService } from '../../services/api/services/users.service'
import { UsersProcessorService } from '../../services/api/processors/users-processor.service'

@Component({
  selector: '',
  templateUrl: './users-permissions-group.component.html',
  styleUrls: ['./users-permissions-group.component.scss']
})
export class PIUsersPermissionsGroupsComponent<T extends ExtendedUsersPermissionsGroupSchema = ExtendedUsersPermissionsGroupSchema> extends TableWithFilters implements OnInit, OnDestroy {

  readonly title = 'PI Users Permissions Group'
  readonly paginationName = 'Users Permissions Groups'
  readonly isAdmin: boolean = false

  filterValue: string = ''

  groups: T[] = []

  //state
  availablePermissions: EnrichedUsersPermissionsGroupMetadata[]
  currentPermissions: EnrichedUsersPermissionsGroupMetadata[] = []

  availableUsers: UsersSchema[]
  currentUsers: UsersSchema[] = []

  //modals
  isCreateGroupActive: boolean = false
  selectedGroup: T

  //table
  readonly tableHeaders: TableHeader[]
  currentSortBy: string
  _isRefreshing: boolean = false

  textToClip = MethodsService.copyToClipboard

  normalizeString = MethodsService.normalizeString

  upperCasedString = MethodsService.upperCasedString

  private _debounce: void | number
  private debounce_timeout: number = 1000

  constructor (private _userData: UserDataService, private _groups: UsersPermissionsGroupService, private _users: UsersService, private _zone: NgZone, private _state: GlobalState) {
    super()
    this.currentItemsPerPage = this.itemsPerPageFilter[1]
    this.tableHeaders = [
      //@formatter:off
      USER_POWER_LEVEL[this._userData.permission] >= USER_POWER_LEVEL[UserPermission.ADMIN] ? { /*width: '4', */  name: '',                   sortable: false,    field: ''                   } : undefined,
      { /*width: '12',*/  name: 'Created',              sortable: true,     field: 'createdAt'          },
      { /*width: '12',*/  name: 'Updated',              sortable: true,     field: 'updatedAt'          },
      { /*width: '12',*/  name: 'ID',                   sortable: true,     field: '_id'                },
      { /*width: '12',*/  name: 'Name',                 sortable: true,     field: 'name'               },
      { /*width: '12',*/  name: 'Info',                 sortable: true,     field: 'info'               },
      { /*width: '12',*/  name: 'Permissions',          sortable: true,     field: 'numOfPermissions'   },
      { /*width: '12',*/  name: 'Users',                sortable: true,     field: 'numOfUsers'         },
      { /*width: '12',*/  name: 'Actions',              sortable: false                                 },
      //@formatter:on
    ].filter(x => !!x)

    this.currentSortBy = this.tableHeaders[2].field
    this.isAdmin = USER_POWER_LEVEL[this._userData.permission] >= USER_POWER_LEVEL[UserPermission.ADMIN]
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    if (!this.selectedGroup && !this.isCreateGroupActive) {
      return
    }
    if (key === 'Escape') {
      this.clearSelections()
    }
  }

  ngOnDestroy (): void {
    window.removeEventListener('keyup', this.registerModalListeners.bind(this))
  }

  onFilterChange (item: any) {
    if (this._debounce) {
      this._debounce = clearTimeout(this._debounce)
    }
    this._debounce = setTimeout(() => this.refreshTable(), this.debounce_timeout) as any as number
  }

  ngOnInit (): void {
    window.addEventListener('keyup', this.registerModalListeners.bind(this))
    this.refreshTable!()
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  selectGroup (group: T) {
    this.currentPermissions = this.availablePermissions.filter(p => group.permissions.includes(p.path))
    this.currentUsers = group.users
    this.selectedGroup = group
  }

  openCreateGroupModal () {
    this.isCreateGroupActive = true
  }

  async deleteGroups () {
    const title = `Delete Confirmation`
    const content = `Delete ${this.selectedTableItems.size} Permission Group${this.selectedTableItems.size > 1 ? 's' : ''} ?`
    const deleteConfirmationText = 'Delete'
    if (await MethodsService.confirm(title, content, deleteConfirmationText)) {

      SpinnerService.spin('mini')

      try {
        const success = await this._groups.deleteGroups([...this.selectedTableItems])
        if (success) {
          this.selectedTableItems.clear()
          await this.refreshTable()
        }
      } catch (e) {
        LoggerService.error(e)
      }
      SpinnerService.stop('mini')
    }
  }

  async createNewGroup (name: string, info: string = '') {
    if (!name) {
      return
    }

    const newGroupData: NewUserPermissionsGroup = {
      name,
      info,
      permissions: this.currentPermissions.map(p => p.path),
      users: this.currentUsers.map(u => u._id)
    }

    try {
      SpinnerService.spin('mini')
      const newGroup = await this._groups.createNewGroup(newGroupData)
      if (newGroup) {
        await this.refreshTable()
      }
    } catch (e) {
      MethodsService.toast('error', `Error creating new permission group ${name}`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }

  }

  async updateGroup (name?: string, info?: string) {
    const updateGroupData: UpdateUserPermissionsGroup = {}

    if (name && this.selectedGroup.name != name) {
      updateGroupData.name = name
    }

    if (info && this.selectedGroup.info != info) {
      updateGroupData.info = info
    }

    if (
      (this.selectedGroup.permissions.length != this.currentPermissions.length) ||
      (this.currentPermissions.length && !this.currentPermissions.every(p => this.selectedGroup.permissions.includes(p.path)))
    ) {
      updateGroupData.permissions = this.currentPermissions.map(p => p.path)
    }

    if (
      (this.selectedGroup.users.length != this.currentUsers.length) ||
      (this.currentUsers.length && !this.currentUsers.every(p => !!this.selectedGroup.users.find(u => p._id == u._id)))
    ) {
      updateGroupData.users = this.currentUsers.map(u => u._id)
    }

    try {
      SpinnerService.spin('mini')
      const newGroup = await this._groups.updateGroup(this.selectedGroup._id, updateGroupData)
      if (newGroup) {
        await this.refreshTable(false, true)
      }
    } catch (e) {
      MethodsService.toast('error', `Error updating permission group ${this.selectedGroup.name}`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }

  }

  async refreshTable (background: boolean = false, ignoreSelected: boolean = false): Promise<void> {
    if (this._isRefreshing || (!ignoreSelected && this.selectedGroup)) {
      return
    }
    this._isRefreshing = true
    try {
      if (!background) {
        this.groups = []
        this.clearSelections()
        SpinnerService.spin('mini')
      }
      const [
        { data, count },
        metadata,
        users
      ] = await Promise.all([
        this.getGroups(),
        this._groups.getMetadata(),
        this._users.getUsers({ limit: Number.MAX_SAFE_INTEGER, sort_by: 'updatedAt', sort_direction: 'desc' })
      ].filter(x => !!x) as PromiseLike<any>[])

      this.numOfAvailableDocs = count
      this.groups = data
      this.groups.forEach(group => group.users = UsersProcessorService.process(group.users) as UsersSchema[])

      this.availableUsers = users.users
      this.availablePermissions = metadata

      UsersPermissionsGroupProcessorService.enrichGroupsWithMetadata(data, metadata)
    } catch (e) {
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._zone.run(() => {})
      this._isRefreshing = false
    }

  }

  private clearSelections () {
    this.selectedGroup = undefined
    this.isCreateGroupActive = false
    this.currentUsers = []
    this.currentPermissions = []
    this.selectedTableItems.clear()
  }

  private async getGroups (options?: FilterArguments) {
    options = options || {
      filter: this.filterValue,
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sort_by: this.currentSortBy,
      sort_direction: this.isSortReversed ? 'asc' : 'desc'
    }

    return await this._groups.getGroups(options)
  }

}
