import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { GlobalState } from '../../../app.state'
import { PiKafkaPanelProcessorService } from '../processors/pi-kafka-panel-processor.service'

@Injectable()
export class PiKafkaPanelService extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async getAll (options: KafkaTopicMetricsFilters): Promise<KafkaTopicMetricsCountedResult> {
    const queryString = MethodsService.objectToQueryString(options)
    const response = await this.get<KafkaTopicMetricsCountedResult>(`api/admin_api/kafka_panel/topics?${queryString}`)
    return {
      // filterOptions: res.data.filterOptions,
      count: response.data.count,
      queryTimes: response.data.queryTimes,
      filterOptions: response.data.filterOptions,
      data: PiKafkaPanelProcessorService.process(response.data.data) as KafkaPanelItem[]
    }
    // return response.success && PiKafkaPanelProcessorService.process(response.data) as KafkaPanelItem[]
  }

  async getMetadata (): Promise<KafkaMetadata> {
    const response = await this.get(`api/admin_api/kafka_panel/metadata`)
    return response.data
  }

  async resizeTopicPartitions (body: KafkaResizeTopicRequestParams): Promise<ResultObject<void>> {
    return this.post(`api/admin_api/kafka_panel/resize_partitions`, JSON.stringify(body), true, 'application/json')
  }

  async setConsumerGroupOffset (body: KafkaSetConsumerOffsetByTimestampOptions): Promise<ResultObject<void>> {
    return this.post(`api/admin_api/kafka_panel/consumer_group_ts_offset`, JSON.stringify(body), true, 'application/json')
  }


  async deleteConsumerGroup (body: KafkaDeleteConsumerGroupOptions): Promise<ResultObject<void>> {
    return this.post(`api/admin_api/kafka_panel/delete_consumer_group`, JSON.stringify(body), true, 'application/json')
  }

  async deleteConsumerGroupData (body: KafkaDeleteConsumerGroupOptions): Promise<ResultObject<void>> {
    return this.post(`api/admin_api/kafka_panel/delete_consumer_group_db_data`, JSON.stringify(body), true, 'application/json')
  }

}

