import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { GlobalState } from '../../../app.state'
import { PiIntelligenceProcessorService } from '../processors/pi-intelligence-processor.service'

@Injectable()
export class PiIntelligenceService extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async getBlacklistDomains (options: FilterArguments): Promise<CountedResultObject<StandardObject>> {
    const queryString = MethodsService.objectToQueryString(options)
    let baseEndpoint = `api/admin_api/intelligence_feed/blacklisted_domains?${queryString}`

    const response = await this.get(baseEndpoint) as ResultObject<CountedResultObject>
    const { success, count, blacklist } = response
    const parsedData = PiIntelligenceProcessorService.process(blacklist)
    return { success, count, data: parsedData }
  }

  async getBlacklistDomain (domain: string): Promise<StandardObject> {
    let baseEndpoint = `api/admin_api/intelligence_feed/blacklisted_domain?domain=${domain}`

    const response = await this.get(baseEndpoint) as ResultObject<StandardObject>
    const { success, count, data } = response
    return { success, count, data }
  }

  async getVendors (options: FilterArguments): Promise<CountedResultObject<StandardObject>> {
    const queryString = MethodsService.objectToQueryString(options)
    let baseEndpoint = `api/admin_api/intelligence_feed/vendors?${queryString}`

    const response = await this.get(baseEndpoint) as ResultObject<CountedResultObject>
    const { success, count, vendors } = response
    const parsedData = PiIntelligenceProcessorService.process(vendors)
    return { success, count, data: parsedData }
  }

  async getVendor (domain: string): Promise<StandardObject> {
    let baseEndpoint = `api/admin_api/intelligence_feed/vendor?domain=${domain}`

    const response = await this.get(baseEndpoint) as ResultObject<StandardObject>
    const { success, count, data } = response
    return { success, count, data }
  }

  async getTrustedDomains (options: FilterArguments): Promise<CountedResultObject<StandardObject>> {
    const queryString = MethodsService.objectToQueryString(options)
    let baseEndpoint = `api/admin_api/intelligence_feed/trusted_domains?${queryString}`

    const response = await this.get(baseEndpoint) as ResultObject<CountedResultObject>
    const { success, count, trustedDomains } = response
    const parsedData = PiIntelligenceProcessorService.process(trustedDomains)
    return { success, count, data: parsedData }
  }

  async getTrustedDomain (_id: string): Promise<StandardObject> {
    let baseEndpoint = `api/admin_api/intelligence_feed/trusted_domain?id=${_id}`

    const response = await this.get(baseEndpoint) as ResultObject<StandardObject>
    const { success, count, data } = response
    return { success, count, data }
  }

  async updateCustomer (customer: CustomersSchema): Promise<boolean> {
    const body = JSON.stringify(customer)
    const response: ResultObject = await this.post(`api/admin_api/intelligence_feed/vendors`, body, true, 'application/json')
    return response.success
  }

  async createOrUpdateBlacklistDomain (blacklistedDomain: Partial<DomainBlacklistSchema>): Promise<ResultObject> {
    const body = JSON.stringify(blacklistedDomain)
    const response: ResultObject = await this.post(`api/admin_api/intelligence_feed/set_blacklisted_domain`, body, true, 'application/json')
    return response
  }

  async createOrUpdateVendor (vendor: Partial<VendorsSchema>): Promise<ResultObject> {
    const body = JSON.stringify(vendor)
    const response: ResultObject = await this.post(`api/admin_api/intelligence_feed/set_vendor`, body, true, 'application/json')
    return response
  }

  async createOrUpdateTrustedDomain (trustedDomain: Partial<TrustedDomainsSchema>): Promise<ResultObject> {
    const body = JSON.stringify(trustedDomain)
    const response: ResultObject = await this.post(`api/admin_api/intelligence_feed/set_trusted_domain`, body, true, 'application/json')
    return response
  }

  async getVendorHuntingDomains (options: FilterArguments, selectedCluster: string, minTimesSeen: number): Promise<CountedResultObject<StandardObject>> {
    const queryString = MethodsService.objectToQueryString({
      ...options,
      instance_id: selectedCluster,
      min_times_seen: minTimesSeen
    })
    let baseEndpoint = `api/admin_api/intelligence_feed/get_vendor_hunting?${queryString}`

    const response = await this.get(baseEndpoint) as CountedResultObject<AggregatedResourcesSchema[]>
    const { success, count, data } = response
    const parsedData = PiIntelligenceProcessorService.process(data)
    return { success, count, data: parsedData }
  }

  async ignoreGPTDomains (_ids: string[], ignore: boolean) {
    return this.post(`api/admin_api/intelligence_feed/ignore_gpt_results`, JSON.stringify({
      _ids,
      ignore
    }), true, 'application/json')
  }

  async deleteGPTResults (_ids: string[]) {
    return this.post(`api/admin_api/intelligence_feed/delete_gpt_results`, JSON.stringify({ _ids }), true, 'application/json')
  }

  async queryGPTDomains (domains: string[], model: string) {
    return this.post(`api/admin_api/intelligence_feed/gpt_query_domains`, JSON.stringify({
      domains,
      model
    }), true, 'application/json')
  }

  async getAllVendorGPTResults (options: FilterArguments<VendorGPTResultsFilterOptions>): Promise<VendorGPTResultsGetAllResponse> {
    const queryString = MethodsService.objectToQueryString(options)
    const response = await this.get(`api/admin_api/intelligence_feed/vendor_gpt_results?${queryString}`) as VendorGPTResultsGetAllResponse
    return response.success && {
      success: response.success,
      count: response.count,
      data: response.data.map(PiIntelligenceProcessorService.processGPTVendorResult),
      filterOptions: response.filterOptions
    }
  }

  async applyRecommendations (recommendations: VendorGPTResultRecommendationObject[]) {
    return this.post(`api/admin_api/intelligence_feed/set_gpt_recommendations`, JSON.stringify({ recommendations }), true, 'application/json')
  }

  async getVendorGPTConfiguration (): Promise<ResultObject<StandardObject>> {
    const response = await this.get(`api/admin_api/intelligence_feed/vendor_gpt_config`)
    return response.success && {
      success: response.success,
      count: response.count,
      data: response.data,
      filterOptions: response.filterOptions
    }
  }

}

