import { Component, NgZone, OnInit } from '@angular/core'
import { MethodsService } from '../../services/methods/methods.service'
import { UserDataService } from '../../services/user-data/user-data.service'
import { GlobalState } from '../../app.state'
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap'
import { PITenantsService } from '../../services/api/services/pi-tenants.service'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { LoggerService } from '../../services/logger/logger.service'

const enum PaginationNames {
  event_groups = 'Event Groups',
  custom_alerts = 'Custom Alerts',
  pci_alerts = 'PCI Alerts',
}

const enum ConsoleScope {
  event_groups = 'event_groups',
  custom_alerts = 'custom_alerts',
  pci_alerts = 'pci_alerts',
}

@Component({
  selector: '',
  templateUrl: './pi-event-console.component.html',
  styleUrls: ['./pi-event-console.component.scss']
})

export class PiEventConsoleComponent implements OnInit {

  readonly _storageKey = 'EventGroupConsole_lastViewedTenants'
  readonly title = 'CPC Event Console'

  paginationName = PaginationNames.event_groups

  metadata: CollectionIndexStatsMetadataObject

  lastViewedTenants: { customerId: string, name: string, timestamp: number }[] = []

  //filters
  filterValue: string = ''

  currentScope: ConsoleScope
  activeScope: `${ConsoleScope}-tab` = 'event_groups-tab'

  currentSortBy: string

  //state
  currentTenantId: string
  selectedTenant: [MappedTenant]
  associatedTenants: MappedTenants = []

  _isRefreshing: boolean = false
  integerWithCommas = MethodsService.integerWithCommas
  numberToSuffix = MethodsService.numberToSuffix
  cxDateFormat = MethodsService.cxDateFormat
  stringify = JSON.stringify

  private _debounce: void | number
  private debounce_timeout: number = 1000

  constructor (private _userData: UserDataService, private _tenants: PITenantsService, private _zone: NgZone, private _state: GlobalState) {
    this.fetchStorage()
  }

  async ngOnInit () {
    try {
      SpinnerService.spin('mini')
      this.associatedTenants = await this._tenants.getMappedCustomers()
      this.associatedTenants = this.associatedTenants.filter(tenant => !!tenant.clusterId)
    } catch (e) {
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
      this._zone.run(() => {})
    }
  }

  onTabChange ($event: NgbTabChangeEvent) {
    switch ($event.nextId) {
      case 'event_groups-tab':
        this.currentScope = ConsoleScope.event_groups
        this.activeScope = 'event_groups-tab'
        break
      case 'custom_alerts-tab':
        this.currentScope = ConsoleScope.custom_alerts
        this.activeScope = 'custom_alerts-tab'
        break
      case 'pci_alerts-tab':
        this.currentScope = ConsoleScope.pci_alerts
        this.activeScope = 'pci_alerts-tab'
        break
    }
  }

  onTenantSelect () {
    if (this.currentTenantId && this.currentTenantId != this.selectedTenant[0]?.id) {
      this.currentTenantId = undefined
      setTimeout(() => {
        this.currentTenantId = this.selectedTenant[0]?.id || undefined
        this.setStorage()
      }, 50)
    } else {
      this.currentTenantId = this.selectedTenant[0]?.id || undefined
      this.setStorage()
    }
    this._zone.run(() => {})
  }

  selectLastViewedTenant (item: { customerId: string, name: string, timestamp: number }) {
    this.selectedTenant = [this.associatedTenants.find(tenant => tenant.id === item.customerId)]
    this.onTenantSelect()
    this.setStorage()
  }

  removeLastViewedTenant (item: { customerId: string, name: string, timestamp: number }) {
    this.lastViewedTenants = this.lastViewedTenants.filter(tenant => tenant.customerId !== item.customerId)
    this.prepareData()
    localStorage.setItem(this._storageKey, JSON.stringify(this.lastViewedTenants))
  }

  fetchStorage () {
    const db = localStorage.getItem(this._storageKey)
    if (db) {
      this.lastViewedTenants = JSON.parse(db)
    }
  }

  setStorage () {
    const { id, _name } = this.associatedTenants.find(tenant => tenant.id === this.selectedTenant[0].id)
    this.lastViewedTenants.push({
      customerId: id,
      name: _name,
      timestamp: Date.now()
    })
    this.prepareData()
    localStorage.setItem(this._storageKey, JSON.stringify(this.lastViewedTenants))
  }

  prepareData () {
    this.lastViewedTenants.sort((a, b) => b.timestamp - a.timestamp)
    const visited: Set<string> = new Set()
    this.lastViewedTenants = this.lastViewedTenants.filter(({ customerId }) => {
      if (visited.has(customerId)) {
        return false
      }
      visited.add(customerId)
      return true
    })
  }

}

