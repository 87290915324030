import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { MethodsService } from '../../services/methods/methods.service'
import { LoggerService } from '../../services/logger/logger.service'
import { CxConsts } from '../../shared/typings/chameleonx.typings'
import { GlobalNotification, GlobalState } from '../../app.state'
import { AgentLibrariesService } from '../../services/api/services/agent-libraries.service'
import { GIT_REPO, JIRA_TICKET_PREFIX } from '../../services/data/data.service'

@Component({
  selector: 'pi-agent-libraries',
  templateUrl: './agent-libraries.component.html',
  styleUrls: ['./agent-libraries.component.scss']
})

export class PIAgentLibrariesComponent<T extends AgentLibModulesSchema> extends TableWithFilters implements OnInit, OnDestroy {

  readonly JIRA_TICKET_PREFIX = JIRA_TICKET_PREFIX
  readonly GIT_REPO = GIT_REPO

  readonly title = 'Agent Libraries Modules'

  modules: T[] = []
  selectedModule: T

  //table
  readonly tableHeaders: TableHeader[]

  filterValue: string = ''

  currentSortBy: string

  paginationName = 'Agent Modules'

  textToClip = MethodsService.copyToClipboard

  normalizeString = MethodsService.normalizeString

  module_icons = CxConsts.rwasp_modules_icon

  //modules filter
  agentLibModules: ResolvedApiModulesName[] = [
    ResolvedApiModulesName.Buffer,
    ResolvedApiModulesName.DataDriven,
    ResolvedApiModulesName.AsyncDetectors,
    ResolvedApiModulesName.AsyncUtils,
    ResolvedApiModulesName.InputExplorer,
    ResolvedApiModulesName.AudienceHijacking,
  ].sort()
  currentLibModules = this.agentLibModules

  private _debounce: void | number
  private debounce_timeout: number = 1000

  _isRefreshing: boolean = false

  constructor (private _libs: AgentLibrariesService, private _zone: NgZone, private _state: GlobalState) {
    super()
    this.currentItemsPerPage = this.itemsPerPageFilter[1]
    this.tableHeaders = [
      //@formatter:off
      { name: 'Created',                sortable: true,   field: 'createdAt'          },
      { name: 'Module',                 sortable: true,   field: 'module'             },
      { name: 'Version',                sortable: true,   field: 'paddedVersion'      },
      { name: 'JIRA',                   sortable: false                               },
      { name: 'Filename',               sortable: true,   field: 'filename'           },
      { name: 'Status',                 sortable: true,   field: 'active'             },
      //@formatter:on
    ].filter(x => !!x)
    this.currentSortBy = this.tableHeaders[0].field
  }

  ngOnDestroy (): void {
    window.removeEventListener('keyup', this.registerModalListeners.bind(this))
  }

  ngOnInit (): void {
    window.addEventListener('keyup', this.registerModalListeners.bind(this))
    this.refreshTable!()
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    if (!this.selectedModule) {
      return
    }
    if (key === 'Escape') {
      this.selectedModule = undefined
    }
  }

  onFilterChange (item: any) {
    if (this._debounce) {
      this._debounce = clearTimeout(this._debounce)
    }
    this._debounce = setTimeout(() => this.refreshTable(), this.debounce_timeout) as any as number
  }

  async refreshTable (background: boolean = false): Promise<void> {
    if (this._isRefreshing) {
      return
    }
    this._isRefreshing = true
    try {
      if (!background) {
        SpinnerService.spin('mini')
        this.selectedModule = undefined
      }

      const { data, count } = await this.getVersions()

      //validate
      if (data && typeof count === 'number') {
        this.numOfAvailableDocs = count
        this.modules = data
        if (!background) {
          LoggerService.info('RWASP Modules:', this.modules)
        }
      } else {
        throw Error('Bad Response')
      }
    } catch (e) {
      if (!background) {
        MethodsService.toast('error', 'Error fetching modules', e.toString(), 8)
      }
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._zone.run(() => {})
      this._isRefreshing = false
    }
  }

  async removePage ({ item: module, page }: TableJiraEmitValue<T>) {
    try {
      SpinnerService.spin()
      const lib_module = await this._libs.removePages(module.module, module.version, [page]) as IfDefined<T>
      if (!lib_module) {
        throw Error('')
      }
      this.modules[this.modules.indexOf(this.modules.find(v => v.module == module.module && v.version == module.version))] = lib_module
    } catch (e) {
      MethodsService.toast('error', `Error deleting PAGE-${page} for module ${module.module} v${module.version}`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop()
      this._zone.run(() => {})
    }
  }

  async addPage ({ item: module, page }: TableJiraEmitValue<T>) {
    try {
      SpinnerService.spin()
      const lib_module = await this._libs.addPages(module.module, module.version, page.split(',')) as IfDefined<T>
      if (!lib_module) {
        throw Error('')
      }
      this.modules[this.modules.indexOf(this.modules.find(v => v.module == module.module && v.version == module.version))] = lib_module
    } catch (e) {
      MethodsService.toast('error', `Error adding PAGEs ${page} for module ${module.module} v${module.version}`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop()
      this._zone.run(() => {})
    }

  }

  private async getVersions (options?: FilterArguments<GetAllBundledModulesFilters & any>): Promise<CountedResultObject<T[]>> {
    options = options || {
      filter: this.filterValue,
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sort_by: this.currentSortBy,
      sort_direction: this.isSortReversed ? 'asc' : 'desc',
      modules: this.currentLibModules.length && this.currentLibModules || null,
    }
    return await this._libs.getAllModules(options) as CountedResultObject<T[]>
  }

}
