import { Component, NgZone, OnInit } from '@angular/core'
import { PITenantsService } from '../../services/api/services/pi-tenants.service'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { MethodsService } from '../../services/methods/methods.service'
import { LoggerService } from '../../services/logger/logger.service'
import { PIInstancesService } from '../../services/api/services/pi-instances.service'
import { UserDataService } from '../../services/user-data/user-data.service'
import { GlobalState } from '../../app.state'
import { PIToolsService } from '../../services/api/services/tools.service'
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap'
import { CxConsts } from '../../shared/typings/chameleonx.typings'
import { ENV_SHORT_TO_FULL_RESOLVER } from '../../services/api/processors/pi-instances-processor.service'

@Component({
  selector: '',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss']
})
export class PIToolsComponent implements OnInit {
  isEligibleForBinner: boolean = false
  isEligibleForConfy: boolean = false
  isEligibleForAnything: boolean = false

  title = 'PI Tools'

  _isRefreshing: boolean = false

  TOOLS: APP_TOOLS = {
    [MASTER_TOOLS.BINNER]: {
      type: MASTER_TOOLS.BINNER,
      icon: CxConsts.IconClasses.FILE_ARCHIVE,
      name: 'binner',
      title: 'Event Archive Downloader',
      info: 'Download an event binary from a Kafka topic with partition and offset.',
      assets: {
        regions: [],
        selectedRegion: [],
        topics: [],
        selectedTopic: [],
        allInstances: [],
        selectedInstance: [],
        partition: 0,
        offset: '',
        dataType: '',
      }
    },
    [MASTER_TOOLS.CUSTOMER_CONFIG_DOWNLOADER]: {
      type: MASTER_TOOLS.CUSTOMER_CONFIG_DOWNLOADER,
      icon: CxConsts.IconClasses.FORM,
      name: 'Confy',
      title: 'Customer Config Downloader',
      info: 'Download parsed customer config for local execution.',
      assets: {
        bundleEnvs: [BundleEnvironment.DRY, BundleEnvironment.WET],
        selectedBundleEnv: '',
        selectedTenants: [],
        allTenants: []
      }
    }
  }

  _currentTool: Tool

  ENV_SHORT_TO_FULL_RESOLVER = ENV_SHORT_TO_FULL_RESOLVER

  constructor (private _userData: UserDataService, private _zone: NgZone, private _state: GlobalState, private _tools: PIToolsService, private _clusters: PIInstancesService, private _tenants: PITenantsService) {
    this.isEligibleForBinner = this._userData.hasPermissions('/tools/binner', UserPermission.MAINTAINER)
    this.isEligibleForConfy = this._userData.hasPermissions('/tools/confy', UserPermission.MAINTAINER)
    this.isEligibleForAnything = this.isEligibleForBinner || this.isEligibleForConfy
    switch (true) {
      case !!this.isEligibleForBinner:
        this._currentTool = this.TOOLS[MASTER_TOOLS.BINNER]
        break
      case !!this.isEligibleForConfy:
        this._currentTool = this.TOOLS[MASTER_TOOLS.CUSTOMER_CONFIG_DOWNLOADER]
        break
    }
  }

  ngOnInit () {
    if (this.isEligibleForAnything) {
      void this.reload()
    }
  }

  clearSelections (tool: MASTER_TOOLS) {
    for (const k in this.TOOLS[tool].assets) {
      let value: any
      const _realValue = this.TOOLS[tool].assets[k]
      const type = typeof _realValue
      if (type == 'number') {
        value = 0
      } else if (type == 'string') {
        value = ''
      } else if (type == 'boolean') {
        value = false
      } else if (type == 'object') {
        value = !_realValue ? undefined : Array.isArray(_realValue) ? [] : {}
      } else {
        value = undefined
      }
      this.TOOLS[tool].assets[k] = value
    }
  }

  changeTool ($event: NgbTabChangeEvent) {
    this._currentTool = this.TOOLS[$event.nextId]
    this.clearSelections($event.activeId as MASTER_TOOLS)
    void this.reload()
  }

  async reload (background: boolean = false) {
    if (this._isRefreshing) {
      return
    }
    this._isRefreshing = true
    try {
      if (!background) {
        SpinnerService.spin('mini')
      }

      await this.toolPromise()

    } catch (e) {
      if (!background) {
        MethodsService.toast('error', 'Error fetching reports', e.toString(), 8)
      }
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._zone.run(() => {})
      this._isRefreshing = false
    }
  }

  async toolPromise () {
    switch (this._currentTool.type) {
      case MASTER_TOOLS.BINNER: {

        const [allInstances, binnerMetadata] = await Promise.all([
          this._clusters.getMappedClusters(),
          this._tools.getBinnerMetadata()
        ])

        this._currentTool.assets.allInstances = allInstances
        this._currentTool.assets.topics = binnerMetadata.processorQueues
        this._currentTool.assets.regions = binnerMetadata.regions
        break
      }
      case MASTER_TOOLS.CUSTOMER_CONFIG_DOWNLOADER: {
        const [allTenants] = await Promise.all([
          this._tenants.getMappedCustomers(),
        ])

        this._currentTool.assets.allTenants = allTenants
        break
      }
    }
  }

  async getBinary (type: 'download' | 'preview') {
    const binner = this.TOOLS.BINNER.assets
    console.log('binner:', binner)

    try {
      SpinnerService.spin('mini')
      const response = await this._tools.downloadBinary({
        type: type,
        dataType: binner.dataType,
        region: binner.selectedRegion[0],
        clusterId: binner.selectedInstance[0].id,
        topic: binner.selectedTopic[0].topic,
        offset: binner.offset,
        partition: Number(binner.partition),
      })

      if (response.status >= 300) {
        throw Error(response.data)
      }

      if (type == 'download') {
        const filename = response.headers.get('content-disposition')
          ?.replace('attachment; filename=', '')
          .replace(/"/g, '')

        return MethodsService.downloadAsFile(response.data, filename)

      } else if (type == 'preview') {
        return MethodsService.openTab(response.data)
      }

    } catch (e) {
      MethodsService.toast('error', 'Error fetching binary', e.toString(), 8)

    } finally {
      SpinnerService.stop('mini')
      this._zone.run(() => {})
    }

  }

  async getCustomerConfig () {
    const assets = this.TOOLS[MASTER_TOOLS.CUSTOMER_CONFIG_DOWNLOADER].assets

    const tenant_id = assets.selectedTenants[0].id
    const env = assets.selectedBundleEnv

    try {
      SpinnerService.spin('mini')
      const response = await this._tools.downloadCustomerConfig(tenant_id, env)
      if (response.status >= 300 || !response.data) {
        throw Error(response.data)
      }

      const { filename, fileContent } = response.data

      return MethodsService.downloadAsFile(fileContent, filename)
    } catch (e) {
      MethodsService.toast('error', 'Error fetching Customer Config', e.toString(), 8)

    } finally {
      SpinnerService.stop('mini')
      this._zone.run(() => {})
    }

  }

}
