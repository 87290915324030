import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { GlobalState } from '../../../app.state'
import { UsersPermissionsGroupProcessorService } from '../processors/users-permissions-group-processor.service'

@Injectable()
export class UsersPermissionsGroupService extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async getMetadata (): Promise<EnrichedUsersPermissionsGroupMetadata[]> {
    const response = await this.get(`api/admin_api/user_group/metadata`)
    return UsersPermissionsGroupProcessorService.filterMetadata(
      UsersPermissionsGroupProcessorService.enrichMetadata(response.data as UsersPermissionsGroupMetadata[])
    )
  }

  async getGroups (options: UserPermissionsGroupFilters): Promise<IfDefined<CountedResultObject<ExtendedUsersPermissionsGroupSchema[]>>> {
    const queryString = MethodsService.objectToQueryString(options)
    const response = await this.get(`api/admin_api/users_groups?${queryString}`)
    if (typeof response.code == 'number' && response.code >= 400) {
      throw Error(response.message || '')
    }
    return response.success && {
      success: response.success,
      count: response.count,
      data: UsersPermissionsGroupProcessorService.process(response.data)
    }
  }

  async getGroup (userId: string): Promise<ExtendedUsersPermissionsGroupSchema> {
    const response = await this.get(`api/admin_api/user_group/${userId}`)
    if (typeof response.code == 'number' && response.code >= 400) {
      throw Error(response.message || '')
    }
    return response.success && UsersPermissionsGroupProcessorService.process(response.data as ExtendedUsersPermissionsGroupSchema) as ExtendedUsersPermissionsGroupSchema
  }

  async updateGroup (_id: string, body: UpdateUserPermissionsGroup): Promise<boolean> {
    const response: Users.Response = await this.post(`api/admin_api/user_group/update`, JSON.stringify({ _id, ...body }), true, 'application/json')
    if (typeof response.code == 'number' && response.code >= 400) {
      throw Error(response.message || '')
    }
    return response.success
  }

  async createNewGroup (body: NewUserPermissionsGroup): Promise<boolean> {
    const response = await this.post(`api/admin_api/user_group/create`, JSON.stringify(body), true, 'application/json')
    if (response.success) {
      return response.success
    }
    throw Error(response.data)
  }

  async deleteGroups (_ids: string[]): Promise<boolean> {
    const response = await this.post(`api/admin_api/user_group/delete`, JSON.stringify({ _ids }), true, 'application/json')
    if (response.success) {
      return response.success
    }
    throw Error(response.data)
  }

}

