import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { MethodsService } from '../../services/methods/methods.service'
import { LoggerService } from '../../services/logger/logger.service'
import { GlobalNotification, GlobalState } from '../../app.state'
import { AgentVersionsService } from '../../services/api/services/agent-versions.service'
import { GIT_REPO, JIRA_TICKET_PREFIX } from '../../services/data/data.service'
import { ENV_TO_DEPLOY_FULL_RESOLVER } from '../../services/api/processors/pi-instances-processor.service'

type EnvObject = {
  name: Exclude<CxEnvironment, CxEnvironment.LOCAL>
  short: Exclude<CxEnvironmentShort, CxEnvironmentShort.LOCAL>
  badge: string
}

@Component({
  selector: 'pi-agent-versions',
  templateUrl: './agent-versions.component.html',
  styleUrls: ['./agent-versions.component.scss']
})

export class PIAgentVersionsComponent<T extends AgentVersionsSchema> extends TableWithFilters implements OnInit, OnDestroy {
  readonly JIRA_TICKET_PREFIX = JIRA_TICKET_PREFIX
  readonly GIT_REPO = GIT_REPO

  readonly ENVS: EnvObject[] = [
    { name: CxEnvironment.DEV, short: CxEnvironmentShort.DEV, badge: 'info' },
    { name: CxEnvironment.STAGING, short: CxEnvironmentShort.STAGING, badge: 'primary' },
    { name: CxEnvironment.PRODUCTION, short: CxEnvironmentShort.PRODUCTION, badge: 'note' },
  ]
  readonly title = 'Agent Versions'

  readonly currentEnv = ENV_TO_DEPLOY_FULL_RESOLVER[ENV] || CxEnvironment.LOCAL

  readonly INFO_BANNER = ENV != CxEnvironment.PRODUCTION

  versions: T[] = []
  selectedVersion: T

  //table
  readonly tableHeaders: TableHeader[]

  filterValue: string = ''

  currentSortBy: string

  paginationName = 'Agent Versions'

  textToClip = MethodsService.copyToClipboard

  normalizeString = MethodsService.normalizeString

  _isRefreshing: boolean = false

  constructor (private _versions: AgentVersionsService, private _zone: NgZone, private _state: GlobalState) {
    super()
    this.currentItemsPerPage = this.itemsPerPageFilter[1]
    this.tableHeaders = [
      //@formatter:off
      { name: 'Created',                sortable: true,   field: 'createdAt'          },
      { name: 'Hash',                   sortable: true,   field: 'hash'               },
      { name: 'Version',                sortable: true,   field: 'paddedVersion'     },
      { name: 'Modules',                sortable: false                               },
      { name: 'JIRA',                   sortable: false,                              },
      { name: 'Available',              sortable: true,   field: 'prod.available'     },
      // { name: 'Active',                 sortable: true,   field: 'prod.active'        },
      { name: 'Default',                sortable: true,   field: 'prod.isDefault'     },
      { name: 'Actions',                sortable: false                               },
      //@formatter:on
    ].filter(x => !!x)
    this.currentSortBy = this.tableHeaders[0].field
  }

  ngOnDestroy (): void {
    window.removeEventListener('keyup', this.registerModalListeners.bind(this))
  }

  ngOnInit (): void {
    window.addEventListener('keyup', this.registerModalListeners.bind(this))
    this.refreshTable!()
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    if (!this.selectedVersion) {
      return
    }
    if (key === 'Escape') {
      this.selectedVersion = undefined
    }
  }

  async refreshTable (background: boolean = false): Promise<void> {
    if (this._isRefreshing) {
      return
    }
    this._isRefreshing = true
    try {
      if (!background) {
        SpinnerService.spin('mini')
        this.selectedVersion = undefined
      }
      const { data, count } = await this.getVersions()

      //validate
      if (data && typeof count === 'number') {
        this.numOfAvailableDocs = count
        this.versions = data
        if (!background) {
          LoggerService.info('RWASP Versions:', this.versions)
        }
      } else {
        throw Error('Bad Response')
      }
    } catch (e) {
      if (!background) {
        MethodsService.toast('error', 'Error fetching versions', e.toString(), 8)
      }
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._zone.run(() => {})
      this._isRefreshing = false
    }
  }

  async setAvailable (version: T, env: EnvObject) {
    const message = await MethodsService.confirm(
      `Version Activation`,
      `You are about to activate Agent v${version.version} for ${env.name} environment,<br>Proceed?`,
      `ACTIVATE`,
    )

    if (!message) {
      return
    }

    try {
      SpinnerService.spin()
      const success = await this._versions.setAvailable(version.hash, env.name)
      if (!success) {
        throw Error('')
      }
      await this.refreshTable()
    } catch (e) {
      MethodsService.toast('error', `Error activating version ${version.version} for ${env.name.toUpperCase()} environment.`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop()
      this._zone.run(() => {})
    }
  }

  async setUnavailable (version: T, env: EnvObject) {
    if (version[env.name].isDefault) {
      return MethodsService.dialog(
        `Action not allowed - ${version.version} - ${env.short}`,
        `Cannot set Default version Unavailable<br>Please set another Default first for the ${env.name.toUpperCase()} environment.`
      )
    }
    const prompt = await MethodsService.prompt(
      `Version Deactivation`,
      `You are about to deactivate Agent v${version.version} for ${env.name.toUpperCase()} environment.<br>Proceed?`,
      `eg: 'PAGE-1234 - performance issue'..`,
      { title: 'Input Error', message: 'Must enter "page-xxxx"' },
      'DEACTIVATE',
      (str: string) => /(page-(\d+)\W+)?(.*)/i.test(str)
    )

    if (!prompt) {
      return
    }

    const [_, __, page, comment = ''] = /(page-(\d+)\W+)?(.*)/i.exec(prompt.input) || []

    try {
      SpinnerService.spin()
      const success = await this._versions.setUnavailable(version.hash, env.name, comment, page ? [page] : [])
      if (!success) {
        throw Error('')
      }
      await this.refreshTable()
    } catch (e) {
      MethodsService.toast('error', `Error setting version ${version.version} unavailable for ${env.name.toUpperCase()} environment`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop()
      this._zone.run(() => {})
    }
  }

  async setDefault (version: T, env: EnvObject) {
    if (!await MethodsService.confirm(
      `Default Version`,
      `You are about to set ${version.version} as Default for ${env.name.toUpperCase()} environment.<br>Proceed?`,
      `PROCEED`
    )) {
      return
    }

    try {
      SpinnerService.spin()
      const success = await this._versions.setDefault(version.hash, env.name)
      if (!success) {
        throw Error('')
      }
      await this.refreshTable()
    } catch (e) {
      MethodsService.toast('error', `Error setting version ${version.version} as default for ${env.name} environment`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop()
      this._zone.run(() => {})
    }

  }

  async removePage ({ item: version, page }: TableJiraEmitValue<T>) {
    try {
      SpinnerService.spin()
      const agent_version = await this._versions.removePages(version.hash, [page])
      if (!agent_version) {
        throw Error('')
      }
      this.versions[this.versions.indexOf(this.versions.find(v => v.hash == agent_version.hash))] = agent_version as T
    } catch (e) {
      MethodsService.toast('error', `Error removing PAGE-${page} from version ${version.version}`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop()
      this._zone.run(() => {})
    }

  }

  async addPage ({ item: version, page }: TableJiraEmitValue<T>) {
    try {
      SpinnerService.spin()
      const agent_version = await this._versions.addPages(version.hash, page.split(','))
      if (!agent_version) {
        throw Error('')
      }
      this.versions[this.versions.indexOf(this.versions.find(v => v.hash == agent_version.hash))] = agent_version as T
    } catch (e) {
      MethodsService.toast('error', `Error adding PAGEs ${page} to version ${version.version}`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop()
      this._zone.run(() => {})
    }
  }

  private async getVersions (options?: FilterArguments): Promise<CountedResultObject<T[]>> {
    options = options || {
      filter: this.filterValue,
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sort_by: this.currentSortBy,
      sort_direction: this.isSortReversed ? 'asc' : 'desc'
    }
    return await this._versions.getAllVersions(options) as CountedResultObject<T[]>
  }

}
