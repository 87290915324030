import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { PITenantsService } from '../../services/api/services/pi-tenants.service'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { LoggerService } from '../../services/logger/logger.service'
import { MethodsService } from '../../services/methods/methods.service'
import { PIInstancesService } from '../../services/api/services/pi-instances.service'
import { UserDataService } from '../../services/user-data/user-data.service'
import { USER_POWER_LEVEL } from '../../shared/user-power'
import { GlobalNotification, GlobalState } from '../../app.state'
import {
  canPerformActionOnInstance,
  isInstanceActive,
  PiInstancesProcessorService
} from '../../services/api/processors/pi-instances-processor.service'
import { CLUSTER_STATUSES } from '../../services/data/data.service'

@Component({
  selector: 'pi-instances',
  templateUrl: './pi-instances.component.html',
  styleUrls: ['./pi-instances.component.scss']
})
export class PIInstancesComponent extends TableWithFilters implements OnInit, OnDestroy {

  readonly title = 'Instances Management'

  readonly paginationName = 'Instances'

  filterValue: string = ''

  CLUSTER_STATUSES = CLUSTER_STATUSES

  clusterEnvironments: ProcessedPIOperationsEnvironments[] = []
  selectedEnvironment?: ProcessedPIOperationsEnvironments

  clusters: EnrichedCustomerMappingSchema[] = []
  activePaidTierClusters: EnrichedCustomerMappingSchema[] = []
  activeFreeTierClusters: EnrichedCustomerMappingSchema[] = []
  selectedCluster: EnrichedCustomerMappingSchema
  defaultAssignedPaidTierCluster: Nullable<EnrichedCustomerMappingSchema>
  defaultAssignedFreeTierCluster: Nullable<EnrichedCustomerMappingSchema>
  editDefaultPaidInstance: boolean = false
  editDefaultFreeInstance: boolean = false

  availableTenants: MappedTenants = []
  allTenants: MappedTenants = []
  instanceLessTenants: MappedTenants = []

  associatedTenants: MappedTenants = []
  unAssociatedTenants: MappedTenants = []

  metadata: { key: string, value: string }[] = []

  //modals
  isCreateClusterActive: boolean = false

  isAdmin: boolean = false
  //table
  readonly tableHeaders: TableHeader[]

  currentSortBy: string

  _isRefreshing: boolean = false

  textToClip = MethodsService.copyToClipboard

  normalizeString = MethodsService.normalizeString

  upperCasedString = MethodsService.upperCasedString

  canPerformActionOnInstance = canPerformActionOnInstance

  constructor (private _userData: UserDataService, private _clusters: PIInstancesService, private _customers: PITenantsService, private _zone: NgZone, private _state: GlobalState) {
    super()
    this.currentItemsPerPage = this.itemsPerPageFilter[1]
    this.tableHeaders = [
      //@formatter:off
      USER_POWER_LEVEL[this._userData.permission] >= USER_POWER_LEVEL[UserPermission.ADMIN] ?
        { /*width: '4', */  name: '',                   sortable: false,    field: ''             } : undefined,
      { /*width: '12',*/  name: 'Created At',           sortable: true,     field: 'createdAt'    },
      { /*width: '12',*/  name: 'Last Modified',        sortable: true,     field: 'updatedAt'    },
      { /*width: '12',*/  name: 'Instance ID',          sortable: true,     field: 'clusterId'    },
      { /*width: '12',*/  name: 'Primary Instance',     sortable: false,                          },
      { /*width: '12',*/  name: 'Name',                 sortable: true,     field: 'clusterName'  },
      { /*width: '12',*/  name: 'Plan',                 sortable: true,     field: 'tier'         },
      { /*width: '12',*/  name: 'Location',             sortable: true,     field: 'geo'          },
      { /*width: '12',*/  name: 'Tenants',              sortable: false,                          },
      { /*width: '18',*/  name: 'Akamai Accounts',      sortable: false,                          },
      { /*width: '18',*/  name: 'Actions',              sortable: false,                          },

      //@formatter:on
    ].filter(x => !!x)
    this.currentSortBy = this.tableHeaders[1].field
    this.isSortReversed = true
    this.isAdmin = USER_POWER_LEVEL[this._userData.permission] >= USER_POWER_LEVEL[UserPermission.ADMIN]
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    if (!this.selectedCluster && !this.isCreateClusterActive && !this.editDefaultPaidInstance && !this.editDefaultFreeInstance) {
      return
    }
    if (key === 'Escape') {
      this.clearSelections()
    }
  }

  ngOnDestroy (): void {
    window.removeEventListener('keyup', this.registerModalListeners.bind(this))
  }

  ngOnInit (): void {
    window.addEventListener('keyup', this.registerModalListeners.bind(this))
    this.refreshTable!()
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  toggleState () {
    const state = !isInstanceActive(this.selectedCluster.status) ?
      CLUSTER_STATUS.ACTIVE :
      CLUSTER_STATUS.INACTIVE

    this.updateCluster(this.selectedCluster.clusterName, state)
  }

  private clearSelections () {
    this.metadata = []
    this.associatedTenants = []
    this.selectedCluster = undefined
    this.isCreateClusterActive = false
    this.availableTenants = []
    this.editDefaultPaidInstance = false
    this.editDefaultFreeInstance = false
  }

  async refreshTable (background: boolean = false): Promise<void> {
    if (this._isRefreshing) {
      return
    }
    this._isRefreshing = true
    try {
      if (!background) {
        this.clusters = []
        this.allTenants = []
        this.clearSelections()
        SpinnerService.spin('mini')
      }
      const [{ count, data }, mappedCustomers, instanceLessCustomers, uniqueData] = await Promise.all([
        this.getClusters(),
        this._customers.getMappedCustomers(),
        this._customers.getInstancelessCustomers(),
        !this.isCreateClusterActive ? this._clusters.getUniqueData() : null
      ].filter(x => !!x) as PromiseLike<any>[])

      if (typeof count == 'number' && data && mappedCustomers && instanceLessCustomers && uniqueData) {
        this.clusters = data.map(cluster => PiInstancesProcessorService.enrichInstance(uniqueData, cluster))

        // Default assigned clusters
        this.defaultAssignedPaidTierCluster = (this.clusters.find(c => !c.isFreeTier && c.isDefaultAssignedInstance) as EnrichedCustomerMappingSchema) || null
        this.defaultAssignedFreeTierCluster = (this.clusters.find(c => c.isFreeTier && c.isDefaultAssignedInstance) as EnrichedCustomerMappingSchema) || null

        this.activePaidTierClusters = this.clusters.filter(c => canPerformActionOnInstance(c.status) && !c.isFreeTier)
        this.activeFreeTierClusters = this.clusters.filter(c => canPerformActionOnInstance(c.status) && c.isFreeTier)

        this.numOfAvailableDocs = count
        this.allTenants = mappedCustomers
        this.instanceLessTenants = mappedCustomers.filter(({ id }) => instanceLessCustomers.includes(id))
        this.unAssociatedTenants = this.allTenants.filter(t => !t.clusterId)

        if (uniqueData) {
          this.clusterEnvironments = uniqueData
            .map((d: ProcessedPIOperationsEnvironments) => {
              d.regions = d.regions.filter(r => r.enabled)
              d.plans = d.plans.filter(p => p.enabled)
              return d
            })
          this.selectedEnvironment = this.clusterEnvironments[0]
        }
      }
    } catch (e) {
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._zone.run(() => {})
      this._isRefreshing = false
    }

  }

  async getCluster (clusterId: string) {
    try {
      SpinnerService.spin('mini')
      const cluster = await this._clusters.getCluster(clusterId)
      if (cluster) {
        if (cluster.imageEnvironment == PiOperationalEnvironment.CLUSTER) {
          this.associatedTenants = (cluster.tenants as any as string[]).map(tenantId => {
            const tenant = this.allTenants.find(t => t.id == tenantId)
            return tenant ? {
              id: tenantId,
              name: tenant.name,
              _name: tenant.name,
              website: tenant.website
            } : null
          }).filter(x => !!x)
        }

        for (const key in cluster.metadata) {
          this.metadata.push({ key, value: cluster.metadata[key] })
        }

        this.selectedEnvironment = this.clusterEnvironments.find(env => env.id == cluster.environment)

        this.selectedCluster = PiInstancesProcessorService.enrichInstance(this.clusterEnvironments, cluster)

        this.availableTenants = [...this.instanceLessTenants, ...this.associatedTenants]
          .sort(({ name: a }, { name: b }) => a > b ? 1 : b > a ? -1 : 0)

        LoggerService.info(cluster)
      }
    } catch (e) {
      MethodsService.toast('error', 'Error fetching cluster ' + clusterId, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
      this._zone.run(() => {})
    }
  }

  async updateCluster (clusterName: string, status?: CLUSTER_STATUS) {

    if (!clusterName) {
      return MethodsService.dialog(
        'Bad Cluster Parameters',
        'Please fill the required fields'
      )
    }

    const selected: CustomerMappingSchema<PiOperationalEnvironment.CLUSTER> = { ...this.selectedCluster }

    if (status) {
      selected.status = status
    }

    selected.clusterName = clusterName
    ;(selected.tenants as any as string[]) = this.associatedTenants.map(({ id }) => id)

    try {
      SpinnerService.spin('mini')
      const updated = await this._clusters.updateCluster(selected)
      if (updated) {
        await this.refreshTable()
      }
    } catch (e) {
      MethodsService.toast('error', 'Error updating customer', e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }

  }

  async createNewCluster (clusterId: string, clusterName: string, tier: string, geo: string) {
    if (!clusterId || !clusterName || !tier || !geo) {
      return MethodsService.dialog(
        'Bad Cluster Parameters',
        'Please fill the required fields'
      )
    }

    const cluster = {
      clusterId,
      clusterName,
      geo,
      tier,
    }

    try {
      SpinnerService.spin('mini')
      const newCustomer = await this._clusters.createNewCluster(cluster)
      if (newCustomer) {
        await this.refreshTable()
      }
    } catch (e) {
      MethodsService.toast('error', 'Error creating new cluster', e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }
  }

  async deleteClusters () {
    const title = `Delete Confirmation`
    const content = `Delete ${this.selectedTableItems.size} Instance${this.selectedTableItems.size > 1 ? 's' : ''} ?`
    const deleteConfirmationText = 'Delete'
    if (await MethodsService.confirm(title, content, deleteConfirmationText)) {

      SpinnerService.spin('mini')

      try {
        const success = await this._clusters.deleteClusters([...this.selectedTableItems])
        if (success) {
          this.selectedTableItems.clear()
          await this.refreshTable()
        }
      } catch (e) {
        LoggerService.error(e)
      }
      SpinnerService.stop('mini')
    }
  }

  async testTFInstance () {
    if (!await MethodsService.confirm(
      'Operation Confirm',
      `You are about to TEST Instance ${this.selectedCluster.clusterId}<br>Proceed?`,
      `PROCEED`
    )) {
      return
    }
    try {
      SpinnerService.spin('mini')
      const success = await this._clusters.testTFInstance(this.selectedCluster.clusterId)
      if (success) {
        await this.refreshTable()
      } else {
        throw Error('')
      }
    } catch (e) {
      MethodsService.toast('error', 'Error testing instance', e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }
  }

  async updateTFInstance (stage: string) {
    const txt = stage == 'all' ? 'Update Instance (all stages)' : 'Update Application (stage 4 only) for Instance'
    if (!await MethodsService.confirm(
      'Operation Confirm',
      `You are about to ${txt} ${this.selectedCluster.clusterId}<br>Proceed?`,
      `PROCEED`
    )) {
      return
    }
    try {
      SpinnerService.spin('mini')
      const success = await this._clusters.updateTFInstance(this.selectedCluster.clusterId, stage)
      if (success) {
        this.clearSelections()
        await this.refreshTable()
      } else {
        throw Error('')
      }
    } catch (e) {
      MethodsService.toast('error', 'Error updating instance', e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }
  }

  private async getClusters (options?: FilterArguments) {
    options = options || {
      filter: this.filterValue,
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sort_by: this.currentSortBy,
      sort_direction: this.isSortReversed ? 'asc' : 'desc'
    }
    return await this._clusters.getAllClusters(options)
  }

  private async setDefaultAssignedCluster (clusterId: string, isFreeTier: boolean = false) {
    SpinnerService.spin('mini')
    try {
      const success = await this._clusters.setDefaultAssignedCluster(clusterId, isFreeTier)
      if (success) {
        MethodsService.toast('success', 'Default assigned instance successfully set', clusterId, 8)
      }
    } catch (e) {
      MethodsService.toast('error', 'Error setting default assigned instance', e.toString(), 8)
    }
    SpinnerService.stop('mini')
    this.refreshTable()
  }

  getCurrentPlanDescription (planId: string): string {
    for (const { plans } of this.clusterEnvironments) {
      const banner = '-'.repeat(40)
      const plan = plans.find(p => p.id == planId)
      if (plan) {
        const { id, description, k8s_cluster, mongodb, redis } = plan
        const title = `Plan ${id}`
        const sub_title = description && `Description: ${description}`
        const body = JSON.stringify({ k8s_cluster, mongodb, redis }, null, 4).replace(/[",}{]/g, '')

        return [
          title,
          sub_title,
          body
        ]
          .filter(x => !!x)
          .join(`\n${banner}\n`)
      }
    }
    return ''
  }

  openTab (url: string) {
    window.open(url)
  }

  changeEnv (event: any) {
    const env: CxEnvironmentShort = event.target.options[event.target.selectedIndex].value
    this.selectedEnvironment = this.clusterEnvironments.find(e => e.id == env)
  }

}
