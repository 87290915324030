import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public readonly UI_VERSION: string

  constructor () {
    this.UI_VERSION = `UI Version ${UI_VERSION}`
  }

  ngOnInit () {

  }

}
