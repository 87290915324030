import { Component, Input, NgZone, OnInit } from '@angular/core'
import { MethodsService } from '../../../../services/methods/methods.service'
import { PIInstancesService } from '../../../../services/api/services/pi-instances.service'
import { GlobalState } from '../../../../app.state'
import { PiEventConsoleService } from '../../../../services/api/services/pi-event-console.service'
import { TableWithFilters } from '../../../absracts/table-with-filters'
import { SpinnerService } from '../../../../services/spinner/spinner.service'
import { LoggerService } from '../../../../services/logger/logger.service'
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap'

declare const enum SourceDestinationScope {
  source_hostname = 'source_hostname',
  source_scripts = 'source_scripts',
  destination_hostname = 'destination_hostname',
  destination_urls = 'destination_urls',
}

type ScopeTabKey = `${SourceDestinationScope}-tab`

@Component({
  selector: 'event-group-source-destination',
  templateUrl: './event-group-source-destination.component.html',
  styleUrls: ['./event-group-source-destination.component.scss'],
})
export class EventGroupSourceDestinationComponent extends TableWithFilters implements OnInit {

  @Input() tenantId: string
  @Input() eventGroupId: string
  @Input() currentTime: { text: string, absTime: Function, time: Function }
  @Input() metadata: ExtendedEventGroupSchema
  @Input() scripts: EventGroupScripts = { count: 0, scripts: [] }
  @Input() scriptDestinations: EventGroupScriptEndpointsResult

  tableHeaders: TableHeader[]
  currentSortBy: string = 'timesSeen'
  paginationName = 'Source Scripts'

  //state
  currentScope: SourceDestinationScope = SourceDestinationScope.source_scripts
  activeScope: ScopeTabKey = 'source_scripts-tab'
  _isRefreshing: boolean = false

  //imports
  numberToSuffix = MethodsService.numberToSuffix
  truncateURL = MethodsService.truncateURL
  copyToClipboard = MethodsService.copyToClipboard
  open = window.open

  constructor (private _api: PiEventConsoleService, private _clusters: PIInstancesService, protected _zone: NgZone, private _state: GlobalState) {
    super()
    this.tableHeaders = [
      //@formatter:off
      { width: '85',  name: 'Script',             sortable: true,    field: 'source.url'        },
      { width: '',    name: 'Beacons',            sortable: true,    field: 'timesSeen'         },
      { width: '',    name: '',                   sortable: false,   field: ''                  },
      //@formatter:on
    ].filter(x => !!x)
  }

  ngOnInit (): void {
    this.numOfAvailableDocs = this.scripts.count
  }

  onTabChange ($event: NgbTabChangeEvent) {
    const tab = $event.nextId as ScopeTabKey
    const scope = tab.split('-')[0] as SourceDestinationScope
    this.currentScope = scope
    this.activeScope = tab
    this.currentPage = 1
    switch (scope) {
      case SourceDestinationScope.source_scripts: {
        this.paginationName = 'Source Scripts'
        this.numOfAvailableDocs = this.scripts.count
        break
      }
      case SourceDestinationScope.destination_urls: {
        this.paginationName = 'Destination URLs'
        this.numOfAvailableDocs = this.scriptDestinations.count
      }
    }
    this.ref_hljs(this._zone)
  }

  async refreshTable (background: boolean = false): Promise<void> {
    if (this._isRefreshing) {
      return
    }
    this._isRefreshing = true
    try {

      switch (this.currentScope) {
        case SourceDestinationScope.source_scripts: {
          SpinnerService.spin('mini')
          this.scripts = await this._api.getEventGroupScripts(this.tenantId, this.eventGroupId, {
            fromDate: this.currentTime.time(),
            toDate: Date.now(),
            limit: this.currentItemsPerPage,
            page: this.currentPage - 1,
            sortBy: this.currentSortBy,
            sortDirection: this.isSortReversed ? 1 : -1,
          })
          this.numOfAvailableDocs = this.scripts.count
          break
        }
        case SourceDestinationScope.destination_urls: {
          SpinnerService.spin('mini')
          this.scriptDestinations = await this._api.getScriptDestinations(this.tenantId, this.eventGroupId, {
            fromDate: this.currentTime.time(),
            toDate: Date.now(),
            limit: this.currentItemsPerPage,
            page: this.currentPage - 1,
            sortBy: this.currentSortBy,
            sortDirection: this.isSortReversed ? 1 : -1,
          })
          this.numOfAvailableDocs = this.scriptDestinations.count
          break
        }
      }

      // await this.fetchData()
    } catch (e) {
      MethodsService.toast('error', 'Error fetching event groups scripts', e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
      this.ref_hljs(this._zone)
      this._isRefreshing = false
    }
  }

}
