import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { GlobalState } from '../../../app.state'
import { AgentVersionsProcessor } from '../processors/agent-versions-processor.service'

@Injectable()
export class AgentVersionsService extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async getAvailableBundlingVersions (): Promise<LatestVersion[]> {
    return (
      await this.get(`api/admin_api/agent_versions/get_available`)
    ).data
  }

  async getAllVersions (options: FilterArguments): Promise<CountedResultObject<AgentVersionsSchema[]>> {
    const queryString = MethodsService.objectToQueryString(options)
    const response = await this.get(`api/admin_api/agent_versions?${queryString}`) as CountedResultObject<AgentVersionsSchema[]>
    return {
      count: response.count,
      success: response.success,
      data: AgentVersionsProcessor.process(response.data) as AgentVersionsSchema[]
    }
  }

  async setAvailable (hash: number, environment: EligibleEnv) {
    const body = JSON.stringify({ hash, environment })
    const response = await this.post(`api/admin_api/agent_versions/set_available`, body, true, 'application/json')
    return response.success
  }

  async setUnavailable (hash: number, environment: EligibleEnv, comment: string, pages: string[]) {
    const body = JSON.stringify({ hash, environment, comment, pages })
    const response = await this.post(`api/admin_api/agent_versions/set_unavailable`, body, true, 'application/json')
    return response.success
  }

  async setDefault (hash: number, environment: EligibleEnv) {
    const body = JSON.stringify({ hash, environment })
    const response = await this.post(`api/admin_api/agent_versions/set_default`, body, true, 'application/json')
    return response.success
  }

  async addPages (hash: number, pages: string[]): Promise<AgentVersionsSchema> {
    const body = JSON.stringify({ hash, pages })
    const response = await this.post(`api/admin_api/agent_versions/add_pages`, body, true, 'application/json')
    return AgentVersionsProcessor.process(response.data as AgentVersionsSchema) as AgentVersionsSchema
  }

  async removePages (hash: number, pages: string[]): Promise<AgentVersionsSchema> {
    const body = JSON.stringify({ hash, pages })
    const response = await this.post(`api/admin_api/agent_versions/remove_pages`, body, true, 'application/json')
    return AgentVersionsProcessor.process(response.data as AgentVersionsSchema) as AgentVersionsSchema
  }

}

