import { AfterViewInit, Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'cx-full-tag',
  templateUrl: './cx-full-tag.component.html',
  styleUrls: ['./cx-full-tag.component.scss']
})
export class CxFullTagComponent implements OnInit, AfterViewInit {
  @Input() text: string

  @Input() class?: string = ''

  @Input() iconClass?: string = ''

  @Input() clickFunction?: (text: string) => any

  constructor () {

  }

  ngOnInit (): void {
  }

  ngAfterViewInit (): void {
  }

  clickEvent (): void {
    if (this.clickFunction) {
      this.clickFunction(this.text)
    }
  }


}
