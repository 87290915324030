import { AfterContentInit, Component, Input } from '@angular/core'

function k8sServiceAppLink (instanceId: string): string {
  return `https://${instanceId}-dashboard.pilayer.net/#/workloads?namespace=app`
}
@Component({
  selector: 'pi-instance-id',
  templateUrl: './pi-instance-id.component.html',
})
export class PIInstanceIdComponent implements AfterContentInit {
  @Input() public id: string
  @Input() public name?: string
  @Input() public hideID?: boolean = false

  k8sLink: string

  ngAfterContentInit () {
    this.k8sLink = k8sServiceAppLink(this.id)
  }

}
