import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { GlobalState } from '../../../app.state'
import { LogsProcessorService } from '../processors/logs-processor.service'

type LogsUniqueDataResponse = {
  users: string[]
  collections: CxMasterCollections[]
}

@Injectable()
export class LogsService extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async getAllLogs (options: FilterArguments): Promise<CountedResultObject<LogsSchema[]>> {
    const queryString = MethodsService.objectToQueryString(options)
    const response = await this.get(`api/admin_api/logs?${queryString}`) as CountedResultObject<LogsSchema[]>
    return {
      count: response.count,
      success: response.success,
      data: response.data.map(LogsProcessorService.process) as LogsSchema[]
    }
  }

  async getUniqueData (): Promise<LogsUniqueDataResponse> {
    const response = await this.get(`api/admin_api/logs/distinct`) as ResultObject<LogsUniqueDataResponse>
    return response.data
  }

}

