import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { GlobalState } from '../../../app.state'
import { PiDeploymentsGroupsProcessorService } from '../processors/pi-deployment-groups-processor.service'

@Injectable()
export class PiDeploymentGroupsService<T extends ExtendedDeploymentGroupsSchema = ExtendedDeploymentGroupsSchema> extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async getConfig (): Promise<DeploymentGroupsConfigObject> {
    const response = await this.get(`api/admin_api/deployment_group/config`)
    return response.data
  }

  async getAllGroups (options: FilterArguments): Promise<IfDefined<CountedResultObject<T[]>>> {
    const queryString = MethodsService.objectToQueryString(options)
    const response = await this.get(`api/admin_api/deployment_groups?${queryString}`)
    return response.success && {
      success: response.success,
      count: response.count,
      data: PiDeploymentsGroupsProcessorService.process(response.data) as T[]
    }
  }

  async createNewGroup (body: NewDeploymentGroupOptions): Promise<boolean> {
    const response = await this.post(`api/admin_api/deployment_group/create`, JSON.stringify(body), true, 'application/json')
    if (response.success) {
      return response.success
    }
    throw Error(response.data)
  }

  async updateGroup (_id: string, body: UpdateDeploymentGroupOptions): Promise<boolean> {
    const response = await this.post(`api/admin_api/deployment_group/update`, JSON.stringify({ _id, ...body }), true, 'application/json')
    if (response.success) {
      return response.success
    }
    throw Error(response.data)
  }

  async deleteGroups (_ids: string[]): Promise<boolean> {
    const response = await this.post(`api/admin_api/deployment_group/delete`, JSON.stringify({ _ids }), true, 'application/json')
    if (response.success) {
      return response.success
    }
    throw Error(response.data)
  }
}

