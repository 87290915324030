import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { GlobalState } from '../../../app.state'
import { PiCustomerPoliciesProcessorService } from '../processors/pi-customer-policies-processor.service'

@Injectable()
export class PiCustomerPoliciesService extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async getCustomerPolicies (options: AllPoliciesFilterArguments): Promise<AllPoliciesResult> {
    const queryString = MethodsService.objectToQueryString(options)
    const res = await this.get<AllPoliciesResult>(`api/admin_api/policy/customer_policies?${queryString}`)
    return {
      count: res.data.count,
      data: PiCustomerPoliciesProcessorService.processPolicies(res.data.data)
    }
  }

}
