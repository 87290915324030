import { Component, forwardRef } from '@angular/core'
import { MethodsService } from '../../../services/methods/methods.service'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
  selector: 'cx-spinner',
  templateUrl: './cx-spinner.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CxSpinnerComponent),
      multi: true
    }
  ]
})
export class CxSpinnerComponent implements ControlValueAccessor {

  public readonly UNIQUE_SPINNER_ID = MethodsService.generateUniqueID('letters')

  private isSpinnerActive: boolean //ngModel

  onChange (isSpinnerActive: boolean) {
    this.handler(isSpinnerActive)
  }

  writeValue (isSpinnerActive: boolean): void {
    this.handler(isSpinnerActive)
  }

  registerOnChange (fn: (isSpinnerActive: boolean) => void): void {
    this.onChange = fn
  }

  registerOnTouched (fn: (isSpinnerActive: boolean) => void): void {
    this.onChange = fn
  }

  private handler (isSpinnerActive: boolean) {
    this.isSpinnerActive = isSpinnerActive
    const element = document.getElementById(this.UNIQUE_SPINNER_ID)
    if (element) {
      element.style.display = this.isSpinnerActive ? 'block' : 'none'
    }
  }

}
