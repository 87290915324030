import { Component, NgZone, OnInit } from '@angular/core'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { MethodsService } from '../../services/methods/methods.service'
import { LoggerService } from '../../services/logger/logger.service'
import { UserDataService } from '../../services/user-data/user-data.service'
import { GlobalNotification, GlobalState } from '../../app.state'
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap'
import { PIInstancesService } from '../../services/api/services/pi-instances.service'
import { PiMongodbAnalyticsService } from '../../services/api/services/pi-mongodb-analytics.service'

const enum TabScopes {
  COLLECTIONS,
  COLLECTIONS_INDEXES,
}

const enum PaginationNames {
  COLLECTIONS = 'Collections',
  COLLECTIONS_INDEXES = 'Indexes',
}

@Component({
  selector: '',
  templateUrl: './pi-mongodb-analytics.component.html',
  styleUrls: ['./pi-mongodb-analytics.component.scss']
})

export class PIMongoDBAnalyticsComponent extends TableWithFilters implements OnInit {

  readonly title = 'PI MongoDB Analytics'

  paginationName = PaginationNames.COLLECTIONS

  //table headers
  tableHeaders: TableHeader[]
  readonly collectionsTableHeaders: TableHeader[]
  readonly collectionIndexesTableHeaders: TableHeader[]

  metadata: CollectionIndexStatsMetadataObject

  //filters
  filterValue: string = ''
  itemsPerPageFilter = [10, 20, 50, 100, 200, 500, 1000]
  availableGranularity: string[] = ['auto', 'hour', 'day']
  currentGranularity = this.availableGranularity[0]

  availableClusters: string[] = []
  currentClusters = this.availableClusters

  availableDBs: string[] = []
  currentDBs = this.availableDBs

  availableCols: string[] = []
  currentCols = this.availableCols

  //collections filters
  indexesNonMatchSchema: boolean = false
  filterInactiveCollections: boolean = true

  //indexes tab filters
  isEligibleToSyncIndexes: boolean = false

  availableIndexes: string[] = []
  currentIndexes = this.availableIndexes

  schemaIndexes: string[] = ['all', 'schema', 'non-schema']
  currentSchemaIndexes = this.schemaIndexes[0]

  filterInactiveIndexes: boolean = true

  //table
  collectionsTableValues: ExtendedCollectionStatsTimeSeries[]
  collectionIndexesTableValues: ExtendedCollectionIndexStatsTimeSeries[]

  currentTime = this.timeFilter[4]

  currentScope: TabScopes
  activeIdString: 'collections-tab' | 'indexes-tab' = 'collections-tab'

  currentSortBy: string

  //collection total data
  totalDocuments: number = 0
  totalStorageSize: number = 0
  totalIndexesSize: number = 0
  totalSize: number = 0
  totalCacheSize: number = 0

  //indexes total data
  totalIndexesIndexesSize: number = 0
  totalIndexesCacheSize: number = 0

  _isRefreshing: boolean = false
  sliceString = MethodsService.sliceString
  integerWithCommas = MethodsService.integerWithCommas
  formatBytes = MethodsService.formatBytes
  numberToSuffix = MethodsService.numberToSuffix
  stringify = JSON.stringify

  private _debounce: void | number
  private debounce_timeout: number = 1000

  constructor (private _userData: UserDataService, private _api: PiMongodbAnalyticsService, private _clusters: PIInstancesService, private _zone: NgZone, private _state: GlobalState) {
    super()
    this.isEligibleToSyncIndexes = this._userData.hasPermissions('/mongodb_analytics/sync_indexes', UserPermission.ADMIN)
    this.currentItemsPerPage = this.itemsPerPageFilter[1]
    this.collectionsTableHeaders = [
      //@formatter:off
        { width: '',    name: 'Last Sample',    sortable: true,     field: 'lastSample'     },
        { width: '',    name: 'Cluster ID',     sortable: true,     field: 'clusterId'      },
        { width: '',    name: 'Database',       sortable: true,     field: 'db'             },
        { width: '',    name: 'Collection',     sortable: true,     field: 'col'            },
        { width: '',    name: 'Documents',      sortable: true,     field: 'count'          },
        // { width: '',    name: 'Indexes',        sortable: true,     field: 'nIndexes'       },
        { width: '',    name: 'Storage Size',   sortable: true,     field: 'storageSize'    },
        { width: '',    name: 'Indexes Size',   sortable: true,     field: 'totalIndexSize' },
        { width: '',    name: 'Total Size',     sortable: true,     field: 'totalSize'      },
        { width: '',    name: 'Cache Size',     sortable: true,     field: 'cacheSize'      },
      //@formatter:on
    ].filter(x => !!x)
    this.collectionIndexesTableHeaders = [
      //@formatter:off
        { width: '',    name: 'Last Sample',    sortable: true,     field: 'lastSample'     },
        { width: '',    name: 'Cluster ID',     sortable: true,     field: 'clusterId'      },
        { width: '',    name: 'Database',       sortable: true,     field: 'db'             },
        { width: '',    name: 'Collection',     sortable: true,     field: 'col'            },
        { width: '',    name: 'Index Name',     sortable: true,     field: 'index'          },
        { width: '',    name: 'Schema Details', sortable: true,     field: 'isSchemaIndex'  },
        { width: '',    name: 'Index Size',     sortable: true,     field: 'indexSize'      },
        { width: '',    name: 'Cache Size',     sortable: true,     field: 'cacheSize'      },
      //@formatter:on
    ].filter(x => !!x)

    // Init collections
    this.tableHeaders = this.collectionsTableHeaders
    this.currentScope = TabScopes.COLLECTIONS

    this.currentSortBy = this.tableHeaders.find(({ field }) => field == 'totalSize').field
  }

  ngOnInit (): void {
    this.refreshTable!()
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  async loadRelevantData (): Promise<CountedResultObject<StandardObject>> {
    let results
    switch (this.currentScope) {
      case TabScopes.COLLECTIONS:
        results = await this.getCollections()
        this.collectionsTableValues = results.data
        break
      case TabScopes.COLLECTIONS_INDEXES:
        results = await this.getCollectionsIndexes()
        this.collectionIndexesTableValues = results.data
        break
    }
    this.metadata = results.filterOptions

    this.availableClusters = (this.metadata.clusters || []).sort()
    this.availableCols = (this.metadata.cols || []).sort()
    this.availableDBs = (this.metadata.dbs || []).sort()
    if (this.currentScope == TabScopes.COLLECTIONS_INDEXES) {
      this.availableIndexes = (this.metadata.indexes || []).sort()
    }

    return results
  }

  async refreshTable (background: boolean = false): Promise<void> {
    if (this._isRefreshing) {
      return
    }
    this._isRefreshing = true

    try {
      if (!background) {
        this.clearState()
        SpinnerService.spin('mini')
      }

      const feedResponse = await this.loadRelevantData()

      if (feedResponse) {
        this.numOfAvailableDocs = feedResponse.count
        if (!background) {
          LoggerService.info(feedResponse)
        }
      } else {
        throw Error('Bad Response')
      }
    } catch (e) {
      if (!background) {
        MethodsService.toast('error', 'Error fetching customers', e.toString(), 8)
      }
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this.calcTotals()
      this._zone.run(() => {})
      this._isRefreshing = false
    }
  }

  calcTotals () {

    switch (this.currentScope) {
      case TabScopes.COLLECTIONS_INDEXES:
        this.totalIndexesIndexesSize = this.collectionIndexesTableValues.reduce((acc, { indexSize }) => acc + indexSize, 0)
        this.totalIndexesCacheSize = this.collectionIndexesTableValues.reduce((acc, { cacheSize }) => acc + cacheSize, 0)
        break
      case TabScopes.COLLECTIONS: {
        this.totalDocuments = this.collectionsTableValues.reduce((acc, { count }) => acc + count, 0)
        this.totalStorageSize = this.collectionsTableValues.reduce((acc, { storageSize }) => acc + storageSize, 0)
        this.totalIndexesSize = this.collectionsTableValues.reduce((acc, { totalIndexSize }) => acc + totalIndexSize, 0)
        this.totalSize = this.collectionsTableValues.reduce((acc, { totalSize }) => acc + totalSize, 0)
        this.totalCacheSize = this.collectionsTableValues.reduce((acc, { cacheSize }) => acc + cacheSize, 0)
        break
      }
    }
  }

  async syncIndexes () {
    const prompt = await MethodsService.prompt(
      `Sync MongoDB Indexes`,
      `Makes the indexes in MongoDB match the indexes defined in every model's schema. This function will drop any indexes that are not defined in the model's schema except the "_id" index, and build any indexes that are in your schema but not in MongoDB.`,
      undefined,
      undefined,
      'SYNC',
      undefined,
      false,
      { text: 'Select Clusters', list: this.availableClusters, multi: true, input: false },
      undefined,
      '_mdb-analytics-index-modal'
    )

    if (!prompt || !prompt.pickedFromList?.length) {
      return
    }

    const clusters = prompt.pickedFromList

    try {
      SpinnerService.spin('mini')
      await this._api.syncIndexes(clusters)
      MethodsService.toast('success', 'Index Syncing Started', `The following clusters: ${clusters.toString()} are currently being synced. This process may take a while.`, 15)
      await this.refreshTable()
    } catch (e) {
      MethodsService.toast('error', 'Error Syncing Clusters', e.toString(), 15)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }
  }

  onFilterChange (item: any) {
    if (this._debounce) {
      this._debounce = clearTimeout(this._debounce)
    }
    this._debounce = setTimeout(() => this.refreshTable(), this.debounce_timeout) as any as number
  }

  onTabChange ($event: NgbTabChangeEvent) {
    switch ($event.nextId) {
      case 'collections-tab':
        this.paginationName = PaginationNames.COLLECTIONS
        this.tableHeaders = this.collectionsTableHeaders
        this.currentSortBy = this.tableHeaders.find(({ field }) => field == 'totalSize').field
        this.currentScope = TabScopes.COLLECTIONS
        this.activeIdString = 'collections-tab'
        break
      case 'indexes-tab':
        this.paginationName = PaginationNames.COLLECTIONS_INDEXES
        this.tableHeaders = this.collectionIndexesTableHeaders
        this.currentSortBy = this.tableHeaders.find(({ field }) => field == 'indexSize').field
        this.currentScope = TabScopes.COLLECTIONS_INDEXES
        this.activeIdString = 'indexes-tab'
        break
    }

    void this.refreshTable()
  }

  async getCollections (options?: FilterArguments<CollectionStatsFilters>): Promise<MongoDBAnalyticsCollectionsResponse> {
    options = options || {
      fromDate: this.currentTime.time(),
      filter: this.filterValue,
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sortBy: this.currentSortBy,
      sortDirection: this.isSortReversed ? 1 : -1,
    }
    this.fillOptions(options)
    if (this.filterInactiveCollections) {
      options.filterInactive = this.filterInactiveCollections
    }
    if (this.indexesNonMatchSchema) {
      options.indexesNonMatchSchema = this.indexesNonMatchSchema
    }

    return this._api.getCollections(options)
  }

  async getCollectionsIndexes (options?: FilterArguments<CollectionIndexStatsFilters>): Promise<MongoDBAnalyticsCollectionsIndexesResponse> {
    options = options || {
      fromDate: this.currentTime.time(),
      filter: this.filterValue,
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sortBy: this.currentSortBy,
      sortDirection: this.isSortReversed ? 1 : -1,
    }
    this.fillOptions(options)
    if (this.currentIndexes?.length) {
      options.indexes = [...new Set(this.currentIndexes)].sort()
    }
    // when requesting more 7 days or more
    if (this.currentSchemaIndexes != 'all') {
      options.schemaIndex = this.currentSchemaIndexes as CollectionIndexStatsFilters['schemaIndex']
    }
    if (this.filterInactiveIndexes) {
      options.filterInactive = this.filterInactiveIndexes
    }
    return this._api.getCollectionsIndexes(options)
  }

  private fillOptions (options: FilterArguments<CollectionIndexStatsFilters>) {
    if (this.currentGranularity == 'auto') {
      if (this.timeFilter.indexOf(this.currentTime) >= 5) {
        options.granularity = 'day'
      } else {
        options.granularity = 'hour'
      }
    } else {
      options.granularity = this.currentGranularity as CollectionIndexStatsFilters['granularity']
    }

    if (this.currentClusters?.length) {
      options.clusters = [...new Set(this.currentClusters)].sort()
    }

    if (this.currentCols?.length) {
      options.cols = [...new Set(this.currentCols)].sort()
    }

    if (this.currentDBs?.length) {
      options.dbs = [...new Set(this.currentDBs)].sort()
    }

  }

  private clearState () {
    this.availableCols = []
    this.availableClusters = []
    this.availableIndexes = []
    this.availableDBs = []

    //collection total data
    this.totalDocuments = 0
    this.totalStorageSize = 0
    this.totalIndexesSize = 0
    this.totalSize = 0
    this.totalCacheSize = 0

    //indexes total data
    this.totalIndexesIndexesSize = 0
    this.totalIndexesCacheSize = 0
  }

  secondsToDays (seconds: number): number {
    return seconds / 60 / 60 / 24
  }

}

