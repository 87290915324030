import { AfterContentInit, Component, EventEmitter, Input, Output } from '@angular/core'
import { JIRA_TICKET_PREFIX } from '../../../services/data/data.service'
import { MethodsService } from '../../../services/methods/methods.service'

@Component({
  selector: 'pi-table-jira',
  templateUrl: './pi-table-jira.component.html',
})

export class PITableJiraComponent<T extends any = any> implements AfterContentInit {
  @Input() public name: string
  @Input() public item: T
  @Input() public pages: string[]
  @Input() public commitUrl: string
  @Input() public commitMessage: string

  @Output() public removePage: EventEmitter<TableJiraEmitValue<T>> = new EventEmitter<TableJiraEmitValue<T>>() //fires an event when clicked
  @Output() public addPage: EventEmitter<TableJiraEmitValue<T>> = new EventEmitter<TableJiraEmitValue<T>>() //fires an event when clicked

  JIRA_TICKET_PREFIX = JIRA_TICKET_PREFIX

  ngAfterContentInit () {
  }

  async _addPage () {
    const page = await AddPageQuery()
    if (!page) {
      return
    }

    this.addPage.emit({
      item: this.item,
      page: page.input
    })
  }

  async _removePage (page: string) {
    const confirm = await DeletePageQuery(page, this.name)

    if (!confirm) {
      return
    }

    this.removePage.emit({
      item: this.item,
      page
    })
  }

}

export async function AddPageQuery () {
  return await MethodsService.prompt(
    'Enter Page Number',
    'PAGEs numbers separated by commas',
    '2154,7741,15',
    { title: 'Bad Format', message: 'Page number only' },
    'Add',
    (str: string) => str.split(',').every(x => /\d+/.test(x) && !isNaN(parseInt(x)) && parseInt(x) > 0)
  )
}

export async function DeletePageQuery (page: string, name: string): Promise<Nullable<boolean>> {
  return await MethodsService.confirm(
    `Delete Page Confirmation`,
    `Remove <b>PAGE-${page}</b> from <b>${name}</b> ?`,
    `Remove`
  )
}
