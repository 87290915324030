import { UserDataService } from '../../services/user-data/user-data.service'
import { GlobalNotification, GlobalState } from '../../app.state'

export abstract class ApiService {
  private static AUTH_SERVER: string

  protected constructor (protected _state: GlobalState, protected _user: UserDataService) {
    ApiService.AUTH_SERVER = API_ENDPOINT + (API_ENDPOINT.endsWith('/') ? '' : '/')
  }

  private shouldRevokeSession (data: Expected<{ revoke_session: null }>): boolean {
    return data && typeof data === 'object' && 'revoke_session' in data
  }

  private async _fetch (url, method, body?: string, requestWithAuthToken?: string | boolean, applicationType?: string, responseType: 'json' | 'arraybuffer' = 'json'): Promise<CxApiResponse> {
    const requestUrl = url
    const requestOptions: RequestInit & { headers: Obj<string> } = {
      method,
      headers: { 'Content-Type': applicationType || 'application/x-www-form-urlencoded' },
      credentials: 'include'
    }

    if (body) {
      requestOptions.body = body
    }

    // if (requestWithAuthToken) {
    //   requestOptions.headers.authorization = `Bearer ${typeof requestWithAuthToken === 'string' ? requestWithAuthToken : this._user.token.token}`
    // }

    const response = await fetch(requestUrl, requestOptions)

    if (response.status == 204) {
      const locationHeader = response.headers.get('Location')
      if (locationHeader) {
        location.href = locationHeader
        return
      }
    }

    if (responseType == 'arraybuffer') {
      return {
        success: response.status < 300,
        headers: response.headers,
        data: response.status < 300 ? await response.arrayBuffer() : await response.text(),
        status: response.status
      }
    } else {
      const json = await response.json()
      if (this.shouldRevokeSession(json)) {
        this._state.notify(GlobalNotification.SIGN_OUT, null)
        throw Error()
      }
      return {
        ...json,
        headers: response.headers,
        status: response.status
      }
    }
  }

  protected async get<T = undefined> (path: string, requestWithAuthToken: string | boolean = true, responseType: 'json' | 'arraybuffer' = 'json'): Promise<ResultObject<T> | CountedResultObject<T>> {
    return await this._fetch(ApiService.AUTH_SERVER + path, 'GET', null, requestWithAuthToken, undefined, responseType)
  }

  protected async post<T = undefined> (path, body?: string, requestWithAuthToken: string | boolean = true, contentType?: string): Promise<ResultObject<T> | CountedResultObject<T>> {
    return await this._fetch(ApiService.AUTH_SERVER + path, 'POST', body, requestWithAuthToken, contentType)
  }

  protected async delete<T = undefined> (path, body?: string, requestWithAuthToken: string | boolean = true): Promise<ResultObject<T> | CountedResultObject<T>> {
    return await this._fetch(ApiService.AUTH_SERVER + path, 'DELETE', body, requestWithAuthToken)
  }

}
