import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { CustomerConfigProcessorService } from '../processors/customer-config-processor.service'
import { GlobalState } from '../../../app.state'

@Injectable()
export class CustomerConfigService extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async getCustomersConfigs (options: FilterArguments): Promise<IfDefined<ResultObject<CustomerConfigsResponse>>> {
    const queryString = MethodsService.objectToQueryString(options)
    const response = await this.get(`api/admin_api/customer_configs?${queryString}`) as ResultObject<CustomerConfigsResponse>
    return response.success ? {
      success: response.success,
      data: {
        staging: {
          count: response.data.staging.count,
          data: CustomerConfigProcessorService.process(response.data.staging.data) as CustomerWithConfig[]
        },
        production: {
          count: response.data.production.count,
          data: CustomerConfigProcessorService.process(response.data.production.data) as CustomerWithConfig[]
        },
      }
    } : undefined
  }

  async getCustomer (customerId: string): Promise<{ staging: CustomerWithConfig, production: CustomerWithConfig }> {
    const response = await this.get(`api/admin_api/customer_config/${customerId}`) as ResultObject<{ staging: CustomerWithConfig, production: CustomerWithConfig }>
    return {
      staging: CustomerConfigProcessorService.process(response.data.staging) as CustomerWithConfig,
      production: CustomerConfigProcessorService.process(response.data.production) as CustomerWithConfig,
    }
  }

  async getCustomerConfigWithoutSensitiveAreas (): Promise<CustomerConfigsWithoutSAResponse> {
    const response = await this.get(`api/admin_api/customer_config_without_sa`) as CustomerConfigsWithoutSAResponse
    response.count = response.data && response.data.length || 0
    response.data.forEach(record => record.updatedAt = MethodsService.cxDateFormat(record.updatedAt).dts)
    return response
  }

  async setCustomerConfigFromStaging (customerId: string): Promise<boolean> {
    const response: ResultObject = await this.post(`api/admin_api/customer_config/set_prod/${customerId}`, null, true, 'application/json')
    return response.success
  }

  async modifyStagingConfig (customerId: string, config: EditableCustomer): Promise<boolean> {
    const response: ResultObject = await this.post(`api/admin_api/customer_config/modify/${customerId}`, JSON.stringify(config), true, 'application/json')
    return response.success
  }

  async getDefaults (): Promise<Record<keyof EditableCustomer, any>> {
    return (
      await this.get(`api/admin_api/customer_config/defaults`) as ResultObject<Record<keyof EditableCustomer, any>>
    ).data
  }

  async getClusterConfigData (): Promise<SpaAndAhResult[]> {
    return (
      await this.get(`api/admin_api/customer_config/tenants_settings`) as ResultObject<any>
    ).data
  }

}

