import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { MethodsService } from '../../services/methods/methods.service'
import { GlobalNotification, GlobalState } from '../../app.state'
import { PiCustomerPoliciesService } from '../../services/api/services/pi-customer-policies.service'

@Component({
  selector: '',
  templateUrl: './pi-customer-policies.component.html',
  styleUrls: ['./pi-customer-policies.component.scss']
})

export class PiCustomerPoliciesComponent<T extends ExtendedPolicySchema> extends TableWithFilters implements OnInit, OnDestroy {

  readonly title = 'PI Tenant Policies'

  paginationName = 'Policies'

  tableHeaders: TableHeader[]

  //filters
  filterValue: string = ''
  itemsPerPageFilter = [10, 20, 50, 100, 200, 500, 1000]
  currentItemsPerPage = this.itemsPerPageFilter[1]

  activePolicies: string[] = ['all', 'active-only', 'non-active']
  currentActivePolicies = this.activePolicies[0]
  //table
  data: T[]
  selectedPolicy?: PolicySchema
  selectedJSONData?: JsonStringified<PolicySchema>

  currentSortBy: string

  _isRefreshing: boolean = false

  Max = Math.max
  Min = Math.min
  Ceil = Math.ceil
  log = console.log

  constructor (private _api: PiCustomerPoliciesService, private _zone: NgZone, private _state: GlobalState) {
    super()
    this.tableHeaders = [
      //@formatter:off
      { /*width: '12',*/  name: 'Updated',              sortable: true,     field: 'updatedAt'          },
      { /*width: '12',*/  name: 'Tenant ID',            sortable: true,     field: 'customerId'         },
      { /*width: '12',*/  name: 'Name',                 sortable: true,     field: 'name'               },
      { /*width: '12',*/  name: 'Account ID',           sortable: true,     field: 'akamaiAccountId'    },
      { /*width: '12',*/  name: 'Account Name',         sortable: true,     field: 'akamaiAccountName'  },
      { /*width: '12',*/  name: 'Policies',             sortable: true,     field: 'numOfPolicies'      },
      { /*width: '12',*/  name: 'Actions',              sortable: false,                                },
      //@formatter:on
    ].filter(x => !!x)

    this.currentSortBy = this.tableHeaders[0].field
  }

  ngOnDestroy (): void {
    window.removeEventListener('keyup', this.registerModalListeners.bind(this))
  }

  ngOnInit (): void {
    window.addEventListener('keyup', this.registerModalListeners.bind(this))
    this.refreshTable!()
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    if (!this.selectedPolicy) {
      return
    }
    if (key === 'Escape') {
      this.resetSelected()
    }
  }

  selectPolicy (policy: PolicySchema): void {
    this.selectedPolicy = policy
    const _policy = JSON.parse(JSON.stringify(policy))
    Object.keys(_policy)
      .filter(key => key.startsWith('_'))
      .forEach(key => delete _policy[key])
    this.selectedJSONData = JSON.stringify(_policy, null, 2)
    this.ref_hljs(this._zone)
  }

  resetSelected () {
    this.selectedPolicy = undefined
    this.selectedJSONData = undefined
  }

  setPage (c: HTMLTableRowElement) {
    const _c = c as any
    if (typeof _c.page != 'number') {
      _c.page = 1
    }
  }

  async refreshTable (background: boolean = false): Promise<void> {
    if (this._isRefreshing) {
      return
    }
    this._isRefreshing = true

    try {
      if (!background) {
        SpinnerService.spin('mini')
      }

      const options: AllPoliciesFilterArguments = {
        filter: this.filterValue,
        limit: this.currentItemsPerPage,
        page: this.currentPage - 1,
        sortBy: this.currentSortBy,
        sortDirection: this.isSortReversed ? 1 : -1,
      }

      if (this.currentActivePolicies !== 'all') {
        options.active = this.currentActivePolicies === 'active-only'
      }

      const { data, count } = await this._api.getCustomerPolicies(options)

      this.data = data as T[]
      this.numOfAvailableDocs = count

    } catch (e) {
      if (!background) {
        MethodsService.toast('error', 'Error fetching policies', e.toString(), 8)
      }
      console.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._zone.run(() => {})
      this._isRefreshing = false
    }
  }

}

