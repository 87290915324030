import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { PITenantsService } from '../../services/api/services/pi-tenants.service'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { LoggerService } from '../../services/logger/logger.service'
import { MethodsService } from '../../services/methods/methods.service'
import { PIInstancesService } from '../../services/api/services/pi-instances.service'
import { UserDataService } from '../../services/user-data/user-data.service'
import { USER_POWER_LEVEL } from '../../shared/user-power'
import { GlobalNotification, GlobalState } from '../../app.state'
import { CLUSTER_STATUSES } from '../../services/data/data.service'
import {
  ENV_DEPLOY_FULL_TO_DEPLOY_RESOLVER,
  ENV_DEPLOY_TO_DEPLOY_FULL_RESOLVER,
  ENV_SHORT_TO_DEPLOY_FULL_RESOLVER,
  ShortEnvToDeploymentEnv,
} from '../../services/api/processors/pi-instances-processor.service'
import {
  PiDeploymentsGroupsProcessorService
} from '../../services/api/processors/pi-deployment-groups-processor.service'
import { PiDeploymentGroupsService } from '../../services/api/services/pi-deployment-groups.service'
import { PIDeploymentImagesService } from '../../services/api/services/pi-deployment-images.service'
import { PiDeploymentReleasesService } from '../../services/api/services/pi-deployment-releases.service'
import { PIDeploymentImagesProcessor } from '../../services/api/processors/deployment-images-processor.service'
import {
  PiDeploymentsReleasesProcessorService
} from '../../services/api/processors/pi-deployment-releases-processor.service'

@Component({
  selector: 'pi-deployments-group',
  templateUrl: './pi-depyloyment-groups.component.html',
  styleUrls: ['./pi-depyloyment-groups.component.scss']
})
export class PIDeploymentGroupsComponent<T extends ExtendedDeploymentGroupsSchema = ExtendedDeploymentGroupsSchema> extends TableWithFilters implements OnInit, OnDestroy {

  readonly title = 'PI Deployment Groups'
  readonly paginationName = 'Groups'
  readonly isAdmin: boolean = false

  filterValue: string = ''

  CLUSTER_STATUSES = CLUSTER_STATUSES
  ENV_DEPLOY_TO_DEPLOY_FULL_RESOLVER = ENV_DEPLOY_TO_DEPLOY_FULL_RESOLVER
  groups: T[] = []
  groupsConfiguration: DeploymentGroupsConfigObject
  availableVersions: DockerImagesAvailableVersions
  inactiveImages: InactiveImagesSets
  allReleases: ReleasesByImageEnv
  releasesMapByImageHash: Map<string, ExtendedDeploymentReleasesSchema>

  //state
  currentEligibleReleases: ExtendedDeploymentReleasesSchema[] = []
  currentClustersRelease: ExtendedDeploymentReleasesSchema

  //modals
  isCreateGroupActive: boolean = false
  selectedGroup: T

  availableClusters: CustomerMappingSchema[] = []
  associatedClusters: CustomerMappingSchema[] = []
  unAssociatedClusters: CustomerMappingSchema[] = []
  currentUnAssociatedClusters: CustomerMappingSchema[] = []

  //table
  readonly tableHeaders: TableHeader[]
  currentSortBy: string
  _isRefreshing: boolean = false

  //environments filter
  availableDeploymentEnvironments: string[] = []
  currentDeploymentEnvironments = this.availableDeploymentEnvironments

  //operational filter
  availableOperationalEnvironments: string[] = []
  currentOperationalEnvironments = this.availableOperationalEnvironments

  textToClip = MethodsService.copyToClipboard

  normalizeString = MethodsService.normalizeString

  upperCasedString = MethodsService.upperCasedString

  private _debounce: void | number
  private debounce_timeout: number = 1000

  constructor (private _userData: UserDataService, private _clusters: PIInstancesService, private _groups: PiDeploymentGroupsService, private _images: PIDeploymentImagesService, private _releases: PiDeploymentReleasesService, private _customers: PITenantsService, private _zone: NgZone, private _state: GlobalState) {
    super()
    this.currentItemsPerPage = this.itemsPerPageFilter[1]
    this.tableHeaders = [
      //@formatter:off
      USER_POWER_LEVEL[this._userData.permission] >= USER_POWER_LEVEL[UserPermission.ADMIN] ? { /*width: '4', */  name: '',                   sortable: false,    field: ''                   } : undefined,
      { /*width: '12',*/  name: 'Created',              sortable: true,     field: 'createdAt'          },
      { /*width: '12',*/  name: 'Updated',              sortable: true,     field: 'updatedAt'          },
      { /*width: '12',*/  name: 'Group ID',             sortable: true,     field: '_id'                },
      { /*width: '12',*/  name: 'Group Name',           sortable: true,     field: 'group'              },
      { /*width: '12',*/  name: 'Environment',          sortable: true,     field: 'deploymentEnvironment'},
      { /*width: '12',*/  name: 'Images Environment',   sortable: true,     field: 'imageEnvironment'   },
      { /*width: '12',*/  name: 'Clusters',             sortable: true,     field: 'numOfClusters'      },
      { /*width: '12',*/  name: 'Release',              sortable: false,                       },
      { /*width: '12',*/  name: 'Actions',              sortable: false                                 },
      //@formatter:on
    ].filter(x => !!x)

    this.currentSortBy = this.tableHeaders[2].field
    this.isAdmin = USER_POWER_LEVEL[this._userData.permission] >= USER_POWER_LEVEL[UserPermission.ADMIN]
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    if (!this.selectedGroup && !this.isCreateGroupActive) {
      return
    }
    if (key === 'Escape') {
      this.clearSelections()
    }
  }

  ngOnDestroy (): void {
    window.removeEventListener('keyup', this.registerModalListeners.bind(this))
  }

  onFilterChange (item: any) {
    if (this._debounce) {
      this._debounce = clearTimeout(this._debounce)
    }
    this._debounce = setTimeout(() => this.refreshTable(), this.debounce_timeout) as any as number
  }

  ngOnInit (): void {
    window.addEventListener('keyup', this.registerModalListeners.bind(this))
    this.refreshTable!()
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  updateUnassociatedClusters (deploymentEnvironment: PiDeploymentEnvironment, imageEnvironment: PiOperationalEnvironment) {
    this.setEligibleRelease(deploymentEnvironment, imageEnvironment)
    if (this.selectedGroup) {
      this.availableClusters = [...this.unAssociatedClusters, ...this.selectedGroup.clusters]
        .filter(cluster =>
          imageEnvironment == cluster.imageEnvironment &&
          deploymentEnvironment == ShortEnvToDeploymentEnv(cluster.environment, cluster.isCanary))
    } else {
      this.currentUnAssociatedClusters = [...this.unAssociatedClusters]
        .filter(cluster =>
          imageEnvironment == cluster.imageEnvironment &&
          deploymentEnvironment == ShortEnvToDeploymentEnv(cluster.environment, cluster.isCanary))
    }

  }

  selectGroup (group: T) {
    this.associatedClusters = [...group.clusters]
    const scope = this.allReleases[group.imageEnvironment]

    const current_ids = group.clusters.map(c => c._id)
    const clustersReleases = scope.filter(release => release.clusters.find(c => current_ids.includes(c._id)))

    this.currentClustersRelease = clustersReleases.length == 1 ? clustersReleases[0] : undefined

    this.selectedGroup = group
    this.updateUnassociatedClusters(group.deploymentEnvironment, group.imageEnvironment)
  }

  openCreateGroupModal () {
    this.setEligibleRelease()
    this.updateUnassociatedClusters(this.groupsConfiguration.deployEnvironments[0], this.groupsConfiguration.imageEnvironments[0])
    this.isCreateGroupActive = true
  }

  setEligibleRelease (deploymentEnvironment: PiDeploymentEnvironment = this.groupsConfiguration.deployEnvironments[0], imageEnvironment: PiOperationalEnvironment = this.groupsConfiguration.imageEnvironments[0]) {
    const scope = this.allReleases[imageEnvironment]
    this.currentEligibleReleases = PiDeploymentsReleasesProcessorService.getEligibleReleases(scope, deploymentEnvironment)
  }

  getReleaseText (release_id: string): string {
    //@ts-ignore
    return this.currentEligibleReleases.find(r => r._id == release_id)?._imagesText
  }

  async deleteGroups () {
    const title = `Delete Confirmation`
    const content = `Delete ${this.selectedTableItems.size} Group${this.selectedTableItems.size > 1 ? 's' : ''} ?`
    const deleteConfirmationText = 'Delete'
    if (await MethodsService.confirm(title, content, deleteConfirmationText)) {

      SpinnerService.spin('mini')

      try {
        const success = await this._groups.deleteGroups([...this.selectedTableItems])
        if (success) {
          this.selectedTableItems.clear()
          await this.refreshTable()
        }
      } catch (e) {
        LoggerService.error(e)
      }
      SpinnerService.stop('mini')
    }
  }

  async createNewGroup (group: string, deploymentEnvironment: PiDeploymentEnvironment, imageEnvironment: PiOperationalEnvironment, deployRelease?: string) {
    if (!this.testGroupName(group)) {
      return
    }

    const clusters = this.associatedClusters.map(cluster => cluster._id)
    const newGroupData: NewDeploymentGroupOptions = { group, deploymentEnvironment, imageEnvironment, clusters }

    if (deployRelease) {
      if (this.currentEligibleReleases.find(r => r._id == deployRelease && (r as any)._active === false)) {
        return MethodsService.dialog('Error', `Cannot apply disabled release!`)
      }
      newGroupData.release = deployRelease
    }

    try {
      SpinnerService.spin('mini')
      const newGroup = await this._groups.createNewGroup(newGroupData)
      if (newGroup) {
        await this.refreshTable()
      }
    } catch (e) {
      MethodsService.toast('error', `Error creating new group ${group}`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }

  }

  async updateGroup (group: string, deployRelease?: string) {
    if (!this.testGroupName(group)) {
      return
    }

    const clusters = this.associatedClusters.map(cluster => cluster._id)
    const updateGroupOptions: UpdateDeploymentGroupOptions = {
      group,
      clusters,
      updatedAt: new Date(this.selectedGroup.updatedAt).getTime()
    }
    if (deployRelease) {
      if (this.currentEligibleReleases.find(r => r._id == deployRelease && (r as any)._active === false)) {
        return MethodsService.dialog('Error', `Cannot apply disabled release!`)
      }
      updateGroupOptions.release = deployRelease
    }

    try {
      SpinnerService.spin('mini')
      const updatedGroup = await this._groups.updateGroup(this.selectedGroup._id, updateGroupOptions)
      if (updatedGroup) {
        await this.refreshTable(false, true)
      }
    } catch (e) {
      MethodsService.toast('error', `Error editing group ${this.selectedGroup._id}`, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }

  }

  async refreshTable (background: boolean = false, ignoreSelected: boolean = false): Promise<void> {
    if (this._isRefreshing || (!ignoreSelected && (this.selectedGroup || this.isCreateGroupActive))) {
      return
    }
    this._isRefreshing = true
    try {
      if (!background) {
        this.groups = []
        this.clearSelections()
        SpinnerService.spin('mini')
      }
      const [
        { data, count },
        versions,
        releases,
        groupsConfiguration,
        { deploymentEnvironments, operationalEnvironments },
        uniqueData
      ] = await Promise.all([
        this.getGroups(),
        this._images.getAvailableVersions(),
        this._releases.getAllDeploymentReleases(),
        this._groups.getConfig(),
        this._clusters.getDRSFilteringOptions(),
        this._clusters.getUniqueData()
      ].filter(x => !!x) as PromiseLike<any>[])

      this.numOfAvailableDocs = count
      this.groups = data.map(group => PiDeploymentsGroupsProcessorService.enrichGroup(uniqueData, group))

      this.availableVersions = versions
      this.inactiveImages = PIDeploymentImagesProcessor.getInactiveImagesSet(versions)

      this.allReleases = releases
      this.releasesMapByImageHash = PIDeploymentImagesProcessor.getImagesHashMap(releases)

      this.groupsConfiguration = groupsConfiguration
      this.unAssociatedClusters = groupsConfiguration.unassignedClusters

      this.availableDeploymentEnvironments = deploymentEnvironments.map(s => ENV_SHORT_TO_DEPLOY_FULL_RESOLVER[s]).sort().filter(Boolean)
      if (this.availableDeploymentEnvironments.includes(PiDeploymentEnvironmentFull.PRODUCTION)) {
        this.availableDeploymentEnvironments.push(PiDeploymentEnvironmentFull.CANARY)
      }
      this.availableOperationalEnvironments = operationalEnvironments.map(s => MethodsService.normalizeString(s))
    } catch (e) {
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._zone.run(() => {})
      this._isRefreshing = false
    }

  }

  private clearSelections () {
    this.selectedGroup = undefined
    this.currentClustersRelease = undefined
    this.isCreateGroupActive = false
    this.associatedClusters = []
    this.availableClusters = []
    this.currentUnAssociatedClusters = []
    // this.unAssociatedClusters = []
  }

  private async getGroups (options?: FilterArguments) {
    options = options || {
      filter: this.filterValue,
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sort_by: this.currentSortBy,
      sort_direction: this.isSortReversed ? 'asc' : 'desc'
    }

    if (this.currentDeploymentEnvironments?.length) {
      options.deployment_envs = this.currentDeploymentEnvironments.map(s => ENV_DEPLOY_FULL_TO_DEPLOY_RESOLVER[s])
    }

    if (this.currentOperationalEnvironments?.length) {
      options.operational_envs = this.currentOperationalEnvironments.map(s => s.toUpperCase())
    }

    return await this._groups.getAllGroups(options)
  }

  private testGroupName (group: string): boolean {
    const { pattern, flags } = this.groupsConfiguration.name
    const name_regex = new RegExp(pattern, flags)
    if (!name_regex.test(group)) {
      MethodsService.dialog(`Bad Arguments`, `Invalid group format! (regex: /${name_regex.toString()})`)
      return false
    }
    return true
  }

}
