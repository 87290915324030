import { Component, Input, OnInit } from '@angular/core'
import { DataService } from '../../../services/data/data.service'
import { MethodsService } from '../../../services/methods/methods.service'

@Component({
  selector: 'cx-line-chart',
  templateUrl: './cx-line-chart.component.html',
  styleUrls: ['./cx-line-chart.component.scss']
})

export class CxLineChartComponent implements OnInit {

  public readonly lineChartType: Chart.ChartType = 'line'
  public readonly lineChartColors: Chart.ChartArcOptions[]

  @Input() public lineChartLabels: string[]
  @Input() public lineChartData: number[]
  @Input() public lineChartDataSets: {data: number[], label: string}[]
  @Input() public lineChartLegend: boolean
  @Input() public lineChartLegendPosition?: Chart.PositionType

  @Input() lineChartOptions: Chart.ChartOptions = {
    responsive: true,
    cutoutPercentage: 65,
    legend: {
      labels: {
        boxWidth: 10,
        fontFamily: '\'Open Sans\', sans-serif',
        usePointStyle: true
      },
      position: 'top'
    }
  }

  constructor () {
    this.lineChartColors = [
      {
        backgroundColor: [...DataService.chartsColors.pie_colors_dark.map(color => MethodsService.hexToRGBA(color))]
      }
    ]
  }

  ngOnInit () {
    console.log(this.lineChartColors)
    this.lineChartOptions.legend.position = this.lineChartLegendPosition || this.lineChartOptions.legend.position
  }
}