import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { PiInstancesProcessorService } from '../processors/pi-instances-processor.service'
import { GlobalState } from '../../../app.state'

@Injectable()
export class PIInstancesService extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async testTFInstance (cluster_id: string): Promise<boolean> {
    const response = await this.post(`api/admin_api/cluster/test/${cluster_id}`, null, true, 'application/json')
    return response.success
  }

  async updateTFInstance (cluster_id: string, stage: string): Promise<boolean> {
    const response = await this.post(`api/admin_api/cluster/update/${cluster_id}/${stage}`, null, true, 'application/json')
    return response.success
  }

  async deleteClusters (clustersIds: string[]): Promise<boolean> {
    const body = JSON.stringify({ clustersIds })
    const response = await this.post(`api/admin_api/delete_clusters`, body, true, 'application/json')
    return response.success
  }

  async getCluster (clusterId: string): Promise<CustomerMappingSchema> {
    const response = await this.get(`api/admin_api/cluster/${clusterId}`) as ResultObject<CustomerMappingSchema>
    return PiInstancesProcessorService.process(response.data) as CustomerMappingSchema
  }

  async getMappedClusters (): Promise<MappedClusters> {
    const { data } = await this.getAllClusters({
      limit: Number.MAX_SAFE_INTEGER
    })

    return data
      .filter(i => i.imageEnvironment == PiOperationalEnvironment.CLUSTER)
      .map(({ clusterName, clusterId, status, isFreeTier }) => ({
        name: clusterName, id: clusterId, status, isFreeTier
      }))
  }

  async getAllClusters (options: FilterArguments): Promise<IfDefined<CountedResultObject<CustomerMappingSchema[]>>> {
    const queryString = MethodsService.objectToQueryString(options)
    const response = await this.get(`api/admin_api/clusters?${queryString}`)
    return response.success && {
      success: response.success,
      count: response.count,
      data: PiInstancesProcessorService.process(response.data) as CustomerMappingSchema[]
    }
  }

  async getUniqueData (): Promise<ProcessedPIOperationsEnvironments[]> {
    return PiInstancesProcessorService.processUniqueData((
      await this.get(`api/admin_api/clusters/get_config`) as ResultObject<PIOperationsDataRequest>
    ).data)
  }

  async updateCluster (cluster: CustomerMappingSchema): Promise<boolean> {
    const body = JSON.stringify(cluster)
    const response: ResultObject = await this.post(`api/admin_api/update_cluster`, body, true, 'application/json')
    return response.success
  }

  async createNewCluster (cluster: StandardObject): Promise<boolean> {
    const body = JSON.stringify(cluster)
    const response: ResultObject = await this.post(`api/admin_api/create_cluster`, body, true, 'application/json')
    return response.success
  }

  async setDefaultAssignedCluster (clusterId: string, isFreeTier: boolean = false): Promise<boolean> {
    const body = JSON.stringify({ clusterId, isFreeTier })
    const response: ResultObject = await this.post(`api/admin_api/clusters/default_assigned_cluster`, body, true, 'application/json')
    return response.success
  }

  async getDRSFilteringOptions (): Promise<PiDeploymentsMetadata> {
    const response = await this.get(`api/admin_api/drs_clusters/filtering_options`) as ResultObject<PiDeploymentsMetadata>
    return response.data
  }

  async updateDRSCluster (_id: string, options: UpdateDRSClusterOptions): Promise<CustomerMappingSchema<PiOperationalEnvironment, false>> {
    const body = JSON.stringify({ _id, ...options })
    const response = await this.post(`api/admin_api/drs_clusters/update`, body, true, 'application/json') as ResultObject<CustomerMappingSchema<PiOperationalEnvironment, false>>
    if (response.code > 200) {
      throw Error(response.message)
    }
    return response.data
  }

}

